import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "pages/Monitors/monitors.scss";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import SnackBar from "components/snackBar";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import PopupModal from "components/popupModal";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import { useNavigate, useLocation } from "react-router-dom";

/*---------------------------------------------------------*/
const CommentsMentionsBody = (props) => {
  const {
    response,
    preLoaderTrackerDataSources,
    resetAllFields,
    changePagination,
    setTotalPage,
    totalPage,
  } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );

  const [page, setPage] = useState(1);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    changePagination(page);
  };

  // Refresh all values at Each request to default values
  const handleResponseFunc = async () => {
    if (
      response.eventName === "Twitter__CommentsMentionsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      if (props.pageNumber == 1) {
        setTotalPage(response.eventData.top_posts_count);
        setPage(1);
      }
      setCommentsMentionsPreloader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      // reset s param
      navigate(window.location.pathname);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(commentsMentionsPreloader);

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(commentsMentions);
  }, [commentsMentionsPreloader, commentsMentions]);

  //handle response with useEffect (when load page & any update)
  useEffect(() => {
    if (resetAllFields !== 1) {
      setCommentsMentions([]);
      setCommentsMentionsPreloader([]);
    }
  }, [resetAllFields]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      commentsMentionsPreloader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([commentsMentions]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [
    commentsMentions,
    commentsMentionsPreloader,
    props.preLoaderTrackerDataSources,
  ]);

  // ___________________________________________________________________________________________________________________
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
    openSnack,
    handleHideSnackBar,
  } = CommonFunctions();

  useEffect(() => {
    props?.setTotalPostsCount?.(totalPage);
  }, [totalPage]);
  const showUserSnackbar = location?.state?.showUserSnackbar;

  return (
    <>
      <Box>
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={commentsMentions}
          unAnsweredQuestion={totalPage}
          commentsHeader
          ShowPagination
          changePagination={changePagination}
          latestUnanswerPreloader={commentsMentionsPreloader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          icon={xPlatform}
          iconColor={"twitter_icon"}
          showRetweet={true}
          showBanIcon={true}
          showScreenName={true}
          updateAnalysis={updateAnalysis}
          showHeart={true}
          noOfChar={15}
          singleTweetAnalysis={props.singleTweetAnalysis}
          hideWhenRetweeted
          showDefaultImg
          getFiltersFileds={props?.getFiltersFileds}
        />
        {totalPage !== 0 && totalPage > 10 && commentsMentions?.length > 0 && (
          <CustomPagination
            onChange={handleChangePage}
            page={page}
            pagesCount={Math.ceil(totalPage / 10)}
            itemsCount={totalPage}
          />
        )}
        {showUserSnackbar ? (
          <SnackBar
            open={openSnack}
            handleClose={handleHideSnackBar}
            message={CheckValueLocale(
              "page_displays_data_based_on_tooltip",
              "",
              {},
              intl,
            )}
            severity={"info"}
          />
        ) : null}

        {showAnalyzeTweetSnackBar && (
          <SnackBar
            open={true}
            handleClose={handleCloseSnackBar}
            title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
            message={CheckValueLocale(
              analyzeTweetSnackBar.message,
              "",
              {},
              intl,
            )}
            severity={analyzeTweetSnackBar.severity}
          />
        )}
        <PopupModal
          title={stats?.modalTitle}
          body={modalBody}
          open={stats?.openCreateModal}
          close={handleCloseCreateModal}
          warning="warning"
          classeName="create-historical-popup"
        />
      </Box>
    </>
  );
};

export default CommentsMentionsBody;
