import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData, handleSortedCase } from "../excelCommonFn";

const engagements_tw_AA = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var commentsVolume = [],
      commentsInteractions = [],
      sentimentAnalysis = [],
      contentStyles = [],
      associatedTopics = [],
      topLanguages = [],
      dialects = [],
      subDialects = [],
      topCountries = [],
      topCities = [],
      genderDistribution = [],
      accountTypes = [],
      topEngagers = [],
      topSources = [],
      topVerifiedEngagers = [],
      topInfleuncers = [],
      commentsMentions = [],
      topKeywords = [],
      topHashtags = [],
      topImages = [],
      topVideos = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "commentsVolume":
            value?.map((i) => {
              commentsVolume?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value,
              });
            });
            allSheetData?.push([commentsVolume, lang?.comments_volume]);
            break;
          // // _______________________________________________________________________________
          case "accountTypes":
            value?.map((i) => {
              accountTypes?.push({
                [lang.account_types]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([accountTypes, lang?.account_types]);
            break;
          // // _______________________________________________________________________________
          case "topSources":
            value?.map((i) => {
              topSources?.push({
                [lang.top_sources]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topSources, lang?.top_sources]);
            break;
          // // _______________________________________________________________________________
          case "topVerifiedEngagers":
            value?.map((i) => {
              topVerifiedEngagers.push({
                [lang.top_verified_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData?.push([
              topVerifiedEngagers,
              lang?.top_verified_engagers,
            ]);
            break;
          // _______________________________________________________________________________
          case "topEngagers":
            value?.map((i) => {
              topEngagers?.push({
                [lang.top_engagers]: i?.id,
                [lang.users]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData?.push([topEngagers, lang.top_engagers]);
            break;
          // // _______________________________________________________________________________
          case "topInfleuncers":
            value?.map((i) => {
              topInfleuncers?.push({
                [lang.top_infleuncers]: i?.id,
                [lang.user]: i?.name,
                [lang.followers]: i?.followers_count,
                [lang.engagements]: i?.total_engagements,
              });
            });
            allSheetData?.push([topInfleuncers, lang?.top_infleuncers]);
            break;
          // _______________________________________________________________________________
          case "commentsMentions":
            value?.map((i) => {
              commentsMentions?.push({ [lang.links]: i?.tweet_link });
            });
            allSheetData?.push([commentsMentions, lang?.top_comments_mentions]);

            break;
          // //_______________________________________________________________________________

          case "genderDistribution":
            value?.map((i) => {
              genderDistribution.push({
                [lang.gender]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([genderDistribution, lang?.gender_distribution]);
            break;
          //_______________________________________________________________________________

          case "contentStyles":
            value?.map((i) => {
              contentStyles?.push({
                [lang.comments_content_style]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([contentStyles, lang?.comments_content_style]);
            break;

          //_______________________________________________________________________________
          case "topCountries":
            value?.map((i) => {
              topCountries.push({
                [lang.countries]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCountries, lang?.top_countries]);
            break;
          //_______________________________________________________________________________

          case "topCities":
            value?.map((i) => {
              topCities?.push({
                [lang.cities]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCities, lang?.top_cities]);
            break;
          // _______________________________________________________________________________

          case "topLanguages":
            value?.map((i) => {
              topLanguages?.push({
                [lang.language]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topLanguages, lang?.top_languages]);
            break;
          //_______________________________________________________________________________
          case "commentsInteractions":
            value?.map((i) => {
              commentsInteractions?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.likes]: i?.value[1],
                [lang.retweets]: i?.value[2],
                [lang.total]: i?.value[0] + i?.value[1] + i?.value[2],
              });
            });
            allSheetData?.push([
              commentsInteractions,
              lang?.comments_interactions,
            ]);
            break;
          //_______________________________________________________________________________

          case "associatedTopics":
            value?.map((i) => {
              associatedTopics?.push({
                [lang.topic]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([
              associatedTopics,
              lang?.comments_associated_topics,
            ]);
            break;
          // //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value?.neutral[index]?.name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis?.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? ((value?.neutral[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? ((value?.positive[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? ((value?.negative[index]?.value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData?.push([
              sentimentAnalysis,
              lang?.comments_sentiment_analysis,
            ]);
            break;

          // //_______________________________________________________________________________

          case "dialects":
            handleSortedCase(
              value,
              dialects,
              lang?.dialect,
              lang?.main_dialect_filter,
            );
            allSheetData?.push([dialects, lang?.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________

          case "subDialects":
            handleSortedCase(
              value,
              subDialects,
              lang?.sub_dialects,
              lang?.number,
            );
            allSheetData?.push([subDialects, lang?.sub_dialects]);
            break;
          // _______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords?.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topKeywords,
              lang?.comments_top_keywords_xlsx_sheetname,
            ]);
            break;
          // // _______________________________________________________________________________

          case "topHashtags":
            value?.map((i) => {
              topHashtags?.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([
              topHashtags,
              lang?.comments_top_hashtags_xlsx_sheetname,
            ]);
            break;
          // _______________________________________________________________________________

          case "topImages":
            value?.map((i) => {
              topImages?.push({ [lang.image]: i?.id });
            });
            allSheetData?.push([topImages, lang?.comments_top_images]);
            break;
          // _______________________________________________________________________________

          case "topVideos":
            value?.map((i) => {
              topVideos?.push({ [lang.video]: i?.id });
            });
            allSheetData?.push([topVideos, lang?.comments_top_videos]);
            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { engagements_tw_AA };
