import axios from "axios";
import { setCookie, deleteCookie } from "utils/helpers";
import { jwtDecode as jwt_decode } from "jwt-decode";
import store from "utils/redux/store/store";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";

import projectLogoMainHeaderSVGLucidya from "../../images/lucidya-logo-header.svg";
import projectLogoSVGLucidya from "../../images/lucidya-logo.svg";
import projectLogoPNGLucidya from "../../images/lucidyalogo.png";
import loginLogoLucidya from "../../images/login-logo.svg";

import projectLogoMainHeaderSVGFalcon from "../../images/falcon/lucidya-logo-falcon.svg";
import projectLogoSVGFalcon from "../../images/falcon/lucidya-logo-falcon.svg";
import projectLogoPNGFalcon from "../../images/falcon/lucidyalogo-falcon.png";

import companyFaviconPathPNGLucidya from "../../images/logo_favicon_lucidya.png";
import companyFaviconPathSVGFalcon from "../../images/falcon/logo-favicon-falcon.png";

let Services;

let defaultCaseValues = {
  project_name_en: "Lucidya", // English name of the project, used for displaying the project name in English interfaces
  project_name_ar: "لوسيديا", // Arabic name of the project, used for displaying the project name in Arabic interfaces
  ai_product_name_en: "Luci", // English name of the AI product, used for branding the AI product in English
  ai_product_name_ar: "لوسي", // Arabic name of the AI product, used for branding the AI product in Arabic
  companyURL: "https://www.lucidya.com", // URL of the company's website, used for linking to the company's homepage
  productSupportEmail: "support@lucidya.com", // Support email for the product, used for customer support and inquiries
  prouctSurveyURL: "survey.lucidya.com", // URL for the product survey, used for collecting user feedback
  showThirdCompany: true, // Flag to show terms and conditions, used to display the terms and conditions section
  passCheckBox: false, // Flag for password checkbox, used to control the visibility of a certain check box in form fields
  showYouCanAlsoRead: true, // Flag to show "You can also read" section, used to display additional reading materials
  showProjectLogoInLiveDashboard: true, // Flag to show project logo in live dashboard, used to display the project logo in the live dashboard interface
  companyFaviconPathPNG: companyFaviconPathPNGLucidya, // Path to the company's favicon in PNG format, used for setting the favicon in the browser tab
  projectLogoMainHeaderSVG: projectLogoMainHeaderSVGLucidya, // Path to the main header logo in SVG format, used for displaying the logo in the main header
  projectLogoSVG: projectLogoSVGLucidya, // Path to the project logo in SVG format, used for various branding purposes
  projectLogoPNG: projectLogoPNGLucidya, // Path to the project logo in PNG format, used for various branding purposes
  loginLogo: loginLogoLucidya, // Path to the login logo, used for displaying the logo on the login page
  showPoweredBy: true, // Flag to show "Powered by" text, used to display the "Powered by" branding
  showUserPilot: true, // Flag to show UserPilot, used to control the visibility of the UserPilot feature
  enableExtraSources: false, // Flag to enable extra sources, used to control the usage of extra sources in the application
};

let defaultCaseValuesFalcon = {
  project_name_en: "Falcon",
  project_name_ar: "فالكون",
  ai_product_name_en: "Shihana",
  ai_product_name_ar: "شيهانة",
  companyURL: "https://www.falcon-site.com",
  prouctSurveyURL: "survey.falcon-site.com",
  productSupportEmail: "support@falcon-site.com",
  showThirdCompany: false,
  passCheckBox: true,
  showYouCanAlsoRead: false,
  showProjectLogoInLiveDashboard: false,
  companyFaviconPathSVG: companyFaviconPathSVGFalcon,
  companyFaviconPathPNG: "logo-favicon-falcon.png",
  projectLogoMainHeaderSVG: projectLogoMainHeaderSVGFalcon,
  projectLogoSVG: projectLogoSVGFalcon,
  loginLogo: projectLogoSVGFalcon,
  projectLogoPNG: projectLogoPNGFalcon,
  showPoweredBy: false,
  showUserPilot: false,
  enableExtraSources: true,
};

switch (process.env.REACT_APP_ENV) {
  case "production":
    Services = {
      baseRoute: "https://backend2-lb.lucidya.com/",
      nodeRoute: "https://ppt.lucidya.com:443/",
      switchRoute: "https://backend.lucidya.com/",
      switchUrl: "https://sm.lucidya.com/",
      googleTrackingId: "UA-219742565-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://liveservice.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "falcon":
    Services = {
      baseRoute: "https://backend2.falcon-site.com/",
      nodeRoute: "https://ppt.falcon-site.com:443/",
      switchRoute: "https://backend2.falcon-site.com/",
      switchUrl: "https://sm.falcon-site.com/",
      googleTrackingId: "UA-219742565-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard.falcon-site.com",
      wsport: 443,
      socketUrl: "https://liveservice.falcon-site.com/",
      ...defaultCaseValuesFalcon,
    };
    break;
  case "production_local":
    Services = {
      baseRoute: "https://backend2-lb.lucidya.com/",
      nodeRoute: "https://ppt.lucidya.com:443/",
      switchRoute: "https://backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-219742565-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://liveservice.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "staging_survey":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-survey-backend.lucidya.com/`,
      switchRoute: `https://stg-survey-backend.lucidya.com/`,
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "testing":
    Services = {
      baseRoute: "https://testing-backend2.lucidya.com/",
      switchRoute: "https://testing-backend.lucidya.com/",
      switchUrl: "https://sm-testing.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "testing_local":
    Services = {
      baseRoute: "https://testing-backend2.lucidya.com/",
      switchRoute: "https://testing-backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "livedashboard3.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "staging_port":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
  case "staging_port_local":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      enableExtraSources: true,
      ...defaultCaseValues,
    };
    break;
  case "staging":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      enableExtraSources: true,
      ...defaultCaseValues,
    };
    break;
  case "staging_local":
    //STG WITH CERTAIN PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      nodeRoute: "https://stg-ppt.lucidya.com/",
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://localhost:3080/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      enableExtraSources: true,
      ...defaultCaseValues,
    };
    break;
  default:
    //MAIN STG PORT
    Services = {
      baseRoute: `https://stg-backend2.lucidya.com/`,
      switchRoute: "https://stg-backend.lucidya.com/",
      switchUrl: "http://stg-sm.lucidya.com/",
      googleTrackingId: "UA-216005184-1",
      googleTagManager: "GTM-NP9CT8R",
      wsbroker: "stg-streaming.lucidya.com",
      wsport: 443,
      socketUrl: "https://stg-socket.lucidya.com/",
      ...defaultCaseValues,
    };
    break;
}

Services.getData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      }
      if (
        response.statuss === 401 &&
        window.location.href.includes("redirect_url")
      ) {
        window.location.href =
          "/login?redirect_url=https://knowledge.lucidya.com/";
      } else if (response.status === 401) {
        window.location.href = "/login";
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (
        (err.response.status === 401 || err.response.status === 500) &&
        window.location.href.includes("redirect_url")
      ) {
        window.location.href =
          "/login?redirect_url=https://knowledge.lucidya.com/";
      } else if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

Services.getDataBuffer = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    responseType: "arraybuffer",
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        window.location.href = "/login";
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      }
    });
};

Services.postData = (queryURL, queryData, pathData, cancelToken, headers) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "post",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
      ...headers,
    },
  })
    .then((response) => {
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "luc-authorization") {
          window.localStorage.setItem("user_token", value);
          setCookie("email", response?.data?.data?.attributes?.email);
          let decodeAccessToken = jwt_decode(value);
          window.localStorage.setItem(
            "user_roles",
            JSON.stringify(decodeAccessToken.permissions),
          );
          if (decodeAccessToken?.product_feature_permissions) {
            window.localStorage.setItem(
              "advansed_roles",
              JSON.stringify(decodeAccessToken?.product_feature_permissions),
            );
          }
          window.localStorage.setItem(
            "active_features",
            JSON.stringify(decodeAccessToken.active_features),
          );
        }
      }
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        // return <Redirect to="/login" />
        return (window.location.href = "/");
        // return useHistory().replace('/login')
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
        return Promise.resolve(err);
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

Services.deleteData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "DELETE",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

Services.putData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "put",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

Services.patchData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "patch",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
let smRoute = Services.switchRoute + "exchange_token";
// let smRoute = "https://stg-backend-p17.lucidya.com/"+"exchange_token";

Services.getSmData = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = smRoute;
  return axios({
    method: "get",
    url: queryURL,
    params: queryData,
    cancelToken: cancelToken,
    headers: {
      authorization: window.localStorage.user_token,
    },
  })
    .then((response) => {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        return (window.location.href = "./login");
      } else {
        return Promise.reject(response);
      }
    })
    .catch((thrown) => {
      return (window.location.href = "/login");
    })
    .catch((err) => {
      console.log(err);
    });
};
Services.getDataById = (queryURL, queryData, pathData, cancelToken) => {
  var queryURL = pathData !== undefined ? queryURL + "/" + pathData : queryURL;
  return axios({
    method: "get",
    url: Services.baseRoute + queryURL,
    data: queryData,
    cancelToken: cancelToken,
    headers: {
      "luc-authorization": window.localStorage.user_token,
    },
  })
    .then((response) => {
      for (const [key, value] of Object.entries(response.headers)) {
        if (key === "luc-authorization") {
          window.localStorage.setItem("user_token", value);
          setCookie("email", response?.data?.data?.attributes?.email);
          let decodeAccessToken = jwt_decode(value);
          window.localStorage.setItem(
            "user_roles",
            JSON.stringify(decodeAccessToken.permissions),
          );
          window.localStorage.setItem(
            "active_features",
            JSON.stringify(decodeAccessToken.active_features),
          );
        }
      }
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else if (response.status === 401) {
        // return <Redirect to="/login" />
        return (window.location.href = "/");
        // return useHistory().replace('/login')
      } else {
        return Promise.reject(response);
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("loginRedirection", true);
        document.location.pathname !== "/login" &&
          (window.location.href = "/login");
      } else if (err.response.status === 500) {
        localStorage.clear();
        store.dispatch(resetChatMessages());
        deleteCookie("email");
        window.localStorage.setItem("sessionExpired", true);
        window.location.href = "/login";
      } else {
        return Promise.reject(err);
      }
    });
};

// convert arabic number to english number
const arabic2englishNumber = (s) =>
  s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
var serializeJSON = function (data) {
  var queryData = {};

  if (data != undefined) {
    queryData = data;
  }

  return Object.keys(queryData)
    .map(function (keyName) {
      if (keyName === "start_date" || keyName === "end_date") {
        return (
          encodeURIComponent(keyName) +
          "=" +
          encodeURIComponent(
            arabic2englishNumber(queryData[keyName].toString()),
          )
        );
      }
      return (
        encodeURIComponent(keyName) +
        "=" +
        encodeURIComponent(queryData[keyName])
      );
    })
    .join("&");
};

Services.downloadTwitterReport = function (queryUrl, queryData) {
  delete queryData.user_token;
  delete queryData.user_id;
  let downloadLink =
    Services.nodeRoute + queryUrl + "?" + serializeJSON(queryData);
  return fetch(downloadLink, {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("user_token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(function (res) {
      if (res.status >= 200 && res.status < 400) {
        res.blob().then((file) => {
          var file = window.URL.createObjectURL(file);

          // window.location.assign(file);
          window.open(file, "_blank");
        });
        return Promise.resolve({
          success: true,
          status: res.status,
        });
      } else {
        return Promise.reject(res);
      }
    })
    .catch(function (err) {
      var fireErr = { status: 401 };
      if (err.message == "NetworkError when attempting to fetch resource.") {
        throw fireErr;
      } else {
        throw err;
      }
    });
};

Services.nodeGetData = function (queryUrl, queryData) {
  delete queryData.user_token;
  delete queryData.user_id;
  let downloadLink =
    Services.nodeRoute + queryUrl + "?" + serializeJSON(queryData);
  return fetch(downloadLink, {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("user_token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then(function (response) {
      if (response.status >= 200 && response.status < 400) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    })
    .catch(function (err) {
      var fireErr = { status: 401 };
      if (err.message == "NetworkError when attempting to fetch resource.") {
        throw fireErr;
      } else {
        throw err;
      }
    });
};

export default Services;
