import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useIntl } from "react-intl";
import "./tableWidget.scss";
import ItemsList from "./itemsList.js";
import WidgetHeader from "../widgetHeader";
import WordsList from "./wordsList";
import { TableFooter, TablePagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
// import NoDataFound from "components/no-Data/noDataFound";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";
import ChartDetailsWidget from "../customStatisctics/chartDetailsWidget";
import CustomizedTooltip from "components/customizedTooltip";
import WordCloud from "components/echarts/WordCloud";
import { TableWordCloudHiddenView } from "./tableWordCloudHiddenView";

const TableWidget = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const { monitorDataSource, activeTab } = urlParams;
  const [page, setPage] = useState(0);
  const [viewType, setViewType] = useState("table");
  const [dataForChart, setDataForChart] = useState(props.data);

  //handle sorted data
  const handelSortingData = () => {
    if (props?.isSorted && props?.data.length !== 0) {
      props?.data?.sort((a, b) => b.value - a.value);
    }
  };

  // _______________________________________________________
  useEffect(() => {
    handelSortingData();
    getChartMeta(props.data);
  }, [props.data]);
  // _______________________________________________________

  const getChartMeta = (data) => {
    setDataForChart(data);
  };

  // _______________________________________________________
  const rowsPerPage = props?.isVerifiedContacts ? 3 : 5;
  const calculatedRows = props.data?.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  // _______________________________________________________

  const handleChangePage = (event, newPage) => {
    const calculatedRowsNewPage = props.data?.slice(
      newPage * rowsPerPage,
      (newPage + 1) * rowsPerPage,
    );
    if (!calculatedRowsNewPage?.length && props?.setGlobalPage) {
      props?.setGlobalPage?.(newPage + 1);
    }
    setPage(newPage);
  };

  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
  };

  const isWordCloudShown = viewType === "wordCloud";
  const hiddenChartId = `table-word-cloud-hidden-container-${
    props?.isCustomDashboard
      ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
      : props?.title
  }`;

  return (
    <div
      className={
        props.reduceHeight
          ? "chart"
          : props.isSlasAgents
            ? "chart automatedHeight"
            : "chart table-widget"
      }
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
    >
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        data={props.data}
        getChartMeta={getChartMeta}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
        viewType={viewType}
        handleViewTypeChange={handleViewTypeChange}
        withWordCloud={props?.withWordCloud}
        isWordCloudShown={isWordCloudShown}
        hiddenChartId={hiddenChartId}
      />
      {_.isEqual(
        props.tableChartPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : isEmptyArray(props.data) ? (
        <NoDataFound />
      ) : (
        <>
          {!isWordCloudShown ? (
            <TableContainer>
              {props.showChartDetailsWidget && (
                <ChartDetailsWidget statsDetailsData={props.statsDetailsData} />
              )}
              <Table className="tablemargin">
                <TableHead className="border-none">
                  <TableRow>
                    <TableCell
                      className={`ar-postuser-heading1 ${
                        props.wordsList &&
                        !props.isSlasAgents &&
                        "table-cell-keyword"
                      }`}
                    >
                      {CheckValueLocale(props?.leftColomnTitle, "", {}, intl)}
                    </TableCell>
                    <TableCell
                      className={`ar-postuser-heading1 ${
                        props.wordsList &&
                        !props.isSlasAgents &&
                        "table-cell-keyword"
                      }`}
                    >
                      {CheckValueLocale(props?.rightColomnTitle, "", {}, intl)}
                    </TableCell>
                    {props?.thirdColomnTitle ? (
                      <TableCell
                        className={`ar-postuser-heading1 ${
                          props?.wordsList && !props.isSlasAgents
                            ? "table-cell-keyword"
                            : ""
                        }`}
                      >
                        {CheckValueLocale(
                          props?.thirdColomnTitle,
                          "",
                          {},
                          intl,
                        )}
                      </TableCell>
                    ) : null}
                    {props?.fourthColomnTitle ? (
                      <TableCell
                        className={`ar-postuser-heading1 ${
                          props?.wordsList && !props.isSlasAgents
                            ? "table-cell-keyword"
                            : ""
                        }`}
                      >
                        {CheckValueLocale(
                          props?.fourthColomnTitle,
                          "",
                          {},
                          intl,
                        )}
                      </TableCell>
                    ) : null}
                    {props?.isXPlatform ? (
                      <TableCell
                        className={`ar-postuser-heading1 ${
                          props.wordsList && "table-cell-keyword"
                        }`}
                      >
                        <CustomizedTooltip
                          id="total-number-of-engagements-tooltip"
                          title={
                            <div className="tooltip-custom-new">
                              {CheckValueLocale(
                                "total_number_of_engagements_tooltip",
                                "",
                                {},
                                intl,
                              )}
                            </div>
                          }
                          arrow
                          placement="top"
                        >
                          <Typography
                            variant={"h7"}
                            className="widget-title-table-header"
                          >
                            {CheckValueLocale(
                              props?.secondaryTitle,
                              "",
                              {},
                              intl,
                            )}
                          </Typography>
                        </CustomizedTooltip>
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                {/* Case of Items List that include avatar+name+username____________________________________________ */}
                {props.itemsList && (
                  <TableBody>
                    {calculatedRows?.map((row, index) => (
                      <>
                        <ItemsList
                          unixDate={props?.unixDate}
                          showProfile={props?.showProfile}
                          monitorId={props?.monitorId}
                          productId={props?.productId}
                          circularPP={props?.circularPP}
                          linkedInPage={props?.linkedInPage}
                          key={index}
                          totalValue={
                            row?.followers_count && props?.isXPlatform
                              ? row?.followers_count
                              : row?.value
                                ? row?.value
                                : row?.stats_count
                          }
                          isEngagInbox={props?.isEngagInbox}
                          totalValueEng={
                            row?.value !== undefined
                              ? row?.total_engagements
                              : null
                          }
                          isXPlatform={props?.isXPlatform}
                          row={row}
                          isTiktok={props?.isTiktok}
                          iconBeforeItemTitle={props?.iconBeforeItemTitle}
                          monitorDataSource={monitorDataSource}
                        />
                      </>
                    ))}
                  </TableBody>
                )}
                {/* Case of words list which includes words _________________________________________________________ */}
                {props.wordsList && (
                  <TableBody>
                    {calculatedRows?.map((row, index) => (
                      <WordsList
                        row={row}
                        index={index}
                        rowsLength={calculatedRows?.length}
                        activeTab={activeTab}
                        isSlasAgents={props?.isSlasAgents}
                        monitorDataSource={monitorDataSource}
                        showAtIcon={props.showAtIcon} // showAtIcon is to display '@' icon
                        socialKeywordName={props.socialKeywordName} // to handle name of keyword in social --> CA
                        //this props used for handle QuickInspect
                        handleOpenQuickInspect={props.handleOpenQuickInspect}
                        inspectorName={props.inspectorName}
                        dynamicClass={props.dynamicClass}
                        dataSource={props.dataSource}
                        iconBeforeItemTitle={props?.iconBeforeItemTitle}
                        isGensys={props?.isGensys}
                        isCustomDashboard={props?.isCustomDashboard}
                        links={props?.links}
                      />
                    ))}
                  </TableBody>
                )}
                {/* _________________________________________________________ */}

                {props.tablePagination && props.count !== 0 && (
                  <TableFooter className="pagination-table-style">
                    <TableRow>
                      <TablePagination
                        className="intl1"
                        count={props.count}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={props?.isVerifiedContacts ? 3 : 5}
                        rowsPerPageOptions={false}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          ) : props?.withWordCloud ? (
            <WordCloud
              data={props?.data}
              inspectorName={props?.inspectorName}
              handleOpenQuickInspect={props?.handleOpenQuickInspect}
            />
          ) : null}
        </>
      )}
      {props?.withWordCloud ? (
        <TableWordCloudHiddenView
          {...props}
          hiddenChartId={hiddenChartId}
          monitorDataSource={monitorDataSource}
          activeTab={activeTab}
        />
      ) : null}
    </div>
  );
};

export default TableWidget;
