import { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import Header from "../header";
import { checkIsDataFecthing } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import {
  calcAverage,
  handleAgentPerformanceAggregation,
  handleHitsAndMisses,
} from "../../sharedFunctions/aggregateFunctions";
import EngagementsController from "services/controllers/engagementsController";
import { AgentTabPerformanceTable } from "../../components/agentsTabPerformanceTable";
import DropdownFilter from "../../components/dropdownFilter/dropdownFilter";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { commasAfterDigit } from "utils/helpers";
import {
  faMessage,
  faComments,
  faGlobe,
  faReply,
  faCircleCheck,
  faClock,
  faReplyAll,
  faFolderOpen,
  faGroupArrowsRotate,
} from "@fortawesome/pro-solid-svg-icons";
import {
  displayRemainingTime,
  renderRemainingTime,
} from "../../sharedFunctions/datesUtils";
import StatisticsWidget from "components/widgets/statisticsWidget/statisticsWidget";
import { faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import PieChartWidget from "components/widgets/pieChartWidget";
import {
  handleAggregatePieChart,
  handleAggregatePunchCardCharts,
  handleAggregateLegends,
  handleAggregateLineChartMultipleValues,
} from "utils/helpers/aggregateFunctions";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  getHandleSortedArray,
  orderObjectByArray,
} from "../../sharedFunctions/aggregateFunctions";
import CircularLoading from "components/circularProgress";

// for agent inbox performance chart widget
const COLORS_ARR = ["#304D7D", "#6CADE9"];
let typesSorted = ["assigned", "completed"];

const AgentsTab = ({
  selectedTab,
  preLoaderTrackerDataSources,
  response,
  startDate,
  endDate,
  handleSeletedOptions,
  resetAllFields,
  isLoading,
}) => {
  const intl = useIntl();

  // excel State
  const [excelSheetData, setExcelSheetData] = useState([]);
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);

  // Overview widget states
  const [engagOverView, setEngagOverView] = useState({});
  const [engagOverViewPreLoader, setEngagOverViewPreLoader] = useState([]);

  // Agents Performance Table
  const [agentPerformance, setAgentPerformance] = useState([]);
  const [agentPerformancePreLoader, setAgentPerformancePreLoader] = useState(
    [],
  );
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const appliedSelectedAgents = useRef([]);

  // agent distribution pie chart
  const [agentsDistribution, setAgentsDistribution] = useState([]);
  const [agentsDistributionPreLoader, setAgentsDistributionPreLoader] =
    useState([]);
  const [agentsDistributionCopy, setAgentsDistributionCopy] = useState([]);
  const [agentsDistributionXlxs, setAgentsDistributionXlxs] = useState([]);
  const [isLoadingAgents, setIsloadingAgents] = useState(0);
  const isAgentsLoading = isLoadingAgents === 0;

  // agent inbox performance
  const [agentInboxPerformance, setAgentInboxPerformance] = useState([]);
  const [agentInboxPerformancePreLoader, setAgentInboxPerformancePreLoader] =
    useState([]);
  const [agentInboxPerformanceChart, setagentInboxPerformanceChart] = useState(
    [],
  );

  // hist and misses table
  const [hitsAndMisses, setHitsAndMisses] = useState({});
  const [hitsAndMissesPreLoader, setHitsAndMissesPreLoader] = useState([]);

  const handleTableData = () => {
    const sortedTableData = [
      {
        type: "avgs_time_to_complete",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["time_to_complete"]?.avgTime
              ? hitsAndMisses["time_to_complete"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["time_to_complete"]?.hits,
        misses: hitsAndMisses["time_to_complete"]?.misses,
      },
      {
        type: "avgs_first_response_time",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["first_response_time"]?.avgTime
              ? hitsAndMisses["first_response_time"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["first_response_time"]?.hits,
        misses: hitsAndMisses["first_response_time"]?.misses,
      },
      {
        type: "avgs_next_response_time",
        avg_slas: renderRemainingTime(
          displayRemainingTime(
            hitsAndMisses["next_response_time"]?.avgTime
              ? hitsAndMisses["next_response_time"]?.avgTime
              : 0,
          ),
          intl,
        ),
        hits: hitsAndMisses["next_response_time"]?.hits,
        misses: hitsAndMisses["next_response_time"]?.misses,
      },
    ];
    return sortedTableData;
  };
  const handleDropdwonChange = (newValue) => {
    setSelectedAgents(newValue);
  };
  const handleDropdownApply = () => {
    appliedSelectedAgents.current = selectedAgents;
    handleSeletedOptions(
      "assignees_ids",
      selectedAgents?.map((item) => item?.id)?.join(","),
    );
  };
  const handleDropdownClose = () => {
    setSelectedAgents(appliedSelectedAgents.current);
  };

  useEffect(() => {
    switch (response?.eventName) {
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agents_performance":
        const accumulatedData = handleAgentPerformanceAggregation(
          response?.eventData,
          agentPerformance,
        );
        setAgentPerformance(accumulatedData);
        setAgentPerformancePreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__overview":
        setEngagOverView({
          manual_assignment:
            (+engagOverView?.manual_assignment || 0) +
            (+response?.eventData?.overview_result?.manual_assignment || 0),
          auto_assignment_total:
            (+engagOverView?.auto_assignment_total || 0) +
            (+response?.eventData?.overview_result?.auto_assignment || 0),
          total_replied:
            (+engagOverView?.total_replied || 0) +
            (+response?.eventData?.overview_result?.total_replied || 0),
          total_completed:
            (+engagOverView?.total_completed || 0) +
            (+response?.eventData?.overview_result?.total_completed || 0),
          total_avg_value:
            (+engagOverView?.total_avg_value || 0) +
            (+response?.eventData?.overview_result?.handling_time?.value || 0),
          total_avg_count:
            (+engagOverView?.total_avg_count || 0) +
            (+response?.eventData?.overview_result?.handling_time?.count || 0),
          total_reopened:
            (+engagOverView?.total_reopened || 0) +
            (+response?.eventData?.overview_result?.total_reopened || 0),
        });
        setEngagOverViewPreLoader([
          ...engagOverViewPreLoader,
          response?.data_source,
        ]);
        break;

      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__engagement_distribution_over_agents":
        const newAgentDist =
          response?.eventData?.data?.agents?.map?.((item) => {
            return {
              name: item?.user_id,
              value: item?.value,
            };
          }) || [];
        handleAggregatePieChart(newAgentDist, agentsDistributionCopy);
        setAgentsDistributionPreLoader((prev) => [
          ...prev,
          response?.data_source,
        ]);
        break;

      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__SLAsHitsAndMisses__Overview":
        const responseArr = response?.eventData?.data;
        const accumaltedData = handleHitsAndMisses(responseArr, hitsAndMisses);
        setHitsAndMisses(accumaltedData);
        setHitsAndMissesPreLoader((prev) => [...prev, response?.data_source]);
        break;

      case "Facebook__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Facebookprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Twitter__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Twitterprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Instagram__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Instagramprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
      case "Whatsappprivate__EngagementAnalyticsV2Page__AGENT_PAGE__agent_inbox_performance":
        handleAggregateLegends(
          response?.eventData?.data?.agent_inbox_legends,
          agentInboxPerformanceChart,
        );
        handleAggregateLineChartMultipleValues(
          response?.eventData?.data?.posts_over_time,
          agentInboxPerformance,
          setAgentInboxPerformance,
        );
        setAgentInboxPerformancePreLoader((oldArray) => [
          ...oldArray,
          response?.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  useEffect(() => {
    if (
      _.isEqual(
        engagOverViewPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      ) &&
      engagOverViewPreLoader?.length
    ) {
      let overViewData = {
        ...engagOverView,
        avg_engag: calcAverage(
          engagOverView?.total_avg_value || 0,
          engagOverView?.total_avg_count || 0,
        ),
      };
      delete overViewData.total_avg_count;
      delete overViewData.total_avg_value;
      setEngagOverView({ ...overViewData });
    }
  }, [engagOverViewPreLoader, preLoaderTrackerDataSources]);

  // handle excel data
  useEffect(() => {
    let totalPreLoadersDataSources = [
      agentPerformancePreLoader,
      engagOverViewPreLoader,
      agentsDistributionPreLoader,
      hitsAndMissesPreLoader,
      agentInboxPerformancePreLoader,
    ]; // all preloader  added here

    const isAllPreLoadersLoaded =
      checkIsDataFecthing(
        totalPreLoadersDataSources,
        preLoaderTrackerDataSources,
      ) && isLoadingAgents >= 2;
    setCheckAllDataFetchingExport(isAllPreLoadersLoaded);

    const excelData = {
      engagOverView: engagOverView,
      agentInboxPerformance: agentInboxPerformance,
      hitsAndMisses: hitsAndMisses,
      agentPerformance: { data: agentPerformance, agents },
      agentsDistribution: agentsDistributionXlxs,
    };
    setExcelSheetData(excelData);
  }, [
    agentPerformance,
    agentPerformancePreLoader,
    engagOverView,
    engagOverViewPreLoader,
    agentsDistributionXlxs,
    agentsDistributionPreLoader,
    isLoadingAgents,
    hitsAndMisses,
    hitsAndMissesPreLoader,
    agentInboxPerformance,
    agentInboxPerformancePreLoader,
  ]);

  useEffect(() => {
    const getAgents = () => {
      // Call API to get agents
      EngagementsController.getUsers(window.localStorage.engagements_id).then(
        (res) => {
          if (!res?.errorMsg && res?.data?.data?.length) {
            const agents = res?.data?.data?.map((agent) => ({
              ...(agent?.attributes || {}),
            }));
            setAgents(agents);
          }
          setIsloadingAgents(1);
        },
      );
    };

    getAgents();
  }, []);

  // handle agents distribution
  useEffect(() => {
    if (
      isLoadingAgents == 1 &&
      preLoaderTrackerDataSources?.length &&
      _.isEqual(
        agentsDistributionPreLoader?.sort(),
        preLoaderTrackerDataSources?.sort(),
      )
    ) {
      const newAgentDist = agentsDistributionCopy
        ?.map((item) => {
          const agentName =
            agents?.find((agent) => agent?.id == item?.name)?.name || null;
          if (agentName) {
            return {
              name: agentName,
              value: item?.value,
            };
          }
          return null;
        })
        ?.filter(Boolean);
      setAgentsDistributionXlxs(_.cloneDeep(newAgentDist));
      setAgentsDistribution(_.cloneDeep(newAgentDist));
      setIsloadingAgents(2);
    }
  }, [agentsDistributionPreLoader, isLoadingAgents]);

  // reset all states
  useEffect(() => {
    if (resetAllFields !== 1) {
      setAgentPerformance([]);
      setAgentPerformancePreLoader([]);
      setEngagOverView({});
      setEngagOverViewPreLoader([]);
      setAgentsDistribution([]);
      setAgentsDistributionPreLoader([]);
      setAgentsDistributionCopy([]);
      setAgentsDistributionXlxs([]);
      isLoadingAgents && setIsloadingAgents(1);
      setHitsAndMisses({});
      setHitsAndMissesPreLoader([]);
      setAgentInboxPerformance([]);
      setagentInboxPerformanceChart([]);
      setAgentInboxPerformancePreLoader([]);
    }
  }, [resetAllFields]);

  if (isLoading) {
    return <CircularLoading />;
  }

  return (
    <>
      <Header
        selectedTab={selectedTab}
        checkAllDataExist={checkAllDataFetchingExport}
        excelSheetData={excelSheetData}
        checkDataIsEmpty={
          +engagOverView?.manual_assignment > 0 ||
          +engagOverView?.auto_assignment_total > 0
        }
        startDate={startDate}
        endDate={endDate}
      />
      <Box className="engag-dashboard-widgets-container">
        <Box mb={5}>
          <DropdownFilter
            options={agents}
            value={selectedAgents}
            label={CheckValueLocale("engag_select_agent", "", {}, intl)}
            emptyListMessage={CheckValueLocale("engag_no_agents", "", {}, intl)}
            onChange={handleDropdwonChange}
            onApply={handleDropdownApply}
            onClose={handleDropdownClose}
            selectedOpitonsNum={appliedSelectedAgents.current?.length}
            disabled={isAgentsLoading}
          />
        </Box>
        <Box mb={5}>
          <StatisticsWidget
            title={"engag_agents_overview"}
            titleToolTip={"engag_agents_overview_tooltip"}
            chartId={"agent_performance"}
            toolTipLabel={"agent_overview_tooltip"}
            showDownloadIcon
            bigImg
            statsData={[
              {
                name: "manual_assignment",
                value: engagOverView?.manual_assignment
                  ? commasAfterDigit(engagOverView?.manual_assignment)
                  : 0,
                icon: faPeopleArrows,
                titleToolTip: "manual_assignment_tooltip",
              },
              {
                name: "auto_assignment_total",
                value: engagOverView?.auto_assignment_total
                  ? commasAfterDigit(engagOverView?.auto_assignment_total)
                  : 0,
                icon: faGroupArrowsRotate,
                titleToolTip: "auto_assignment_tooltip",
              },
              {
                name: "total_replied",
                value: engagOverView?.total_replied
                  ? commasAfterDigit(engagOverView?.total_replied)
                  : 0,
                icon: faReplyAll,
                titleToolTip: "total_replied_agents_tooltip",
              },
              {
                name: "total_completed",
                value: engagOverView?.total_completed
                  ? commasAfterDigit(engagOverView?.total_completed)
                  : 0,
                icon: faCircleCheck,
                titleToolTip: "total_completed_agents_tooltip",
              },
              {
                name: "avg_engag",
                value: renderRemainingTime(
                  displayRemainingTime(
                    engagOverView?.avg_engag ? engagOverView?.avg_engag : 0,
                  ),
                  intl,
                ),
                icon: faClock,
                titleToolTip: "avg_engag_agents_tooltip",
              },
              {
                name: "reopens_engag",
                value:
                  engagOverView?.total_reopened || 0
                    ? commasAfterDigit(engagOverView?.total_reopened)
                    : 0,
                icon: faFolderOpen,
                titleToolTip: "reopens_engag_tooltip",
              },
            ]}
            containerClassName={"engag-overview"}
            statisticsPreLoaderDataSources={engagOverViewPreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            toolTipPosition="top"
          />
        </Box>
        <Box mb={5}>
          <StackedLineWidget
            title="agent_inbox_performance"
            titleToolTip={"agent_inbox_performance_tooltip"}
            data={orderObjectByArray(agentInboxPerformance, typesSorted)}
            areaStyle={true}
            isTopicSection
            hideLegend
            showDownloadIcon
            bigImg
            stackedLineWidgetPreLoaderDataSources={
              agentInboxPerformancePreLoader
            }
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isSwitchCase
            hidePercentage
            isSentimentAnalysis={false}
            dataPiechart={getHandleSortedArray(
              typesSorted,
              agentInboxPerformanceChart,
            )}
            labelColors={COLORS_ARR}
            colors={COLORS_ARR}
            isCityAnalysisWidget={true}
          />
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableWidget
                wordsList
                showDownloadIcon
                title={"slas_hits_and_misses_table"}
                isSlasAgents
                titleToolTip={"slas_hits_and_misses_table_tooltip"}
                data={handleTableData()}
                leftColomnTitle={"slas_hits_and_misses_table_type"}
                rightColomnTitle={"avg_slas_table_column"}
                thirdColomnTitle={"table_hits_title"}
                fourthColomnTitle={"table_misses_title"}
                tableChartPreLoaderDataSources={hitsAndMissesPreLoader}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={5}>
          <AgentTabPerformanceTable
            agentPerformanceData={agentPerformance}
            agents={agents}
            statisticsPreLoaderDataSources={agentPerformancePreLoader}
            preLoaderTrackerDataSources={preLoaderTrackerDataSources}
            isLoading={isAgentsLoading}
          />
        </Box>
        <Box mb={5}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <PieChartWidget
                title="agents_disribution"
                titleToolTip="agents_disribution_tooltip"
                data={agentsDistribution}
                areaStyle={true}
                toolTipLabel="toolTipLabel"
                showDownloadIcon
                bigImg
                disableLocales
                pieChartPreLoaderDataSources={
                  isLoadingAgents < 2 ? [] : agentsDistributionPreLoader
                }
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AgentsTab;
