import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ExportBtn from "pages/Monitors/Components/exportButton/exportButton";

const Header = ({
  selectedTab,
  startDate,
  endDate,
  excelSheetData,
  checkAllDataExist,
  checkDataIsEmpty,
}) => {
  const intl = useIntl();

  return (
    <Box className={"engag-analytics-header"}>
      {CheckValueLocale(`${selectedTab}_analytics`, "", {}, intl)}
      <ExportBtn
        excelSheetData={excelSheetData}
        disablePPTExport={true}
        checkAllDataExist={checkAllDataExist}
        engagementTabName={selectedTab || "inbox"}
        checkDataIsEmpty={checkDataIsEmpty}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
};

export default Header;
