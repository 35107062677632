import {
  CheckValueLocale,
  getDatasourceId,
  getSocialIcon,
} from "utils/helpers";
import { useIntl } from "react-intl";
import {
  Box,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormHelperText,
  FormControlLabel,
  Radio,
  Tooltip,
  Zoom,
  Snackbar,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import StarsIcon from "@mui/icons-material/Stars";
import CallIcon from "@mui/icons-material/Call";
import ReportController from "services/controllers/reportController";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    padding: "10px",
    width: 200,
    lineHeight: 1.5,
    borderRadius: "4px",
  },
}));

const categoryTypeIcon = (type) => {
  if (type === "All Channels") {
    return <SmsIcon />;
  } else if (type === "Social Media") {
    return <ShareIcon />;
  } else if (type === "chats") {
    return <ChatBubbleIcon />;
  } else if (type === "emails") {
    return <EmailIcon />;
  } else if (type === "Ratings") {
    return <StarsIcon />;
  } else if (type === "Calls") {
    return <CallIcon />;
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ChannelAnalyticsCreate = ({
  fieldError,
  step2ChannelFormFields,
  setStep2ChannelFormFields,
  cxmCategories,
  productId,
  setCheckDisable,
  setStep2FormFields,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    channelCategory,
    cxmFilteredDataSources,
    cxmDataSourceIds,
    cxmFilteredMonitos,
    cxmSelectedMonitors,
    selectedMonitorsNames,
  } = step2ChannelFormFields;
  const [categories, setCategories] = useState([]);
  const [sourceType, setSourceType] = useState("public");
  const [isDisabledDm, setIsDisabledDm] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);

  const raiseARequestErrorIfError = useCallback((error) => {
    if (error) {
      setIsRequestError(!!true);
    }
  }, []);
  useEffect(() => {
    ReportController.getReportTypes().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data) {
        let channelReportType = res.data?.filter(
          (val) => val?.attributes?.name === "channel",
        )[0];
        setStep2FormFields((val) => ({
          ...val,
          reportType: channelReportType?.attributes?.report_type,
        }));
      }
    });
  }, []);

  useEffect(() => {
    ReportController.getReportChannels(
      productId,
      cxmDataSourceIds?.join(","),
      sourceType,
    ).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      let monitorSourcs = [];
      res?.data &&
        Object.keys(res?.data).forEach(function (key, index) {
          let sourceItems = { source: key };
          res?.data[key]?.map((item) => {
            sourceItems.id = item?.id;
            sourceItems.name = item?.name;
            sourceItems.paused = item?.paused;
            sourceItems.type = item?.type;
            monitorSourcs.push({ ...sourceItems });
          });
        });
      let selectedMonitorsArr = [];
      let selectedMonitorsNamesArr = [];
      monitorSourcs?.map((item) => {
        cxmSelectedMonitors.map((id) => {
          if (item?.id == id) {
            selectedMonitorsArr?.push(id);
            selectedMonitorsNamesArr?.push(item?.name);
          }
        });
      });
      setStep2ChannelFormFields((val) => ({
        ...val,
        cxmFilteredMonitos: [...monitorSourcs],
        cxmSelectedMonitors: [...selectedMonitorsArr],
        selectedMonitorsNames: [...selectedMonitorsNamesArr],
      }));
    });
  }, [cxmDataSourceIds, sourceType]);

  useEffect(() => {
    let cateogries = [];
    let dataSourceList = [];
    if (cxmCategories.length) {
      // cateogries?.push("All Channels"); //COMMENTED AS REQUREID FROM PM [until BE finish it]
      cxmCategories?.map((item) => {
        dataSourceList?.push({ id: item?.id, source: item?.source });
        if (!cateogries?.includes(item?.category)) {
          cateogries?.push(item?.category);
        }
      });
      setStep2ChannelFormFields((val) => ({
        ...val,
        dataSourceList: [...dataSourceList],
      }));
      setCategories([...cateogries]);
    }
  }, [cxmCategories]);

  const handelCategoryType = (type) => {
    setStep2FormFields((val) => ({
      ...val,
      channelType: type,
    }));
    let filteredDataSources = cxmCategories?.filter(
      (item) => item?.category == type,
    );
    let filteredWhatsappdatasource = (filteredDataSources =
      filteredDataSources?.filter(
        (item) => item.source?.toLowerCase?.() != "whatsapp",
      ));

    setStep2ChannelFormFields((val) => ({
      ...val,
      channelCategory: type,
      channelName: "",
      channelAccount: [],
      channelDataIncluded: "",
      cxmFilteredDataSources: [...filteredWhatsappdatasource],
      cxmDataSourceIds: [],
    }));
    setSourceType("public");
  };

  const handleDataSources = (id) => {
    setIsDisabledDm(false);
    let dataSources = [];
    if (!cxmDataSourceIds?.includes(id)) {
      dataSources = [...cxmDataSourceIds, id];
    } else {
      dataSources = cxmDataSourceIds?.filter((i) => i !== id);
    }
    setStep2ChannelFormFields((val) => ({
      ...val,
      cxmDataSourceIds: [...dataSources],
    }));
    let datasourceIdLinkedIn = getDatasourceId(
      cxmFilteredDataSources,
      "LINKEDIN",
    )?.id;
    let datasourceIdTiktok = getDatasourceId(
      cxmFilteredDataSources,
      "TIKTOK",
    )?.id;
    if (
      (dataSources?.length === 1 &&
        (dataSources[0] == datasourceIdLinkedIn ||
          dataSources[0] == datasourceIdTiktok)) ||
      (dataSources?.length === 2 &&
        dataSources?.includes(datasourceIdTiktok) &&
        dataSources?.includes(datasourceIdLinkedIn))
    ) {
      setIsDisabledDm(true);
    }
  };

  const handleDataSourceTypes = (e) => {
    setSourceType(e.target.value);

    let filteredDataSourcesTypes = cxmCategories?.filter(
      (item) => item?.category == channelCategory,
    );
    if (e.target.value === "private" && channelCategory == "Social Media") {
      filteredDataSourcesTypes = filteredDataSourcesTypes?.filter(
        (item) => item?.source !== "LINKEDIN" && item?.source !== "TIKTOK",
      );
      let datasourceIdLinkedIn = getDatasourceId(
        cxmFilteredDataSources,
        "LINKEDIN",
      )?.id;
      let datasourceIdTiktok = getDatasourceId(
        cxmFilteredDataSources,
        "TIKTOK",
      )?.id;
      let datasourcesArr = cxmDataSourceIds?.filter(
        (i) => i !== datasourceIdLinkedIn && i !== datasourceIdTiktok,
      );
      setStep2ChannelFormFields((val) => ({
        ...val,
        cxmDataSourceIds: [...datasourcesArr],
      }));
    }
    setStep2ChannelFormFields((val) => ({
      ...val,
      cxmFilteredDataSources: [...filteredDataSourcesTypes],
    }));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let monitorsId = [...value];
    if (value.includes("all")) {
      if (monitorsId?.length - 1 == cxmFilteredMonitos?.length) {
        monitorsId = [];
      } else {
        monitorsId = [];
        cxmFilteredMonitos?.map((item) => {
          monitorsId.push(item?.id);
        });
      }
    }
    let names = [];
    monitorsId?.map((id) => {
      cxmFilteredMonitos?.map((item) => {
        if (item?.id == id) {
          names.push(item?.name);
        }
      });
    });
    setStep2ChannelFormFields((val) => ({
      ...val,
      cxmSelectedMonitors: [...monitorsId],
      selectedMonitorsNames: [...names],
    }));
  };

  useEffect(() => {
    if (cxmDataSourceIds?.length && cxmSelectedMonitors?.length) {
      setCheckDisable(false);
    } else {
      setCheckDisable(true);
    }
  }, [cxmDataSourceIds, cxmSelectedMonitors]);
  return (
    <Grid container spacing={2} className="channel-analytics">
      <Grid item xs={12}>
        <Box className="text-field">
          <Typography variant="h6" className="body-title">
            {CheckValueLocale("select_channel_type", "", {}, intl)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {categories?.map((catItem) => (
            <Grid key={catItem} item xs={4}>
              {catItem == "Social Media" ||
              catItem == "Calls" ||
              catItem == "Ratings" ||
              catItem == "emails" ||
              catItem == "chats" ? (
                <FormControlLabel
                  value={catItem}
                  checked={channelCategory === catItem}
                  disabled={
                    catItem !== "Social Media" ||
                    catItem !== "Calls" ||
                    catItem !== "Ratings" ||
                    catItem !== "chats" ||
                    catItem !== "emails"
                  }
                  onClick={() =>
                    (catItem == "Social Media" ||
                      catItem == "Calls" ||
                      catItem == "Ratings" ||
                      catItem == "chats" ||
                      catItem == "emails") &&
                    handelCategoryType(catItem)
                  }
                  control={<Radio checked={channelCategory === catItem} />}
                  className={
                    channelCategory === catItem
                      ? "report-choose-category category-item-selected"
                      : catItem !== "Social Media" &&
                          catItem !== "Calls" &&
                          catItem !== "Ratings" &&
                          catItem !== "chats" &&
                          catItem !== "emails"
                        ? "report-choose-category disabled-category"
                        : "report-choose-category"
                  }
                  label={
                    <div className="choose-category-item">
                      {categoryTypeIcon(catItem)}
                      <Typography variant="body1">
                        {CheckValueLocale(catItem, "", {}, intl)}
                      </Typography>
                    </div>
                  }
                  labelPlacement="start"
                  id={`channel-analytics-report-category${
                    catItem ?? "category-item-title"
                  }`}
                />
              ) : (
                <Tooltip
                  title={CheckValueLocale("report_disabled_type", "", {}, intl)}
                  arrow
                  placement="bottom"
                  classes={{ tooltip: classes.customWidth }}
                >
                  <FormControlLabel
                    value={catItem}
                    checked={channelCategory === catItem}
                    disabled={catItem !== "Social Media"}
                    onClick={() =>
                      (catItem == "Social Media" ||
                        catItem == "Calls" ||
                        catItem == "Ratings" ||
                        catItem == "chats" ||
                        catItem == "emails") &&
                      handelCategoryType(catItem)
                    }
                    control={<Radio checked={channelCategory === catItem} />}
                    className={
                      channelCategory === catItem
                        ? "report-choose-category category-item-selected"
                        : catItem !== "Social Media" &&
                            catItem !== "Calls" &&
                            catItem !== "Ratings" &&
                            catItem !== "chats" &&
                            catItem !== "emails"
                          ? "report-choose-category disabled-category"
                          : "report-choose-category"
                    }
                    label={
                      <div className="choose-category-item">
                        {categoryTypeIcon(catItem)}
                        <Typography variant="body1">
                          {CheckValueLocale(catItem, "", {}, intl)}
                        </Typography>
                      </div>
                    }
                    labelPlacement="start"
                    id={`channel-analytics-report-category${
                      catItem ?? "category-item-title"
                    }`}
                  />
                </Tooltip>
              )}
            </Grid>
          ))}

          {fieldError?.channelCategory && (
            <Grid item xs={12}>
              <FormHelperText error>
                {fieldError?.channelCategory}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </Grid>
      {cxmFilteredDataSources?.length && cxmCategories.length ? (
        <Box className="data-source-countainer">
          <Box className="types-source-countainer">
            <Box>
              <Grid item xs={12}>
                <Box>
                  <InputLabel className="text-field-cxm">
                    {CheckValueLocale("select_channels", "", {}, intl)}
                  </InputLabel>
                </Box>
              </Grid>
              <Box className="data-source-items">
                {cxmFilteredDataSources?.map((item) => (
                  <Box key={item?.id} className="data-source-item">
                    <FormControlLabel
                      value={item?.id}
                      control={
                        <Checkbox
                          checked={cxmDataSourceIds?.includes(item?.id)}
                        />
                      }
                      onClick={() => handleDataSources(item?.id)}
                    />
                    {getSocialIcon(item?.source)}
                  </Box>
                ))}
              </Box>
            </Box>
            {step2ChannelFormFields?.channelCategory == "Social Media" ? (
              <Box>
                <Grid item xs={12}>
                  <Box>
                    <InputLabel className="text-field-cxm">
                      {CheckValueLocale("data_included", "", {}, intl)}
                    </InputLabel>
                  </Box>
                </Grid>
                <RadioGroup
                  className="radio-group"
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="public"
                  name="radio-buttons-group"
                  onChange={(e) => handleDataSourceTypes(e)}
                  value={sourceType}
                >
                  <FormControlLabel
                    value="public,private"
                    control={<Radio />}
                    label={CheckValueLocale("all_data", "", {}, intl)}
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label={CheckValueLocale("only_posts", "", {}, intl)}
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label={CheckValueLocale("only_dms", "", {}, intl)}
                    disabled={isDisabledDm}
                  />
                </RadioGroup>
              </Box>
            ) : null}
          </Box>
          <Box className="select-countainer">
            <Grid item xs={12}>
              <Box>
                <InputLabel className="text-field-cxm">
                  {CheckValueLocale("select_accounts", "", {}, intl)}
                </InputLabel>
              </Box>
            </Grid>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel
                className="select-label"
                id="demo-multiple-checkbox-label"
              >
                {CheckValueLocale("select", "", {}, intl)}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="select-input"
                multiple
                value={cxmSelectedMonitors}
                disabled={!cxmFilteredMonitos?.length}
                onChange={(e) => handleChange(e)}
                input={<OutlinedInput label="Tag" />}
                renderValue={() => selectedMonitorsNames.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem value={"all"} className="select-all">
                  <Checkbox
                    checked={
                      cxmFilteredMonitos?.length == cxmSelectedMonitors?.length
                    }
                  />
                  <ListItemText
                    primary={CheckValueLocale("select_all", "", {}, intl)}
                  />
                </MenuItem>
                {cxmFilteredMonitos?.map((item) => {
                  return (
                    <MenuItem key={item?.id} value={item?.id}>
                      <Box className="menu-item-countainer">
                        <Checkbox
                          checked={cxmSelectedMonitors?.includes(item?.id)}
                        />
                        <Box>
                          <Box className="name-icon">
                            <ListItemText primary={item?.name} />
                            {getSocialIcon(item?.source)}
                          </Box>
                          <Box
                            className={item?.paused ? "paused" : "collecting"}
                          >
                            {CheckValueLocale(
                              item?.paused ? "paused" : "collecting",
                              "",
                              {},
                              intl,
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      ) : null}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </Grid>
  );
};

export default ChannelAnalyticsCreate;
