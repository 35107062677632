import * as yup from "yup";
const rexSpecialChar = /^[\u0621-\u064A0-9a-zA-Z0-9-_ ]+$/;

export const noOnlySpaces = (message = "required") => {
  return yup.string().test("no-only-spaces", message, (value) => {
    return value && value.trim().length > 0;
  });
};

export const PublishValidationSchema = yup.object({
  name: yup.string().when("is_edit", {
    is: true,
    then: () =>
      yup
        .string()
        .matches(rexSpecialChar, "please_avoid_using_special_characters"),
    otherwise: () =>
      yup
        .string()
        .required("required")
        .matches(rexSpecialChar, "please_avoid_using_special_characters")
        .concat(noOnlySpaces()),
  }),

  enable_agent_nickname: yup.boolean(),

  agent_nickname: yup.string().when("is_edit", {
    is: true,
    then: () =>
      yup
        .string()
        .matches(rexSpecialChar, "please_avoid_using_special_characters"),
    otherwise: () =>
      yup.string().when("enable_agent_nickname", {
        is: true,
        then: () =>
          yup
            .string()
            .required("required")
            .matches(rexSpecialChar, "please_avoid_using_special_characters")
            .concat(noOnlySpaces()),
        otherwise: () =>
          yup
            .string()
            .matches(rexSpecialChar, "please_avoid_using_special_characters"),
      }),
  }),
  ai_agent_image: yup.mixed().nullable(),

  engagement_type: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),

  conversation_end_time: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  grouping_threshold_time: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),

  survey_id: yup.number().nullable(),

  auto_reply: yup.string(),
  business_industry: yup
    .array()
    .of(yup.string())
    .when("is_edit", {
      is: true,
      then: () => yup.array().of(yup.string()),
      otherwise: () =>
        yup.array().of(yup.string()).required("required").min(1, "required"),
    }),

  business_type: yup
    .array()
    .of(yup.string())
    .when("is_edit", {
      is: true,
      then: () => yup.array().of(yup.string()),
      otherwise: () =>
        yup.array().of(yup.string()).required("required").min(1, "required"),
    }),

  business_hq: yup.string().when("is_edit", {
    is: true,
    then: () =>
      yup
        .string()
        .matches(rexSpecialChar, "please_avoid_using_special_characters"),
    otherwise: () =>
      yup
        .string()
        .required("required")
        .matches(rexSpecialChar, "please_avoid_using_special_characters")
        .concat(noOnlySpaces()),
  }),
  business_brand_of_tone_voice: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  ai_agent_instruction: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required").concat(noOnlySpaces()),
  }),

  auto_assignment: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  inbound_routing_id: yup.string().when("is_edit", {
    is: true,
    then: () => yup.number().nullable(),
    otherwise: () =>
      yup.number().when("auto_assignment", {
        is: "inbound_routing",
        then: () => yup.number().required("required"),
        otherwise: () => yup.number().nullable(),
      }),
  }),

  allow_routing_from_ai_to_human: yup.boolean(),

  team_id: yup.number().when("is_edit", {
    is: true,
    then: () => yup.number().nullable(),
    otherwise: () =>
      yup.number().when("allow_routing_from_ai_to_human", {
        is: true,
        then: () => yup.number().required("required"),
        otherwise: () => yup.number().nullable(),
      }),
  }),

  auto_assign_algorithm_id: yup.number().when("is_edit", {
    is: true,
    then: () => yup.number().nullable(),
    otherwise: () =>
      yup.number().when("allow_routing_from_ai_to_human", {
        is: true,
        then: () => yup.number().required("required"),
        otherwise: () => yup.number().nullable(),
      }),
  }),

  auto_reply_transfer: yup.string(),

  resources: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () =>
      yup
        .string()
        .test("equal", "required_resource", (val) => val !== "empty")
        .test("equal", "invalid_resource", (val) => val !== "not valid")
        .test(
          "equal",
          "only_swagger_validation_message",
          (val) => val !== "only_swagger",
        )
        .test(
          "equal",
          "ai_agent_failed_files",
          (val) => val !== "failed_files",
        ),
  }),

  gender: yup.string().nullable(),
  nationality: yup.string().nullable(),
  department: yup
    .string()
    .matches(rexSpecialChar, "please_avoid_using_special_characters")
    .nullable(),
  dialect: yup
    .string()
    .matches(rexSpecialChar, "please_avoid_using_special_characters")
    .nullable(),
  business_additional_details: yup.string().nullable(),
  emoji_usage: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  response_length: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  response_style: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () => yup.string().required("required"),
  }),
  seasonal_greetings: yup.boolean(),
  seasonal_event_name: yup.string().when("is_edit", {
    is: true,
    then: () =>
      yup
        .string()
        .matches(rexSpecialChar, "please_avoid_using_special_characters"),
    otherwise: () =>
      yup.string().when("seasonal_greetings", {
        is: true,
        then: () =>
          yup
            .string()
            .required("required")
            .matches(rexSpecialChar, "please_avoid_using_special_characters")
            .concat(noOnlySpaces()),
        otherwise: () =>
          yup
            .string()
            .matches(rexSpecialChar, "please_avoid_using_special_characters"),
      }),
  }),
  event_start_date: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () =>
      yup.string().when("seasonal_greetings", {
        is: true,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().nullable(),
      }),
  }),
  event_end_date: yup.string().when("is_edit", {
    is: true,
    then: () => yup.string(),
    otherwise: () =>
      yup.string().when("seasonal_greetings", {
        is: true,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().nullable(),
      }),
  }),
  event_greeting_message: yup.string().when("is_edit", {
    is: true,
    then: () =>
      yup
        .string()
        .matches(rexSpecialChar, "please_avoid_using_special_characters"),
    otherwise: () =>
      yup.string().when("seasonal_greetings", {
        is: true,
        then: () =>
          yup
            .string()
            .required("required")
            .matches(rexSpecialChar, "please_avoid_using_special_characters")
            .concat(noOnlySpaces()),
        otherwise: () =>
          yup
            .string()
            .matches(rexSpecialChar, "please_avoid_using_special_characters"),
      }),
  }),
});
