import React, { useEffect, useState } from "react";
import { MonitorContainer } from "../../../../Components/sidebar/monitor.styles";
import AuthorsBody from "./authorProfileBody";
import MonitorsController from "services/controllers/monitorsController";
import { convertXPlatformToTwitter, isEmptyObj } from "utils/helpers";
import { connectWithRabbitMQ } from "pages/channelAnalytics/InsightsPage/insightsCommonFunctions";
import { useParams } from "react-router-dom";
import CompaniesController from "services/controllers/companiesController";

/*---------------------------------------------------------*/
const AuthorsProfilePage = (props) => {
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;
  const monitorActiveTab = urlParams.activeTab;
  const monitorMonitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorTweetId = urlParams.tweetId;
  const monitorUserId = urlParams.userId;

  /*---------------------------------------------------------*/

  const [response, setResponse] = useState([]);
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [resetAllFields, setResetAllFields] = useState(0);
  /*---------------------------------------------------------*/
  const [postsUsedLimit, setPostsUsedLimit] = useState({
    used: 0,
    limit: 0,
  });
  /*---------------------------------------------------------*/
  const [analyzeProfile, setAnalyzeProfile] = useState(false);
  const [isProfileAnalyzed, setIsProfileAnalyzed] = useState(false);
  const [deepInsightsLoading, setDeepInsightsLoading] = useState(false);
  const [enterFirstTime, setEnterFirstTime] = useState(false);
  /*---------------------------------------------------------*/
  //Handle Pagination with [ Posts Page , Comments & Mentions Page ]
  const changePagination = (pageNumber) => {
    setPageNumber(pageNumber);
    setResetAllFields((prev) => prev + 1);
  };

  /*---------------------------------------------------------*/

  const getCredentials = () => {
    setResponse([]);
    MonitorsController.getAuthorProfileData(
      monitorMonitorDataSource,
      monitorId,
      window.localStorage.sm_id,
      monitorActiveTab,
      1, //page number
      monitorTweetId, //tweet id
      monitorUserId, //user id
    ).then((data) => {
      if (!isEmptyObj(data.data)) {
        setPreLoaderTrackerDataSources([parseInt(monitorId)]);
        setAnalyzeProfile(data.data.analyze_profile_enabled);
        setIsProfileAnalyzed(data?.data?.is_analyzed);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  useEffect(() => {
    getCredentials();
  }, []);
  /*---------------------------------------------------------*/
  const getCompanyDataQuota = (startDate, endDate) => {
    if (!enterFirstTime) setDeepInsightsLoading(true);
    CompaniesController.getCompanyDataInsightsQuota(startDate, endDate).then(
      (result) => {
        var smQuota = result?.data;
        for (let i = 0; i < smQuota?.length; i++) {
          if (smQuota[i]?.name === "SM") {
            setPostsUsedLimit({
              limit:
                smQuota[i]?.quota?.posts_limit?.limit -
                smQuota[i]?.quota?.posts_limit?.used,
              used: smQuota[i]?.quota?.posts_limit?.used,
              type: 2,
            });
          }
        }
        setDeepInsightsLoading(false);
        setEnterFirstTime(false);
      },
    );
  };

  /*---------------------------------------------------------*/

  return (
    <MonitorContainer maxWidth="xl" className="monitor-container">
      <AuthorsBody
        response={response}
        preLoaderTrackerDataSources={preLoaderTrackerDataSources}
        resetAllFields={resetAllFields}
        changePagination={changePagination}
        pageNumber={pageNumber}
        analyzeProfile={analyzeProfile}
        monitorData={props.monitorData}
        singleTweetAnalysis={props.singleTweetAnalysis}
        postsUsedLimit={postsUsedLimit}
        isProfileAnalyzed={isProfileAnalyzed}
        getCompanyDataQuota={getCompanyDataQuota}
        deepInsightsLoading={deepInsightsLoading}
        setDeepInsightsLoading={setDeepInsightsLoading}
      />
    </MonitorContainer>
  );
};

export default AuthorsProfilePage;
