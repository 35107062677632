import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  Snackbar,
} from "@mui/material";

import ReportController from "services/controllers/reportController";

import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import "./create-report.scss";
import { isInThePast } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import dayjs from "dayjs";
import LucButton from "shared/lucButton/lucButton";

const steps = ["report_information", "report_setting", "report_delivery"];

const CreateReports = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [checkDisable, setCheckDisable] = useState(true);
  const [formFieldErrors, setFormFieldErrors] = useState({});
  const [step1FormFields, setStep1FormFields] = useState({
    reportName: "",
    platform: "",
    productId: "",
  });

  const [step2FormFields, setStep2FormFields] = useState({
    monitorType: "",
    reportType: "",
    channelType: "",
    dataSource: "",
    monitor: "",
    KeywordDataSource: [],
    remainingMonitorsDataSource: "",
    KeywordMonitors: [],
  });

  const [step2ChannelFormFields, setStep2ChannelFormFields] = useState({
    channelCategory: "",
    channelName: "",
    channelAccount: [],
    channelDataIncluded: "",
    cxmFilteredDataSources: [],
    cxmFilteredMonitos: [],
    selectedMonitorsNames: [],
    cxmSelectedMonitors: [],
    cxmDataSourceIds: [],
    dataSourceList: [],
  });

  const [step3FormFields, setStep3FormFields] = useState({
    reportEmails: [],
    reportFrequancy: "",
    reportFrequancyId: 0,
    reportCustomDay: "",
    reportLanguage: "",
    reportStartDate: dayjs(new Date()?.toLocaleDateString()),
    reportPPTTemplete: "dynamic_design",
    reportBranding: "standard",
    disabledLuciFeature: false,
    isLuciReport: false,
    isBranding: false,
  });
  const [isRequestError, setIsRequestError] = useState(false);

  const setpValidation = (step) => {
    let errorFileds = {};
    switch (step) {
      case 0:
        if (!step1FormFields?.reportName?.length) {
          errorFileds["reportName"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (!step1FormFields?.platform) {
          errorFileds["platform"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        return errorFileds;
      case 1:
        if (!step1FormFields?.platform) {
          errorFileds["platform"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (step1FormFields?.platform === "SM") {
          if (!step2FormFields?.monitorType) {
            errorFileds["monitorType"] = CheckValueLocale(
              "field_required",
              "",
              {},
              intl,
            );
          }

          if (step2FormFields?.monitorType === 3) {
            if (!step2FormFields?.reportType) {
              errorFileds["reportType"] = CheckValueLocale(
                "field_required",
                "",
                {},
                intl,
              );
            }

            if (!step2FormFields?.KeywordMonitors?.length) {
              if (!step2FormFields?.monitor) {
                errorFileds["monitor"] = CheckValueLocale(
                  "field_required",
                  "",
                  {},
                  intl,
                );
              }
            }

            if (step2FormFields?.reportType === 1) {
              if (!step2FormFields?.KeywordDataSource?.length) {
                errorFileds["dataSource"] = CheckValueLocale(
                  "field_required",
                  "",
                  {},
                  intl,
                );
              }
            }
          } else {
            if (!step2FormFields?.monitor) {
              errorFileds["monitor"] = CheckValueLocale(
                "field_required",
                "",
                {},
                intl,
              );
            }
            if (
              !step2FormFields?.KeywordDataSource?.length &&
              step2FormFields?.remainingMonitorsDataSource === ""
            ) {
              errorFileds["dataSource"] = CheckValueLocale(
                "field_required",
                "",
                {},
                intl,
              );
            }
          }
        }
        if (step1FormFields?.platform === "CXM") {
          if (!step2ChannelFormFields?.channelCategory) {
            errorFileds["channelCategory"] = CheckValueLocale(
              "field_required",
              "",
              {},
              intl,
            );
          }
          if (
            !step2ChannelFormFields?.channelName &&
            ["SocialMedia", "Chat"].includes(
              step2ChannelFormFields?.channelCategory,
            )
          ) {
            errorFileds["channelName"] = CheckValueLocale(
              "field_required",
              "",
              {},
              intl,
            );
          }
          if (
            !step2ChannelFormFields?.channelAccount?.length &&
            step2ChannelFormFields?.channelCategory === "SocialMedia"
          ) {
            errorFileds["channelAccount"] = CheckValueLocale(
              "field_required",
              "",
              {},
              intl,
            );
          }

          if (
            !step2ChannelFormFields?.channelDataIncluded &&
            step2ChannelFormFields?.channelCategory === "SocialMedia"
          ) {
            errorFileds["channelDataIncluded"] = CheckValueLocale(
              "field_required",
              "",
              {},
              intl,
            );
          }
        }

        return errorFileds;
      case 2:
        if (!step3FormFields?.reportEmails?.length) {
          errorFileds["reportEmails"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (step3FormFields?.reportFrequancy === "") {
          errorFileds["reportFrequancy"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (!step3FormFields?.reportLanguage) {
          errorFileds["reportLanguage"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (!step3FormFields?.reportStartDate) {
          errorFileds["reportStartDate"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (isInThePast(new Date(step3FormFields?.reportStartDate))) {
          errorFileds["reportStartDate"] = CheckValueLocale(
            "wrong_date",
            "",
            {},
            intl,
          );
        }
        if (step3FormFields?.reportStartDate.$d == "Invalid Date") {
          errorFileds["reportStartDate"] = CheckValueLocale(
            "invalid_date",
            "",
            {},
            intl,
          );
        }
        if (!step3FormFields?.reportPPTTemplete) {
          errorFileds["reportPPTTemplete"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        if (!step3FormFields?.reportBranding) {
          errorFileds["reportBranding"] = CheckValueLocale(
            "field_required",
            "",
            {},
            intl,
          );
        }
        return errorFileds;
      default:
        return (errorFileds["step"] = "Invalid step found");
    }
  };

  const handleNext = () => {
    const setpErrors = setpValidation(activeStep);
    setFormFieldErrors({ ...setpErrors });
    if (setpErrors && Object.keys(setpErrors)?.length == 0) {
      if (activeStep > 1) {
        setLoadingCreate(true);
        let dataSource = [];
        let monitorData = [];
        let monitor = [];
        if (step1FormFields?.platform === "SM") {
          dataSource = step2FormFields?.dataSource
            ? step2FormFields?.dataSource
            : step2FormFields?.remainingMonitorsDataSource !== ""
              ? [step2FormFields?.remainingMonitorsDataSource]
              : step2FormFields?.KeywordDataSource?.length
                ? step2FormFields?.KeywordDataSource
                : step2FormFields?.talkwalkerDataSource;

          monitor = step2FormFields?.monitor
            ? [step2FormFields?.monitor]
            : step2FormFields?.KeywordMonitors;
          dataSource?.map((dataSorceId) => {
            let monitorList = [];
            monitor?.map((monitorId) => {
              monitorList.push({
                monitor_id: monitorId,
                type: "public",
              });
            });
            monitorData.push({
              data_source_id: dataSorceId,
              monitors: monitorList,
            });
          });
        }
        if (step1FormFields?.platform === "CXM") {
          dataSource = [...step2ChannelFormFields?.cxmDataSourceIds];
          monitor = [...step2ChannelFormFields?.cxmSelectedMonitors];

          dataSource?.map((id) => {
            let monitorList = [];
            let sourceName = step2ChannelFormFields?.dataSourceList.filter(
              (item) => item?.id == id,
            )[0]?.source;
            let filteredMonitor =
              step2ChannelFormFields?.cxmFilteredMonitos.filter(
                (item) => item?.source == sourceName,
              );
            let selectedMonitor = [];
            monitor?.map((id) => {
              filteredMonitor?.map((item) => {
                if (item?.id == id) {
                  selectedMonitor.push({ id: id, type: item?.type });
                }
              });
            });
            selectedMonitor?.map((monitor) => {
              monitorList?.push({
                monitor_id: monitor?.id,
                type: monitor?.type,
              });
            });
            monitorData.push({
              data_source_id: id,
              monitors: monitorList,
            });
          });
        }

        const saveData = {
          channel_type: step2FormFields?.channelType,
          product_id: step1FormFields?.productId,
          title: step1FormFields?.reportName,
          report_type_id: step2FormFields?.reportType,
          monitor_list: monitorData,
          users: step3FormFields?.reportEmails,
          frequency: step3FormFields?.reportFrequancy,
          report_recurrence_id: step3FormFields?.reportFrequancyId,
          on_specific_day: step3FormFields?.reportCustomDay,
          language: step3FormFields?.reportLanguage,
          start_date_time: step3FormFields?.reportStartDate?.unix(),
          report_template:
            step2FormFields?.monitorType == 3 &&
            step2FormFields?.reportType == 2
              ? "old_design"
              : "dynamic_design",
          ...(step2FormFields?.reportType === 2
            ? {}
            : {
                is_luci_report: step3FormFields?.isLuciReport,
                is_branding: step3FormFields?.isBranding,
              }),
          // {/* Commented Till Asiri request till NOW */}
          // branding_type: step3FormFields?.reportBranding,
        };

        ReportController.createReport(saveData).then((res) => {
          setIsRequestError(!!res?.errorMsg);
          let type = "error";
          let message = CheckValueLocale("report_added_fail", "", {}, intl);
          if (res?.status === 201) {
            type = "success";
            message = CheckValueLocale("report_added_success", "", {}, intl);
          }
          if (location?.state?.isMonitorAction) {
            navigate(location?.state?.monitorPath, {
              state: {
                showSnackBar: true,
                snackBarStatus: res?.status === 201 ? "success" : "error",
                snackBarMsg:
                  res?.status === 201
                    ? CheckValueLocale("report_added_success", "", {}, intl)
                    : CheckValueLocale("report_added_fail", "", {}, intl),
              },
            });
          } else
            navigate("/reports/list", {
              state: { createReportData: { type, message } },
            });

          setLoadingCreate(false);
        });
      } else {
        setActiveStep((step) => step + 1);
      }
    }
  };

  const [backBtn, setBackBtn] = useState(false);

  const handleBack = () => {
    setActiveStep((step) => step - 1);
    setBackBtn(true);
  };

  useEffect(() => {
    if (activeStep === 0) {
      navigate("/reports/add-report/information", {
        state: { ...location?.state },
      });
    } else if (activeStep === 1) {
      navigate("/reports/add-report/setting", {
        state: { ...location?.state },
      });
    } else if (activeStep === 2) {
      navigate("/reports/add-report/delivery", {
        state: { ...location?.state },
      });
    }
  }, [activeStep]);
  return (
    <Container maxWidth="xl" className="create-report">
      <Box className="create-report-header">
        <Typography variant="h6">
          {CheckValueLocale("create_report", "", {}, intl)}
        </Typography>
      </Box>
      <Card className="create-report-card">
        <Box className="report-step-header">
          <Stepper activeStep={activeStep}>
            {steps?.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel className="report-step-label">
                    {CheckValueLocale(label, "", {}, intl)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <Box sx={{ py: 7, px: 12 }} className="create-report-body">
          {activeStep === 0 && (
            <StepOne
              fieldError={formFieldErrors}
              fieldsValue={step1FormFields}
              setFieldErrors={setFormFieldErrors}
              setFormFields={setStep1FormFields}
              setCheckDisable={setCheckDisable}
              setStep2FormFields={setStep2FormFields}
              isMonitorAction={location?.state?.isMonitorAction}
              preSelectedData={location?.state?.preSelected}
            />
          )}
          {activeStep === 1 && (
            <StepTwo
              productId={step1FormFields?.productId}
              platformType={step1FormFields?.platform}
              fieldError={formFieldErrors}
              step2FormFields={step2FormFields}
              setStep2FormFields={setStep2FormFields}
              step2ChannelFormFields={step2ChannelFormFields}
              setStep2ChannelFormFields={setStep2ChannelFormFields}
              backBtn={backBtn}
              setBackBtn={setBackBtn}
              setCheckDisable={setCheckDisable}
              isMonitorAction={location?.state?.isMonitorAction}
              preSelectedData={location?.state?.preSelected}
              setStep3FormFields={setStep3FormFields}
            />
          )}
          {activeStep === 2 && (
            <StepThree
              fieldError={formFieldErrors}
              step3FormFields={step3FormFields}
              step2FormFields={step2FormFields}
              setStep3FormFields={setStep3FormFields}
              setCheckDisable={setCheckDisable}
              isMonitorAction={location?.state?.isMonitorAction}
              preSelectedData={location?.state?.preSelected}
            />
          )}
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "row" }}
          className="report-step-footer"
        >
          {activeStep !== 0 ? (
            <LucButton
              type="secondary"
              variant="outline"
              onClick={handleBack}
              id="report-create-report-back-btn"
            >
              {CheckValueLocale("back", "", {}, intl)}
            </LucButton>
          ) : null}

          <Box sx={{ flex: "1 1 auto" }} />
          <LucButton
            variant="flat"
            className="cancel-btn"
            onClick={() => navigate("/reports/list")}
            id="report-create-report-cancel-btn"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            onClick={handleNext}
            disabled={checkDisable}
            loading={loadingCreate}
            id="report-create-report-next-btn"
          >
            {CheckValueLocale(
              activeStep > 1 ? "create_report" : "next_step",
              "",
              {},
              intl,
            )}
          </LucButton>
        </Box>
      </Card>

      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </Container>
  );
};

export default CreateReports;
