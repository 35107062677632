import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PostItem from "./postItem/postItem";
import "./postItem/postItem.scss";
import { Link, useParams } from "react-router-dom";
import {
  isEmptyArray,
  CheckValueLocale,
  convertXPlatformToTwitter,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import { useIntl } from "react-intl";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import Header from "pages/Monitors/Components/header";
import MonitorsController from "services/controllers/monitorsController.js";
import { CustomPagination } from "components/CustomPagination/CustomPagination";

/*---------------------------------------------------------*/
const TopPosts = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorDataSource = convertXPlatformToTwitter(
    urlParams.monitorDataSource,
  );
  const monitorId = urlParams.monitorId;
  const monitorDetails = props.monitorData;

  const [replyModalData, setReplyModalData] = useState([]);
  const [allawedTrackers, setAllawedTrackers] = useState([]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    props.setPage(page);
    props.changePagination(page);
  };
  const [sortBy, setSortBy] = useState("latest_posts");
  const sortByData = ["latest_posts", "most_interactions"];

  const handleChange = (event) => {
    setSortBy(event.target.value);
    props.setFilterParams({
      ...props.filterParams,
      sort_by: event.target.value,
    });
    props.applyFilter(props.startDate, props.endDate, {
      ...props.filterParams,
      sort_by: event.target.value,
    });
  };
  useEffect(() => {
    if (props.filterParams["sort_by"] !== undefined) {
      setSortBy(props.filterParams["sort_by"]);
    } else {
      setSortBy("latest_posts");
    }
  }, [props.filterParams["sort_by"]]);
  /*---------------------------------------------------------*/

  useEffect(() => {
    let replyData = [];
    props?.data?.map((post) => {
      replyData.push({
        selectedValue: "",
        replyTime: "",
      });
    });
    setReplyModalData(replyData);
  }, [props?.data]);

  useEffect(() => {
    if (
      monitorDataSource !== "NEWSBLOGS" &&
      handlelUserRoles("ENGAGEMENT_ACCESS", "SL_ENGAGEMENT") &&
      retrieveActiveProductsArray()?.includes("ENGAGEMENTS")
    ) {
      MonitorsController.getAllawedMonitors(window?.localStorage?.sm_id).then(
        (AllawedData) => {
          if (AllawedData?.data?.data?.monitors_list?.length) {
            setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
          }
        },
      );
    }
  }, []);

  return (
    <Box className="top-posts-container">
      {props.topPostsHeader && (
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${urlParams.monitorDataSource}/posts/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-all-posts-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-posts-${monitorId ?? "monitorId"}`}
          >
            {CheckValueLocale("check_all_posts", "", {}, intl)} (
            {props.topPostsCount})
          </Link>
        </Box>
      )}

      {/* TopPosts mainPage --> Sort By Box */}
      {props.response?.eventData !== undefined && (
        <Header
          title="total_posts"
          titleValue={props.topPostsCount}
          sortByData={sortByData}
          sortBy={sortBy}
          handleChange={handleChange}
        />
      )}
      {/* TopPosts mainPage --> Sort By Box */}

      {_.isEqual(
        props.topTopPostsPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <Box>
          <FetchingData />
        </Box>
      ) : (
        isEmptyArray(props.data) && <NoDataFound />
      )}
      <Box className="topposts_wrapper">
        {props.data.map((post, index) => (
          <PostItem
            index={index}
            post={post}
            iconAfterTitle={props.iconAfterTitle}
            instagramAccount={props.instagramAccount}
            monitorDataSource={monitorDataSource}
            monitorId={monitorId}
            updateAnalysis={props.updateAnalysis}
            singleTweetAnalysis={props.singleTweetAnalysis}
            hideChangeSentiment={props?.hideChangeSentiment}
            replyModalData={replyModalData}
            allawedTrackers={allawedTrackers}
          />
        ))}
      </Box>

      {props?.topPostsCount !== 0 &&
        props?.ShowPagination &&
        props?.topPostsCount > 10 &&
        props?.data?.length > 0 && (
          <CustomPagination
            onChange={handleChangePage}
            page={props.page}
            pagesCount={Math.ceil(props?.topPostsCount / 10)}
            itemsCount={props?.topPostsCount}
          />
        )}
    </Box>
  );
};

export default TopPosts;
