import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Container, Box, Typography, Card, Snackbar } from "@mui/material";
import CompaniesController from "services/controllers/companiesController";
import ReportController from "services/controllers/reportController";
import CircularLoading from "components/circularProgress";
import EditReportHeader from "./components/editReportHeader/editReportHeader";
import EditReportStepTwo from "./components/editReportStepTwo/editReportStepTwo";
import EditReportButtons from "./components/editReportButtons/editReportButtons";
import EditReportStepOne from "./components/editReportStepOne/editReportStepOne";
import EditReportStepThree from "./components/editReportStepThree/editReportStepThree";
import "./edit-report.scss";
import moment from "moment";
import LuciInReports from "../components/luci/luciInReports";

const EditReport = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({});

  const [fieldError, setFormFieldErrors] = useState({});
  const [reportName, setReportName] = useState("");
  const [reportLanguage, setReportLanguage] = useState("");
  const [reportEmails, setReportEmails] = useState([]);
  const [reportUsers, setReportUsers] = useState([]);
  const [reportStateDateTime, setReportStateDateTime] = useState("");

  const [companyUsers, setCompanyUsers] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);
  const [startDate, setStartDate] = useState({});
  const [selectedFreq, setSelectedFreq] = useState("");
  const [specDay, setSpecDay] = useState("");
  const [freqId, setFreqId] = useState(0);
  const [isLuciReport, setIsLuciReport] = useState(false);
  const [luciPerReport, setLuciPerReport] = useState(0);
  const [luciLoading, setLuciLoading] = useState(true);
  const [isBrandingReport, setIsBrandingReport] = useState(false);
  const [companyhasBranding, setCompanyhasBranding] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(0);

  const loginUserData = {
    id: parseInt(window.localStorage.getItem("user_id")),
    email: window.localStorage.getItem("email"),
    name: window.localStorage.getItem("name"),
  };

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.detail) {
        const reportStateData = location?.state?.detail;
        setReportData({ ...reportStateData });
        setReportName(reportStateData?.attributes?.title);
        setReportLanguage(reportStateData?.attributes?.report_extra?.language);
        reportStateData?.attributes?.users.map((val) =>
          reportUsers.push(val?.id),
        );
        reportStateData?.attributes?.users && setReportEmails([...reportUsers]);
        setReportStateDateTime(reportStateData?.attributes?.created_at);
        if (
          reportData?.attributes?.report_type_id !== 2 &&
          reportData?.attributes?.report_type?.toLowerCase() !== "newsletter"
        ) {
          if (reportStateData?.attributes?.is_luci_report !== undefined) {
            setIsLuciReport(reportStateData?.attributes?.is_luci_report);
          }
          if (reportStateData?.attributes?.is_branding !== undefined) {
            setIsBrandingReport(reportStateData?.attributes?.is_branding);
          }
          getReportLuciToken(reportStateData?.attributes?.report_type_id);
        }
      }
    }
  }, [location.state]);

  const getReportLuciToken = (reportTypeId) => {
    ReportController.getReportLuciToken(reportTypeId).then((res) => {
      setLuciLoading(false);
      if (res?.status >= 200) {
        setLuciPerReport(res?.data?.luci_token);
        setCompanyhasBranding(res?.data?.is_company_branding);
        setLastUpdated(res?.data?.company_branding_last_update);
      }
    });
  };

  useEffect(() => {
    getComanyEmails();
  }, []);

  const getComanyEmails = () => {
    CompaniesController.getCompanyActiveUsers().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      const usersData = res?.data?.data
        ? res?.data?.data?.map((val) => ({
            id: val?.attributes?.id,
            email: val?.attributes?.email,
            name: val?.attributes?.name,
          }))
        : [{ ...loginUserData }];
      setCompanyUsers(usersData);
    });
  };
  const handleDateValue = (event) => {
    setStartDate(event);
  };
  const handelSaveChanges = () => {
    let errorFileds = {};
    if (!reportName) {
      errorFileds["reportName"] = CheckValueLocale(
        "field_required",
        "",
        {},
        intl,
      );
    }
    if (!reportEmails?.length) {
      errorFileds["reportEmails"] = CheckValueLocale(
        "field_required",
        "",
        {},
        intl,
      );
    }
    if (!reportLanguage) {
      errorFileds["reportLanguage"] = CheckValueLocale(
        "field_required",
        "",
        {},
        intl,
      );
    }
    setFormFieldErrors({ ...errorFileds });
    if (errorFileds && Object.keys(errorFileds)?.length == 0) {
      const saveData = {
        title: reportName,
        language: reportLanguage,
        users:
          JSON.stringify(reportUsers) !== JSON.stringify(reportEmails)
            ? reportEmails
            : undefined,
        report_recurrence_id: freqId ? freqId : undefined,
        report_type_id: reportData?.attributes?.report_type_id,
        send_date: startDate !== 0 ? moment(startDate?.$d)?.unix() : undefined,
        on_specific_day: specDay ? specDay : undefined,
        ...(reportData?.attributes?.report_type_id === 2 ||
        reportData?.attributes?.report_type?.toLowerCase() === "newsletter"
          ? {}
          : { is_luci_report: isLuciReport, is_branding: isBrandingReport }),
      };

      ReportController.editReports(reportData?.id, saveData).then((res) => {
        let type = "error";
        let message = CheckValueLocale("report_edit_fail", "", {}, intl);

        if (res?.status === 202) {
          type = "success";
          message = CheckValueLocale("report_edit_success", "", {}, intl);
          message = message?.replace("#", `${reportName}`);
        }
        navigate("/reports/list", {
          replace: true,
          state: { editReportData: { type, message } },
        });
      });
    }
  };

  // To hide Luci in report in case of instagram public account
  const isInstagramPublicAccount =
    reportData?.attributes?.channel_type === "ACCOUNT_ANALYSIS" &&
    reportData?.attributes?.data_sources?.some(
      (item) => item?.name === "INSTAGRAM",
    );

  return (
    <Container maxWidth="xl" className="create-report">
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <EditReportHeader />
          <Card className="edit-report-card">
            <Box className="edit-report-body">
              <Typography variant="h6" className="edit-report-body-title">
                1. {CheckValueLocale("report_information", "", {}, intl)}
              </Typography>
              <EditReportStepOne
                setReportName={setReportName}
                reportName={reportName}
                reportData={reportData}
                fieldError={fieldError}
              />
              {/* Step Two */}
              <Typography
                variant="h6"
                className="edit-report-body-title step-two"
              >
                2. {CheckValueLocale("report_setting", "", {}, intl)}
              </Typography>
              <EditReportStepTwo reportData={reportData} />

              {/* Step Three */}
              <Typography
                variant="h6"
                className="edit-report-body-title step-three"
              >
                3. {CheckValueLocale("delivery_requirment", "", {}, intl)}
              </Typography>
              <EditReportStepThree
                setReportEmails={setReportEmails}
                reportEmails={reportEmails}
                companyUsers={companyUsers}
                reportData={reportData}
                setReportLanguage={setReportLanguage}
                reportLanguage={reportLanguage}
                fieldError={fieldError}
                handleDateValue={handleDateValue}
                reportStateDateTime={reportStateDateTime}
                startDate={startDate}
                selectedFreq={selectedFreq}
                setSelectedFreq={setSelectedFreq}
                specDay={specDay}
                setSpecDay={setSpecDay}
                freqId={freqId}
                setFreqId={setFreqId}
                isBrandingReport={isBrandingReport}
                setIsBrandingReport={setIsBrandingReport}
                companyhasBranding={companyhasBranding}
                lastUpdated={lastUpdated}
              />
              {reportData?.attributes?.report_type_id !== 2 &&
              reportData?.attributes?.report_type?.toLowerCase() !==
                "newsletter" &&
              !isInstagramPublicAccount ? (
                <LuciInReports
                  isEdit={true}
                  isLuciReport={isLuciReport}
                  setIsLuciReport={setIsLuciReport}
                  luciLoading={luciLoading}
                  luciPerReport={luciPerReport}
                />
              ) : null}
            </Box>

            {/* EditReport Buttons */}
            <EditReportButtons handelSaveChanges={handelSaveChanges} />
          </Card>
          {isRequestError && (
            <Snackbar
              lang={window?.localStorage?.lang}
              open={true}
              handleClose={() => {
                setIsRequestError(!!false);
              }}
              severity="error"
              message={CheckValueLocale(
                "something_went_wrong_try_agin",
                "",
                {},
                intl,
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};
export default EditReport;
