import BigTableWidget from "components/widgets/bigTableWidget/bigTableWidget";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import { renderRemainingTime } from "../sharedFunctions/datesUtils";
import {
  getTableHeader,
  RenderAgentColumn,
  RenderNormalColumn,
} from "./helpersComponents";
import { commasAfterDigit } from "utils/helpers";

// helper function for the table

const tablesHeaders = [
  {
    name: "agent_performance_grouped_agents_column",
  },
  {
    name: "agent_performance_frt",
  },
  {
    name: "agent_performance_nrt",
  },
  {
    name: "agent_performance_ttc",
  },
  {
    name: "agent_performance_hits",
  },
  {
    name: "agent_performance_misses",
  },
  {
    name: "agent_performance_compliance_rate",
  },
  {
    name: "avg_engag",
  },
  {
    name: "agent_performance_manual_assign",
  },
  {
    name: "agent_performance_auto_assign",
  },
  {
    name: "agent_performance_total_assignments",
  },
];

const createTableRow = (rowData, agents, intl) => {
  const agent = agents?.find((agent) => +agent.id === +rowData?.agent_id);
  const rows = [];
  // if the agent is not found we will return null
  if (!agent) {
    return null;
  }
  rows.push(<RenderAgentColumn agent={agent} />);

  const {
    nrt,
    frt,
    ttc,
    hits_count,
    misses_count,
    compliance_rate,
    avg_handle,
    manual_assign,
    auto_assign,
    total_assignments,
  } = rowData;
  const values = [
    renderRemainingTime(frt, intl),
    renderRemainingTime(nrt, intl),
    renderRemainingTime(ttc, intl),
    commasAfterDigit(hits_count),
    commasAfterDigit(misses_count),
    compliance_rate,
    renderRemainingTime(avg_handle, intl),
    manual_assign,
    auto_assign,
    total_assignments,
  ];
  values.forEach((value) => {
    rows.push(<RenderNormalColumn value={value} />);
  });
  return rows;
};

const rowsPerPage = 5;

export const AgentTabPerformanceTable = ({
  agentPerformanceData,
  agents,
  isLoading,
  ...props
}) => {
  const intl = useIntl();

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);

  const paginatedTableData = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return tableData?.slice?.(start, end) || [];
  }, [tableData, page]);

  const tableHeaders = useMemo(() => {
    return getTableHeader(tablesHeaders, intl);
  }, [intl?.locale]);

  const handleTablePagination = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const stillFetchingData =
      _.isEqual(
        props?.statisticsPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true;

    if (isLoading || stillFetchingData) {
      return;
    }

    const newTableData = agentPerformanceData
      ?.map((data) => {
        return createTableRow(data, agents, intl);
      })
      ?.filter(Boolean);
    setPage(0);
    setTableData(newTableData);
  }, [
    intl?.locale,
    isLoading,
    props?.statisticsPreLoaderDataSources,
    props?.preLoaderTrackerDataSources,
  ]);

  return (
    <BigTableWidget
      chartId="agents-performance"
      title="agent_tab_performance_title"
      titleToolTip="agent_tab_performance_desc"
      handleTablePagination={handleTablePagination}
      stickyColumn={true}
      tableHeaders={tableHeaders}
      tableData={paginatedTableData}
      isLoading={isLoading}
      count={tableData?.length}
      page={page}
      rowsPerPage={rowsPerPage}
      {...props}
    />
  );
};
