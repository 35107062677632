import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slaData } from "utils/redux/features/Engagements/EngagementsSlice.js";

const monthTranslations = {
  january: "يناير",
  february: "فبراير",
  march: "مارس",
  april: "أبريل",
  may: "مايو",
  june: "يونيو",
  july: "يوليو",
  august: "أغسطس",
  september: "سبتمبر",
  october: "أكتوبر",
  november: "نوفمبر",
  december: "ديسمبر",
};
let transletedTimeUnit = {
  am: "ص",
  pm: "م",
};
/** convertDateTimeFormat function to convert the date time format
 * unixTime: the time in unix format
 * lang: the language of the user.e.g ar or en
 * return: the time, monthName, day, year in the correct format
 * e.g {time: "12:00 ص", monthName: "يناير", day: "01", year: "2021"}
 * e.g {time: "12:00 AM", monthName: "January", day: "01", year: "2021"}
 */
export const convertDateTimeFormat = (unixTime, lang) => {
  let unitTime = moment.unix(unixTime).utc().format("A");

  const time =
    moment.unix(unixTime).utc().format("h:mm") +
    " " +
    (lang == "ar"
      ? transletedTimeUnit[unitTime?.toLowerCase()] || unitTime
      : unitTime);
  const monthName = moment.unix(unixTime).utc().format("MMMM");
  const day = moment.unix(unixTime).utc().format("DD");
  const year = moment.unix(unixTime).utc().format("YYYY");

  let newMonth =
    lang == "ar"
      ? monthTranslations[monthName?.toLowerCase()] || monthName
      : monthName;
  return { time, monthName: newMonth, day, year };
};

/** getEngagementId function to get the engagement id
 * item: the item that we want to get the id for it. e.g selectedCard
 * return: the id of the item
 */
export const getEngagementId = (item) =>
  `${item?.tweet_id ? item?.tweet_id : item?.id}-${item?.tracker_id}`;

/** useSLACounterUpdater function to update the sla counter
 * chatData: the chat data that we want to update the sla counter for it
 * itemSelected: the selected item that we want to update the sla counter for it
 */
export const useSLACounterUpdater = (chatData, itemSelected) => {
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const [mainDates, setMainDates] = useState({});

  useEffect(() => {
    let dates = {};
    setMainDates({ ...dates });
    const engagementId = getEngagementId(itemSelected);
    //here we handle sla counter to be dynamic , because sla counters effected by any change at masseges data
    let slaObj = { ...reduxSlaData };
    let slaSelectedObj = {
      ...slaObj[engagementId],
    };
    slaSelectedObj.cardText = chatData[chatData?.length - 1]?.content?.text;
    slaObj[engagementId] = {
      ...slaSelectedObj,
    };
    dispatch(slaData(slaObj));
    // here we filter msgs from greeting msg to not affect sla counters
    let chatDataExptGreetingMsg = [];
    if (chatData?.length) {
      chatDataExptGreetingMsg = chatData?.filter(
        (item) =>
          item?.content?.automated_by !== "greeting" && !item?.isFullDmHistory,
      );
    }
    if (slaSelectedObj?.slaId) {
      slaSelectedObj.lastMsgDirecton =
        chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]?.direction;

      //here we check if frt counter is live
      if (!slaSelectedObj?.frtSec) {
        /*check if last msg direction === "outbound" to stop frt live counter and update frtSec to 
         be diferance between card created at date and last msg date*/
        if (
          chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
            ?.direction === "outbound"
        ) {
          slaSelectedObj.frtSec =
            chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
              ?.created_at - itemSelected?.created_at;
          slaObj[engagementId] = {
            ...slaSelectedObj,
          };
          dispatch(slaData(slaObj));
        }
      } else {
        //here we handle nrt counter
        /*we save nrt as alist of blocks if time_value is falsy this block well be live, if last msg
         direction === "outbound" this mean if last block of nrt list is live we will stop it,if last msg
         direction === "inbound" this mean we add new block as alast block and this will be live  */
        if (
          chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
            ?.direction === "outbound" &&
          slaSelectedObj?.nrtList?.length &&
          !slaSelectedObj?.nrtList?.[0]?.time_value
        ) {
          let nrtArr = [
            {
              created_at:
                chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
                  ?.created_at,
              time_value:
                chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
                  ?.created_at -
                chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 2]
                  ?.created_at,
            },
            ...slaSelectedObj?.nrtList?.slice(
              1,
              slaSelectedObj?.nrtList?.length,
            ),
          ];
          slaSelectedObj.nrtList = [...nrtArr];
          slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
          slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
          slaObj[engagementId] = {
            ...slaSelectedObj,
          };
          dispatch(slaData(slaObj));
        } else if (
          chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
            ?.direction === "inbound"
        ) {
          if (
            slaSelectedObj?.nrtList?.length &&
            !slaSelectedObj?.nrtList?.[0]?.time_value
          ) {
            let nrtArr = [
              {
                created_at:
                  chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
                    ?.created_at,
                time_value: null,
              },
              ...slaSelectedObj?.nrtList?.slice(
                1,
                slaSelectedObj?.nrtList?.length,
              ),
            ];
            slaSelectedObj.nrtList = [...nrtArr];
            slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
            slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
            slaObj[engagementId] = {
              ...slaSelectedObj,
            };
            dispatch(slaData(slaObj));
          } else {
            let nrtArr = [
              {
                created_at:
                  chatDataExptGreetingMsg[chatDataExptGreetingMsg?.length - 1]
                    ?.created_at,
                time_value: null,
              },
              ...(slaSelectedObj?.nrtList?.length && slaSelectedObj?.nrtList
                ? slaSelectedObj?.nrtList
                : []),
            ];
            slaSelectedObj.nrtList = [...nrtArr];
            slaSelectedObj.nrtSec = nrtArr?.[0]?.time_value;
            slaSelectedObj.nrtDate = nrtArr?.[0]?.created_at;
            slaObj[engagementId] = {
              ...slaSelectedObj,
            };
            dispatch(slaData(slaObj));
          }
        }
      }
    }
    // handle the main date for grop of masseges
    /* ex: if we have first and second massage created at for them at same day we save at mainDates object
    state {[index of first massage]:"date of this day"} */
    chatData?.map((item, index) => {
      const date = moment.unix(item?.created_at).utc().format("DD/MM/YYYY");
      let check = false;
      Object?.keys(dates)?.forEach(function (key, index) {
        if (dates[key] === date) {
          check = true;
        } else {
          check = false;
        }
      });
      if (!check) {
        dates[index] = date;
      }
    });
    setMainDates({ ...dates });
  }, [chatData]);
  return { mainDates };
};
