import Services from "./Services";

let Audience = {};

Audience.getAudience = (queryData) => {
  return Services.getData("api/v3/cdp/profiles", queryData);
};

Audience.getUserProfile = (id) => {
  return Services.getData(`api/v3/cdp/profiles/${id}`);
};

Audience.audienceUserProfile = (profileId) => {
  return Services.getData(`api/v3/cdp/profiles/${profileId}`);
};

Audience.deleteProfile = (profileID) => {
  return Services.deleteData(`api/v3/cdp/profiles/${profileID}`);
};

Audience.editProfileData = (profileID, queryData) => {
  return Services.putData(`api/v3/cdp/profiles/${profileID}`, queryData);
};

Audience.getAudienceSurvey = (profileID, queryData) => {
  return Services.getData(
    `api/v3/cdp/profiles/${profileID}/surveys`,
    queryData,
  );
};

Audience.getAudienceSurveyDetails = (profileId, queryData) => {
  return Services.getData(
    `api/v3/cdp/profiles/${profileId}/surveys/survey_detail`,
    queryData,
  );
};

Audience.getAudienceInteractions = (profileID, queryData) => {
  return Services.getData(
    `api/v3/cdp/profiles/${profileID}/interactions`,
    queryData,
  );
};
Audience.getSegmentList = (queryData) => {
  return Services.getData("api/v3/cdp/segments", queryData);
};

Audience.getListOfSegments = (queryData) => {
  return Services.getData("api/v3/cdp/segments/list_segments", queryData);
};

Audience.deleteSegment = (segmentId) => {
  return Services.deleteData(`api/v3/cdp/segments/${segmentId}`);
};

Audience.updateSegmentName = (segmentId, queryData) => {
  return Services.putData(`api/v3/cdp/segments/${segmentId}`, queryData);
};

Audience.createSegment = (queryData) => {
  return Services.postData("api/v3/cdp/segments", queryData);
};
Audience.excludeSegmantProfile = (queryData) => {
  return Services.deleteData(`api/v3/cdp/segments/destroy_profiles`, queryData);
};

Audience.addSegmantProfile = (queryData) => {
  return Services.putData(`api/v3/cdp/segments/add_profiles`, queryData);
};

Audience.exportCDPExcelFile = (queryData) => {
  return Services.postData(`api/v3/export/xlsx`, queryData);
};
Audience.importCDPExcelFile = (queryData) => {
  return Services.postData(`api/v3/cdp/imports`, queryData);
};
Audience.getCDPXlsxGuide = () => {
  return Services.getData("api/v3/cdp/imports/xlsx_guide");
};
Audience.importCDPImports = (queryData) => {
  return Services.getData(`api/v3/cdp/imports`, queryData);
};
Audience.importCDPDwonload = (id) => {
  return Services.getData(`/api/v3/cdp/imports/${id}/download`);
};
Audience.importCDPDelete = (id) => {
  return Services.deleteData(`/api/v3/cdp/imports/${id}`);
};
Audience.importCDpViewNum = (id) => {
  return Services.getData(`/api/v3/cdp/imports/${id}/errors_numbers`);
};

export default Audience;
