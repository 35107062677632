import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import "../createHeader/createHeader.scss";
import "./steppers.scss";
import { checkCrossedValid } from "../../pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/crossedKeywords";
import TopicsFuntions from "../../pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/manualTopics/components/topicsFunctions";
import DashboardFunctions from "../../pages/dashboard/dashboardFunctions";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
const StepperButtons = (props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { handleTopicsData } = TopicsFuntions();
  const { submitCreateDashboard } = DashboardFunctions();

  const {
    activeStep,
    setActiveStep,
    handleNext,
    setKeywords,
    keywords,
    disableNextStep,
    languages,
    sources,
    nbRanks,
    nbDuplicated,
    setNbDuplicated,
    countries,
    nbExcludedSource,
    titleSearchKeyword,
    checked,
    arrowChecked,
    selectedMonitorType,
    monitorTopics,
    errorKeywordsMsg,
    errorExKeywordsMsg,
    displayNameMsgError,
    twitterCountries,
    twitterLanguages,
    exceededLimit,
    selectedDashboardType,
    selectedOptions,
    isCustomDashboard,
    createCustomDashboard,
    isVarifiedAccounts,
    ignoretweetsFlag,
  } = props;

  const {
    submitCreateMonitor,
    createMonitorBtn,
    monitorData,
    createMonitor,
    createDashboard,
    dashboardData,
    isVaildDashboard,
    selectedFacebookPages,
  } = props;

  const handleCancel = () => {
    createMonitor
      ? navigate("/social/monitor_list")
      : navigate("/dashboard/dashboard_list");
  };

  const handleSubmit = () => {
    if (createMonitor) {
      const keyword = [...keywords];
      const obj = { monitor_options: {}, data_sources: [] };
      const twitter = { Twitter: {} };
      const facebook = { FACEBOOK: {} };
      const instagram = { INSTAGRAM: {} };
      // const talkwalker = { TALKWALKER: {} };
      const newsblogs = { NEWSBLOGS: {} };
      const keywordsData = { KEYWORD: {} };
      let check;
      let arrowCheck;
      let isValid = false;
      let newsValue = "";
      keyword.map((data) => {
        Object.values(data)[0].map((val) => {
          if (Object.keys(data)[0] === "newsBlogs") {
            newsValue = "news_and_blogs";
          } else {
            newsValue = Object.keys(data)[0];
          }
          if (
            checked.indexOf(newsValue) !== -1 ||
            arrowChecked.indexOf(newsValue) !== -1
          ) {
            check =
              arrowChecked.indexOf(newsValue) !== -1
                ? arrowChecked[arrowChecked.indexOf(newsValue)]
                : "";
            arrowCheck =
              checked.indexOf(newsValue) !== -1
                ? checked[checked.indexOf(newsValue)]
                : "";
          }

          if (
            Object.keys(data)[0] === "twitter" &&
            (Object.keys(data)[0] === check ||
              Object.keys(data)[0] === arrowCheck)
          ) {
            if (Object.values(val)[0].length > 0) {
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "keywords"
              ) {
                twitter.Twitter["ignore_retweets"] = ignoretweetsFlag;
                twitter.Twitter["is_varified_accounts"] = isVarifiedAccounts;
                twitter.Twitter["keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
                if (
                  checkCrossedValid(Object.values(val)[0]) &&
                  Object.values(val)[0].length == 1
                ) {
                  val.errorMsg = CheckValueLocale(
                    "keywords_is_required_to_monitor_data",
                    "",
                    {},
                    intl,
                  );
                }
              }

              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "spam_keywords"
              ) {
                twitter.Twitter["spam_keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "collect_tweets"
              ) {
                twitter.Twitter["included_accounts"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "ignore_tweets"
              ) {
                twitter.Twitter["excluded_accounts"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }
              if (twitterCountries?.length) {
                if (!twitter?.Twitter?.sources) twitter.Twitter.sources = {};
                twitter.Twitter.sources["tw_countries"] =
                  twitterCountries?.join(",");
              }
              if (twitterLanguages?.length) {
                if (!twitter?.Twitter?.sources) twitter.Twitter.sources = {};
                twitter.Twitter.sources["tw_languages"] =
                  twitterLanguages?.join(",");
              }
            } else {
              if (Object.keys(val)[0] === "keywords") {
                val.errorMsg = CheckValueLocale(
                  "keywords_is_required_to_monitor_data",
                  "",
                  {},
                  intl,
                );
              }
            }
          }

          if (
            Object.keys(data)[0] === "instagram" &&
            (Object.keys(data)[0] === check ||
              Object.keys(data)[0] === arrowCheck)
          ) {
            if (Object.values(val)[0].length > 0) {
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "hashtags"
              ) {
                instagram.INSTAGRAM["keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
                if (
                  checkCrossedValid(Object.values(val)[0]) &&
                  Object.values(val)[0].length == 1
                ) {
                  val.errorMsg = CheckValueLocale(
                    "keywords_is_required_to_monitor_data",
                    "",
                    {},
                    intl,
                  );
                }
              }

              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "spam_hashtags"
              ) {
                instagram.INSTAGRAM["spam_keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }
            } else {
              if (Object.keys(val)[0] === "hashtags") {
                val.errorMsg = CheckValueLocale(
                  "keywords_is_required_to_monitor_data",
                  "",
                  {},
                  intl,
                );
              }
            }
          }
          if (
            Object.keys(data)[0] === "facebook" &&
            (Object.keys(data)[0] === check ||
              Object.keys(data)[0] === arrowCheck)
          ) {
            if (Object.values(val)[0].length > 0) {
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "keywords"
              ) {
                facebook.FACEBOOK["keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
                if (
                  checkCrossedValid(Object.values(val)[0]) &&
                  Object.values(val)[0].length == 1
                ) {
                  val.errorMsg = CheckValueLocale(
                    "keywords_is_required_to_monitor_data",
                    "",
                    {},
                    intl,
                  );
                }
              }
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "spam_keywords"
              ) {
                facebook.FACEBOOK["spam_keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }
              if (selectedFacebookPages?.length) {
                facebook.FACEBOOK["facebook_keywords_pages"] =
                  selectedFacebookPages;
              }
            } else {
              if (Object.keys(val)[0] === "keywords") {
                val.errorMsg = CheckValueLocale(
                  "keywords_is_required_to_monitor_data",
                  "",
                  {},
                  intl,
                );
              }
            }
          }
          if (
            newsValue === "news_and_blogs" &&
            (newsValue === check || newsValue === arrowCheck)
          ) {
            if (
              Object.values(val)[0].length > 0 ||
              titleSearchKeyword.length > 0
            ) {
              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "keywords"
              ) {
                newsblogs.NEWSBLOGS["keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
                if (
                  (checkCrossedValid(Object.values(val)[0]) &&
                    Object.values(val)[0].length == 1) ||
                  (checkCrossedValid(titleSearchKeyword) &&
                    titleSearchKeyword.length == 1)
                ) {
                  val.errorMsg = CheckValueLocale(
                    "keywords_is_required_to_monitor_data",
                    "",
                    {},
                    intl,
                  );
                }
              }

              if (
                Object.values(val)[0].length > 0 &&
                Object.keys(val)[0] === "spam_keywords"
              ) {
                newsblogs.NEWSBLOGS["spam_keywords"] = Object.values(val)[0]
                  .toString()
                  .split(",")
                  .join(",");
              }

              let nb_sources =
                  sources?.length === 0
                    ? ""
                    : sources?.toString()?.split(",")?.join(","),
                nb_ranks =
                  nbRanks?.length === 0
                    ? ""
                    : nbRanks?.toString()?.split(",")?.join(","),
                nb_countries =
                  countries?.length === 0
                    ? ""
                    : countries?.toString()?.split(",")?.join(","),
                nb_languages =
                  languages?.length === 0
                    ? ""
                    : languages?.toString()?.split(",")?.join(","),
                nb_excluded_sources =
                  nbExcludedSource?.length === 0
                    ? ""
                    : nbExcludedSource?.toString()?.split(",")?.join(","),
                nb_title_search_keywords =
                  titleSearchKeyword?.length === 0
                    ? ""
                    : titleSearchKeyword?.toString()?.split(",")?.join(",");

              newsblogs.NEWSBLOGS["sources"] = {
                nb_sources: nb_sources,
                nb_ranks: nb_ranks,
                nb_duplicated: nbDuplicated,
                nb_countries: nb_countries,
                nb_languages: nb_languages,
                nb_excluded_sources: nb_excluded_sources,
                nb_title_search_keywords: nb_title_search_keywords,
              };
            } else {
              if (
                Object.keys(val)[0] === "keywords" &&
                titleSearchKeyword.length === 0
              ) {
                val.errorMsg = CheckValueLocale(
                  "keywords_is_required_to_monitor_data",
                  "",
                  {},
                  intl,
                );
              }
            }
          }

          if (val.errorMsg !== "") {
            isValid = true;
          } else if (
            val.errorMsg !==
            CheckValueLocale(
              "keywords_is_required_to_monitor_data",
              "",
              {},
              intl,
            )
          ) {
            isValid = false;
          }
        });
      });

      let dataSource = [];
      if (Object.keys(Object.values(twitter)[0]).length > 0) {
        dataSource.push(1);
        keywordsData.KEYWORD["TWITTER"] = Object.values(twitter)[0];
      }
      if (Object.keys(Object.values(facebook)[0]).length > 0) {
        dataSource.push(2);
        keywordsData.KEYWORD["FACEBOOK"] = Object.values(facebook)[0];
      }
      if (Object.keys(Object.values(instagram)[0]).length > 0) {
        dataSource.push(3);
        keywordsData.KEYWORD["INSTAGRAM"] = Object.values(instagram)[0];
      }

      if (Object.keys(Object.values(newsblogs)[0]).length > 0) {
        dataSource.push(4);
        // keywordsData.KEYWORD["NEWSBLOGS"] = Object.values(newsblogs)[0];
        keywordsData.KEYWORD["TALKWALKER"] = Object.values(newsblogs)[0];
      }
      obj.monitor_options = keywordsData;
      obj.data_sources = dataSource;

      setKeywords(keyword);
      submitCreateMonitor(obj, props.allSocialAccounts, isValid);
    } else if (createDashboard) {
      let dashboardMonitorsArray =
        dashboardData?.dashabordMonitors?.length > 0
          ? dashboardData?.dashabordMonitors?.toString()
          : dashboardData?.dashabordMonitors[0];

      let dashboardObject = {
        name: dashboardData?.dashabordName,
        dashboard_type_id: dashboardData?.dashboardTypeID,
        monitors: dashboardMonitorsArray,
        metrics: "*",
      };

      if (isVaildDashboard) {
        submitCreateDashboard(dashboardObject);
      }
    }
  };
  //check topics data to enable create btn or not
  const [isValidTopics, setIsValidTopics] = useState(false);

  useEffect(() => {
    if (monitorTopics) {
      let validTopics =
        handleTopicsData(monitorTopics) &&
        errorKeywordsMsg?.errorMsg === "" &&
        errorExKeywordsMsg?.errorMsg === "" &&
        displayNameMsgError?.errorMsg === "";
      setIsValidTopics(validTopics);
    }
  }, [
    monitorTopics,
    errorKeywordsMsg,
    errorExKeywordsMsg,
    displayNameMsgError,
  ]);

  return (
    <Box
      className="steppers-buttons"
      style={{
        marginBottom:
          selectedMonitorType === "KEYWORD" && activeStep === 1 && "8%",
      }}
    >
      <Box className="steppers-btn-wrapper">
        {activeStep === 1 ? (
          <LucButton
            id="back-btn-create"
            variant="outline"
            type={"secondary"}
            onClick={() => setActiveStep(0)}
          >
            {CheckValueLocale("back", "", {}, intl)}
          </LucButton>
        ) : (
          <div />
        )}

        <Box className="question-title-container">
          <Box>
            <LucButton
              variant="flat"
              type={"secondary"}
              className="stepper-cancel-btn"
              onClick={handleCancel}
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>
          </Box>

          {activeStep === 1 && createMonitor ? (
            <LucButton
              onClick={handleSubmit}
              disabled={
                createMonitorBtn && isValidTopics && !exceededLimit
                  ? false
                  : true
              }
            >
              {CheckValueLocale("create_monitor", "", {}, intl)}
            </LucButton>
          ) : activeStep === 1 && createDashboard ? (
            <LucButton
              onClick={isCustomDashboard ? createCustomDashboard : handleSubmit}
              disabled={
                selectedDashboardType == "CUSTOM_DASHBOARD"
                  ? selectedOptions?.length > 0
                    ? false
                    : true
                  : createDashboard && isVaildDashboard
                    ? false
                    : true
              }
            >
              {CheckValueLocale("create_dashboard", "", {}, intl)}
            </LucButton>
          ) : (
            <LucButton
              onClick={handleNext}
              className="next-step-style"
              disabled={disableNextStep}
            >
              {CheckValueLocale(
                createDashboard ? "custom_dashboard_next_step" : "next_step",
                "",
                {},
                intl,
              )}
            </LucButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StepperButtons;
