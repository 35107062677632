import { Box } from "@mui/material";
import WidgetHeader from "../widgetHeader";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import FetchingData from "components/fetchingData/fetchingData";
import { BigTable } from "./bigTable";
import "./bigTableWidget.scss";
import { useState } from "react";
/**
 * BigTableWidget component is used to display the big table widget with horizontal scroll.
 *
 */
const BigTableWidget = ({
  chartId,
  title,
  titleToolTip,
  tableHeaders,
  tableData,
  disabled,
  isLoading,
  stickyColumn,
  groupByOptions,
  selectedGroupBy,
  onGroupByChange,
  count,
  handleTablePagination,
  page,
  rowsPerPage,
  showHeader = true,
  alertMessage,
  ...props
}) => {
  const stillFetchingData =
    _.isEqual(
      props?.statisticsPreLoaderDataSources?.sort(),
      props?.preLoaderTrackerDataSources?.sort(),
    ) !== true || isLoading;

  const hasData = tableData && tableData?.length > 0;

  return (
    <Box className="big-table-widget" id={chartId}>
      {groupByOptions?.length || showHeader ? (
        <WidgetHeader
          title={title}
          showDownloadIcon={false}
          titleToolTip={titleToolTip}
          chartId={chartId}
          bigImg={true}
          generalGroupByOptions={groupByOptions}
          showGeneralGroupBy={groupByOptions?.length}
          selectedGeneralGroupBy={selectedGroupBy}
          handleGeneralGroupByChange={onGroupByChange}
          disabledGeneralGroupBy={disabled}
        />
      ) : null}
      {stillFetchingData ? (
        <Box className="big-table-widget-fetching">
          <FetchingData />
        </Box>
      ) : !hasData ? (
        <Box className="big-table-widget-fetching">
          <NoDataFound />
        </Box>
      ) : (
        <BigTable
          tableHeaders={tableHeaders}
          tableData={tableData}
          disabled={disabled}
          isLoading={isLoading}
          stickyColumn={stickyColumn}
          handleOnPageChange={handleTablePagination}
          count={count}
          paginationData={{ page, rowsPerPage }}
          alertMessage={alertMessage}
        />
      )}
    </Box>
  );
};

export default BigTableWidget;
