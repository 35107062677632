import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  handlelUserRoles,
  handleDateLangFormat,
} from "utils/helpers";
import moment from "moment";
import {
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "@mui/material/Pagination";
import CircularLoading from "components/circularProgress";
import ReportController from "services/controllers/reportController";
import Snackbar from "components/snackBar";
import ReportDetail from "./report-detail/report-detail";
import ReportHeader from "./report-header/report-header";
import "./view-report.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";
import { formatDate } from "../utils/formatDateUtlit";

const ViewReport = () => {
  const intl = useIntl();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({});
  const [reportDateData, setReportDateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [reportDownloadFlag, setReportDownloadFlag] = useState(null);
  const [isRequestError, setIsRequestError] = useState(false);

  const getReportDetails = (reportId, page) => {
    setLoading(true);
    ReportController.getReportDetails(reportId, page).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data && setReportData(res?.data[0]?.report_info);
      res?.data && setReportDateData(res?.data[1]?.records);
      res?.pagination?.pages &&
        res?.pagination?.pages > 0 &&
        setTotalReports(res?.pagination?.pages);
      setLoading(false);
    });
  };

  const exportEnhancedReport = (reportId) => {
    ReportController.exportEnhancedReport(reportId).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data &&
        res?.data?.map((item, key) => {
          window.open(item?.url, "_blank");
        });
      setReportDownloadFlag(true);
    });
  };

  const exportOldReport = (report) => {
    let type_report = reportData?.attributes?.report_extra?.branding_type,
      reportId = reportData?.id,
      monitorName =
        reportData?.attributes?.monitors &&
        reportData?.attributes?.monitors[0]?.name,
      dataFileName = report?.data_file_name,
      monitor_id =
        reportData?.attributes?.monitors &&
        reportData?.attributes?.monitors[0]?.id;
    var user_language = "";
    if (window?.localStorage?.lang === "en") {
      user_language = "en-US";
    } else {
      user_language = "ar-SA";
    }

    var monitor_sections = reportData?.attributes?.monitor_sections;
    var data_source_name;

    for (let i = 0; i < monitor_sections?.length; i++) {
      data_source_name = monitor_sections[i]?.data_source_name;
      for (let x = 0; x < monitor_sections[i]?.sections?.length; x++) {
        getMonitorSentimentCategories(
          monitor_id,
          reportId,
          monitorName,
          dataFileName,
          user_language,
          type_report,
          monitor_sections[i].sections[x],
          data_source_name,
        );
      }
    }
  };

  const getMonitorSentimentCategories = (
    monitor_id,
    reportId,
    moniotr_name,
    dataFileName,
    user_language,
    type_report,
    section_name,
    data_source_name,
  ) => {
    ReportController.getMonitorSentimentCategories(monitor_id).then((data) => {
      setIsRequestError(!!data?.errorMsg);
      if (data?.categories === undefined) {
        // this.handleSuccessErrorMsg(false)
      } else {
        let sentiment_categories = JSON.stringify(data?.categories);
        let suggested_name = reportId + "-" + moniotr_name;
        var queryData = {
          reportId,
          dataFileName,
          user_language,
          monitor_id,
          suggested_name,
          sentiment_categories,
          type_report,
          section_name,
          data_source_name,
        };
        if (data_source_name == "TWITTER" && section_name == "METRICS") {
          ReportController.getTwitterReport(queryData).then((res) => {
            setIsRequestError(!!res?.errorMsg);
            if (res?.status === 200) {
              setReportDownloadFlag(true);
            } else {
              setReportDownloadFlag(false);
            }
          });
        } else {
          ReportController.exportReportFromFile(
            monitor_id,
            type_report,
            queryData,
          ).then((res) => {
            setIsRequestError(!!res?.errorMsg);
            if (res?.params === undefined) {
              setReportDownloadFlag(false);
            } else {
              handleGetPptLink(res?.params);
            }
          });
        }
      }
    });
  };

  const handleGetPptLink = (queryData) => {
    ReportController.getPptLink(queryData).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.status === 200) {
        setReportDownloadFlag(true);
      } else {
        setReportDownloadFlag(false);
      }
    });
  };

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    getReportDetails(reportData?.id, page);
  };

  const navigate = useNavigate();
  const redirectToNewsLetterReport = (reportId, reportDataId) => {
    navigate(
      `/reports/newslettersreport_details/${reportId}?report_data_id=${reportDataId}`,
    );
  };

  useEffect(() => {
    getReportDetails(location?.pathname?.split("/")[3]);
  }, [location]);

  const showPausedMonitors = (monitors) => {
    if (monitors?.length < 3) {
      return monitors?.join(", ");
    } else {
      const remainingCount = monitors?.length - 2;
      return (
        <CustomizedTooltip
          title={monitors?.join(" / ")}
          placement="bottom"
          arrow
          styles={{ height: "auto", padding: "10px" }}
        >
          {`${monitors?.slice(0, 2)?.join(", ")}... (+${remainingCount})`}
        </CustomizedTooltip>
      );
    }
  };

  const renderAlertContent = () => {
    const { issues, platform, manual_status, is_luci_report, has_luci_quota } =
      reportData?.attributes || {};

    if (issues?.is_limit_reached) {
      return CheckValueLocale(
        "view_report_paused_due_to_limit_reached",
        "",
        {},
        intl,
      );
    }

    if (issues?.are_monitors_deleted) {
      return CheckValueLocale(
        platform === "SM"
          ? "view_report_paused_due_to_monitor_deleted"
          : "view_report_paused_due_to_channel_deleted",
        "",
        {
          link: (
            <Link to="/reports/add-report" className="create-report-link">
              "{CheckValueLocale("create_report", "", {}, intl)}"
            </Link>
          ),
        },
        intl,
      );
    }

    if (issues?.paused_monitors?.length) {
      return (
        <Box className="view-report-warning-msg-wrapper deleted-monitors">
          <Typography>
            {CheckValueLocale(
              platform === "SM"
                ? "view_report_paused_due_to_monitor_paused"
                : "view_report_paused_due_to_channel_paused",
              "",
              {
                paused: (
                  <Box component="span">
                    {showPausedMonitors(issues?.paused_monitors)}
                  </Box>
                ),
              },
              intl,
            )}
          </Typography>
          {platform === "SM" && handlelUserRoles("SM", "VIEW_MONITOR_LIST") ? (
            <Link to="/social/monitor_list">
              {CheckValueLocale("view_report_monitors_list", "", {}, intl)}
            </Link>
          ) : platform === "CXM" &&
            (handlelUserRoles("CXM", "CREATE_CHANNEL") ||
              handlelUserRoles("CXM", "EDIT_CHANNEL") ||
              handlelUserRoles("CXM", "DESTROY_CHANNEL")) ? (
            <Link to="/manage-channels/list">
              {CheckValueLocale("view_report_manage_channels", "", {}, intl)}
            </Link>
          ) : null}
        </Box>
      );
    }

    if (is_luci_report && !has_luci_quota) {
      return (
        <Box className="view-report-warning-msg-wrapper luci-quota">
          <Typography>
            {CheckValueLocale("luci_report_energy_warning", "", {}, intl)}
          </Typography>
          {handlelUserRoles("GENERAL", "VIEW_SETTING") ? (
            <Link to="/settings/subscription">
              {CheckValueLocale("upgrade_luci_energy", "", {}, intl)}
            </Link>
          ) : null}
        </Box>
      );
    }

    if (!manual_status) {
      return CheckValueLocale(
        "reports_was_paused",
        "",
        {
          date:
            moment(reportData?.attributes?.created_at)?.format("YYYY-MM-DD") ||
            "",
        },
        intl,
      );
    }

    return null;
  };

  return (
    <Container maxWidth="xl" className="create-report">
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {reportDownloadFlag != null ? (
            <Snackbar
              open={reportDownloadFlag != null ? true : false}
              handleClose={() => {
                setReportDownloadFlag(null);
              }}
              severity={reportDownloadFlag ? "success" : "error"}
              message={
                reportDownloadFlag === true ? (
                  <Box className="view-report-msg">
                    <Box>
                      {CheckValueLocale("report_generated", "", {}, intl)}
                    </Box>
                    <Box>
                      {CheckValueLocale("report_generated_body", "", {}, intl)}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box>{CheckValueLocale("report_fail", "", {}, intl)}</Box>
                    <Box>
                      {CheckValueLocale("report_fail_try_later", "", {}, intl)}
                    </Box>
                  </Box>
                )
              }
            />
          ) : (
            ""
          )}
          <ReportHeader reportData={reportData} />
          <ReportDetail reportData={reportData} />

          {reportData?.attributes && renderAlertContent() ? (
            <Box my={2}>
              <Alert id="view-report-warning-alert" severity="warning">
                {renderAlertContent()}
              </Alert>
            </Box>
          ) : null}

          {/* Next Delivery Date shows only when no reports are available and next delivery date is available from BE */}
          {!reportDateData?.length &&
          reportData?.attributes?.next_delivery &&
          reportData?.attributes?.next_delivery !== "N/A" ? (
            <Box my={2}>
              <Box className="report-date-empty-box">
                <Typography variant="subtitle2" component="div">
                  {CheckValueLocale(
                    "report_next_delivery_msg",
                    "",
                    {
                      date:
                        moment(reportData?.attributes?.next_delivery)
                          ?.utcOffset(reportData?.attributes?.next_delivery)
                          ?.format("YYYY-MM-DD (h:mma)") || "",
                    },
                    intl,
                  )}
                </Typography>
              </Box>
            </Box>
          ) : null}

          {reportDateData?.length > 0 ? (
            <Box className="view-report-list">
              <Grid container>
                {reportDateData?.map((val, index) => (
                  <Box key={`list-card-${index}`}>
                    <Box className="list-card">
                      <Typography variant="subtitle2">
                        {CheckValueLocale("report_for", "", {}, intl)}
                        <Box component={"span"} className="date-card">
                          {CheckValueLocale(
                            handleDateLangFormat(
                              val?.created_at,
                              "dddd",
                              intl?.locale,
                            ),
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                        {", "}
                        <Box component={"span"} className="date-card">
                          {formatDate(val?.created_at, intl?.locale)}
                        </Box>
                      </Typography>

                      {reportData?.attributes?.report_type_id === 2 ? (
                        <Tooltip
                          title={CheckValueLocale("view_report", "", {}, intl)}
                          arrow
                          placement="top"
                        >
                          <IconButton
                            onClick={() =>
                              redirectToNewsLetterReport(
                                location.pathname.split("/")[3],
                                val?.report_data_id,
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      ) : reportData?.attributes?.report_extra
                          ?.report_template === "dynamic_design" ||
                        reportData?.attributes?.report_extra
                          ?.report_template === "enhanced_design" ? (
                        <CustomizedTooltip
                          id="menu-item-list-tooltip"
                          title={
                            <Box className="item-title-tooltip">
                              {CheckValueLocale("download", "", {}, intl)}
                            </Box>
                          }
                          placement="bottom"
                          arrow
                        >
                          <IconButton
                            onClick={() =>
                              exportEnhancedReport(val?.report_data_id)
                            }
                          >
                            <Box className="report-icon-download">
                              <FontAwesomeIcon icon={faArrowToBottom} />
                            </Box>
                          </IconButton>
                        </CustomizedTooltip>
                      ) : (
                        <CustomizedTooltip
                          id="menu-item-list-tooltip"
                          title={
                            <Box className="item-title-tooltip">
                              {CheckValueLocale("download", "", {}, intl)}
                            </Box>
                          }
                          placement="bottom"
                          arrow
                        >
                          <IconButton onClick={() => exportOldReport(val)}>
                            <Box className="report-icon-download">
                              <FontAwesomeIcon icon={faArrowToBottom} />
                            </Box>
                          </IconButton>
                        </CustomizedTooltip>
                      )}
                    </Box>
                  </Box>
                ))}
              </Grid>

              {totalReports > 1 && (
                <Pagination
                  onChange={handleChangePage}
                  page={page}
                  className="pagination-monitor-list"
                  count={totalReports > 1 ? totalReports : 1}
                  variant="outlined"
                />
              )}
            </Box>
          ) : null}
          {isRequestError && (
            <Snackbar
              lang={window?.localStorage?.lang}
              open={true}
              handleClose={() => {
                setIsRequestError(!!false);
              }}
              severity="error"
              message={CheckValueLocale(
                "something_went_wrong_try_agin",
                "",
                {},
                intl,
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};
export default ViewReport;
