import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import "../../../monitors.scss";
import { Link, useParams } from "react-router-dom";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import QuestionCard from "pages/Monitors/Components/questionCard";
import TopicsSection from "../../../Components/topicsSection";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";

const EngagementsBody = (props) => {
  const intl = useIntl();
  let { response } = props;
  const monitorDetails = props.monitorData;
  const { monitorType, monitorDataSource, monitorId } = useParams();
  //handle response

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  // _____________________________________________
  const [reactions, setReactions] = useState([]);
  const [reactionsPreLoader, setReactionsPreLoader] = useState([]);
  const [pageReactionPieChartValue, setPageReactionPieChartValue] = useState(
    [],
  );
  //______________________________________________
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  //_________________________________________
  const [themeTrend, setThemeTrend] = useState([]);
  const [themeAnalysis, setThemeAnalysis] = useState([]);
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);
  const [themeXlxs, setThemeXlxs] = useState([]);
  //_________________________________________
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );

  //_________________________________________
  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);

  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);
  // _____________________________________________

  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );
  // _____________________________________________
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [topPostsPreLoader, setTopPostsPreLoader] = useState([]);
  // ___________________________________________________________
  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);
  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Facebook__EngagementsPage__volume_overtime":
        setCommentsVolume(response.eventData.posts_over_time);
        setTotalCommentsVolumeCount(response.eventData.total_posts_count);
        setCommentsVolumePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__reactions":
        setReactions(
          response.eventData.posts_reactions.posts_reactions_over_time,
        );
        setReactionsPreLoader((oldArray) => [...oldArray, response.monitor_id]);

        setPageReactionPieChartValue(
          response.eventData.posts_reactions.posts_reactions_piechart,
        );
        break;
      case "Facebook__EngagementsPage__sentiment_analysis":
        Object.entries(response.eventData.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
        setSentimentAnalysis({
          negative: negativeArray,
          positive: positiveArray,
          neutral: neutralArray,
        });
        setSentimentAnalysisPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__posts_interactions":
        setCommentsInteractions(
          response?.eventData?.posts_interactions?.posts_interactions_over_time,
        );
        setCommentsInteractionsLegend(
          response?.eventData?.posts_interactions?.posts_interactions_legends,
        );

        let totalInteractions = response?.eventData?.posts_interactions
          ?.total_interactions
          ? response?.eventData?.posts_interactions?.total_interactions
          : 0;

        setTotalValuePostsInteractions(totalInteractions);

        setMaxValuePostsInteractions(
          response?.eventData?.posts_interactions?.max_interactions,
        );
        let date = handleUnixFormat(
          response?.eventData?.posts_interactions?.max_interaction_date,
        );
        setMaxValueInteractionsDate(date);

        setAvgValuePostsInteractions(
          response?.eventData?.posts_interactions?.average_interactions,
        );

        setCommentsInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);

        break;
      case "Facebook__EngagementsPage__top_keywords":
        setTopKeywords(response.eventData.top_keywords);
        setTopKeywordsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_hashtags":
        setTopHashtags(response.eventData.top_hashtags);
        setTopHashtagsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__associated_topics":
        setAssociatedTopics(response.eventData.associated_topics);
        setAssociatedTopicsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Facebook__EngagementsPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__EngagementsPage__dialects_and_sub_dialects":
        setDialects(response.eventData.dialects);
        setSubDialects(response.eventData.sub_dialects);
        setDialectsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Facebook__EngagementsPage__themes_and_sub_themes":
        setThemeAnalysis(response?.eventData?.themes?.theme_analysis);
        setThemeTrend(response?.eventData?.themes?.theme_trend);
        setThemeAnalysisPreLoader([response?.monitor_id]);
        setThemeTrendPreLoader([response?.monitor_id]);
        setThemeXlxs(response?.eventData?.themes);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { commentsVolume: commentsVolume },
      { commentsInteractions: commentsInteractions },
      { reactions: reactions },
      { sentimentAnalysis: sentimentAnalysis },
      { themeXlxs: themeXlxs },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { associatedTopics: associatedTopics },
      { topPosts: topPosts },
      { dialects: dialects },
      { subDialects: subDialects },
    ]);
  }, [
    commentsVolume,
    commentsInteractions,
    reactions,
    sentimentAnalysis,
    themeXlxs,
    topKeywords,
    topHashtags,
    associatedTopics,
    topPosts,
    dialects,
    subDialects,
  ]);
  // ________________________________________________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsVolumePreLoader([]);

      setReactions([]);
      setReactionsPreLoader([]);
      setPageReactionPieChartValue([]);

      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setThemeAnalysis([]);
      setThemeAnalysisPreLoader([]);
      setThemeTrend([]);
      setThemeTrendPreLoader([]);
      setThemeXlxs([]);

      setCommentsInteractions([]);
      setCommentsInteractionsLegend([]);
      setCommentsInteractionsPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);

      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoader([]);
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    props.checkDataIsFounded(
      commentsVolume,
      commentsInteractions,
      reactions,
      sentimentAnalysis,
      topKeywords,
      topHashtags,
      associatedTopics,
      topPosts,
      dialects,
      subDialects,
    );

    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      commentsVolumePreLoader,
      reactionsPreLoader,
      sentimentAnalysisPreLoader,
      commentsInteractionsPreLoader,
      topKeywordsPreLoader,
      topHashtagsPreLoader,
      associatedTopicsPreLoader,
      topPostsPreLoader,
      dialectsPreLoader,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );
  }, [
    commentsVolumePreLoader,
    reactionsPreLoader,
    sentimentAnalysisPreLoader,
    commentsInteractionsPreLoader,
    topKeywordsPreLoader,
    topHashtagsPreLoader,
    associatedTopicsPreLoader,
    topPostsPreLoader,
    dialectsPreLoader,
    commentsVolume,
    commentsInteractions,
    reactions,
    sentimentAnalysis,
    topKeywords,
    topHashtags,
    associatedTopics,
    topPosts,
    dialects,
    subDialects,
  ]);

  //Luci check [ noDataFound , Analyzing ]
  useEffect(() => {
    let x = props.checkLuciAllDataFounded(
      topPostsPreLoader,
      props.preLoaderTrackerDataSources,
    );
    let y = props.checkLuciDataIsFounded([topPosts]);
    props.setLuciCheckAllDataExist(x);
    props.setLuciCheckDataIsEmpty(y);
  }, [topPosts, topPostsPreLoader, props.preLoaderTrackerDataSources]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"posts_volume_kw"}
          totalLabel={"posts"}
          totalValue={totalCommentsVolumeCount}
          data={commentsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          titleToolTip={"posts_volume_fb_tooltip"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={commentsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.FacebookIcon}
          labelColor={"#2AAAE2"}
        />
      </Box>

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip={"posts_interactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "reactions"]}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          hideLegend
          dataPiechart={commentsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={interactionsStats}
          isInteractionStatsWrapperStyle
        />
      </Box>

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_reactions"}
          titleToolTip={"reactions_fb_tooltip"}
          showDownloadIcon
          bigImg
          posts={reactions}
          showZoomPercentage
          iconAfterTitle={props.FacebookIcon}
          postsColumnPreLoaderDataSources={reactionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          labels={[
            "like_reaction",
            "love_reaction",
            "care_reaction",
            "haha_reaction",
            "wow_reaction",
            "angry_reaction",
            "sad_reaction",
          ]}
          hideLegend
          dataPiechart={pageReactionPieChartValue}
          colors={[
            "#1892F2",
            "#FB5C77",
            "#F7B94D",
            "#FEDC5F",
            "#00D7B5",
            "#EC8A92",
            "#1C404E",
          ]}
        />
      </Box>

      <Box mt={3}>
        <StackedLineWidget
          title={"posts_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_fb_tooltip"}
          data={sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.FacebookIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>

      <Box mt={3}>
        <ThemeAnalysis
          title={"posts_themes"}
          titleToolTip={"themes_tooltip"}
          data={themeAnalysis ? themeAnalysis : []}
          themeAnalysisPreLoaderDataSources={themeAnalysisPreLoader}
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          iconAfterTitle={props?.FacebookIcon}
          themesList={props?.themesOptions}
          isSocialListening={true}
        />
      </Box>

      <Box mt={3}>
        <ThemesTrends
          title={"posts_themes_trend"}
          titleToolTip={"themes_trend_tooltip"}
          data={themeTrend ? themeTrend : []}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={themeTrendPreLoader}
          preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
          iconAfterTitle={props?.FacebookIcon}
          widgetSection={props?.widgetSection}
          themesList={props?.themesOptions}
          isSocialListening={true}
        />
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_keywords"}
              titleToolTip={"posts_top_keywords_fb_tooltip"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              iconAfterTitle={props.FacebookIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"posts_top_hashtags"}
              titleToolTip={"posts_top_hashtags_ig_tooltip"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              iconAfterTitle={props.FacebookIcon}
              showDownloadIcon
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"posts_associated_topics"}
              titleToolTip={"posts_associated_topics_fb_tooltip"}
              data={associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={associatedTopicsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.FacebookIcon}
            />
          </Grid>
        </Grid>
      </Box>

      <Box my={3}>
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-all-posts-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-posts-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount}
            )
          </Link>
        </Box>
        <QuestionCard
          unixDate={props.unixDate}
          showProfile={true}
          productId={window.localStorage?.getItem("sm_id")}
          latestUnanswer={topPosts.slice(0, 6)}
          icon={faFacebook}
          iconColor={"facebook_icon"}
          showHeart
          hideChartBar
          showDefaultImg
          className={"no-data-found-question"}
          latestUnanswerPreloader={topPostsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          widget="top_posts"
          monitorType="keyword"
        />
      </Box>
      <>
        <Typography className="section-name">
          {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <PieChartWidget
                title={"dialects_subdialects"}
                titleToolTip={"dialects_subdialects_ig_tooltip"}
                data={dialects}
                subData={subDialects}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                iconAfterTitle={props.FacebookIcon}
                showAI
                showDownloadIcon
                pieChartPreLoaderDataSources={dialectsPreLoader}
                preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              />
            </Grid>
          </Grid>
        </Box>
      </>
      <TopicsSection iconToDisplay={props.FacebookIcon} isFacebook {...props} />
    </>
  );
};

export default EngagementsBody;
