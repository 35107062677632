import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { handelViewLink } from "../sharedFunctions/viewDownloadFunctions";
import { useGetDownloadableExcel } from "pages/notifications/hooks/useGetDownloadableExcel";
import LucButton from "shared/lucButton/lucButton";

const ViewDownloadComponent = ({
  item,
  navigate,
  intl,
  setSnackbar,
  isHomepage,
}) => {
  const { mutate: getDownloadableExcel, isPending: isGetFileLoading } =
    useGetDownloadableExcel(); // API call to get excel sheet body and name to use it in downloading it

  const isDownload = item?.notification_type === "excel_export";

  return (
    <Box>
      <LucButton
        id="notification-download-view-btn"
        type="primary"
        variant={isHomepage ? "flat" : isDownload ? "outline" : "filled"}
        size={isHomepage ? "large" : "small"}
        fullWidth
        loading={isGetFileLoading}
        onClick={() =>
          handelViewLink(item, navigate, setSnackbar, getDownloadableExcel)
        }
      >
        {CheckValueLocale(
          isDownload ? "download" : "view_notification",
          "",
          {},
          intl,
        )}
      </LucButton>
    </Box>
  );
};

export default ViewDownloadComponent;
