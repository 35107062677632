import "./main.scss";
import { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SideBar from "./sideBar/sideBar";
import { EngagementAnalyticsHeader } from "./components/engagementAnalyticsHeader";
import moment from "moment";
import { getActiveParams } from "shared/general-filter/activeFilters/activeFilters";
import EngagementsController from "services/controllers/engagementsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import InboxTab from "./contant/tabs/inboxTab";
import SLAsTab from "./contant/tabs/slasTab";
import AgentsTab from "./contant/tabs/agentsTab";
import TeamsTab from "./contant/tabs/teamsTab";
import InsightsTab from "./contant/tabs/insightsTab";
import FiltersController from "services/controllers/filtersController";
import SetupFlow from "../setup-flow/setupFlow";
import { handlelUserRoles } from "utils/helpers";
import Footer from "shared/footer/Footer";
import Header from "./contant/header";

const tabsArr = ["inbox", "slas", "agents", "teams", "Insights"];
const allowedTabs = ["inbox", "slas", "agents"];

const tabsComponents = {
  inbox: InboxTab,
  slas: SLAsTab,
  agents: AgentsTab,
  teams: TeamsTab,
  Insights: InsightsTab,
};

const EngagementAnalytics = () => {
  const intl = useIntl();
  const location = useLocation();

  const navigate = useNavigate();

  const mqttConnectionClosed = useRef(false);
  const clientMQTT = useRef();
  // isInitialLoading used for showing a loader when the user open the tabs for the first time
  // it's setted to true when the user open the tab and setted to false when the data is loaded
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [filterFields, setFilterFields] = useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [filterParams, setFilterParams] = useState({});
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [pickerStateApply, setPickerStateApply] = useState("last_7_days");
  const [response, setResponse] = useState({});
  const [openSetupFlow, setOpenSetupFlow] = useState(false);
  const [isDoneSetup, setIsDoneSetup] = useState(true);

  // ddl filter
  const selectedOptions = useRef({});

  const handleSeletedOptions = (key, value) => {
    if (!value) {
      delete selectedOptions.current[key];
    } else {
      selectedOptions.current[key] = value;
    }
    applyFilter(startDate, endDate, filterParams, 1, true);
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
  };
  const getFiltersFileds = () => {
    FiltersController.getEngagementAnalyticsFiltersFields(
      window?.localStorage?.engagements_id,
    ).then((data) => {
      if (!data?.errorMsg && data?.data) {
        setFilterFields(data?.data);
      }
    });
  };
  const applyFilter = (
    startDate,
    endDate,
    activeFilterParams,
    pageNo,
    isAppliedFilter,
    isInitialLoad = false,
  ) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (!selectedTab) return;

    let selectedFilterParams = !isAppliedFilter
      ? assignActiveFilters
      : activeFilterParams !== undefined
        ? activeFilterParams
        : filterParams;
    getCredentials(startDate, endDate, selectedFilterParams, isInitialLoad);
    setAssignActiveFilters(selectedFilterParams);
    setResetAllFields((prev) => prev + 1);
  };

  const getCredentials = (startDate, endDate, filters, isInitialLoad) => {
    if (isInitialLoad) setIsInitialLoading(true);
    let queryData = {
      product_id: window?.localStorage?.engagements_id,
      start_date: startDate,
      end_date: endDate,
      monitors: filters?.engagement_analytics_monitors?.join(",") || undefined,
      filters: JSON.stringify({
        data_sources: filters?.engagement_data_sources?.join(",") || undefined,
        engagement_types: filters?.engagement_type?.join(",") || undefined,
        routings_ids: filters?.engagement_routing?.join(",") || undefined,
        ...selectedOptions.current,
      }),
    };
    EngagementsController.getEngagementAnalyticsData(selectedTab, {
      ...queryData,
    }).then((data) => {
      if (!data?.errorMsg && data?.data) {
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        setResponse({});

        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
      if (isInitialLoad) setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    // reset the selected options when the tab is changed
    selectedOptions.current = {};
    if (selectedTab) {
      applyFilter(startDate, endDate, filterParams, 1, undefined, true);
    }
  }, [selectedTab]);

  useEffect(() => {
    let tabsName = allowedTabs?.filter(
      (item) => `/engagements/analytics/${item}` == location.pathname,
    );
    if (tabsName?.length) {
      setSelectedTab(tabsName[0]);
    } else {
      navigate(`/engagements/analytics/inbox`);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS"))
      EngagementsController.getSavedMonitors(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        setIsDoneSetup(data?.data?.engagements_setuped);
      });
  }, []);

  // this handle open popup to redirect to engagement setup
  useEffect(() => {
    if (!isDoneSetup) {
      setOpenSetupFlow(true);
    }
  }, [isDoneSetup]);

  const SelectedTabComponent = tabsComponents[selectedTab];
  return (
    <Box className={"engag-analytics-main"}>
      <EngagementAnalyticsHeader
        filterFields={filterFields}
        applyFilter={applyFilter}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        assignActiveFilters={assignActiveFilters}
        setAssignActiveFilters={setAssignActiveFilters}
        handleClearFilterParams={handleClearFilterParams}
        getFiltersFileds={getFiltersFileds}
        allActiveFilters={getActiveParams(assignActiveFilters, filterFields)}
        getSelectedDate={setPickerStateApply}
        pickerStateApply={pickerStateApply}
        selectedTab={selectedTab}
      />
      <Box className={"analytics-main-contant"}>
        <SideBar selectedTab={selectedTab} tabs={allowedTabs} />
        <Box className="engag-analytics-contant">
          {SelectedTabComponent ? (
            <>
              <SelectedTabComponent
                response={response}
                selectedTab={selectedTab}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                isLoading={isInitialLoading}
                resetAllFields={resetAllFields}
                currDate={pickerStateApply}
                startDate={startDate}
                endDate={endDate}
                handleSeletedOptions={handleSeletedOptions}
              />
            </>
          ) : null}
          {/* Main App Footer as it is excluded from App.js */}
          <Footer isEngagementAnalytics={true} />
        </Box>
      </Box>
      {openSetupFlow &&
      handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") &&
      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS")) ? (
        <SetupFlow
          setClose={() => setOpenSetupFlow(false)}
          isDoneSetup={isDoneSetup}
        />
      ) : null}
    </Box>
  );
};

export default EngagementAnalytics;
