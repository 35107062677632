import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../navigationSidebar/navigationContent.scss";
import NavigationSidebar from "../navigationSidebar/navigationContent";
import { CheckValueLocale, classNames, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter as faFilterFilled } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import LucButton from "shared/lucButton/lucButton";
import { useState, useEffect, useRef, Fragment } from "react";
import { EngagementsTabs } from "./engagementsTabs/engagementsTabs";
import {
  faFilter,
  faGear,
  faMessageMedical,
} from "@fortawesome/pro-regular-svg-icons";

const HeadingSidebar = ({
  handleClickReload,
  handleSettings,
  setOpenNavigationMenu,
  title,
  subTitle,
  openNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  showReload,
  isDoneSetup,
  setOpenNewConversation,
  startDate,
  endDate,
  handleDatePicker,
  setOpenFilters,
  openFilters,
  filtersChangesCount,
  isEngagementSidebarMenuOpen,
}) => {
  const intl = useIntl();
  const [haveMoreThanOne, setHaveMoreThanOne] = useState(false);
  const tabsContainerRef = useRef(null);
  const tabsAdjacentButtonsRef = useRef(null);
  const dividerRef = useRef(null);
  const [pickerState, setPickerState] = useState("last_7_days");

  const handleOnhover = () => {
    dividerRef.current.style.visibility = "hidden";
  };

  const handleOnLeave = () => {
    dividerRef.current.style.visibility = "visible";
  };

  useEffect(() => {
    setHaveMoreThanOne(false);
    if (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS")) {
      setHaveMoreThanOne(true);
    } else {
      let checkArr = [];

      handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
        checkArr.push("PUBLISH");

      handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") && checkArr.push("INBOX");

      handlelUserRoles("ENGAGEMENT_ACCESS", "ENGAGEMENT_ANALYTICS") &&
        checkArr.push("ENGAGEMENT_ANALYTICS");

      var activeProducts = JSON.parse(
        window.localStorage.activeProducts || null,
      );
      let aiAgentActive = activeProducts?.find(
        (product) => product?.name == "AI_AGENT",
      );

      handlelUserRoles("AI_AGENT", "VIEW_AI_AGENT_SETTINGS") &&
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
        aiAgentActive &&
        checkArr.push("AI_AGENT");
      if (checkArr?.length > 1) {
        setHaveMoreThanOne(true);
      }
    }
  }, []);
  const isInbox = navigationMenuStatus === "inbox";
  const shouldSettingsBeVisible = handlelUserRoles(
    "ENGAGEMENTS",
    "ENGAGEMENT_SETTINGS",
  );

  const isCustomDate = pickerState?.length > 15;
  return (
    <>
      <Box
        component={isInbox ? "div" : Fragment}
        className={classNames(
          isInbox
            ? "engagement-navigation-header"
            : "publish-navigation-header",
          isEngagementSidebarMenuOpen ? "sidebar-menu-open" : "",
        )}
      >
        <Box
          id="engagements-inbox-btn"
          className="heading-refrsh"
          onClick={() => haveMoreThanOne && setOpenNavigationMenu(true)}
        >
          <NavigationSidebar
            title={title}
            subTitle={subTitle}
            openNavigationMenu={openNavigationMenu}
            navigationMenuStatus={navigationMenuStatus}
            setNavigationMenuStatus={setNavigationMenuStatus}
            notOpened={!haveMoreThanOne}
          />
        </Box>
        {/* In case of Inbox we show the filters and date picker otherwise we show the refresh and settings icon in Publish */}
        {isInbox ? (
          <Box className="engagement-navigation-header-buttons">
            <Tooltip
              title={
                !isEngagementSidebarMenuOpen
                  ? CheckValueLocale("filters", "", {}, intl)
                  : ""
              }
              arrow
              placement="bottom"
            >
              <Box>
                <LucButton
                  id="engagements-filter-btn"
                  variant="text"
                  onClick={() => setOpenFilters(!openFilters)}
                  size="small"
                  type="secondary"
                  minWidth={isEngagementSidebarMenuOpen ? "auto" : 32}
                  startIcon={
                    <FontAwesomeIcon
                      icon={filtersChangesCount ? faFilterFilled : faFilter}
                      className={`${filtersChangesCount ? "choosed-filter" : ""}`}
                    />
                  }
                  onMouseEnter={handleOnhover}
                  onMouseLeave={handleOnLeave}
                >
                  {isEngagementSidebarMenuOpen
                    ? CheckValueLocale("filters", "", {}, intl)
                    : null}
                  <span className="engagement-filter-count">
                    {filtersChangesCount && isEngagementSidebarMenuOpen
                      ? `(${filtersChangesCount})`
                      : filtersChangesCount
                        ? `${filtersChangesCount}`
                        : ""}
                  </span>
                </LucButton>
              </Box>
            </Tooltip>
            <Box className="filter-date-divider" ref={dividerRef} />
            <Box
              id="engagements-data-picker"
              className={classNames(
                "engagement-header-custom-date-picker",
                isCustomDate ? "custom-date" : "",
                isEngagementSidebarMenuOpen ? "custom-date-wide" : "",
              )}
              onMouseEnter={handleOnhover}
              onMouseLeave={handleOnLeave}
              onClickCapture={() => {
                handleOnLeave();
              }}
            >
              <DateRangePicker
                applyFilter={(startDate, endDate) =>
                  handleDatePicker(startDate, endDate)
                }
                startDate={startDate}
                endDate={endDate}
                openCustomDateLeft={true}
                smallBoxCustom={classNames(
                  "engagement-custom-date-picker",
                  isCustomDate ? "custom-date" : "",
                  isEngagementSidebarMenuOpen ? "custom-date-wide" : "",
                )}
                getSelectedDate={(value) => {
                  setPickerState(value);
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box className="refresh-setting-container">
            <Tooltip
              title={CheckValueLocale("inbox_refresh", "", {}, intl)}
              arrow
              placement="bottom"
            >
              <RefreshIcon
                id="engagements-refresh-btn"
                className="refrsh-icon publish-menu"
                onClick={() => handleClickReload()}
              />
            </Tooltip>
            <SettingsIconComponent
              handleSettings={handleSettings}
              isDoneSetup={isDoneSetup}
              isInbox={isInbox}
            />
          </Box>
        )}
      </Box>

      {/*Engagements Tabs */}
      {isInbox ? (
        <Box
          className={classNames(
            "engagement-navigation-header-tabs-container",
            shouldSettingsBeVisible ? "with-settings" : "",
          )}
        >
          <EngagementsTabs
            tabsContainerRef={tabsContainerRef}
            tabsAdjacentButtonsRef={tabsAdjacentButtonsRef}
          />
          <Box
            ref={tabsAdjacentButtonsRef}
            className="engagement-navigation-header-tabs-adjacent"
          >
            {handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
            handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") ? (
              <Tooltip
                title={CheckValueLocale("start_new_conversation", "", {}, intl)}
                arrow
                placement="bottom"
              >
                <Box>
                  <LucButton
                    onClick={() => setOpenNewConversation(true)}
                    size="large"
                    variant="text"
                    type="secondary"
                    minWidth={42}
                    id="engagements-new-conversation-btn"
                    className="adjacent-btn"
                  >
                    <FontAwesomeIcon icon={faMessageMedical} />
                  </LucButton>
                </Box>
              </Tooltip>
            ) : null}
            {shouldSettingsBeVisible ? <Box className="tabs-divider" /> : null}
            <SettingsIconComponent
              handleSettings={handleSettings}
              isDoneSetup={isDoneSetup}
              isInbox={isInbox}
            />
          </Box>
        </Box>
      ) : null}
    </>
  );
};

const SettingsProperIcon = ({ isInbox, onClick, className }) => {
  if (isInbox) {
    return (
      <LucButton
        onClick={onClick}
        size="large"
        variant="text"
        type="secondary"
        minWidth={42}
        id="engagements-settings-btn"
        className="adjacent-btn"
      >
        <FontAwesomeIcon icon={faGear} />
      </LucButton>
    );
  }
  return <SettingsIcon className={className} onClick={onClick} />;
};

const SettingsIconComponent = ({ isInbox, isDoneSetup, handleSettings }) => {
  const intl = useIntl();
  return (
    <>
      {handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") ? (
        isDoneSetup ? (
          <Tooltip
            title={CheckValueLocale("engagm_setting", "", {}, intl)}
            arrow
            placement={"bottom"}
          >
            <Box>
              <SettingsProperIcon
                className={
                  isInbox
                    ? "engagement-settings-icon"
                    : "engagement-settings-icon publish-menu"
                }
                onClick={handleSettings}
                isInbox={isInbox}
              />
            </Box>
          </Tooltip>
        ) : (
          <SettingsProperIcon
            className={
              isInbox
                ? "engagement-settings-icon"
                : "engagement-settings-icon publish-menu"
            }
            onClick={handleSettings}
            isInbox={isInbox}
          />
        )
      ) : null}
    </>
  );
};

export default HeadingSidebar;
