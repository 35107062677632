import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import OverView from "components/widgets/overViewWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import { CheckValueLocale } from "utils/helpers";
import ThemeSection from "pages/Monitors/Components/themeSection";

const OverViewSection = (props) => {
  const intl = useIntl();

  let {
    response,
    overView,
    setOverView,
    overViewPreLoader,
    setOverViewPreLoader,
    postsVolume,
    setPostsVolume,
    postsVolumePreLoader,
    setPostsVolumePreLoader,
    postsInteractions,
    setPostsInteractions,
    postsInteractionsPreLoader,
    setPostsInteractionsPreLoader,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    topEngaged,
    setTopEngaged,
    topEngagedPreLoader,
    setTopEngagedPreLoader,
    topSites,
    setTopSites,
    topSitesPreLoader,
    reachFunnelLegacy,
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
    topPosts,
    setTopPosts,
    topTopPostsPreLoaderDataSources,
    setTopSitesPreLoader,
    setTopPostsPreLoaderDataSources,
    setReachFunnelLegacy,
    setThemeTrend,
    setThemeAnalysis,
    setThemeTrendPreLoader,
    setThemeAnalysisPreLoader,
    themeXlxs,
    setThemeXlxs,
  } = props;
  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;
  //handle response

  const [topPostsCount, setTopPostsCount] = useState(10);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);

  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  // _____________________________________________
  // handle response with useEffect (when load page & any update)

  const handleResponseFunc = async () => {
    switch (response.eventName) {
      case "Talkwalker__EngagementsPage__volume_overtime":
        setPostsVolume(response.eventData.posts_over_time);
        setTotalPostsVolumeCount(response.eventData.total_posts_count);
        setPostsVolumePreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__posts_interactions":
        setPostsInteractions(response.eventData.posts_interactions);
        setPostsInteractionsPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_engaged_sites":
        setTopEngaged(response.eventData.top_engaged_sites);
        setTopEngagedPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_sites":
        setTopSites(response.eventData.top_sites);
        setTopSitesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Talkwalker__EngagementsPage__sentiment_analysis":
        Object.entries(response.eventData.sentiment_overtime).forEach(
          ([key, value]) => {
            switch (key) {
              case "negative":
                value?.map((i) => {
                  return negativeArray.push(i);
                });
                break;
              case "positive":
                value?.map((i) => {
                  return positiveArray.push(i);
                });
                break;
              case "neutral":
                value?.map((i) => {
                  return neutralArray.push(i);
                });
                break;
              default:
                break;
            }
          },
        );
        setSentimentAnalysis({
          negative: negativeArray,
          positive: positiveArray,
          neutral: neutralArray,
        });
        setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
        setSentimentAnalysisPreLoader((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__overview":
        let overViewArray = [];
        for (let [key, values] of Object.entries(response.eventData)) {
          overViewArray.push({ name: key, value: values });
        }
        setOverView([...overViewArray]);
        setOverViewPreLoader((oldArray) => [...oldArray, response.monitor_id]);
        break;
      case "Talkwalker__EngagementsPage__reach_funnel":
        setReachFunnelLegacy(response.eventData);
        setReachFunnelLegacyPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;
      case "Talkwalker__EngagementsPage__top_posts_comments":
        setTopPosts(response.eventData.top_posts);
        setTopPostsCount(response.eventData.top_posts_count);
        setTopPostsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.monitor_id,
        ]);
        break;

      case "Talkwalker__EngagementsPage__themes_and_sub_themes":
        setThemeTrend(response?.eventData?.themes?.theme_trend);
        setThemeAnalysis(response?.eventData?.themes?.theme_analysis);
        setThemeTrendPreLoader([response?.monitor_id]);
        setThemeAnalysisPreLoader([response?.monitor_id]);
        setThemeXlxs(response?.eventData?.themes);
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // ________________________________________________________________________________
  useEffect(() => {
    props.setOverViewData([
      { overView: overView },
      { postsVolume: postsVolume },
      { sentimentAnalysis: sentimentAnalysis },
      { themeXlxs: themeXlxs },
      { topEngaged: topEngaged },
      { topSites: topSites },
      { reachFunnelLegacy: reachFunnelLegacy },
      { postsInteractions: postsInteractions },
      { topPosts: topPosts },
    ]);
  }, [
    overView,
    postsVolume,
    sentimentAnalysis,
    themeXlxs,
    topEngaged,
    topSites,
    reachFunnelLegacy,
    postsInteractions,
    topPosts,
  ]);

  // ________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsInteractions([]);
      setPostsInteractionsPreLoader([]);
      setPostsVolumePreLoader([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentAnalysisPieChart([]);
      setThemeAnalysis([]);
      setThemeAnalysisPreLoader([]);
      setThemeTrend([]);
      setThemeTrendPreLoader([]);
      setThemeXlxs([]);
      setOverView([]);
      setOverViewPreLoader([]);
      setTopEngaged([]);
      setTopEngagedPreLoader([]);
      setTopSites([]);
      setTopSitesPreLoader([]);

      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);

      setReachFunnelLegacy([]);

      setReachFunnelLegacyPreLoaderDataSources([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoaderDataSources([]);
    }
  }, [props?.resetAllFields]);
  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <OverView
        data={overView}
        overViewPreLoaderDataSources={overViewPreLoader}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
      />
      <Box mt={3}>
        <LineChartWidget
          title={"posts_volume_kw"}
          titleToolTip={"posts_volume_fb_tooltip"}
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={postsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.newsBlogIcon}
          labelColor={"#2AAAE2"}
        />
      </Box>
      {/* Sentiment_analysis Chart */}
      <Box my={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis_nb"}
          titleToolTip={"comments_sentiment_analysis_nb_tooltip"}
          data={sentimentAnalysis}
          showGroupBy={true}
          showScales
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.newsBlogIcon}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          dataPiechart={sentimentPieChart}
          hideLegend
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>

      <ThemeSection
        iconAfterTitle={props?.newsBlogIcon}
        {...props}
        preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
        eventName="Talkwalker__EngagementsPage__themes_and_sub_themes"
        isSocialListening
        themeAnalysisTitle={"posts_themes"}
        themeTrendTitle={"posts_themes_trend"}
      />

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"top_engaged_sites"}
              titleToolTip={"top_engaged_sites_nb_tooltip"}
              data={topEngaged}
              leftColomnTitle={"links"}
              rightColomnTitle={"nb_posts"}
              tableChartPreLoaderDataSources={topEngagedPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topEngaged.length}
              iconAfterTitle={props.newsBlogIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"top_sites"}
              titleToolTip={"top_sites_nb_tooltip"}
              data={topSites}
              leftColomnTitle={"links"}
              rightColomnTitle={"nb_reach"}
              tableChartPreLoaderDataSources={topSitesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topSites.length}
              iconAfterTitle={props.newsBlogIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} my={2}>
        <Grid item md={6}>
          <StackedBarWidget
            title={"posts_reach"}
            titleToolTip={"posts_reach_nb_tooltip"}
            bigImg
            posts={reachFunnelLegacy}
            colors={["#64748B"]}
            labels={["original"]}
            xAxisValue
            iconAfterTitle={props.newsBlogIcon}
            showDownloadIcon
            showLogChart
            postsColumnPreLoaderDataSources={
              reachFunnelLegacyPreLoaderDataSources
            }
            preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          />
        </Grid>
      </Grid>

      <Box mt={3} className="nb-posts-wrapper">
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_articles", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-all-posts-${monitorType?.toLowerCase() ?? "monitor-type"}-${monitorDataSource?.toLowerCase() ?? "monitor-data-source"}-posts-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount}
            )
          </Link>
        </Box>
        <QuestionCard
          latestUnanswer={topPosts.slice(0, 6)}
          latestUnanswerPreloader={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faNewspaper}
          iconColor={"news-blogs-icon"}
          showHeart
          hideVector={true}
          hideChartBar={true}
          showFlash
          showEye
          showEarth
          newsBlogImg={true}
          showBanIcon={true}
        />
      </Box>
    </>
  );
};

export default OverViewSection;
