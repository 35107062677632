import { Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import moment from "moment";
import LineChartWidget from "components/widgets/lineChartWidget";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import QuestionCard from "pages/Monitors/Components/questionCard";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import BarChartWidget from "components/widgets/barChartWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import "pages/Monitors/monitors.scss";
import { Link, useParams } from "react-router-dom";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";

/*---------------------------------------------------------*/
const AccountBody = (props) => {
  const intl = useIntl();
  let { response, insightsID, insightsResponse, dates } = props;
  const { monitorType, monitorDataSource, monitorId } = useParams();
  const monitorDetails = props.monitorData;

  //handle response
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  // ___________________________________________________________

  const [topVideos, setTopVideos] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  // ___________________________________________________________

  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  // ___________________________________________________________

  const [postsContentType, setPostsContentType] = useState([]);
  const [reachImpression, setReachImpression] = useState([]);
  const [reachImpressionLegend, setReachImpressionLegend] = useState([]);
  const [reach, setReach] = useState([]);
  const [impression, setImpression] = useState([]);

  // ___________________________________________________________

  const [postsContactDetails, setPostsContactDetails] = useState([]);
  const [postsContactDetailsLegend, setPostsContactDetailsLegend] = useState(
    [],
  );
  // ___________________________________________________________
  const [postsInteractions, setPostsInteractions] = useState([]);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [postInteractionsLegend, setPostInteractionsLegend] = useState([]);
  // ___________________________________________________________
  const [averageEngagementPerPost, setAverageEngagementPerPost] = useState([]);
  const [
    maxValueAverageEngagementPerPost,
    setMaxValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    minValueAverageEngagementPerPost,
    setMinValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    avgValueAverageEngagementPerPost,
    setAvgValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    maxValueAverageEngagementPerPostDate,
    setMaxValueAverageEngagementPerPostDate,
  ] = useState(0);
  const [
    minValueAverageEngagementPerPostDate,
    setMinValueAverageEngagementPerPostDate,
  ] = useState(0);
  // ___________________________________________________________
  const [postContentPieChart, setPostContentPieChart] = useState([]);
  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);
  // ___________________________________________________________

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  const [topVideosPreLoaderDataSources, setTopVideosPreLoaderDataSources] =
    useState([]);
  const [topImagesPreLoaderDataSources, setTopImagesPreLoaderDataSources] =
    useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    postsInteractionsPreLoaderDataSources,
    setPostsInteractionsPreLoaderDataSources,
  ] = useState([]);

  const [
    averageEngagementPerPostPreLoaderDataSources,
    setAverageEngagementPerPostPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContactDetailsPreLoaderDataSources,
    setPostsContactDetailsPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContentTypePreLoaderDataSources,
    setPostsContentTypePreLoaderDataSources,
  ] = useState([]);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);
  const [
    reachImpressionPreLoaderDataSources,
    setReachImpressionPreLoaderDataSources,
  ] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (response.eventName === "Instagram__AccountPage__volume_overtime") {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Instagram__AccountPage__reach_funnel") {
      Object.entries(response.eventData.reach_funnel_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "reach":
              value?.map((i) => {
                return reach.push(i);
              });
              break;
            case "impression":
              value?.map((i) => {
                return impression.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setReachImpression({
        reach: reach,
        impression: impression,
      });
      setReachImpressionLegend(response.eventData.reach_funnel_legends);
      setReachImpressionPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__AccountPage__account_author_activity"
    ) {
      setMinValueAuthorActivity(response.eventData.min_value);
      setMaxValueAuthorActivity(response.eventData.max_value);
      setAuthorActivity(response.eventData.account_author_activity);
      setAuthorActivityPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__AccountPage__followers_growth"
    ) {
      setFollowersGrowth(response.eventData.followers_change_over_time);
      setTotalFollowersGrowth(response.eventData.total_followers);
      setMaxChangeFollowersGrowth(response.eventData.max_change_of_followers);
      setAvgChangeFollowersGrowth(response.eventData.avg_chage_of_day);
      setFollowersGrowthPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__AccountPage__posts_interactions"
    ) {
      setPostsInteractions(
        response.eventData.posts_interactions.posts_interactions_over_time,
      );
      setMaxValuePostsInteractions(
        response.eventData.posts_interactions.max_interactions,
      );
      setMinValuePostsInteractions(
        response.eventData.posts_interactions.min_interactions,
      );
      setAvgValuePostsInteractions(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_interaction_date,
      );
      setMaxValueInteractionsDate(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_interaction_date,
      );
      setMinValueInteractionsDate(date);
      setPostInteractionsLegend(
        response.eventData.posts_interactions.posts_interactions_legends,
      );
      setPostsInteractionsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName ===
      "Instagram__AccountPage__average_engagement_per_post"
    ) {
      setAverageEngagementPerPost(
        response.eventData.posts_interactions.posts_engagements_over_time,
      );
      setMaxValueAverageEngagementPerPost(
        response.eventData.posts_interactions.max_engagements,
      );
      setMinValueAverageEngagementPerPost(
        response.eventData.posts_interactions.min_engagements,
      );
      setAvgValueAverageEngagementPerPost(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_engagement_date,
      );
      setMaxValueAverageEngagementPerPostDate(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_engagement_date,
      );
      setMinValueAverageEngagementPerPostDate(date);
      setAverageEngagementPerPostPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName ===
      "Instagram__AccountPage__page_contact_details_interactions"
    ) {
      setPostsContactDetails(
        response.eventData.page_contact_details_interactions_overtime,
      );
      setPostsContactDetailsLegend(
        response.eventData.pie_chart_page_contact_details_interactions,
      );
      setPostsContactDetailsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Instagram__AccountPage__content_type") {
      setPostsContentType(response.eventData.posts_content_type);
      setPostsContentTypePreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
      setPostContentPieChart(response.eventData.posts_content_types_legends);
    } else if (response.eventName === "Instagram__AccountPage__top_videos") {
      setTopVideos(response.eventData.top_videos);
      setTopVideosPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (response.eventName === "Instagram__AccountPage__top_images") {
      setTopImages(response.eventData.top_images);
      setTopImagesPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__AccountPage__top_posts_comments"
    ) {
      setTopPosts(response.eventData.top_posts);
      setTopPostsCount(response.eventData.top_posts_count);
      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // ________________________________________________________________________________
  //getting Data to export xls sheet
  useEffect(() => {
    props.setExcelSheetData([
      { followersGrowth: followersGrowth },
      { postsContactDetails: postsContactDetails },
      {
        authorActivity: [
          authorActivity,
          minValueAuthorActivity,
          maxValueAuthorActivity,
        ],
      },
      { postsVolume: postsVolume },
      { reachImpression: reachImpression },
      { postsInteractions: postsInteractions },
      { averageEngagementPerPost: averageEngagementPerPost },
      { postsContentType: postsContentType },
      { topVideos: topVideos },
      { topImages: topImages },
      { topPosts: topPosts },
    ]);
  }, [
    followersGrowth,
    postsContactDetails,
    authorActivity,
    postsVolume,
    reachImpression,
    postsInteractions,
    averageEngagementPerPost,
    postsContentType,
    topVideos,
    topImages,
    topPosts,
  ]);
  // ________________________________________________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setTopImages([]);
      setTopVideos([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setPostContentPieChart([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setPostsInteractions([]);
      setMaxValuePostsInteractions(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setMinValueInteractionsDate(0);
      setPostsInteractions([]);
      setPostInteractionsLegend([]);
      setReachImpression([]);
      setReachImpressionLegend([]);
      setReach([]);
      setImpression([]);
      //////
      setAverageEngagementPerPost([]);
      setMaxValueAverageEngagementPerPost(0);
      setMinValueAverageEngagementPerPost(0);
      setAvgValueAverageEngagementPerPost(0);
      setMaxValueAverageEngagementPerPostDate(0);
      setMinValueAverageEngagementPerPostDate(0);
      ///////
      setPostsContentType([]);
      setFollowersGrowth([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);
      setPostsContactDetails([]);
      setPostsContactDetailsLegend([]);

      // Values of DataSources toTrack each widget preloader
      setPostsVolumePreLoaderDataSources([]);
      setTopVideosPreLoaderDataSources([]);
      setTopImagesPreLoaderDataSources([]);
      setTopPostsPreLoaderDataSources([]);
      setAuthorActivityPreLoaderDataSources([]);
      setPostsInteractionsPreLoaderDataSources([]);
      setAverageEngagementPerPostPreLoaderDataSources([]);
      setPostsContentTypePreLoaderDataSources([]);
      setFollowersGrowthPreLoaderDataSources([]);
      setPostsContactDetailsPreLoaderDataSources([]);
      setReachImpressionPreLoaderDataSources([]);
    }
  }, [props.resetAllFields]);

  useEffect(() => {
    let totalPreLoaderDataSouces = [];

    totalPreLoaderDataSouces.push(
      postsVolumePreLoaderDataSources,
      topVideosPreLoaderDataSources,
      topImagesPreLoaderDataSources,
      topTopPostsPreLoaderDataSources,
      authorActivityPreLoaderDataSources,
      postsInteractionsPreLoaderDataSources,
      averageEngagementPerPostPreLoaderDataSources,
      postsContactDetailsPreLoaderDataSources,
      postsContentTypePreLoaderDataSources,
      reachImpressionPreLoaderDataSources,
      followersGrowthPreLoaderDataSources,
    );

    props.checkAllDataFounded(
      totalPreLoaderDataSouces,
      props.preLoaderTrackerDataSources,
    );

    props.checkDataIsFounded(
      followersGrowth,
      postsContactDetails,
      authorActivity,
      postsVolume,
      reachImpression,
      postsInteractions,
      averageEngagementPerPost,
      postsContentType,
      topPosts,
      topVideos,
      topImages,
    );
  }, [
    followersGrowth,
    postsContactDetails,
    authorActivity,
    postsVolume,
    reachImpression,
    postsInteractions,
    averageEngagementPerPost,
    postsContentType,
    topPosts,
    topVideos,
    topImages,
    postsVolumePreLoaderDataSources,
    topVideosPreLoaderDataSources,
    topImagesPreLoaderDataSources,
    topTopPostsPreLoaderDataSources,
    authorActivityPreLoaderDataSources,
    postsInteractionsPreLoaderDataSources,
    averageEngagementPerPostPreLoaderDataSources,
    postsContactDetailsPreLoaderDataSources,
    postsContentTypePreLoaderDataSources,
    reachImpressionPreLoaderDataSources,
    followersGrowthPreLoaderDataSources,
  ]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>
      <Box mt={3}>
        {/* __________________________________ */}
        <BarChartWidget
          title={"followers_growth"}
          titleToolTip={"followers_growth_ig_tooltip"}
          data={followersGrowth}
          showGroupBy
          dataColor="#2AAAE2"
          toolTipLabel={"posts"}
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "total_followers",
              value: totalFollowersFollowersGrowth,
            },
            {
              name: "total_change_in_followers",
              value: maxChangeFollowersGrowth,
            },
            {
              name: "avg_change_per_day",
              value: avgChangeFollowersGrowth,
            },
          ]}
          showDownloadIcon
          bigImg
          showZoomPercentage
          iconAfterTitle={props.InstagramIcon}
          barChartPreLoaderDataSources={followersGrowthPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
        {/* __________________________________ */}
      </Box>
      <Box mt={3}>
        <StackedBarWidget
          title={"page_contact_details_interactions"}
          titleToolTip={"page_contact_details_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={postsContactDetails}
          colors={["#2AAAE2", "#80868C", "#334155", "#AFBCCE"]}
          labels={[
            "website_clicks",
            "phone_clicks",
            "directions",
            "profile_visits",
          ]}
          showScales
          showZoomPercentage
          iconAfterTitle={props.InstagramIcon}
          postsColumnPreLoaderDataSources={
            postsContactDetailsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showReportIcon
          hideLegend
          dataPiechart={postsContactDetailsLegend}
        />
      </Box>
      {/* __________________________________ */}

      <Box my={3} className="channel-activity">
        <PunchCardWidget
          title="account_authors_activity"
          titleToolTip={"account_authors_activity_ig_tooltip"}
          clientActivity={authorActivity}
          minValueClient={minValueAuthorActivity}
          maxValueClient={maxValueAuthorActivity}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.InstagramIcon}
          authorActivityPreLoaderDataSources={
            authorActivityPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>

      <Typography className="section-name">
        {CheckValueLocale("posts_analytics", "", {}, intl).toUpperCase()}
      </Typography>
      {/* __________________________________ */}

      <Box mt={3} className="posts-volume-chart">
        <LineChartWidget
          title={"posts_volume"}
          titleToolTip={"posts_volume_ig_tooltip"}
          totalLabel={"posts"}
          totalValue={totalPostsVolumeCount}
          data={postsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.InstagramIcon}
          labelColor={"#2AAAE2"}
          lineChartPreLoaderDataSources={postsVolumePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* __________________________________ */}

      <Box className="sentiment-chart" mt={3}>
        <StackedLineWidget
          title={"posts_reach_impressions"}
          titleToolTip={"posts_reach_impressions_ig_tooltip"}
          data={reachImpression}
          areaStyle={true}
          isSwitchCase={true}
          toolTipLabel={"toolTipLabel"}
          colors={["#2AAAE2", "#80868C"]}
          showDownloadIcon
          stackedLineWidgetPreLoaderDataSources={
            reachImpressionPreLoaderDataSources
          }
          isPostsReachImpression
          bigImg
          iconAfterTitle={props.InstagramIcon}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupBy={true}
          showScales
          showReportIcon
          hideLegend
          dataPiechart={reachImpressionLegend}
          labelColors={["#2AAAE2", "#80868C"]}
        />
      </Box>
      {/* __________________________________ */}

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_interactions"}
          titleToolTip={"posts_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={postsInteractions}
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "max_interaction",
              value: maxValuePostsInteractions,
              date: maxValueInteractionsDate,
            },
            {
              name: "min_interaction",
              value: minValuePostsInteractions,
              date: minValueInteractionsDate,
            },
            {
              name: "avg_interaction_per_day",
              value: avgValuePostsInteractions,
            },
          ]}
          colors={["#2AAAE2", "#80868C"]}
          labels={["comments", "likes"]}
          showPostsInteractionStats
          showScales
          showZoomPercentage
          iconAfterTitle={props.InstagramIcon}
          postsColumnPreLoaderDataSources={
            postsInteractionsPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={postInteractionsLegend}
        />
      </Box>
      {/* __________________________________ */}

      <Box mt={3} className="average-engagements-per-post">
        <BarChartWidget
          title={"average_engagements_per_post"}
          titleToolTip={"average_engagements_per_post_ig_tooltip"}
          data={averageEngagementPerPost}
          showGroupBy
          dataColor="#2AAAE2"
          showChartDetailsWidget
          statsDetailsData={[
            {
              name: "max_interactions_per_post",
              value: maxValueAverageEngagementPerPost,
              date: maxValueAverageEngagementPerPostDate,
            },
            {
              name: "min_interactions_per_post",
              value: minValueAverageEngagementPerPost,
              date: minValueAverageEngagementPerPostDate,
            },
            {
              name: "avg_interaction_per_post",
              value: avgValueAverageEngagementPerPost,
            },
          ]}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          iconAfterTitle={props.InstagramIcon}
          showZoomPercentage
          barChartPreLoaderDataSources={
            averageEngagementPerPostPreLoaderDataSources
          }
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* __________________________________ */}

      <Box mt={3}>
        <StackedBarWidget
          title={"posts_content_type"}
          titleToolTip={"posts_content_type_ig_tooltip"}
          posts={postsContentType}
          colors={["#2AAAE2", "#80868C", "#334155"]}
          labels={["video", "photo", "catalog"]}
          hideLegend
          dataPiechart={postContentPieChart}
          showDownloadIcon
          bigImg
          showScales
          showZoomPercentage
          iconAfterTitle={props.InstagramIcon}
          postsColumnPreLoaderDataSources={postsContentTypePreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        />
      </Box>
      {/* __________________________________ */}

      <Box my={6}>
        <MediaInstagram
          title={CheckValueLocale("top_images", "", {}, intl)}
          titleToolTip={"posts_top_images_tooltip"}
          data={topImages}
          errorMessage={CheckValueLocale("no_images_found", "", {}, intl)}
          iconAfterTitle={props.InstagramIcon}
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topImagesPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          questions={topImages}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
        />
      </Box>
      {/* __________________________________ */}
      <Box my={4}>
        <MediaInstagram
          title={CheckValueLocale("top_reels", "", {}, intl)}
          titleToolTip={"posts_top_reels_tooltip"}
          data={topVideos}
          errorMessage={CheckValueLocale("no_videos_found", "", {}, intl)}
          iconAfterTitle={props.InstagramIcon}
          monitorInstagramMedia
          mediaInstaPreLoaderDataSources={topVideosPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          questions={topVideos}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
        />
      </Box>
      {/* __________________________________ */}

      <Box my={3}>
        <Box className="top-posts-head">
          <Box>{CheckValueLocale("top_posts", "", {}, intl)}</Box>
          <Link
            to={{
              pathname: `/monitor/${monitorType}/${monitorDataSource}/posts/${monitorId}`,
              state: monitorDetails,
            }}
            className="top-posts-all"
            id={`check-all-posts-${
              monitorType?.toLowerCase() ?? "monitor-type"
            }-${
              monitorDataSource?.toLowerCase() ?? "monitor-data-source"
            }-posts-${monitorId ?? "monitor-id"}`}
          >
            {CheckValueLocale("check_all_posts", "", {}, intl)} ({topPostsCount}
            )
          </Link>
        </Box>
        <QuestionCard
          latestUnanswer={topPosts.slice(0, 6)}
          latestUnanswerPreloader={topTopPostsPreLoaderDataSources}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={faInstagram}
          widget={"top_posts"}
          iconColor={"instagram_icon"}
          showHeart={true}
          showDefaultImg
          instagramAccount
          className={"no-data-found-question"}
          insightsID={insightsID}
          response={insightsResponse}
          dates={dates}
          hideChangeSentiment
        />
      </Box>
    </>
  );
};

export default AccountBody;
