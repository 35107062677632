import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import "../../../monitors.scss";
import ThemeSection from "./../../../Components/themeSection";
import { handleUnixFormat, CheckValueLocale } from "utils/helpers";

const OverViewSection = (props) => {
  const intl = useIntl();
  let {
    response,
    commentsVolume,
    setCommentsVolume,
    totalCommentsVolumeCount,
    setTotalCommentsVolumeCount,
    commentsInteractions,
    setCommentsInteractions,
    commentsInteractionsPreLoader,
    setCommentsInteractionsPreLoader,
    commentsInteractionsLegend,
    setCommentsInteractionsLegend,
    sentimentAnalysis,
    setSentimentAnalysis,
    sentimentAnalysisPreLoader,
    setSentimentAnalysisPreLoader,
    negativeArray,
    setNegativeArray,
    positiveArray,
    setPositiveArray,
    neutralArray,
    setNeutralArray,
    topKeywords,
    setTopKeywords,
    topKeywordsPreLoader,
    setTopKeywordsPreLoader,
    topHashtags,
    setTopHashtags,
    topHashtagsPreLoader,
    setTopHashtagsPreLoader,
    commentsVolumePreLoader,
    setCommentsVolumePreLoader,
    associatedTopics,
    setAssociatedTopics,
    associatedTopicsPreLoader,
    setAssociatedTopicsPreLoader,
  } = props;
  //handle response
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);

  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];
  // ___________________________________________________________

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (response.eventName === "Instagram__EngagementsPage__volume_overtime") {
      setCommentsVolume(response.eventData.posts_over_time);
      setTotalCommentsVolumeCount(response.eventData.total_posts_count);
      setCommentsVolumePreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__comments_interactions"
    ) {
      setCommentsInteractions(response?.eventData?.comments_interactions);
      setCommentsInteractionsLegend(
        response?.eventData?.comments_interactions_legends,
      );

      setTotalValuePostsInteractions(response?.eventData?.total_interactions);
      setMaxValuePostsInteractions(response?.eventData?.max_interactions);
      let date = handleUnixFormat(response?.eventData?.max_interaction_date);
      setMaxValueInteractionsDate(date);
      setAvgValuePostsInteractions(response?.eventData?.average_interactions);

      setCommentsInteractionsPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response.eventData.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value?.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value?.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value?.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_keywords"
    ) {
      setTopKeywords(response.eventData.top_keywords);
      setTopKeywordsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_hashtags"
    ) {
      setTopHashtags(response.eventData.top_hashtags);
      setTopHashtagsPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__associated_topics"
    ) {
      setAssociatedTopics(response.eventData.associated_topics);
      setAssociatedTopicsPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // ________________________________________________________________________________
  useEffect(() => {
    props.setOverViewData([
      { commentsVolume: commentsVolume },
      { sentimentAnalysis: sentimentAnalysis },
      { commentsInteractions: commentsInteractions },
      { topKeywords: topKeywords },
      { topHashtags: topHashtags },
      { associatedTopics: associatedTopics },
    ]);
  }, [
    commentsVolume,
    sentimentAnalysis,
    commentsInteractions,
    topKeywords,
    topHashtags,
    associatedTopics,
  ]);

  // ________________________________________________________________________________
  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setCommentsInteractionsLegend([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setCommentsVolumePreLoader([]);
      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);

      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
    }
  }, [props.resetAllFields]);

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("overview", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip={"comments_volume_ig_tooltip"}
          totalLabel={"posts"}
          totalValue={totalCommentsVolumeCount}
          data={commentsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={commentsVolumePreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          iconAfterTitle={props.instagramIcon}
          labelColor={"#2AAAE2"}
        />
      </Box>
      <Box mt={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_tooltip"}
          data={sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={false}
          hideLegend
          showDownloadIcon
          bigImg
          stackedLineWidgetPreLoaderDataSources={sentimentAnalysisPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          isSwitchCase
          iconAfterTitle={props.instagramIcon}
          showGroupByClientAgent={true}
          showScales
          dataPiechart={sentimentPieChart}
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          isSentimentAnalysisWidget
          isSentimentAnalysisShowCharts
        />
      </Box>
      <ThemeSection
        iconAfterTitle={props.instagramIcon}
        {...props}
        preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
        eventName={"Instagram__EngagementsPage__themes_and_sub_themes"}
        isSocialListening
      />
      {/* Sentiment_analysis Chart */}
      <Box my={3}>
        <StackedBarWidget
          title={"comments_interactions"}
          titleToolTip={"comments_interactions_ig_tooltip"}
          showDownloadIcon
          bigImg
          posts={commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["likes", "comments"]}
          showZoomPercentage
          iconAfterTitle={props.instagramIcon}
          postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          showGroupByClientAgent={true}
          showScales
          hideLegend
          dataPiechart={commentsInteractionsLegend}
          showChartDetailsWidget
          statsDetailsData={interactionsStats}
          isInteractionStatsWrapperStyle
        />
      </Box>
      {/* Content Segmentation */}

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              data={topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topKeywordsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={topKeywords.length}
              showDownloadIcon
              iconAfterTitle={props.instagramIcon}
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip={"comments_top_hashtags_ig_tooltip"}
              data={topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={topHashtagsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={topHashtags.length}
              showDownloadIcon
              iconAfterTitle={props.instagramIcon}
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={associatedTopicsPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.instagramIcon}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OverViewSection;
