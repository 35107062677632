import { useEffect, useState } from "react";
import { Grid, Container, Box, Tooltip, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import OptionsDropDown from "./optionsDropDown.js";
import { useIntl } from "react-intl";
import { handlelUserRoles as handleUserRoles } from "utils/helpers/index.js";
import Snackbar from "components/snackBar";
import Pagination from "@mui/material/Pagination";
import CircularLoading from "components/circularProgress";
import AlertMessage from "shared/alert/alertMessage.js";
import { CheckValueLocale, getDatasourceId } from "utils/helpers";
import PageHeader from "components/pageHeader.js";
import SharedTable from "../../../shared/table/sharedTable";
import "./manageChannels.scss";
import GenesysPopup from "./addChannel/genesysPopup.js";
import { genesysLastStepAuth } from "./manageChannels.hooks";
import { useSortableTable } from "hooks/useSortableTable.js";
import LucButton from "shared/lucButton/lucButton.js";
import NoDataLinks from "components/no-Data/noDataLinks.js";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis.js";
import { useMonitors } from "./hooks/useChannelMonitors.js";
import { useMonitorsDataSources } from "./hooks/useMonitorsDataSources.js";
import { useCompanyApps } from "./hooks/useCompanyApps.js";
import { renderChannelsTabBody } from "./components/renderChannelsTabBody.js";
import { manageChannelsInitialColumns } from "./manageChannelsInitialColumns.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetSnackbar } from "utils/redux/features/ManageChannels/ManageChannels.js";

const ManageChannels = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { channelAdded, channelFailed, channelFailedMsg, isSnackBarOpen } =
    useSelector((state) => state.manageChannels);
  const { data: dataSourcesResponse } = useMonitorsDataSources();
  const dataSourcesData = dataSourcesResponse?.data?.data;
  const genesysDataSourceId = getDatasourceId(dataSourcesData, "GENESYS")?.id;
  const tiktokDataSourceId = getDatasourceId(dataSourcesData, "TIKTOK")?.id;

  const { data: companyAppsResponse } = useCompanyApps();
  const companyApps = companyAppsResponse?.data?.data;

  const [page, setPage] = useState(1);
  const [reachInteractionsLimitAlert, setReachInteractionsLimitAlert] =
    useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [openGenesysModal, setOpenGenesysModal] = useState(false);
  const [genesysCodeLoading, setGenesysCodeLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [failSnackBarData, setFailSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarAllSource, setOpenSnackBarAllSource] = useState(false);
  const [isSuccessEditSnackBarOpen, setIsSuccessEditSnackBarOpen] =
    useState(false);

  const [isErrorEditSnackBarOpen, setIsErrorEditSnackBarOpen] = useState(false);

  const canShowActionTap =
    handleUserRoles("GENERAL", "CREATE_COMPANY_AUTH") ||
    handleUserRoles("GENERAL", "CREATE_INTEGRATION") ||
    handleUserRoles("CXM", "EDIT_CHANNEL") ||
    handleUserRoles("CXM", "EDIT_OTHERS_CHANNEL") ||
    handleUserRoles("CXM", "DESTROY_CHANNEL") ||
    handleUserRoles("CXM", "DESTROY_OTHERS_CHANNEL");

  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };
  const {
    columns: tableHead,
    sortColumn,
    sortDirection,
    setIsSortableLoading = () => {
      return false;
    },
  } = useSortableTable({
    initialColumns: manageChannelsInitialColumns(canShowActionTap),
    onSortChangeCallback,
  });
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const {
    data: channelsData,
    isError: isMonitorsError,
    isPending: isMonitorsLoading,
  } = useMonitors({
    page,
    sortColumn,
    sortDirection,
    search: searchInput,
    redirectedMonitorId:
      location?.state?.needsAttentionData?.redirected_monitor || undefined,
  });

  useEffect(() => {
    if (isMonitorsError) {
      setPagination(1);
      setFailSnackBarData({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
      });
      setOpenSnackBarAllSource(true);
    }
  }, [isMonitorsError]);

  const companyCanCreateChannel = channelsData?.companyCanCreateChannel;
  const reachInteractionsLimit = channelsData?.interactionsLimitReach;
  const [pagination, setPagination] = useState(channelsData?.pagination);
  if (channelsData?.pagination && pagination !== channelsData.pagination) {
    setPagination(channelsData.pagination);
  }

  useEffect(() => {
    if (isSnackBarOpen) {
      setTimeout(() => {
        dispatch(resetSnackbar());
      }, 5000);
    }
  }, [isSnackBarOpen, dispatch]);

  //genesys auth
  let url = window.location?.search;
  let code = url?.slice(url?.indexOf("?code=") + 6);
  let errorGenesys = url?.slice(url?.indexOf("?error=") + 6);

  useEffect(() => {
    if (
      code !== undefined &&
      code !== "" &&
      genesysDataSourceId &&
      url?.includes("code") &&
      !url?.includes("error")
    ) {
      genesysLastStepAuth(
        genesysDataSourceId,
        code,
        setGenesysCodeLoading,
        () => {},
        setFailSnackBarData,
        setOpenSnackBarAllSource,
      );
    }
  }, [code, genesysDataSourceId]);

  useEffect(() => {
    if (window.location?.search?.includes("?error")) {
      setFailSnackBarData({
        message: "genesys_error_request_message",
        severity: "error",
        title: "genesys_error_request_title",
      });
      setOpenSnackBarAllSource(true);
      navigate(window.location.pathname);
    }
  }, [errorGenesys]);

  const disableNewChannelButton =
    !handleUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
    !handleUserRoles("GENERAL", "VIEW_USER_AUTH") ||
    !companyCanCreateChannel;

  const optionsCell = (channel) => {
    return (
      <OptionsDropDown
        status={channel?.attributes?.stream_status}
        channelId={channel?.id}
        accountName={channel?.attributes?.account_name}
        channelCount={channel?.attributes?.total_count}
        monitorOwner={channel?.attributes?.monitor_owner}
        disableReauth={channel?.attributes?.disable_reauth}
        getMonitors={() => {}}
        channelName={channel?.attributes?.channel[0]}
        companyApps={companyApps || []}
        dm_channel={channel?.attributes.dm_channel}
        dm_Configuration_Id={channel?.attributes?.dm_configuration_id}
        setIsSuccessEditSnackBarOpen={setIsSuccessEditSnackBarOpen}
        setIsErrorEditSnackBarOpen={setIsErrorEditSnackBarOpen}
        // accountError && accountValidError --> to disable resume btn if two props have errors
        accountError={channel?.attributes?.account_error}
        accountValidError={channel?.attributes?.account_valid_error}
        setOpenGenesysModal={setOpenGenesysModal}
        tiktokDataSourceId={tiktokDataSourceId}
        setFailSnackBarData={setFailSnackBarData}
        setOpenSnackBarAllSource={setOpenSnackBarAllSource}
        customerCare={channel?.attributes?.customer_care_accounts}
        ignoreTweets={channel?.attributes?.excluded_twitter_accounts}
      />
    );
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && !validationError) {
      navigate({ ...location, state: { needsAttentionData: null } });
      setSearchInput(searchText);
      e.target.blur();
      if (page !== 1) setPage(1);
    }
  };

  const searchIconOnClick = () => {
    if (!validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      navigate({ ...location, state: { needsAttentionData: null } });

      setSearchInput(searchText);
      if (page !== 1) setPage(1);
    }
  };

  return (
    <Container maxWidth="xl">
      {isMonitorsLoading ||
      (genesysCodeLoading &&
        url?.includes("code") &&
        !url?.includes("error")) ? (
        <CircularLoading />
      ) : (
        <>
          <div>
            <Grid>
              <Box container className="title-header-box">
                <PageHeader title="manage_channels" />

                {handleUserRoles("CXM", "CREATE_CHANNEL") && (
                  <Box id="title-button-box">
                    <span
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Tooltip
                        title={CheckValueLocale(
                          "new_channel_btn_tooltip",
                          "",
                          {},
                          intl,
                        )}
                        placement="bottom"
                        arrow
                        id="manage-channel-btn-tooltip"
                        open={!!companyCanCreateChannel && isHovered}
                      >
                        <LucButton
                          startIcon={<Add className="button-padding-channel" />}
                          onClick={() => {
                            navigate("/manage-channels/add-channel");
                            localStorage.removeItem("redirection_done");
                            localStorage.removeItem("active_category");
                          }}
                          disabled={disableNewChannelButton}
                          id="channel-analytics-new-channel-btn"
                        >
                          {CheckValueLocale("new_channel", "", {}, intl)}
                        </LucButton>
                      </Tooltip>
                    </span>
                  </Box>
                )}
              </Box>
              <Box className="search-desblay">
                {channelsData?.data?.length > 0 || searchInput ? (
                  <Box className="search-company">
                    <Box className="search-box">
                      <TextField
                        className="search-field"
                        label={CheckValueLocale("search", "", {}, intl)}
                        variant="outlined"
                        value={searchText}
                        onChange={(e) => {
                          const invalidText = isPublicSpecialCharactersOrEmojis(
                            e?.target?.value,
                            undefined,
                            true,
                          );
                          setValidationError(invalidText);
                          setSearchText(e.target.value);
                        }}
                        onBlur={() => setSearchBoxClicked(false)}
                        onFocus={() => setSearchBoxClicked(true)}
                        onKeyPress={handleEnterKeyPress}
                        id="manage-channels-search-field"
                      />
                      <Box
                        className={
                          "search-icon-container " +
                          (searchBoxClicked ? "search-icon-container-blue" : "")
                        }
                        onClick={() => searchIconOnClick()}
                        id="social-media-search-by-name-icon-box"
                      >
                        <FontAwesomeIcon
                          className="search-icon"
                          icon={faSearch}
                          id="social-media-search-by-name-icon"
                        />
                      </Box>
                    </Box>
                    <Box
                      variant="p"
                      className="search-input-validation-error manage-channels"
                    >
                      {validationError
                        ? CheckValueLocale("spam_keywords_rules", "", {}, intl)
                        : null}
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </div>

          <AlertMessage
            mainFlag={reachInteractionsLimit}
            alertMainFlag={reachInteractionsLimitAlert}
            setAlertMainFlag={setReachInteractionsLimitAlert}
            alertMessageId={"interactions_limit_alert"}
            boxStyle={{ marginBottom: "-10px" }}
          />
          {handleUserRoles("CXM", "VIEW_CHANNEL_LIST") && (
            <SharedTable
              className="channel-table monitor-table"
              tableHeader={tableHead}
              tableBody={
                channelsData?.data?.length == 0
                  ? []
                  : renderChannelsTabBody({
                      channelsData,
                      optionsCell: (channel) => ({
                        body: optionsCell(channel),
                        permission: canShowActionTap,
                      }),
                    })
              }
              isSharedStyle
              emptyMsgId="empty_channel_list_title"
              emptysubMsgId="empty_channel_list_massage"
              image="ManageChannels"
              title="manage_your_channels"
              description="click_New_Channel_to_collect"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.manage_channel_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
              loading={isMonitorsLoading}
            />
          )}
          {pagination?.pages > 1 && !isMonitorsLoading ? (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list"
              count={pagination?.pages}
              variant="outlined"
            />
          ) : (
            <div className="pagination-one-page"></div>
          )}
        </>
      )}

      <Snackbar
        open={channelAdded}
        severity={"success"}
        message={CheckValueLocale(
          "your_channel_was_added_succssfully",
          "",
          {},
          intl,
        )}
        handleClose={() => dispatch(resetSnackbar())}
      />

      <Snackbar
        open={channelFailed}
        severity={"error"}
        title={CheckValueLocale(channelFailedMsg, "", {}, intl)}
        message={CheckValueLocale(channelFailedMsg, "", {}, intl)}
        handleClose={() => dispatch(resetSnackbar())}
      />

      <Snackbar
        open={isSuccessEditSnackBarOpen}
        severity={"success"}
        handleClose={() => setIsSuccessEditSnackBarOpen(false)}
        message={CheckValueLocale(
          "Configuration applied successfully!",
          "",
          {},
          intl,
        )}
      />

      <Snackbar
        open={isErrorEditSnackBarOpen}
        severity={"error"}
        handleClose={() => setIsErrorEditSnackBarOpen(false)}
        title={CheckValueLocale("display_configuration_failed", "", {}, intl)}
        message={CheckValueLocale(
          "display_configuration_failed_msg",
          "",
          {},
          intl,
        )}
      />

      <Snackbar
        open={openSnackBarAllSource}
        severity={failSnackBarData?.severity}
        message={CheckValueLocale(failSnackBarData?.message, "", {}, intl)}
        title={CheckValueLocale(failSnackBarData?.title, "", {}, intl)}
        handleClose={() => setOpenSnackBarAllSource(false)}
      />

      {openGenesysModal ? (
        <GenesysPopup
          openGenesysModal={openGenesysModal}
          setOpenGenesysModal={setOpenGenesysModal}
          genesysDataSourceId={genesysDataSourceId}
          setFailSnackBarData={setFailSnackBarData}
          setOpenSnackBarAllSource={setOpenSnackBarAllSource}
        />
      ) : null}
    </Container>
  );
};
export default ManageChannels;
