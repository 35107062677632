import { Box, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "./mainSetup.scss";
import { useState, useEffect } from "react";
import PopupModal from "components/popupModal";
import {
  CheckValueLocale,
  isEmptyObj,
} from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSparkles,
  faUser,
  faCommentAltLines,
  faCheck,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import MonitorsController from "services/controllers/monitorsController";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import SnackBar from "components/snackBar.js";
import { useEngagementPermissions } from "hooks/useEngagementPermissions";
import { useLookupMessages } from "./hooks/useLookupMessages";
import {
  processFarewellGreetingMessagesData,
  transformMessageDataPayload,
} from "../utils/farewellGreetingUtils";

const steps = [
  { name: "Monitor_Channels", icon: <FontAwesomeIcon icon={faUser} /> },
  { name: "AI_Assistant", icon: <FontAwesomeIcon icon={faSparkles} /> },
  { name: "Saved_Replies", icon: <FontAwesomeIcon icon={faCommentAltLines} /> },
];

const MainSetup = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isSetup, setIsSetup] = useState(false);
  const [stepNum, setStepNum] = useState(0);
  const [openSteptwoPopup, setOpenSteptwoPopup] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [selectedData, setSelectedData] = useState({
    mainData: {
      accounts: [],
      monitorsAndChannels: {},
      feaching: true,
    },
    stepOne: {
      isPublishError: false,
      isCxmAcc: false,
      isEmptyError: false,
      isAccError: false,
      monitors: [],
      TWITTER_SlAcc: [],
      INSTAGRAM_SlAcc: [],
      FACEBOOK_SlAcc: [],
      cxm: [],
      TWITTER_CxmAcc: [],
      INSTAGRAM_CxmAcc: [],
      FACEBOOK_CxmAcc: [],
      TWITTER_PubAcc: [],
      INSTAGRAM_PubAcc: [],
      FACEBOOK_PubAcc: [],
    },
    stepTwo: {
      luci_industry: "",
      luci_business_type: "",
      luci_business_hq: "",
      brand_tone: "",
      aboutYour: "",
      knowledge_base_url: "",
      files: [],
      isFileUploaded: false,
      fetching: false,
      enabled: false,
    },
  });
  const { engagementPriorityProductPath } = useEngagementPermissions();

  const activeProducts = JSON.parse(
    window?.localStorage?.activeProducts || null,
  );
  const aiAgentActive = activeProducts?.find(
    (product) => product?.name == "AI_AGENT",
  );

  const { data: lookupMessages } = useLookupMessages(!aiAgentActive);

  const [messagesData, setMessagesData] = useState({
    farewell_greeting_messages: [
      {
        data_source_name: "INSTAGRAM",
        messages: [],
      },
      {
        data_source_name: "TWITTER",
        messages: [],
      },
      {
        data_source_name: "FACEBOOK",
        messages: [],
      },
    ],
    farewell_greeting_enabled: {
      twitter_greeting_message_enabled: false,
      facebook_greeting_message_enabled: false,
      instagram_greeting_message_enabled: false,
      twitter_farewell_message_enabled: false,
      facebook_farewell_message_enabled: false,
      instagram_farewell_message_enabled: false,
      survey_enabled: false,
      survey_id: null,
    },
    lookup_messages: [],
  });

  useEffect(() => {
    if (lookupMessages?.data) {
      setMessagesData({
        ...messagesData,
        ...processFarewellGreetingMessagesData({
          farewell_greeting_messages: messagesData?.farewell_greeting_messages,
          lookup_messages: lookupMessages?.data || [],
        }),
      });
    }
  }, [lookupMessages]);

  const showPopup = !selectedData?.stepTwo?.enabled;
  const stepTwoPopup = () => {
    return (
      <Box className="main-step-two-popup">
        <Box className="step-two-popup-header">
          {CheckValueLocale("fields_skipped", "", {}, intl)}
        </Box>
        <Box className="step-two-popup-contant">
          {CheckValueLocale("less_personalized", "", {}, intl)}
        </Box>
        <Box className="step-two-popup-btns">
          <Button
            className="move-step-btn"
            onClick={() => {
              setOpenSteptwoPopup(false);
              setStepNum(stepNum + 1);
            }}
          >
            {CheckValueLocale("move_step", "", {}, intl)}
          </Button>
          <Button
            className="keep-editing-btn"
            onClick={() => setOpenSteptwoPopup(false)}
          >
            {CheckValueLocale("keep_editing", "", {}, intl)}
          </Button>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    let engagementsProduct = JSON.parse(
      window?.localStorage?.getItem("activeProducts"),
    );
    let engagementsProductId = engagementsProduct?.filter(
      (item) => item?.name == "ENGAGEMENTS" && item?.active,
    );
    if (!engagementsProductId?.length) {
      navigate("/social/monitor_list");
    } else {
      EngagementsController.getSavedMonitors(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        if (data?.data?.engagements_setuped) {
          navigate("/engagements/edit");
        } else {
          setIsSetup(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isSetup) {
      setSelectedData({
        ...selectedData,
        mainData: {
          ...selectedData?.mainData,
          feaching: true,
        },
      });
      EngagementsController.getMonitorsAndChannels(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        if (data?.data?.company_products) {
          setSelectedData({
            ...selectedData,
            mainData: {
              ...selectedData?.mainData,
              monitorsAndChannels: { ...data?.data },
            },
          });
        }
      });
    }
  }, [isSetup]);

  useEffect(() => {
    if (!isEmptyObj(selectedData?.mainData?.monitorsAndChannels)) {
      MonitorsController.getAllAccounts(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        let accountsArr = [];
        data?.data?.data?.TWITTER?.data?.map((item) =>
          accountsArr.push({
            ...item,
            attributes: {
              ...item?.attributes,
              data_sources: ["TWITTER"],
              account_error:
                item?.attributes?.status == "Re-authenticate"
                  ? ["TWITTER"]
                  : [],
            },
          }),
        );
        data?.data?.data?.INSTAGRAM?.included?.map((item) =>
          accountsArr.push({
            ...item,
            attributes: {
              ...item?.attributes,
              data_sources: ["INSTAGRAM"],
              account_error:
                item?.attributes?.status == "Re-authenticate"
                  ? ["INSTAGRAM"]
                  : [],
            },
          }),
        );
        data?.data?.data?.FACEBOOK?.included?.map((item) =>
          accountsArr.push({
            ...item,
            attributes: {
              ...item?.attributes,
              data_sources: ["FACEBOOK"],
              account_error:
                item?.attributes?.status == "Re-authenticate"
                  ? ["FACEBOOK"]
                  : [],
            },
          }),
        );
        setSelectedData({
          ...selectedData,
          mainData: {
            ...selectedData?.mainData,
            accounts: [...accountsArr],
            feaching: false,
          },
        });
      });
    }
  }, [selectedData?.mainData?.monitorsAndChannels]);

  useEffect(() => {
    if (
      selectedData?.stepTwo?.isFileUploaded &&
      selectedData?.stepTwo?.files[0]?.file
    ) {
      setSelectedData({
        ...selectedData,
        stepTwo: {
          ...selectedData?.stepTwo,
          fetching: true,
          isFileUploaded: false,
          network_error: "",
        },
      });
      const formDataFile = new FormData();
      formDataFile.append(
        "knowledge_base_file",
        selectedData?.stepTwo?.files[0]?.file,
      );
      formDataFile.append("product_id", window?.localStorage?.engagements_id);
      EngagementsController.uploadFile(formDataFile).then((data) => {
        if (data?.data?.status == 200) {
          setSelectedData({
            ...selectedData,
            stepTwo: {
              ...selectedData?.stepTwo,
              isFileUploaded: false,
              fetching: false,
              knowledge_base_url: data?.data?.data?.knowledge_base_url,
            },
          });
        } else {
          if (data?.data?.response?.status == 404) {
            setSelectedData({
              ...selectedData,
              stepTwo: {
                ...selectedData?.stepTwo,
                files: [],
                knowledge_base_url: "",
                isFileUploaded: false,
                fetching: false,
                network_error: "upload_failed",
              },
            });
          } else {
            setSelectedData({
              ...selectedData,
              stepTwo: {
                ...selectedData?.stepTwo,
                files: [],
                knowledge_base_url: "",
                isFileUploaded: false,
                fetching: false,
                network_error: "network_failed",
              },
            });
          }
        }
      });
    }
  }, [selectedData?.stepTwo]);

  const disableNext =
    selectedData?.mainData?.feaching ||
    (stepNum == 1 &&
      (!selectedData?.stepTwo?.luci_industry?.length ||
        !selectedData?.stepTwo?.luci_business_type?.length ||
        !selectedData?.stepTwo?.luci_business_hq?.length ||
        !selectedData?.stepTwo?.brand_tone?.length ||
        !selectedData?.stepTwo?.aboutYour?.length ||
        !selectedData?.stepTwo?.knowledge_base_url?.length));

  const handleStepOneValidation = () => {
    setSelectedData({
      ...selectedData,
      stepOne: {
        ...selectedData?.stepOne,
        isPublishError: false,
        isEmptyError: false,
        isAccError: false,
      },
    });
    let isErorr = false;
    let pubErorr = false;
    let emptyErorr = false;
    let accErorr = false;

    if (
      !selectedData?.stepOne?.INSTAGRAM_PubAcc?.length &&
      !selectedData?.stepOne?.TWITTER_PubAcc?.length &&
      !selectedData?.stepOne?.FACEBOOK_PubAcc?.length
    ) {
      isErorr = true;
      pubErorr = true;
    }

    if (
      !selectedData?.stepOne?.monitors?.length &&
      !selectedData?.stepOne?.cxm?.length
    ) {
      isErorr = true;
      emptyErorr = true;
    } else {
      if (selectedData?.stepOne?.monitors?.length) {
        if (
          !selectedData?.stepOne?.INSTAGRAM_SlAcc?.length &&
          !selectedData?.stepOne?.TWITTER_SlAcc?.length &&
          !selectedData?.stepOne?.FACEBOOK_SlAcc?.length
        ) {
          isErorr = true;
          accErorr = true;
        }
      }
      if (
        selectedData?.stepOne?.cxm?.length &&
        selectedData?.stepOne?.isCxmAcc
      ) {
        if (
          !selectedData?.stepOne?.INSTAGRAM_CxmAcc?.length &&
          !selectedData?.stepOne?.TWITTER_CxmAcc?.length &&
          !selectedData?.stepOne?.FACEBOOK_CxmAcc?.length
        ) {
          isErorr = true;
          accErorr = true;
        }
      }
    }
    setSelectedData({
      ...selectedData,
      stepOne: {
        ...selectedData?.stepOne,
        isPublishError: pubErorr,
        isEmptyError: emptyErorr,
        isAccError: accErorr,
      },
    });
    if (!isErorr) {
      setStepNum(1);
    }
  };

  const sendAllData = () => {
    const transformedMessagesPayload = !aiAgentActive
      ? transformMessageDataPayload(messagesData)
      : {};
    const payload = {
      ...selectedData,
      ...(!aiAgentActive ? transformedMessagesPayload : {}),
    };
    EngagementsController.editUserInfo({
      product_id: window?.localStorage?.engagements_id,
      ...payload,
    }).then((data) => {
      if (data?.errorMsg) {
        setSnackBar({
          open: true,
          title: "",
          message: "update_not",
          severity: "error",
        });
        setTimeout(() => {
          setSnackBar({
            open: false,
            title: "",
            message: "update_not",
            severity: "error",
          });
        }, 7000);
      } else {
        localStorage?.setItem("engagSetupDone", true);
        navigate(engagementPriorityProductPath);
      }
    });
  };

  return (
    <Box className="main-sutep-engagment">
      <Box className="main-sutep-comp">
        {steps?.map((step, i) => (
          <>
            {i > 0 ? <Divider className="main-steps-line" /> : null}
            <Box className="main-steps-continer">
              <Box
                className={
                  i == stepNum
                    ? "main-step active-step"
                    : i < stepNum
                      ? "main-step done-step"
                      : "main-step"
                }
              >
                <Box className="main-step-icon">
                  {i < stepNum ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    step?.icon
                  )}
                </Box>
                <Box>
                  <Box className="step-num">
                    {CheckValueLocale("step_num", "", { num: i + 1 }, intl)}
                  </Box>
                  <Box className="step-name">
                    {CheckValueLocale(step?.name, "", {}, intl)}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
      <Box className="main-step-contant">
        <Box className="step-progres">
          {CheckValueLocale("step_progres", "", { num: stepNum + 1 }, intl)}
        </Box>
        {!selectedData?.mainData?.feaching ? (
          <Box minHeight={"360px"}>
            {stepNum == 0 ? (
              <StepOne
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ) : stepNum == 1 ? (
              <StepTwo
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ) : stepNum == 2 ? (
              <StepThree
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                messagesData={messagesData}
                setMessagesData={setMessagesData}
              />
            ) : null}
          </Box>
        ) : (
          <Box className="fetching-loader" minHeight={"360px"}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
            {CheckValueLocale("setup_load", "", {}, intl)}
          </Box>
        )}
        <Box className="main-step-footer">
          <Button
            className="setup-btn btn-cancel"
            onClick={() =>
              stepNum
                ? setStepNum(stepNum - 1)
                : navigate(engagementPriorityProductPath)
            }
          >
            {CheckValueLocale(
              stepNum ? "report_back" : "cancel_btn",
              "",
              {},
              intl,
            )}
          </Button>
          <Button
            className="setup-btn btn-next"
            onClick={() =>
              stepNum == 1 && showPopup
                ? setOpenSteptwoPopup(true)
                : stepNum == 0
                  ? handleStepOneValidation()
                  : stepNum == 2
                    ? sendAllData()
                    : setStepNum(stepNum + 1)
            }
            disabled={disableNext}
          >
            {CheckValueLocale(
              stepNum == 2 ? "finish_step" : "next_Step",
              "",
              {},
              intl,
            )}
          </Button>
        </Box>
      </Box>
      <PopupModal
        body={stepTwoPopup()}
        open={openSteptwoPopup}
        close={() => setOpenSteptwoPopup(false)}
        fixPadding
      />
      <SnackBar
        open={snackBar.open}
        severity={snackBar.severity}
        message={CheckValueLocale(snackBar.message, "", {}, intl)}
      />
    </Box>
  );
};

export default MainSetup;
