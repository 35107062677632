import Services from "./Services";

var Reports = {};

Reports.getReports = (queryData) => {
  return Services.getData("api/v3/reports", queryData);
};

Reports.editReports = (id, queryData) => {
  return Services.patchData(`api/v3/reports/${id}`, queryData);
};

Reports.deleteReports = (id) => {
  return Services.deleteData(`api/v3/reports/${id}`);
};

Reports.getReportTypes = () => {
  return Services.getData("api/v3/report_types");
};

Reports.getReportDataSource = (queryData) => {
  return Services.getData("api/v3/data_sources", queryData);
};

Reports.getReportMonitors = (queryData) => {
  return Services.getData("api/v3/report_monitors_list", queryData);
};

Reports.getReportFrequencies = () => {
  return Services.getData("api/v3/reports/frequencies");
};

Reports.getReportLuciToken = (queryData) => {
  return Services.getData("api/v3/reports/luci_token", queryData);
};

Reports.createReport = (queryData) => {
  return Services.postData("api/v3/reports", queryData);
};

Reports.getDataSources = (queryData) => {
  return Services.getData("api/v3/data_sources", queryData);
};

Reports.getReportFilterDataSources = () => {
  return Services.getData("api/v4/data_sources");
};

Reports.getFilteredReports = (queryData) => {
  return Services.getData("api/v3/reports/filter", queryData);
};
Reports.getReportDetails = (queryData) => {
  return Services.getData("api/v3/reports/report_records", queryData);
};
Reports.exportEnhancedReport = (queryData) => {
  return Services.postData("api/v3/reports/export", queryData);
};

Reports.getTwitterReport = (queryData) =>
  Services.downloadTwitterReport("api_export_report_from_file", queryData);

Reports.exportReportFromFile = (queryData) =>
  Services.nodeGetData("api_export_report_from_file", queryData);

Reports.getPptLink = (queryData) =>
  Services.getData("api/v3/reports/ppt_link", queryData);

Reports.getMonitorSentimentCategories = function (monitorId) {
  return Services.getData(
    "api/v3/monitors/sentiment_categories_stats/" + monitorId,
  );
};

Reports.getReportProducts = function () {
  return Services.getData("api/v3/reports/products");
};

Reports.getReportCategories = function (queryData) {
  return Services.getData("api/v3/reports/categories", queryData);
};

Reports.getReportChannels = function (queryData) {
  return Services.getData("api/v3/reports/monitors", queryData);
};
Reports.resumeOrPauseReports = (id, queryData) => {
  return Services.patchData(`api/v3/reports/update_status/${id}`, queryData);
};

Reports.getNewsLetterReport = function (queryData) {
  return Services.getData("api/v5/reports/newsletter_report_data", queryData);
};

export default Reports;
