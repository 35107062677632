import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PlayArrow, Pause } from "@mui/icons-material";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import ReportController from "services/controllers/reportController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";
import { useNavigate } from "react-router-dom";

const ListOptions = ({
  reportData,
  handleOpenDeleteModal,
  history,
  handelViewReport,
  getReportData,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    openPauseResumeModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    openSnackBar: false,
    snackBarAction: "",
    snackBarSeverity: "success",
    snackBarTitle: "",
    snackBarMsg: "",
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResumeOrPauseReport = (id, updateStatus) => {
    setButtonLoading(true);
    ReportController.resumeOrPauseReports(id, !updateStatus).then((data) => {
      if (data?.data?.status == 204 || data?.data?.status == 200) {
        if (updateStatus === true) {
          showSnackBar("resume", "success");
        } else {
          showSnackBar("pause", "success");
        }
        getReportData();
      } else {
        showSnackBar("pauseOrResume", "error");
      }
      setButtonLoading(false);
    });
  };
  const handleOpenPauseResumeModal = () => {
    if (reportData?.attributes?.manual_status === true) {
      setStats({
        ...stats,
        openPauseResumeModal: true,
        modalTitle: CheckValueLocale("pause_report_title", "", {}, intl),

        modalBody: (
          <Box className="shared-dialog-body">
            <p>
              {`${CheckValueLocale("pause_report_msg", "", { name: reportData?.attributes?.title }, intl)} `}
            </p>

            <h2 className="sub-text-desc">
              {CheckValueLocale("pause_report_sub_msg", "", {}, intl)}
            </h2>
          </Box>
        ),
        modalRightBtn: CheckValueLocale("confirm_rpause", "", {}, intl),
        modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
        anchorEl: null,
        warning: true,
      });
    } else {
      setStats({
        ...stats,
        openPauseResumeModal: true,
        modalTitle: CheckValueLocale("resume_report_title", "", {}, intl),

        modalBody: (
          <Box className="shared-dialog-body">
            <p>
              {`${CheckValueLocale("resume_report_msg", "", { name: reportData?.attributes?.title }, intl)} `}
            </p>

            <h2 className="sub-text-desc">
              {CheckValueLocale("resume_report_sub_msg", "", {}, intl)}
            </h2>
          </Box>
        ),
        modalRightBtn: CheckValueLocale("confirm_resume", "", {}, intl),
        modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
        anchorEl: null,
      });
    }
  };
  const handleClosePauseResumeModal = () => {
    setStats({ ...stats, openPauseResumeModal: false });
  };
  const handleCloseSnackBar = () => {
    setStats({ ...stats, openSnackBar: false });
  };
  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarTitle: "",
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
    if (action === "pause" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        openPauseResumeModal: false,
        snackBarMsg: CheckValueLocale(
          "report_resumed_successfully",
          "",
          {},
          intl,
        ),
      });
    } else if (action === "resume" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        openPauseResumeModal: false,
        snackBarMsg: CheckValueLocale(
          "report_paused_successfully",
          "",
          {},
          intl,
        ),
      });
    } else if (action === "pauseOrResume" && severity === "error") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        openPauseResumeModal: false,
        snackBarTitle: CheckValueLocale("failed_error", "", {}, intl),
        snackBarMsg: CheckValueLocale("wrong_msg", "", {}, intl),
      });
    }
  };
  return (
    <>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="report-action-dropdown"
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handelViewReport(reportData);
          }}
          disableRipple
          id={`reports-dropdown-view-report${reportData?.id ?? "data-id"}`}
        >
          <VisibilityIcon />
          {CheckValueLocale("view_reports", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/reports/edit-report", {
              state: { detail: reportData },
            });
          }}
          disableRipple
          id={`reports-dropdown-edit-report-${reportData?.id ?? "data-id"}`}
        >
          <EditIcon />
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            handleClose();
            handleOpenPauseResumeModal();
          }}
          id={`reports-dropdown-delete-report-${reportData?.id ?? "data-id"}`}
        >
          {reportData?.attributes?.manual_status === true ? (
            <Pause />
          ) : (
            <PlayArrow />
          )}

          {reportData?.attributes?.manual_status === true
            ? CheckValueLocale("pause", "", {}, intl)
            : CheckValueLocale("resume", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenDeleteModal({
              id: reportData?.id,
              name: reportData?.attributes?.title,
            });
          }}
          disableRipple
          id={`reports-dropdown-delete-report-${reportData?.id ?? "data-id"}`}
        >
          <DeleteIcon />
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      </Menu>
      {stats?.openPauseResumeModal && (
        <PopupModal
          title={stats?.modalTitle}
          body={stats?.modalBody}
          leftBtn={stats?.modalLeftBtn}
          rightBtn={stats?.modalRightBtn}
          open={stats?.openPauseResumeModal}
          close={handleClosePauseResumeModal}
          minWidth="600px"
          classeName="actions-buttons-shared "
          warning={stats?.warning}
          accept={() =>
            handleResumeOrPauseReport(
              reportData?.id,
              reportData?.attributes?.manual_status,
            )
          }
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}
      {stats?.openSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          severity={stats?.snackBarSeverity}
          title={stats?.snackBarTitle}
          message={stats?.snackBarMsg}
          autoHideDuration={7000}
          classeName="status-popup"
        />
      )}
    </>
  );
};

export default ListOptions;
