import { Box } from "@mui/material";
import DOMPurify from "dompurify";
import { useIntl } from "react-intl";
import SurveyRequestImgEn from "images/ai-agents/survey-request-en.png";
import SurveyRequestImgAr from "images/ai-agents/survey-request-ar.png";
import LucidyaSurveyIcon from "images/ai-agents/lucidya-survey-icon.svg";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { useSurveyRequest } from "pages/engagements/components/aiAgents/aiAgentsHooks/useSurveyRequest";
import { useContext, useState } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import FarewellGreetingSurveyRequestImgEn from "images/engagements-images/farewell-greeting-survey-image-en.svg";
import FarewellGreetingSurveyRequestImgAr from "images/engagements-images/farewell-greeting-survey-image-ar.svg";
import SnackBar from "components/snackBar";
import Services from "services/api/Services";

const SurveyRequest = ({ isFarewell }) => {
  const intl = useIntl();
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);
  const {
    mutate: createSurveyRequest,
    isPending: createSurveyRequestLoading,
    isSuccess,
    isError,
  } = useSurveyRequest();
  const [snackBarOpen, setSnackBarOpen] = useState({
    success: false,
    error: false,
  });

  const requestSurveyDemo = () => {
    let queryData = {
      service_name: "request_survey_demo",
    };
    createSurveyRequest(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          if (isFarewell) {
            setSnackBarOpen({
              success: true,
              message: CheckValueLocale(
                "farewell_greeting_survey_request_success",
                "",
                {},
                intl,
              ),
            });
          } else {
            setAiAgentSnackBar({
              success: true,
              message: CheckValueLocale("addons_success", "", {}, intl),
            });
          }
        } else {
          if (isFarewell) {
            setSnackBarOpen({
              success: false,
              message: CheckValueLocale(
                "try_again_error_message",
                "",
                {},
                intl,
              ),
            });
          } else {
            setAiAgentSnackBar({
              success: false,
              title: CheckValueLocale("failed_error_message", "", {}, intl),
              message: CheckValueLocale(
                "try_again_error_message",
                "",
                {},
                intl,
              ),
            });
          }
        }
      },
    });
  };
  const isEn = intl?.locale === "en";

  const SurveyRequestImg = isEn
    ? isFarewell
      ? FarewellGreetingSurveyRequestImgEn
      : SurveyRequestImgEn
    : isFarewell
      ? FarewellGreetingSurveyRequestImgAr
      : SurveyRequestImgAr;

  return (
    <Box className="ai-agents-survey-request-wrapper">
      <Box className="survey-request-text-wrapper">
        <img src={LucidyaSurveyIcon} alt="lucidya-survey-icon" />
        <Box className="survey-request-text">
          {CheckValueLocale("survey_request_text_one", "", {}, intl)}
          <Box component="span">
            {intl?.locale === "ar"
              ? `${CheckValueLocale("survey_request_text_survey", "", {}, intl)} ${Services?.project_name_ar}`
              : `${Services?.project_name_en} ${CheckValueLocale("survey_request_text_survey", "", {}, intl)}`}
          </Box>
          {CheckValueLocale("survey_request_text_two", "", {}, intl)}
        </Box>
        <LucButton
          type="secondary"
          variant="outline"
          size="small"
          loading={createSurveyRequestLoading}
          disabled={createSurveyRequestLoading}
          onClick={requestSurveyDemo}
          id="ai-agent-button-survey-request"
        >
          {CheckValueLocale("home_page_request_demo", "", {}, intl)}
        </LucButton>
      </Box>
      <Box className="survey-request-image-wrapper">
        <img src={SurveyRequestImg} alt="survey-request-image" />
      </Box>
      {isFarewell ? (
        <SnackBar
          open={!!snackBarOpen}
          handleClose={() => {
            setSnackBarOpen(false);
          }}
          severity={isSuccess ? "success" : isError ? "error" : undefined}
          message={
            <Box className="ai-agent-snackBar-message">
              {snackBarOpen?.message}
            </Box>
          }
        />
      ) : null}
    </Box>
  );
};

export default SurveyRequest;
