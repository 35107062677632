import Reports from "../api/report";

var ReportController = {};

ReportController.getReports = (search, page) => {
  const queryData = {
    search,
    page,
  };

  return new Promise(function (resolve) {
    Reports.getReports(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          totalCount: response.data.pagination.count || 0,
          totalPages: response.data.pagination.pages || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.editReports = (id, queryData) => {
  return new Promise(function (resolve) {
    Reports.editReports(id, queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          status: response.status || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.getDataSources = (filterData, page) => {
  const queryData = {
    ...filterData,
    page,
  };
  return new Promise(function (resolve) {
    Reports.getDataSources(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.getReportFilterDataSources = () => {
  return new Promise(function (resolve) {
    Reports.getReportFilterDataSources()
      .then((response) => {
        resolve({ data: response?.data?.data || [] });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error, status: 0 });
      });
  });
};

ReportController.getReportTypes = () => {
  return new Promise(function (resolve) {
    Reports.getReportTypes()
      .then((response) => {
        resolve({
          data: response.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.getReportDataSource = (monitor_type_id = "") => {
  const queryData = { monitor_type_id };
  return new Promise(function (resolve) {
    Reports.getReportDataSource(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.getReportMonitors = (
  monitor_type_id,
  report_type_id,
  data_source_id,
) => {
  const queryData = { monitor_type_id, report_type_id, data_source_id };
  return new Promise(function (resolve) {
    Reports.getReportMonitors(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.deleteReports = (id) => {
  return new Promise(function (resolve) {
    Reports.deleteReports(id)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          status: response.status || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

//-----------------------

ReportController.getReportFrequencies = () => {
  return new Promise(function (resolve) {
    Reports.getReportFrequencies()
      .then((response) => {
        resolve({ data: response?.data || [] });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error, status: 0 });
      });
  });
};

ReportController.getReportLuciToken = (report_type_id) => {
  return new Promise(function (resolve) {
    const queryData = { report_type_id };
    Reports.getReportLuciToken(queryData)
      .then((response) => {
        resolve({ data: response?.data || [], status: response?.status || 0 });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error, status: 0 });
      });
  });
};

ReportController.createReport = (queryData) => {
  return new Promise(function (resolve) {
    Reports.createReport(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          status: response.status || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.getFilteredReports = (filterData, page) => {
  const queryData = {
    ...filterData,
    page,
  };
  return new Promise(function (resolve) {
    Reports.getFilteredReports(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          totalCount: response.data.pagination.count || 0,
          totalPages: response.data.pagination.pages || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.getReportDetails = (report_id, page) => {
  const queryData = {
    report_id,
    page,
  };
  return new Promise(function (resolve) {
    Reports.getReportDetails(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
          pagination: response?.data?.pagination,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.exportEnhancedReport = (report_data_id) => {
  const queryData = {
    report_data_id,
  };
  return new Promise(function (resolve) {
    Reports.exportEnhancedReport(queryData)
      .then((response) => {
        resolve({
          data: response.data.data || [],
          status: response.status || 0,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
          status: 0,
        });
      });
  });
};

ReportController.getTwitterReport = function (queryData) {
  return new Promise(function (resolve) {
    Reports.getTwitterReport(queryData)
      .then(function (response) {
        resolve({
          response: response,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.exportReportFromFile = function (
  monitor_id,
  type_report,
  queryData,
) {
  return new Promise(function (resolve) {
    Reports.exportReportFromFile(queryData)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        var params = {
          filename: json.filename,
          path: json.path,
          monitor_id: monitor_id,
          type_report: type_report,
        };
        resolve({
          params: params,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.getPptLink = function (queryData) {
  return new Promise(function (resolve, reject) {
    Reports.getPptLink(queryData)
      .then(function (response) {
        window.open(response.data.data.link, "_blank");
        resolve({
          success: true,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};
ReportController.getMonitorSentimentCategories = function (monitorId) {
  return new Promise(function (resolve, reject) {
    Reports.getMonitorSentimentCategories(monitorId)
      .then(function (response) {
        resolve({
          categories: response.data,
          status: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          success: false,
          errorMsg: error,
          status: error.status,
        });
      });
  });
};

ReportController.getReportProducts = () => {
  return new Promise(function (resolve) {
    Reports.getReportProducts()
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.getReportCategories = (product_id) => {
  let queryData = { product_id };
  return new Promise(function (resolve) {
    Reports.getReportCategories(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.getReportChannels = (
  product_id,
  data_source_id,
  data_source_type,
) => {
  let queryData = { product_id, data_source_id, data_source_type };
  return new Promise(function (resolve) {
    Reports.getReportChannels(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
ReportController.resumeOrPauseReports = (id, update_status) => {
  let queryData = { status: update_status };
  return new Promise(function (resolve) {
    Reports.resumeOrPauseReports(id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ReportController.getNewsLetterReport = (queryData) => {
  return new Promise(function (resolve, reject) {
    Reports.getNewsLetterReport(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default ReportController;
