import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { ArrowForwardIos } from "@mui/icons-material";
import LucButton from "shared/lucButton/lucButton";

const ReportHeader = ({ reportData }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Box className="view-report-header">
      <Box className="report-detail-name">
        <Box>{reportData?.attributes?.title}</Box>
        <Box component="span" className="report-frequency">
          {CheckValueLocale(
            `report_${reportData?.attributes?.frequency?.toLowerCase()}`,
            "",
            {},
            intl,
          )}
        </Box>
      </Box>
      <LucButton
        type="secondary"
        size="small"
        variant="flat"
        onClick={() => {
          navigate(`/reports/list`);
        }}
        endIcon={<ArrowForwardIos />}
      >
        {CheckValueLocale("back_to_reports", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default ReportHeader;
