import { Box, Typography } from "@mui/material";
import Profile from "./component/profile";
import Hint from "./component/hint";
import Info from "./component/info";
import Themes from "./component/themes";
import SubThemes from "./component/subThemes";
import UserSegments from "./component/userSegments";
import Topics from "./component/topics";
import SocialMedia from "./component/socialMedia";
import Cdp from "./component/cdp";
import Anonymous from "./component/anonymous";
import "./profileSideBar.scss";
import ProfileSideBarLoading from "./profileSideBarLoading";
import { ReactComponent as EmptySidebarProfile } from "images/engagements-images/empty-sidebar-profile.svg";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const ProfileSideBar = ({
  selectedCard,
  fetchSideBar,
  profileSideBarData,
  showTotalEngagementsHandler,
}) => {
  const intl = useIntl();
  const sideBarProfileData = profileSideBarData?.profile_data;
  const sideBarSocialMediaData = sideBarProfileData?.social_media;

  const infoKeys = [
    "gender",
    "dialects",
    "location",
    "sentiment",
    "phone_number",
    "email",
  ];
  const infoArr = sideBarProfileData
    ? infoKeys.filter((key) => sideBarProfileData[key])
    : [];

  const socialMediaArray = sideBarSocialMediaData
    ? Object.keys(sideBarSocialMediaData)?.filter(
        (key) => sideBarSocialMediaData[key]?.length,
      )
    : [];

  if (
    socialMediaArray.includes("fb") &&
    socialMediaArray.includes("fb_dm") &&
    sideBarSocialMediaData["fb"] === sideBarSocialMediaData["fb_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("fb_dm"), 1);
  }
  if (
    socialMediaArray.includes("tw") &&
    socialMediaArray.includes("tw_dm") &&
    sideBarSocialMediaData["tw"] === sideBarSocialMediaData["tw_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("tw_dm"), 1);
  }
  if (
    socialMediaArray.includes("ig") &&
    socialMediaArray.includes("ig_dm") &&
    sideBarSocialMediaData["ig"] === sideBarSocialMediaData["ig_dm"]
  ) {
    socialMediaArray.splice(socialMediaArray.indexOf("ig_dm"), 1);
  }

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );

  if (selectedCard?.data_source?.toLowerCase() === "whatsapp")
    return (
      <Box className="details-side-bar-content-empty">
        <EmptySidebarProfile />
        <Typography className="details-side-bar-content-empty-text">
          {CheckValueLocale("empty_sidebar_whatsapp_text", "", {}, intl)}
        </Typography>
      </Box>
    );

  return !fetchSideBar ? (
    <>
      {sideBarProfileData?.length !== 0 ? (
        <Box className="details-side-bar-content">
          <Profile
            profileData={sideBarProfileData?.user_data}
            selectedCard={selectedCard}
          />
          <Hint
            showTotalEngagementsHandler={showTotalEngagementsHandler}
            selectedCard={selectedCard}
          />
          {infoArr?.length > 0 ? (
            <Info infoData={sideBarProfileData} infoArr={infoArr} />
          ) : null}
          {sideBarProfileData?.themes ? (
            <Themes themesData={sideBarProfileData?.themes} />
          ) : null}
          {sideBarProfileData?.sub_themes ? (
            <SubThemes subThemesData={sideBarProfileData?.sub_themes} />
          ) : null}
          {sideBarProfileData?.segments ? (
            <UserSegments userSegmentsData={sideBarProfileData?.segments} />
          ) : null}
          {sideBarProfileData?.topics ? (
            <Topics topicsData={sideBarProfileData?.topics} />
          ) : null}
          {sideBarSocialMediaData ? (
            <SocialMedia
              socialMediaData={sideBarSocialMediaData}
              socialMediaArray={socialMediaArray}
            />
          ) : null}
          {!activeCdp?.length && profileSideBarData?.is_cdp_data ? (
            <Cdp />
          ) : null}
        </Box>
      ) : (
        <Anonymous />
      )}
    </>
  ) : (
    <ProfileSideBarLoading />
  );
};
export default ProfileSideBar;
