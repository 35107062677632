import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import "./activityLog.scss";
import moment from "moment";
import Filter from "shared/general-filter/filter";
import Pagination from "@mui/material/Pagination";
import NoDataFoundProducts from "components/no-Data";
import CircularLoading from "components/circularProgress";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { useGetUserActivityLogs } from "../../../hooks/useGetUserActivityLogs";

const ActivityLog = (props) => {
  const { isUserActivityLog, isTeamActivityLog, teamId, userData } = props;
  const intl = useIntl();
  const todayDate = new Date();
  const [filterFields, setFilterFields] = useState([]);
  const [filterActivityLog, setFilterActivityLog] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [filterParamsApply, setFilterParamsApply] = useState({});
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [filtersFailsStatus, setFiltersFailsStatus] = useState(false);

  const getFiltersFileds = () => {
    CompaniesController.getFilterActivityLogs().then((res) => {
      if (res?.data?.status === 200) {
        setFilterFields(res?.data?.data?.data);
        setFiltersFailsStatus(true);
      } else setFiltersFailsStatus(false);
    });
  };

  let filtersObj = {};
  Object?.keys(filterParamsApply)?.map((key) => {
    filtersObj[key] = filterParamsApply?.[key]?.join(",");
  });

  const user_ids =
    !isUserActivityLog && !isTeamActivityLog
      ? Array.isArray(filterParamsApply?.user)
        ? filtersObj?.user?.replace(/""/g, "")
        : undefined
      : userData?.id;

  const products = Array.isArray(filterParamsApply?.product)
    ? filtersObj?.product?.replace(/""/g, "")
    : undefined;

  const {
    data: dataUserActivityLogs,
    isPending: isGetUserActivityLogsPending,
  } = useGetUserActivityLogs(
    startDate,
    endDate,
    page,
    user_ids,
    products,
    isTeamActivityLog ? teamId : undefined,
  );

  const getInitials = (sentence) => {
    return sentence
      .split(" ")
      .map((word) => word[0])
      .join("");
  };
  const OmniChannel = localStorage.getItem("cxm_id");
  const checkStatusMsg = (
    action,
    entity,
    name,
    controllerName,
    subentity,
    dataSource,
    newAccess,
    oldAccess,
    productId,
    themeName,
  ) => {
    const entityLocale = entity?.replace(/ /g, "_");
    const subentityLocale = subentity?.replace(/ /g, "_");
    const actionLocale = action?.replace(/ /g, "_");
    const actionCheckLocale = {
      // social & omni
      MonitorsController: `activity_log_${actionLocale}`,
      UserSessionsController: `activity_log_${actionLocale}`,
      OmniauthCallbacksController: `activity_log_${
        subentity == "company account authentication"
          ? actionLocale + "_auth"
          : actionLocale
      }`,
      TwitterController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      InstagramController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      FacebookController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      MonitorsController: `activity_log_${entity == "Omnichannel" ? actionLocale + "_onni" : actionLocale}`,
      SingleTweetsController: `activity_log_${actionLocale}`,
      DashboardsController: `activity_log_${actionLocale}_${subentity == "custom dashboard" ? "custom_dashboards" : "dashboards"}`,
      // LiveDashboardController: `live_dashboard_access_${actionLocale}`,
      CustomDashboardsController: `activity_log_${actionLocale}_custom_live_dashboard`,
      ExcelController: `activity_log_${actionLocale}`,
      //omniChannel
      DmConfigurationsController: `activity_log_${actionLocale}-dm`,
      // setting
      UserManagementsController: `activity_log_${actionLocale}_user`,
      CompanyController: `activity_log_custom_domain_${actionLocale}`,

      // UserSessionsController: `activity_log_${actionLocale}_cdp`,
      XlsxController: `activity_log_${subentity == "excel file" ? actionLocale + "_excel" : actionLocale}`,
      // CDP
      SegmentsController: `activity_log_${actionLocale}_cdp`,
      // Surveys
      SurveysController: `activity_log_${entity == "Survey" ? actionLocale + "_survey" : actionLocale}`,
      // Utilities
      ReportsController: `activity_log_${entity == "Utilities" ? actionLocale + "_report" : actionLocale}`,
      AlertsController: `activity_log_${entity == "Utilities" ? actionLocale + "_alerts" : actionLocale}`,
      //luci
      QuestionsController: `activity_log_${actionLocale}`,
      TermPoliciesController: `activity_log_${actionLocale}`,
      //teams
      TeamsController: `activity_log_${actionLocale}_team`,
      // Historical Requests
      HistoricalRequestsController: `activity_log_${actionLocale}_historical`,
      //lucidya api
      PublicApisController: `activity_log_${actionLocale}_apis`,
      EngagementsController: `activity_log_${actionLocale}_${entityLocale.toLowerCase()}`,
      // Account List Controller
      AccountListsController: `activity_log_${subentity == "Account Lists" ? actionLocale + "_Account_Lists" : actionLocale}`,
    };

    // for archive and delete survey
    if (entity === "Survey") {
      if (action === "deleted") {
        return (
          <Box className="msg-content">
            {CheckValueLocale(
              "activity_log_deleted_survey",
              "",
              {
                survey_name: `"${name}"`,
              },
              intl,
            ) + " "}
            <strong>
              {CheckValueLocale("activity_log_Survey", "", {}, intl)}
            </strong>
          </Box>
        );
      }
      if (action === "unpublished") {
        return (
          <Box className="msg-content">
            {CheckValueLocale(
              "activity_log_archived_survey",
              "",
              { survey_name: `"${name}"` },
              intl,
            ) + " "}
            <strong>
              {CheckValueLocale("activity_log_Survey", "", {}, intl)}
            </strong>
          </Box>
        );
      }
    }

    if (controllerName === "EngagementsController") {
      if (action === "Automated public Replies") {
        const actionMessage = entity?.includes(
          "disabled automated public replies",
        )
          ? "disabled"
          : "enabled";
        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}{" "}
            {CheckValueLocale(
              `activity_log_automated_public_replies_${actionMessage}`,
              "",
              {},
              intl,
            )}
          </Box>
        );
      }
      if (
        entity.includes("Greeting Message") ||
        entity.includes("Farewell Message")
      ) {
        const messageType = entity.includes("Greeting")
          ? "greeting"
          : "farewell";
        const actionType = action === "enabled" ? "enabled" : "disabled";
        const messageAction =
          action === "Message Selected" ? "selected" : actionType;

        return (
          <Box className="msg-content">
            {name ? CheckValueLocale(`${name}`, "", {}, intl) : null}{" "}
            {CheckValueLocale(
              `activity_log_${messageAction}_${messageType}_message`,
              "",
              {},
              intl,
            )}{" "}
            <Box component={"strong"}>
              {CheckValueLocale(
                `farewell_greeting_${dataSource}`,
                "",
                {},
                intl,
              )}
              {` `}
            </Box>
            {CheckValueLocale(
              `activity_log_from_engagement_settings`,
              "",
              {},
              intl,
            )}
          </Box>
        );
      }
    }

    return action == "Successful log in" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Unsuccessful log in attempt" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Successful log in" && entity == "Omnichannel" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"} className="entity">
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : entity === "Ai Agent" ? (
      <Box className="msg-content">
        {CheckValueLocale(`ai_agent_activity_log_${action}`, "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : controllerName === "LiveDashboardController" ? (
      <Box className="msg-content">
        {CheckValueLocale("changed_livedashbaord_access", "", {}, intl)}{" "}
        {name ? name : ""} {CheckValueLocale("from", "", {}, intl)}
        {CheckValueLocale(`live_dashboard_access_${oldAccess}`, "", {}, intl)}
        {CheckValueLocale("to_word", "", {}, intl)}
        {CheckValueLocale(`live_dashboard_access_${newAccess}`, "", {}, intl)}
      </Box>
    ) : entity === "Utilities" && subentity === "theme" ? (
      <Box className="msg-content">
        {CheckValueLocale(`themes_activity_log_${action}`, "", {}, intl) + " "}
        {themeName
          ? CheckValueLocale(`"${themeName}"`, "", {}, intl)
          : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : entity === "Settings" && action == "stop" ? (
      <Box className="msg-content">
        {CheckValueLocale(
          `activity_log_${actionLocale}_${productId == OmniChannel ? "channel" : "monitor"}_historical`,
          "",
          {},
          intl,
        ) + " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : entity === "Utilities" && subentity === "Account Lists" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${subentityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    );
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
  };

  const applyFilter = (startDate, endDate) => {
    setPage(1);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  //to call API with change in filter params
  const applyFilterParams = (_, __, filterParams) => {
    setPage(1);
    setFilterParamsApply(filterParams);
  };

  //pagination
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const handleImageError = (event) => {
    event.target.src = profileImg;
    event.target.alt = "placeholder-image";
  };

  const checekDay = (createdAt) => {
    let value =
      moment(createdAt)?.format("DD/MM/yyyy") ==
      moment(todayDate)?.format("DD/MM/yyyy")
        ? true
        : false;

    return value;
  };

  return (
    <Box className="activity-log-container">
      <Box className="activity-log-head">
        <Typography>
          {CheckValueLocale(
            isUserActivityLog
              ? "user_activity_history"
              : "users_activity_history",
            "",
            {},
            intl,
          )}
        </Typography>
        <Box className="activity-log-datepicker-filter">
          <DateRangePicker
            applyFilter={applyFilter}
            startDate={startDate}
            endDate={endDate}
            oldestDate={dataUserActivityLogs?.oldest_date}
            isUserActivity
            openCustomDateLeft={true}
            datePickerPosition="monitorheader-datepicker-position"
            id="date-range-picker-user-activity"
            monitorActiveTabId="user-activity-header"
          />

          {!isUserActivityLog && !isTeamActivityLog ? (
            <Filter
              handleClearFilterParams={handleClearFilterParams}
              filterParams={filterParams}
              filterFields={filterFields}
              setFilterParams={setFilterParams}
              monitorActiveTabFilterID={"user-activity-filter"}
              isUserActivity
              assignActiveFilters={filterActivityLog}
              setAssignActiveFilters={setFilterActivityLog}
              applyFilter={applyFilterParams}
              getFiltersFileds={getFiltersFileds}
              startDate={startDate}
              endDate={endDate}
              disabled={!filtersFailsStatus}
            />
          ) : null}
        </Box>
      </Box>

      {isGetUserActivityLogsPending ? (
        <CircularLoading />
      ) : dataUserActivityLogs?.data?.length > 0 ? (
        Object.entries(dataUserActivityLogs?.data).map(
          ([date, activities], index) => (
            <Box key={index}>
              <Box
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-tab"
              ></Box>
              <Box className="accordion-content">
                <Box
                  className={
                    isUserActivityLog
                      ? "user-item user-single-page"
                      : "user-item"
                  }
                  key={activities?.id}
                >
                  <Box>
                    {isUserActivityLog ? (
                      <Box className="view-user-embty-img"> </Box>
                    ) : (
                      <Box className="user-img-wrapper">
                        <UserAvatar
                          avatarSrc={activities?.attributes?.avatar}
                          fullName={activities?.attributes?.user_name}
                          sizes={{ ratio: "32px", fontSize: "11px" }}
                        />
                      </Box>
                    )}
                    <Typography component={"strong"} className="user-name">
                      {activities?.attributes?.user_name}
                    </Typography>
                    {checkStatusMsg(
                      activities?.attributes?.action,
                      activities?.attributes?.entity,
                      activities?.attributes?.inside_subentity_name,
                      activities?.attributes?.controller_name,
                      activities?.attributes?.subentity,
                      activities?.attributes?.data_source,
                      activities?.attributes?.new_access,
                      activities?.attributes?.old_access,
                      activities?.attributes?.product_id,
                      activities?.attributes?.theme_name,
                    )}
                  </Box>
                  {moment(activities?.attributes?.created_at)?.format("h:mm ") +
                    CheckValueLocale(
                      `hour_${moment(activities?.attributes?.created_at)?.format("A")}`,
                      "",
                      {},
                      intl,
                    )}
                  {!checekDay(activities?.attributes?.created_at)
                    ? ` - ${moment(activities?.attributes?.created_at)?.format(
                        "DD/MM/yyyy",
                      )}`
                    : null}
                </Box>
              </Box>
            </Box>
          ),
        )
      ) : (
        <Box className="empty-box" id="empty-activity-log">
          <NoDataFoundProducts
            image="userActivity"
            title="no_data_found"
            description="no_activity_logs_found_msg"
          />
        </Box>
      )}
      {dataUserActivityLogs?.pagination?.pages > 1 ? (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-user-activity-log"
          count={dataUserActivityLogs?.pagination?.pages}
          variant="outlined"
        />
      ) : null}
    </Box>
  );
};

export default ActivityLog;
