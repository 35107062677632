import React from "react";
import { useIntl } from "react-intl";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, secondsToHms } from "utils/helpers/index";
import DOMPurify from "dompurify";

const ChartStatsWidget = (props) => {
  const { statsData, toolTipPosition, tooltipID, liveDashboardWidget } = props;
  const intl = useIntl();

  const timeConverter = (data) => {
    const time = secondsToHms(data);
    return `${time[0]} ${CheckValueLocale("hrs", "", {}, intl)}:${
      time[1]
    } ${CheckValueLocale("min", "", {}, intl)}:${time[2]} ${CheckValueLocale(
      "sec",
      "",
      {},
      intl,
    )}`;
  };

  return (
    <>
      <Box marginY={2}>
        <Grid container className={props?.containerClassName}>
          {statsData?.map((data) => {
            return (
              <Grid item md={4} xs={liveDashboardWidget ? 12 : 4}>
                <Box className="response_header">
                  {data?.icon && (
                    <Box className="box">
                      <FontAwesomeIcon icon={data.icon} color="#2AAAE2" />
                    </Box>
                  )}
                  <Box>
                    <Typography variant={"h7"} className="response_title">
                      {data?.name && data?.titleToolTip ? (
                        <Tooltip
                          title={
                            <Box className="tooltip-custom-new">
                              {data.titleToolTip &&
                                CheckValueLocale(
                                  data.titleToolTip,
                                  "",
                                  {},
                                  intl,
                                )}
                            </Box>
                          }
                          arrow
                          placement={toolTipPosition ? toolTipPosition : "top"}
                          id={tooltipID}
                        >
                          <Typography
                            variant={"h7"}
                            className="widget-subtitle"
                          >
                            {CheckValueLocale(data?.name, "", {}, intl)}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant={"h7"} className="widget-subtitle">
                          {CheckValueLocale(data?.name, "", {}, intl)}
                        </Typography>
                      )}

                      {(data?.value || data?.value === 0) &&
                      data?.type !== "time" ? (
                        <Typography
                          className={`response_data ${
                            data.percentage && "response-data-percentage"
                          }`}
                        >
                          {/* make conditionn here to Render HTML string  */}
                          {data.showMulipleValues ? (
                            <Typography
                              className="response_data format-data-values"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data?.value === 0
                                    ? data?.value?.toString()
                                    : data?.value,
                                ),
                              }}
                            ></Typography>
                          ) : (
                            data.value
                          )}
                          {data.percentage !== undefined && (
                            <Typography
                              component={"span"}
                              className="response-rate-percentage"
                            >
                              {` (${data.percentage}%) `}
                            </Typography>
                          )}
                        </Typography>
                      ) : (
                        <Box className="customer-report">
                          {timeConverter(data.value)
                            ?.split(":")
                            .map((i, index) => {
                              return (
                                <Typography className="response_data">
                                  {i.split(" ")[0]}
                                  {index < 2 && (
                                    <Typography
                                      component="span"
                                      className="response_colon"
                                    >
                                      {":"}
                                    </Typography>
                                  )}
                                  <Typography className="date_format">
                                    {i.split(" ")[1]}
                                  </Typography>
                                </Typography>
                              );
                            })}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
export default ChartStatsWidget;
