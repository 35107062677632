import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Typography, Box, Tooltip } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { exploreMonitorsData } from "utils/redux/features/ExplorePage/exploreSlice";
import { useDispatch } from "react-redux";
import ExploreController from "services/controllers/exploreController";
import { isEmptyObj } from "utils/helpers";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

const ExploreFunctions = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  // explore fields state
  const [keywordsFocused, setKeywordsFocused] = useState(false);
  const [spamKeywordsFocused, setSpamKeywordsFocused] = useState(false);
  const [keywordsErrorMsg, setkeywordsErrorMsg] = useState("");
  const [spamkeywordsErrorMsg, setSpamkeywordsErrorMsg] = useState("");
  const [selectedDatasource, setSelectedDatasource] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState({
    name: "",
    value: "",
  });
  const [selectedSpamKeyword, setSelectedSpamKeyword] = useState({
    name: "",
    value: "",
  });

  ////// initial State
  const [keywords, setKeywords] = useState([]);
  const [spamKeywords, setSpamKeywords] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [isLoading, setIsLoading] = useState(true);
  const [isSavedExploreLoading, setIsSavedExploreLoading] = useState(true);
  const [isLatestSearchLoading, setIsLatestSearchLoading] = useState(true);
  const [exploreSavedListData, setExploreListData] = useState([]);
  const [latestSearchListData, setLatestSearchListData] = useState([]);
  const [trendtingTopicsData, setTrendtingTopicsData] = useState([]);
  const [datasourceQuota, setDatasourceQuota] = useState({});
  const [formFieldErrors, setFormFieldErrors] = useState({
    keywords: "",
    exclude_keywords: "",
  });
  const [isClear, setIsClear] = useState(false);
  const [isDatePickerChange, setIsDatePickerChange] = useState(false);
  const [disableCreateExplore, setDisableCreateExplore] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState({
    message: "",
    severity: "",
    title: "",
  });
  //saved results list state
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [page, setPage] = useState(1);
  const [statsExploreResults, setStatsExploreResults] = useState({
    openDeleteModal: false,
    snackBarAction: "",
    snackBarSeverity: "",
  });
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [selectedSavedItem, setSetSelectedItem] = useState({});
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);

  //Explore WidgetData
  const [topPostsPreloader, setTopPostsPreloader] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  const [maxData, setMaxData] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // Quick Explore data
  const clientMQTT = useRef();
  const mqttConnectionClosed = useRef(false);
  const [response, setResponse] = useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  // Search data
  const [loadingStatus, setLoadingStatus] = useState("");
  const [exploreDate, setExploreDate] = useState("");
  const [exploreId, setExploreId] = useState("");
  const [exploreDataCount, setExploreDataCount] = useState([]);
  const [activeExploreStep, setActiveExploreStep] = useState(1);
  const [exploreInitialDate, setExploreInitialDate] = useState("last_7_days");
  const [isFilteredFieldsLoaded, setIsFilteredFieldsLoaded] = useState(false);
  //
  const [filterParams, setFilterParams] = useState({});
  const [filterFields, setFilterFields] = useState([]);
  const [filterName, setFilterName] = useState("");
  const { changeFilterParamsToString } = CommonFunctions();

  // make rabbitMQ response as shared with [ show / create explore]
  const handleRabbitMQRespnse = (response) => {
    setResponse([]);
    if (!isEmptyObj(response) && response?.data?.data) {
      setResetAllFields((prev) => prev + 1);
      setPreLoaderTrackerDataSources(response?.data?.data?.dataSources);
      connectWithRabbitMQ(
        mqttConnectionClosed,
        clientMQTT,
        response?.data?.data,
        setResponse,
      );
    } else {
      mqttConnectionClosed.current = true;
    }
  };

  //handle datasources field
  const getMinDate = (noOfDays) => {
    let minDate = moment()
      .subtract(noOfDays, "d")
      ?.tz("utc", true)
      ?.startOf("day");
    let oldestDate = moment.unix(minDate.unix()).format("YYYY-MM-DD");
    return oldestDate;
  };
  const [informerExploreData, setInformerExploreData] = useState({
    minDate: getMinDate(29),
  });

  const handleDatasourceChange = (event) => {
    const { value } = event.target;
    setDisableCreateExplore(false);
    let selectedValues = [...value];
    const lookup = value?.reduce((a, e) => {
      a[e?.id] = ++a[e?.id] || 0;
      return a;
    }, {});
    const removeItem = value?.filter((e) => lookup[e?.id]);
    if (removeItem?.length == 2) {
      selectedValues = value?.filter((item) => item?.id != removeItem[0]?.id);
    }
    setSelectedDatasource([...selectedValues]);
  };

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const clearAllData = () => {
    applyFilter(
      moment().subtract(6, "d").startOf("day").unix(),
      moment().unix(),
    );
    setSelectedDatasource([]);
    setSelectedKeyword({
      name: "",
      value: "",
    });
    setSelectedSpamKeyword({
      name: "",
      value: "",
    });
    setKeywords([]);
    setSpamKeywords([]);
    setFormFieldErrors({
      keywords: "",
      exclude_keywords: "",
    });
    setIsClear(false);
    setIsDatePickerChange(!isDatePickerChange);
  };

  const handleAlertLimitReach = () => {
    let alertValueLimit = "";
    if (datasourceQuota?.TWITTER?.can_create == false) {
      alertValueLimit = "alert_reach_limit_explore_twitter";
    }
    if (datasourceQuota?.TALKWALKER?.can_create == false) {
      alertValueLimit = "alert_reach_limit_explore_newsblogs";
    }
    if (
      datasourceQuota &&
      datasourceQuota?.TWITTER?.can_create == false &&
      datasourceQuota?.TALKWALKER?.can_create == false
    ) {
      alertValueLimit = "alert_reach_limit_explore_twitter_newsblogs";
    }
    return alertValueLimit;
  };

  const handleDisbleCreateExplore = () => {
    return (
      !keywords?.length || !selectedDatasource?.length || disableCreateExplore
    );
  };
  const handleDiableClearAllExplore = () => {
    return (
      keywords?.length || selectedDatasource?.length || spamKeywords?.length
    );
  };

  //get explore data
  const getExploreData = () => {
    setIsLoading(true);
    const trendingValues = [];
    ExploreController.getExploreData().then((result) => {
      setInformerExploreData({
        ...result?.data,
        minDate: getMinDate(
          result?.data?.date_range?.max_days
            ? +result?.data?.date_range?.max_days - 1
            : 29,
        ),
      });
      result?.data?.trending_topics?.hashtags &&
        Object.entries(result?.data?.trending_topics?.hashtags).forEach(
          ([key, value]) => {
            trendingValues.push(...value);
          },
        );
      setTrendtingTopicsData([...trendtingTopicsData, ...trendingValues]);
      setDatasourceQuota(result?.data?.quota);
      setIsLoading(false);
    });
  };
  //get explore saved results
  const getExploreSavedListData = (page) => {
    setIsSavedExploreLoading(true);
    ExploreController.getExploreSavedList(page).then((result) => {
      setExploreListData(result?.data?.data);
      setPagination(result?.data?.pagination);
      setIsSavedExploreLoading(false);
    });
  };
  //get explore latest search
  const getExploreLatestSearchtData = (page) => {
    setIsLatestSearchLoading(true);
    ExploreController.getExploreLatestSearch(page).then((result) => {
      setLatestSearchListData(result?.data?.data);
      setIsLatestSearchLoading(false);
    });
  };

  const getShowExploreData = (id, pageNumber, sortBy, filterParams) => {
    const isTwitterExploreSelected =
      checkDataSourceSelected(filterParams)?.includes("twitter");
    let obj = filterParams
      ? { ...filterParams, data_source: filterParams?.data_source?.join(",") }
      : {};
    if (isTwitterExploreSelected) {
      obj.like_count = filterParams.like_count;
      obj.reply_count = filterParams.reply_count;
      obj.retweet_count = filterParams.retweet_count;
      obj.usernames = filterParams.usernames;
    } else {
      delete obj.like_count;
      delete obj.reply_count;
      delete obj.retweet_count;
      delete obj.usernames;
    }
    ExploreController.showExplore(
      id,
      pageNumber,
      sortBy,
      obj && changeFilterParamsToString(obj, true),
    ).then((res) => {
      if (res?.errorMsg) {
        setShowAlertMessage({
          severity: "error",
          message: "something_went_wrong_try_agin",
        });
        setShowSnackBar(true);
        setIsFilteredFieldsLoaded(true);
      }
      if (res?.data?.data) {
        setDisableCreateExplore(true);
        setExploreDate(res?.data?.data?.created_at);
        setExploreDataCount(res?.data?.data?.collected_count);
        setExploreId(res?.data?.data?.id);
        res?.data?.data?.keywords?.length &&
          setKeywords([...res?.data?.data?.keywords]);
        res?.data?.data?.spam_keywords?.length &&
          setSpamKeywords([...res?.data?.data?.spam_keywords]);
        res?.data?.data?.data_sources?.length &&
          setSelectedDatasource([...res?.data?.data?.data_sources]);
        applyFilter(
          res?.data?.data?.filters?.start_date
            ? res?.data?.data?.filters?.start_date
            : startDate,
          res?.data?.data?.filters?.end_date
            ? res?.data?.data?.filters?.end_date
            : endDate,
        );
        setExploreInitialDate("CUSTOM");
      }
      handleRabbitMQRespnse(res);
    });
  };

  //get ids from data
  const getValueIds = (data) => {
    const valueIds = [];
    data?.map((el) => {
      valueIds.push(el?.id);
    });
    return valueIds;
  };

  //create alert
  const handleCreateExplore = () => {
    let sendData = {
      keywords: keywords,
      spam_keywords: spamKeywords,
      data_sources: getValueIds(selectedDatasource),
      filters: {
        start_date: startDate,
        end_date: endDate,
      },
      product_id: window.localStorage.sm_id,
    };
    ExploreController.createExplore(sendData).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        setDisableCreateExplore(true);
        setLoadingStatus(res?.data?.data?.status);
        setExploreDate(res?.data?.data?.created_at);
        setExploreDataCount(res?.data?.data?.collected_count);
        setExploreId(res?.data?.data?.id);
        handleRabbitMQRespnse(res);
        if (activeExploreStep == 1 || activeExploreStep == 3)
          setActiveExploreStep(2);
        setExploreInitialDate("CUSTOM");
      } else {
        setShowAlertMessage({
          severity: "error",
          message: "update_not",
        });
        setShowSnackBar(true);
      }
    });
  };

  //handle create btn redirect to create monitor
  const getItemLocales = (name) => {
    let datasources = {
      twitter: "twitter",
      talkwalker: "news_and_blogs",
      default: name,
    };
    return datasources[name] || datasources.default;
  };
  let selectedDatasourcesValues = [0];
  selectedDatasource?.map((item) => {
    selectedDatasourcesValues.push(getItemLocales(item?.name?.toLowerCase()));
  });
  const handleCreateMonitor = () => {
    dispatch(
      exploreMonitorsData({
        type: "KEYWORD",
        activeStep: 1,
        datasourcesValues: [...selectedDatasourcesValues],
        keywordsValues: [...keywords],
        spamKeywordsValues: [...spamKeywords],
      }),
    );
    navigate(`/social/monitors/create_monitor`);
  };

  const getPostsCount = (data) => {
    const sumDataCount = data?.reduce((acc, curr) => {
      return acc + curr?.collected_count;
    }, 0);
    return sumDataCount;
  };

  //handle delete explore results
  const handleDeleteSavedResult = (exploreId) => {
    ExploreController.deleteExploreSavedResult(exploreId).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        if (exploreSavedListData?.length === 1 && page > 1) {
          setPage(page - 1);
        } else {
          getExploreSavedListData(page);
        }
        setSnackBarData({
          severity: "success",
          title: "delete_msg_successfully_explore_list",
        });
        setOpenSnackBarDelete(true);
      } else {
        setSnackBarData({
          title: "explore_wrong_msg",
          severity: "error",
          message: "explore_try_again",
        });
        setOpenSnackBarDelete(true);
      }
      setStatsExploreResults({
        ...statsExploreResults,
        openDeleteModal: false,
      });
    });
  };
  const showTooltipText = (text, textLength) => {
    return (
      <>
        {text?.length > textLength ? (
          <Tooltip title={text} arrow placement="top">
            <Box>{text?.substring(0, textLength)}...</Box>
          </Tooltip>
        ) : (
          text
        )}
      </>
    );
  };
  //handle rabbitMq response
  const handleShowSavedExplore = (id, status) => {
    getShowExploreData(id, 1, "latest_posts");
    if (status == "done") {
      setActiveExploreStep(3);
    } else {
      setActiveExploreStep(2);
    }
  };

  //
  const handleClearFilterParams = () => {
    setFilterName("");
    setFilterParams({});
  };

  //get all filter Fields
  const getFiltersFileds = (exploreId) => {
    if (exploreId) {
      ExploreController.getFiltersFields(exploreId).then((data) => {
        if (data?.errorMsg) {
          setShowAlertMessage({
            severity: "error",
            message: "something_went_wrong_try_agin",
          });
          setShowSnackBar(true);
        }
        setFilterFields(data?.data?.data);
        setIsFilteredFieldsLoaded(true);
      });
    }
  };

  const checkDataSourceSelected = (filterParams) => {
    let res = [];
    if (filterParams?.data_source) {
      filterParams?.data_source?.map((e) => {
        informerExploreData?.data_sources?.map((el) => {
          if (el?.id == e) {
            res.push(el?.name?.toLowerCase());
          }
        });
      });
    }
    return res;
  };

  return {
    handleDatasourceChange,
    keywordsFocused,
    setKeywordsFocused,
    keywords,
    setKeywords,
    keywordsErrorMsg,
    setkeywordsErrorMsg,
    selectedDatasource,
    setSelectedDatasource,
    spamKeywords,
    setSpamKeywords,
    spamKeywordsFocused,
    setSpamKeywordsFocused,
    spamkeywordsErrorMsg,
    setSpamkeywordsErrorMsg,
    selectedKeyword,
    setSelectedKeyword,
    selectedSpamKeyword,
    setSelectedSpamKeyword,
    startDate,
    endDate,
    isLoading,
    getExploreData,
    informerExploreData,
    trendtingTopicsData,
    datasourceQuota,
    formFieldErrors,
    setFormFieldErrors,
    setStartDate,
    setEndDate,
    clearAllData,
    isClear,
    setIsClear,
    applyFilter,
    isDatePickerChange,
    setIsDatePickerChange,
    handleAlertLimitReach,
    handleDisbleCreateExplore,
    handleCreateExplore,
    showAlertMessage,
    setShowAlertMessage,
    showSnackBar,
    setShowSnackBar,
    handleCreateMonitor,
    getExploreSavedListData,
    exploreSavedListData,
    isSavedExploreLoading,
    getPostsCount,
    pagination,
    setPagination,
    page,
    setPage,
    statsExploreResults,
    setStatsExploreResults,
    handleDeleteSavedResult,
    openSnackBarDelete,
    setOpenSnackBarDelete,
    snackBarData,
    selectedSavedItem,
    setSetSelectedItem,
    showTooltipText,
    getExploreLatestSearchtData,
    isLatestSearchLoading,
    latestSearchListData,
    handleDiableClearAllExplore,
    loadingStatus,
    exploreDataCount,
    exploreDate,
    response,
    resetAllFields,
    preLoaderTrackerDataSources,
    //////
    topPosts,
    setTopPosts,
    pageNumber,
    setPageNumber,
    topPostsPreloader,
    setTopPostsPreloader,
    topPostsCount,
    setTopPostsCount,
    maxData,
    setMaxData,
    totalPage,
    setTotalPage,
    exploreId,
    getShowExploreData,
    activeExploreStep,
    setActiveExploreStep,
    setDisableCreateExplore,
    disableCreateExplore,
    handleShowSavedExplore,
    exploreInitialDate,
    getFiltersFileds,
    filterParams,
    setFilterParams,
    filterFields,
    handleClearFilterParams,
    checkDataSourceSelected,
    isFilteredFieldsLoaded,
  };
};
export default ExploreFunctions;
