import moment from "moment";
import React, { useEffect, useState } from "react";
import UserActivitesHeader from "./userActivitesHeader";
import SurveyPage from "./survey/surveyPage";
import InteractionsPage from "./interactions/interactionsPage";
import { connectWithRabbitMQ } from "../../../pages/channelAnalytics/InsightsPage/insightsCommonFunctions.js";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { getDataSources } from "../../../pages/channelAnalytics/channelAnalytics.hooks";
import { Box, Typography } from "@mui/material";
import AudienceController from "services/controllers/audienceController";
import CircularLoading from "components/circularProgress";
import NoDataFound from "components/no-Data/noDataFound";
import { useIntl } from "react-intl";
import Luci from "pages/luci";
const MainUserActivites = (props) => {
  const intl = useIntl();
  let { profileId, userProfileData } = props;
  const [response, setResponse] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const cdpAllTimes = moment(localStorage.getItem("cdp_all_times")).unix();
  const [startDate, setStartDate] = useState(cdpAllTimes);
  const [endDate, setEndDate] = useState(moment().unix());
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);

  const [survey, setSurvey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noData, setNoData] = useState(false);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPage(1);
  };

  const getAudienceSurvey = () => {
    setLoading(true);
    AudienceController.getAudienceSurvey(profileId, startDate, endDate).then(
      (data) => {
        setSurvey(data);
        setLoading(false);
      },
    );
  };

  const getAudienceInteractions = () => {
    AudienceController.getAudienceInteractions(
      profileId,
      startDate,
      endDate,
      page,
    ).then((data) => {
      if (data.data) {
        setNoData(false);
        if (!isEmptyObj(data?.data)) {
          let filteredDatasources = data?.data?.data_sources?.filter(
            (item) => item?.toLowerCase?.() != "whatsapp",
          );
          setPreLoaderTrackerDataSources([...filteredDatasources]);
          connectWithRabbitMQ(
            mqttConnectionClosed,
            clientMQTT,
            data?.data,
            setResponse,
          );
        } else {
          mqttConnectionClosed.current = true;
        }
      } else {
        setNoData(true);
      }
    });
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      getAudienceSurvey();
      getDataSources(setDataSources);
    }, 1000);
    return () => clearTimeout(timer);
  }, [startDate, endDate]);

  useEffect(() => {
    let timer = setTimeout(() => {
      getAudienceInteractions();
    }, 1000);
    return () => clearTimeout(timer);
  }, [page, startDate, endDate]);
  let cdpSource =
    dataSources?.length &&
    dataSources?.filter((item) => item?.source === "CDP")[0];
  return (
    <Box>
      <Luci
        isInactive={false}
        pageParams={{
          profileId: profileId,
          startDate: startDate,
          endDate: endDate,
          sectionName: "Profile Page",
          productId: window.localStorage.cdp_id,
          luciFeature: "Profile Page",
          dataSourceId: cdpSource?.id,
          monitorName: `${userProfileData?.data?.name}`,
          monitorProduct: "audience",
          dataLoaded: loading,
          dataEmpty: noData,
          dataLoaded:
            checkAllDataExist || preLoaderTrackerDataSources?.length == 0,
          dataEmpty: checkDataIsEmpty,
        }}
      />
      <UserActivitesHeader
        title="user_activites"
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        allTimeStartDate={cdpAllTimes}
      />
      <Box className="survey-adience">
        <Typography mb={2} variant={"h7"} className="audience-widget-title">
          {CheckValueLocale("survey", "", {}, intl)}
        </Typography>
        {loading ? (
          <CircularLoading />
        ) : survey?.data?.length > 0 ? (
          <SurveyPage
            profileId={profileId}
            userProfileData={userProfileData}
            survey={survey}
          />
        ) : (
          <Box className="chart-no-data survey-nodata">
            <NoDataFound />
          </Box>
        )}
      </Box>
      <Box my={4} className="all-interactions-list audience">
        <Typography variant={"h7"} className="audience-widget-title">
          {CheckValueLocale("interactions", "", {}, intl)}
        </Typography>

        {loading ? (
          <NoDataFound />
        ) : (
          <>
            {noData ? (
              <Box className="chart-no-data survey-nodata">
                <NoDataFound />
              </Box>
            ) : (
              <InteractionsPage
                response={response}
                profileId={profileId}
                preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                dataSources={dataSources}
                startDate={startDate}
                endDate={endDate}
                getAudienceInteractions={getAudienceInteractions}
                page={page}
                setPage={setPage}
                setCheckAllDataExist={setCheckAllDataExist}
                setCheckDataIsEmpty={setCheckDataIsEmpty}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MainUserActivites;
