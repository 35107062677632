import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData } from "../excelCommonFn";

const engagements_nb_Keywords = (response, lang, themesTranslations) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overView = [],
      postsVolume = [],
      sentimentAnalysis = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      topEngaged = [],
      topSites = [],
      reachFunnelLegacy = [],
      postsInteractions = [],
      topPosts = [],
      topLanguages = [],
      topCountries = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overView":
            value?.map((i) => {
              overView?.push({
                [lang.overview]: lang[i?.name],
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([overView, lang?.overview]);
            break;
          //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value.neutral[index].name) {
                let total =
                  value?.neutral[index]?.value +
                  value?.positive[index]?.value +
                  value?.negative[index]?.value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? (value?.neutral[index]?.value / total) * 100 + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? (value?.positive[index]?.value / total) * 100 + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? (value?.negative[index]?.value / total) * 100 + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.posts_sentiment_analysis,
            ]);
            break;
          //_______________________________________________________________________________

          case "themeXlxs":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object?.entries(mainThemes)?.forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object?.entries(subThemes)?.forEach(([key2, value2]) => {
                    theme?.push({
                      [lang?.excel_categories]: `${lang?.[key1]} (${themesTranslations?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([theme, lang?.excel_categories]);

            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object?.entries(mainSubThemes)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object?.entries(mainThemes)?.forEach(([key2, value2]) => {
                    subTheme?.push({
                      [lang?.excel_themes]: `${themesTranslations?.[key1]} (${lang?.[key2]})`,
                      [lang?.total_interactions]: value2,
                    });
                  });
              });
            allSheetData?.push([subTheme, lang?.excel_themes]);

            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object?.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object?.entries(mainThemes)?.forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object?.entries(subThemes)?.forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang?.[key2]} (${themesTranslations?.[key3]})`]:
                            value3,
                        };
                      });
                  });
                themesTrend?.push({
                  [lang?.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([themesTrend, lang?.excel_categories_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object?.entries(mainthemesTrend)?.forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object?.entries(subThemesTrendObj)?.forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object?.entries(mainThemes)?.forEach(
                          ([key3, value3]) => {
                            rawObj = {
                              ...rawObj,
                              [`${themesTranslations?.[key2]} (${lang?.[key3]})`]:
                                value3,
                            };
                          },
                        );
                    },
                  );
                subThemesTrend?.push({
                  [lang.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData?.push([subThemesTrend, lang?.excel_themes_trend]);
            break;

          //_______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions?.push({
                [lang.date]: i.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([postsInteractions, lang?.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume?.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData?.push([postsVolume, lang?.posts_volume]);
            break;
          //_______________________________________________________________________________

          case "topLanguages":
            value?.map((i) => {
              topLanguages?.push({
                [lang.language]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topLanguages, lang?.top_languages]);
            break;
          //_______________________________________________________________________________

          case "topEngaged":
            value?.map((i) => {
              topEngaged?.push({
                [lang.links]: i?.id,
                [lang.nb_posts]: i?.stats_count,
              });
            });
            allSheetData?.push([topEngaged, lang?.top_engaged_sites]);
            break;
          //_______________________________________________________________________________

          case "topCountries":
            value?.map((i) => {
              topCountries?.push({
                [lang.countries]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData?.push([topCountries, lang?.top_countries]);
            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts?.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData?.push([topPosts, lang?.top_articles]);
            break;
          //_______________________________________________________________________________
          case "topSites":
            value?.map((i) => {
              topSites?.push({
                [lang.links]: i?.id,
                [lang.reach]: i?.stats_count,
              });
            });
            allSheetData?.push([topSites, lang?.top_sites]);
            break;
          //_______________________________________________________________________________
          case "reachFunnelLegacy":
            value?.map((i) => {
              reachFunnelLegacy?.push({
                [lang.posts_reach]: i?.name,
                [lang.number]: i?.value[0],
                [lang.total]: i?.value[0],
              });
            });
            allSheetData?.push([reachFunnelLegacy, lang?.posts_reach]);
            break;
          //_______________________________________________________________________________

          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { engagements_nb_Keywords };
