import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { generateExcelFunc } from "./exportExcel/ExportExcelSheet/generateExcelFunc";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import SnackBar from "components/snackBar";
import MonitorsController from "services/controllers/monitorsController";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import {
  handlelUserRoles,
  CheckValueLocale,
  handleFiltersParamsIds,
} from "utils/helpers/index";
import * as xlsx from "xlsx";
import * as fileSaver from "file-saver";
import { Button, Menu } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import excelImage from "images/excel-icon.svg";
import pptImage from "images/power-point-icon.svg";
import { useSelector } from "react-redux";
import moment from "moment";
import InsightsController from "services/controllers/insightsController";
import { useParams } from "react-router-dom";
import "./exportButton.scss";
import LucButton from "shared/lucButton/lucButton";

//____________________________________________________________________________

export const xlsxSupportedExportTabs = [
  "posts",
  "comments_mentions",
  "questions",
  "authors",
  "customer_care",
];

const ExportBtn = (props) => {
  const intl = useIntl();
  const [buttonColor, setButtonColor] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const urlParams = useParams();
  const dataSourceIdCA = urlParams.data_source_id;
  const [snackBarData, setSnackBarData] = useState({
    message: "",
    severity: "",
    title: "",
  });
  let {
    setShowExportBtn,
    loadingButtonID,
    exportToExcelID,
    monitorActiveTabID,
    isCAExportLoaded,
    isCAPage,
    datasourcePath,
    filterFields,
    isSLPage,
    startDate,
    endDate,
  } = props;
  const [anchorExport, setAnchorExport] = useState(null);

  const openExport = Boolean(anchorExport);

  //  Start Redux Declarations
  const reduxFilterStartDateUX = useSelector(
    (state) => state?.socialListening?.startDateUnix,
  );
  const reduxFilterEndDateUX = useSelector(
    (state) => state?.socialListening?.endDateUnix,
  );

  const reduxFilterParams = useSelector(
    (state) => state?.socialListening?.newFilterParams,
  );

  const reduxFilterStartDateUxCA = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxFilterEndDateUxCA = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );

  const reduxFilterDropDownParamsToDisplay = useSelector(
    (state) => state?.channelAnalytics?.filterDropDown,
  );
  //  End Redux Declarations

  const handleClickExport = (event) => {
    if (anchorExport !== event?.currentTarget) {
      setAnchorExport(event?.currentTarget);
    }
    setIsAnalyticsPage(false);
  };

  const handleCloseExport = () => {
    setAnchorExport(null);
  };

  let menuDirection = intl?.locale === "en" ? "right" : "left";
  let exportMenuId = props?.single_tweet_analysis
    ? "export-single-tweet-menu"
    : "export-menu";

  //____________________________________________________________________________
  const [pptPages, setPPtPages] = useState([]);
  const [isAnalyticsPage, setIsAnalyticsPage] = useState(false);

  const getExportPages = () => {
    MonitorsController.getExportPages(
      +window?.localStorage?.sm_id,
      +props?.dataSourceId,
      +props?.monitorId,
    ).then((data) => {
      if (data?.data?.status == 200) {
        setPPtPages(data?.data?.data?.pages);
      }
    });
  };

  useEffect(() => {
    if (isSLPage) {
      props?.dataSourceId && getExportPages();
    }
  }, [isSLPage, props?.dataSourceId]);
  const exportExcelLogExport = () => {
    MonitorsController.exportExcelLogExport(props?.monitorId).then((res) => {});
  };
  const handleExportAnalyticsPages = () => {
    MonitorsController.exportPPTAnalyticsPage(
      +window?.localStorage?.sm_id,
      +props?.dataSourceId,
      +props?.monitorId,
      selectedLanguage && selectedLanguage.name,
      reduxFilterStartDateUX,
      reduxFilterEndDateUX,
    ).then((data) => {
      if (data?.data?.status == 200) {
        handleClose();
        setSnackBarData({
          severity: "success",
          message: CheckValueLocale("ppt_success", "", {}, intl),
          title: CheckValueLocale("ppt_title", "", {}, intl),
        });
        setOpenSnackBar(true);
      } else {
        handleClose();
        setSnackBarData({
          severity: "error",
          message: CheckValueLocale("wrong_request_parameter", "", {}, intl),
          title: CheckValueLocale("try_again", "", {}, intl),
        });
        setOpenSnackBar(true);
      }
    });
  };
  //____________________________________________________________________________
  const exportToXLSX = (fileDetail) => {
    const fileType = "xlsx"; //XLSX file type

    fileDetail?.map((item, index) => {
      item["json"] = xlsx.utils.json_to_sheet(item[0]?.data); // loop on data that come from BE to convert it as JSON [ XLSX ]
    });

    const sheetsDataNames = {
      Sheets: {},
      SheetNames: [],
    };

    fileDetail?.map((item, index) => {
      return (
        (sheetsDataNames.Sheets[item[0]?.name] = item?.json), // sheet JSON Data push to every item
        sheetsDataNames.SheetNames?.push(item[0]?.name) // sheet Name
      );
    });

    const sheetData = { ...sheetsDataNames };
    const excelBuffer = xlsx.write(sheetData, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: fileType });
    fileSaver.saveAs(
      data,
      `${
        isCAPage
          ? props?.datasourceName
          : props?.engagementTabName
            ? props?.engagementTabName
            : props?.monitorData?.attributes?.account_name
      }` +
        "-" +
        `${
          isCAPage || props?.engagementTabName
            ? `${moment
                .unix(
                  props?.engagementTabName
                    ? startDate
                    : reduxFilterStartDateUxCA,
                )
                .utc()
                .format("DD/MM/YYYY")} - ${moment
                .unix(
                  props?.engagementTabName ? endDate : reduxFilterEndDateUxCA,
                )
                .utc()
                .format("DD/MM/YYYY")} `
            : props?.activeTab
        }` +
        ".xlsx",
    );
    if (isSLPage) exportExcelLogExport();
  };
  //____________________________________________________________________________

  let allowExport = JSON.parse(
    window?.localStorage?.info_attributes,
  )?.allow_posts_export;
  //____________________________________________________________________________
  const exportExcelIsDisabled = //this option to hide export excel in some cases
    props?.engagementTabName
      ? false
      : props?.isCAPage
        ? allowExport === false
        : (!handlelUserRoles("SM", "EXPORT_DATA") ||
            allowExport === false ||
            props?.isSingleTweet) &&
          true;
  //this option to hide export PPT in some cases
  const exportPptIsDisabled = //this option to hide export PPT in some cases
    (props.activeTab === "questions" ||
      props.activeTab === "posts" ||
      props.activeTab === "comments_mentions" ||
      props.activeTab === "authors" ||
      props?.disablePPTExport === true) &&
    true;

  const exportExcelIsHideBtn = //this option to hide export Excel in some cases
    (props?.activeTab === "posts" ||
      props?.activeTab === "authors" ||
      props?.activeTab === "comments_mentions" ||
      props?.activeTab === "questions") &&
    allowExport === false;

  const [selectedLanguage, setSelectedLanguage] = useState({
    name: "en" ?? "ar",
  });
  const [selectedType, setSelectedType] = useState({
    name: exportPptIsDisabled ? "excel" : "ppt",
  });

  useEffect(() => {
    setSelectedType({
      name: exportPptIsDisabled ? "excel" : "ppt",
    });
  }, [exportPptIsDisabled]);

  //____________________________________________________________________________
  // this to check if user role not exist and no ppt then don't show btn
  if (exportExcelIsDisabled === true && exportPptIsDisabled === true) {
    setShowExportBtn(true);
  }
  //____________________________________________________________________________
  const multiDataSet =
    selectedLanguage && //we have to check language fist to avoid sending it as null
    generateExcelFunc(
      props?.excelSheetData,
      props?.monitorType,
      props?.activeTab,
      props?.monitorDataSource,
      selectedLanguage && selectedLanguage.name,
      props?.engagementTabName,
      props?.themesOptions, // To use it in translating sub-themes from BE response
    );
  //____________________________________________________________________________
  //handle open export modal in case data is retuned from BE
  const handleClickOpen = () => {
    if (props.checkDataIsEmpty !== false || isAnalyticsPage) {
      handleApplyExport();
      setButtonColor("active-export");
    } else {
      setOpenSnackBar(true);
      setSnackBarData({
        severity: "info",
        title: CheckValueLocale("no_data_found_to_be_exported", "", {}, intl),
        message: CheckValueLocale("export_no_data", "", {}, intl),
      });
    }
  };
  // ________________________________________________________________________________________
  //handle close export popup modal
  const handleClose = () => {
    setSelectedLanguage({
      name: "en" ?? "ar",
    });
    setSelectedType({
      name: exportPptIsDisabled ? "excel" : "ppt",
    });
    handleCloseExport();
  };
  // ________________________________________________________________________________________
  //dropdowns inside export popup modal
  const dropDownTypes = [
    {
      label: "type",
      options: [
        { id: "ppt", name: "ppt", disabled: exportPptIsDisabled },
        { id: "excel", name: "excel", disabled: exportExcelIsDisabled },
      ],
      disabled: true,
    },
    {
      label: "language",
      options: [
        { id: "english", name: "en", disabled: false },
        {
          id: "arabic",
          name: "ar",
          disabled:
            props.activeTab === "comments_mentions" ||
            props.activeTab === "posts" ||
            props.activeTab === "questions" ||
            props.activeTab === "authors" ||
            (selectedType?.name === "excel" &&
              props.activeTab === "customer_care"),
        },
      ],
      disabled: true,
    },
  ];

  // ________________________________________________________________________________________

  //handle selection on lang and type to make export btn enabled
  const handleSelection = (label, option) => {
    label === "language" && setSelectedLanguage(option);
    label === "type" && setSelectedType(option);
  };
  // ________________________________________________________________________________________
  //handle export btn inside the popup modal
  const inputRef = useRef(null);
  const handleApplyExport = () => {
    if (selectedType?.name == "excel") {
      if (xlsxSupportedExportTabs.includes(props?.activeTab)) {
        handleExportExcel();
      } else {
        inputRef.current.click();
        handleClose();
      }
    } else {
      if (props?.isSingleTweet) {
        props?.exportPpt(selectedLanguage.name);
        handleClose();
      } else if (isCAPage) {
        handleCAPPtExport();
      } else {
        !isAnalyticsPage
          ? handlePptExport() //default used in SL
          : handleExportAnalyticsPages(); // use with analytics page
      }
    }
  };
  // ________________________________________________________________________________________
  //handle Excel export from BE using API
  const { changeFilterParamsToString } = CommonFunctions();
  const handleExportExcel = () => {
    // handleExportExcel function is used to call to endpoints, both have different body and response
    // 1- new api xlsx export for SL
    // 2 - old api excel export for monitors
    // we trigger the new api if the page is SL and the tab is one of the supported tabs (which now all tans inside SL are supported)
    // in the xlsx the backend is expecting new parameters, such as page_name, total_posts, etc.
    // total_posts is'not shared variable like props.checkDataIsEmpty, so we had to make it global variable, to be used in the xlsx export.
    // we trigger the old api if the page is'nt SL or the tab is'nt one of the supported tabs
    let pageType;
    let page_name;
    switch (props?.activeTab) {
      case "posts":
        pageType = "PostsList";
        page_name = "PostsPage";
        break;
      case "comments_mentions":
        pageType = "CommentsList";
        page_name = "CommentsMentionsPage";
        break;
      case "questions":
        pageType = "QuestionsList";
        page_name = "QuestionsPage";
        break;
      case "authors":
        pageType = "AuthorsPage";
        page_name = "AuthorsPage";
        break;
      case "customer_care":
        pageType = "CustomerCare";
        page_name = "CustomerCarePage";
        break;
      default:
        pageType = props?.activeTab;
        page_name = props?.activeTab;
        break;
    }

    let queryData = {
      product_id: window?.localStorage?.sm_id,
      monitor_id: props?.monitorData.attributes.id,
      page_type: pageType,
      data_source: props?.monitorDataSource,
      start_date: reduxFilterStartDateUX,
      end_date: reduxFilterEndDateUX,
      filters: !props.showFilterBtn
        ? {}
        : changeFilterParamsToString(reduxFilterParams),
    };
    // exportFn for now holds the old export excel function
    let exportFn = MonitorsController.getExportExcel;
    // for the tabs inside Social Listening we want to use the new export xlsx engine.
    if (props?.isSLPage) {
      // we change the exportFn to the new export xlsx function
      exportFn = MonitorsController.getSLExportXLSX;
      delete queryData.page_type;
      // inject the sort_by in the filter if it exists
      if (props?.postsSortBy) {
        if (!queryData.filters) queryData.filters = {};
        queryData.filters = {
          ...queryData.filters,
          sort_by: props?.postsSortBy,
        };
      }
      // in the new api if the filters object is empty, we don't send it at all.
      if (!queryData.filters || Object.keys(queryData.filters).length === 0) {
        delete queryData.filters;
      }
      // if the data source is news blogs, we change it to TALKWALKER
      if (queryData.data_source === "nb") {
        queryData.data_source = "TALKWALKER";
      }
      // get the total posts count from the props (as global start)
      const total_posts = props?.totalPostsCount;
      const SocialListing = {
        ...queryData,
        page_name,
        total_posts,
      };
      // the new api expects the data to be inside SocialListing object
      queryData = {
        data: {
          SocialListening: SocialListing,
        },
      };
    }

    exportFn(queryData).then((data) => {
      if (data?.data?.status == 204 || data?.data?.status == 200) {
        handleClose();
        setSnackBarData({
          severity: "success",
          message: CheckValueLocale("ppt_success", "", {}, intl),
          title: CheckValueLocale("your_exported_excel_on_way", "", {}, intl),
        });
        setOpenSnackBar(true);
      } else {
        handleClose();
        setSnackBarData({
          severity: "error",
          message: CheckValueLocale("wrong_request_parameter", "", {}, intl),
          title: CheckValueLocale("try_again", "", {}, intl),
        });
        setOpenSnackBar(true);
      }
    });
  };
  // ________________________________________________________________________________________
  //handle PPT export from BE using API
  const handlePptExport = () => {
    MonitorsController.getSocialMediaPPT(
      props.monitorDataSource,
      props.monitorData.attributes.id,
      window.localStorage.sm_id,
      props.activeTab,
      reduxFilterStartDateUX,
      reduxFilterEndDateUX,
      !props.showFilterBtn ? {} : changeFilterParamsToString(reduxFilterParams),
      props.pageNumber,
      selectedLanguage.name,
      true,
    ).then((data) => {
      if (data?.data?.status == 200) {
        handleClose();
        setSnackBarData({
          severity: "success",
          message: CheckValueLocale("ppt_success", "", {}, intl),
          title: CheckValueLocale("ppt_title", "", {}, intl),
        });
        setOpenSnackBar(true);
      } else {
        handleClose();
        setSnackBarData({
          severity: "error",
          message: CheckValueLocale("wrong_request_parameter", "", {}, intl),
          title: CheckValueLocale("try_again", "", {}, intl),
        });
        setOpenSnackBar(true);
      }
    });
  };

  const handleCAPPtExport = () => {
    InsightsController.getPublicExportPPT(
      reduxFilterStartDateUxCA,
      reduxFilterEndDateUxCA,
      handleFiltersParamsIds(reduxFilterDropDownParamsToDisplay, filterFields),
      true,
      window.localStorage.cxm_id,
      dataSourceIdCA,
      selectedLanguage?.name,
      true,
      datasourcePath,
    ).then((data) => {
      if (data?.data?.status == 200) {
        handleClose();
        setSnackBarData({
          severity: "success",
          message: CheckValueLocale("ppt_success", "", {}, intl),
          title: CheckValueLocale("ppt_title", "", {}, intl),
        });
        setOpenSnackBar(true);
      } else {
        handleClose();
        setSnackBarData({
          severity: "error",
          message: CheckValueLocale("wrong_request_parameter", "", {}, intl),
          title: CheckValueLocale("try_again", "", {}, intl),
        });
        setOpenSnackBar(true);
      }
    });
  };
  // ________________________________________________________________________________________
  //the title of the Export Modal changes with the tab or page name
  var exportModalTitle = props?.exportBtnTitle //if page name is sent within props
    ? CheckValueLocale(props?.exportBtnTitle, "", {}, intl)
    : props.activeTab // in case of monitors tabs active tab name will showup
      ? CheckValueLocale("export", "", {}, intl) +
        " " +
        CheckValueLocale(props?.activeTab, "", {}, intl)
      : CheckValueLocale("export", "", {}, intl); //any case that has no certain title
  // ________________________________________________________________________________________
  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  // _______________________________________________________________________________________
  // Handle ID method for ChurnZero elements
  let handleExportMonitorButtonId = (val) => {
    let exportId = val
      ? `export-${val?.id ?? "id"}-${
          props?.monitorData?.id ?? "Id"
        }-${props?.monitorDataSource?.toLowerCase()}-${
          props?.activeTab ?? "tab"
        }-tab`
      : `export-monitor-${
          props?.monitorData?.id ?? "Id"
        }-${props?.monitorDataSource?.toLowerCase()}-${
          props?.activeTab ?? "tab"
        }-button`;
    return exportId;
  };
  // _______________________________________________________________________________________

  const exportText = (type) => {
    const text = {
      ppt: (
        <Box className="export-file">
          <img src={pptImage} alt="ppt" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      excel: (
        <Box className="export-file">
          <img src={excelImage} alt="excel" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      default: CheckValueLocale(type, "", {}, intl),
    };
    return text[type] || text["default"];
  };

  const handleChecked = (type, val) => {
    let checked = false;
    if (type === "language") {
      checked = selectedLanguage?.name === val;
    } else {
      checked = selectedType?.name === val;
    }
    return checked;
  };
  const handleChange = (event) => {
    if (event.target.value == "analyticsPages") {
      setIsAnalyticsPage(true);
    } else {
      setIsAnalyticsPage(false);
    }
  };

  return (
    <>
      <Box className="export-btn">
        {!exportExcelIsHideBtn ? (
          <LucButton
            aria-owns={anchorExport}
            aria-haspopup="true"
            aria-expanded={openExport}
            onClick={handleClickExport}
            endIcon={<ArrowDropDown />}
            variant="outline"
            type={props?.isSecondary ? "secondary" : "primary"}
          >
            {CheckValueLocale("export", "", {}, intl)}
          </LucButton>
        ) : null}

        <Menu
          id={exportMenuId}
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
            onMouseLeave: handleCloseExport,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: menuDirection,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: menuDirection,
          }}
          anchorEl={anchorExport}
          open={openExport}
          onClose={handleCloseExport}
          className="export-menu"
        >
          <Box className="export-btn-menu">
            <Box className="export-options-title">
              {CheckValueLocale("export_options", "", {}, intl)}
            </Box>
            <Box className="radio-item export-item">
              {dropDownTypes?.map((type, index) => {
                return (
                  <Box key={index}>
                    <Box className={`export-label ${type?.label}`}>
                      {type?.options?.map((val, i) => {
                        return (
                          <React.Fragment key={i}>
                            {!val?.disabled ? (
                              <label className="radio-item">
                                <input
                                  type="radio"
                                  name={"export-" + type?.label}
                                  onClick={() =>
                                    handleSelection(type?.label, val)
                                  }
                                  id={handleExportMonitorButtonId(val)}
                                  checked={handleChecked(
                                    type?.label,
                                    val?.name,
                                  )}
                                />
                                {!val?.disabled ? (
                                  <Box
                                    component="span"
                                    className="export-choose"
                                  >
                                    {!val?.disabled
                                      ? exportText(val?.name)
                                      : null}
                                  </Box>
                                ) : null}
                              </label>
                            ) : null}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
              {/* Select Pages in SL ONLY */}
              {isSLPage && selectedType?.name !== "excel" ? (
                <Box>
                  <Box className="export-select-pages-head">
                    {CheckValueLocale("export_select_pages", "", {}, intl)}
                  </Box>
                  <Box className="form-radio-parant">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        defaultValue="currentPage"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="currentPage"
                          control={<Radio />}
                          label={`${CheckValueLocale(
                            "export_current_page",
                            "",
                            {},
                            intl,
                          )} (${CheckValueLocale(
                            props?.activeTab,
                            "",
                            {},
                            intl,
                          )})`}
                        />
                        {pptPages && pptPages?.length ? (
                          <FormControlLabel
                            value="analyticsPages"
                            control={<Radio />}
                            label={
                              <Box>
                                {CheckValueLocale(
                                  "export_analytics_pages",
                                  "",
                                  {},
                                  intl,
                                )}
                                <br />
                                {`(${pptPages
                                  ?.map((page) => {
                                    return CheckValueLocale(page, "", {}, intl);
                                  })
                                  .join(" , ")})`}
                              </Box>
                            }
                          />
                        ) : null}
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              ) : null}
              {selectedType?.name !== "excel" && isAnalyticsPage ? (
                <Box className="filters-not-affect-export">
                  {CheckValueLocale(
                    "filters_not_affect_exported",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              ) : null}
              <Divider />

              <LucButton
                variant="filled"
                loading={
                  props?.checkAllDataExist === false ||
                  (isCAPage && !isCAExportLoaded)
                }
                className="loading-export-btn-margin"
                onClick={handleClickOpen}
                id={handleExportMonitorButtonId()}
                disabled={selectedLanguage && selectedType ? false : true}
              >
                {CheckValueLocale("export", "", {}, intl)}
              </LucButton>
            </Box>
          </Box>
        </Menu>
      </Box>

      {/* __________________________________________________________________________________________ */}
      <button
        onClick={() => exportToXLSX(multiDataSet)}
        style={{ display: "none" }}
        ref={inputRef}
      />
      {/* __________________________________________________________________________________________ */}
      {openSnackBar == true && (
        <SnackBar
          open={openSnackBar}
          severity={snackBarData?.severity}
          message={snackBarData?.message}
          title={snackBarData?.title}
          handleClose={handleCloseSnackBar}
        />
      )}
    </>
  );
};

export default ExportBtn;
