import { Box, Button } from "@mui/material";
import { useIntl } from "react-intl";
import WidgetHeader from "../widgets/widgetHeader.js";
import _ from "lodash";
import InteractionListItem from "./InteractionListItem.js";
import "./InteractionsList.scss";
import FetchingData from "components/fetchingData/fetchingData";
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import NoDataFound from "components/no-Data/noDataFound";
import {
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers/index.js";
import PostQuestionPopup from "pages/Monitors/Components/questionCard/components/postQuestionPopup.js";
import InsightsController from "services/controllers/insightsController.js";
import MonitorsController from "services/controllers/monitorsController.js";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions.js";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions.js";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions.js";
import NoDataFoundProducts from "components/no-Data/index.js";
import NoDataLinks from "components/no-Data/noDataLinks.js";

const RenderProperViewMoreButton = ({ dataSourceId, ...props }) => {
  const intl = useIntl();

  if (props?.showViewMoreButton === false) return null;
  return (
    <>
      {props.isGeneralInteractionPage ? (
        <Link to={"/interactions"} className="view-more">
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}

      {props.isChat ? (
        <Link to={"/interactions/chat"} className="view-more">
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}

      {props.isAudiencePage &&
      props.data.length !== props.latestInteractionsCount ? (
        <Button
          className="audience-load-more-btn"
          onClick={props.handleChangePage}
          id="interactions-list-load-more-btn"
        >
          {CheckValueLocale("load_more", "", {}, intl)}
        </Button>
      ) : null}

      {props.isGoogleMyBusinessPage ? (
        <Link
          to={"/interactions/ratings/google-my-business/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}

      {props?.isSocialMedia ? (
        <Link to={"/interactions/social-media/"} className="view-more">
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}

      {props.isPrivateFacebook ? (
        <Link
          to={"/interactions/social-media/facebook-private/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isPublicFacebook ? (
        <Link
          to={"/interactions/social-media/facebook-public/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isPrivateInstagram ? (
        <Link
          to={"/interactions/social-media/instagram-private/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isPublicInstagram ? (
        <Link
          to={"/interactions/social-media/instagram-public/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isPublicTwitter ? (
        <Link
          to={"/interactions/social-media/x-platform-public/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isPrivateTwitter ? (
        <Link
          to={"/interactions/social-media/x-platform-private/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isSwitchCase ? (
        <Link
          to={"/interactions/chat/whatsapp/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isIntercom ? (
        <Link
          to={"/interactions/chat/intercom/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isGmail ? (
        <Link
          to={"/interactions/email/gmail/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : props.isLinkedIn ? (
        <Link
          to={"/interactions/social-media/linkedin-channel/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}
      {props.isGenesysPage ? (
        <Link
          to={"/interactions/calls/genesys/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}
      {props.isTiktok ? (
        <Link
          to={"/interactions/social-media/tikTok-channel/" + dataSourceId}
          className="view-more"
        >
          {CheckValueLocale("view_more", "", {}, intl)}
        </Link>
      ) : null}
    </>
  );
};

const InteractionsList = (props) => {
  const urlParams = useParams();
  const intl = useIntl();
  const dataSourceId = urlParams.data_source_id;
  const location = useLocation();
  let channelPath = location?.pathname;
  const haveEngagAccess =
    handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
    retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
    (channelPath === "/insights/main" ||
      channelPath === "/interactions" ||
      channelPath === "/interactions/social-media/" ||
      channelPath === "/insights/social-media/" ||
      (channelPath?.includes("/insights/social-media/") &&
        (channelPath?.includes("x-platform-private-channel") ||
          channelPath?.includes("x-platform-channel") ||
          channelPath?.includes("facebook-channel") ||
          channelPath?.includes("facebook-private-channel") ||
          channelPath?.includes("instagram-private-channel") ||
          channelPath?.includes("instagram-channel"))) ||
      (channelPath?.includes("/interactions/social-media/") &&
        (channelPath?.includes("x-platform-public") ||
          channelPath?.includes("x-platform-private") ||
          channelPath?.includes("facebook-public") ||
          channelPath?.includes("facebook-private") ||
          channelPath?.includes("instagram-private") ||
          channelPath?.includes("instagram-public"))));

  const [latestInteractions, setLatestInteractions] = useState([]);
  const [allawedTrackers, setAllawedTrackers] = useState([]);

  const [interactionsPreloaderDatasource, setInteractionsPreloaderDatasource] =
    useState([]);
  const [isThemesPolicy, setIsThemesPolicy] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    open: true,
    item: {},
    done: false,
  });

  const getThemesPolicy = () => {
    InsightsController.getApplyPolicy().then((result) => {
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == "themes") {
            return setIsThemesPolicy(false);
          }
        });
      } else {
        setIsThemesPolicy(true);
      }
    });
  };
  useEffect(() => {
    getThemesPolicy();
    if (haveEngagAccess) {
      MonitorsController.getAllawedMonitors(window?.localStorage?.cxm_id).then(
        (AllawedData) => {
          if (AllawedData?.data?.data?.monitors_list?.length) {
            setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
          }
        },
      );
    }
  }, []);
  // var sortedActivities;
  // useEffect(() => {
  //   sortedActivities = props.data?.sort((a, b) => b.created_at - a.created_at)
  //   setLatestInteractions(sortedActivities)
  // }, [props.data]);

  // var sortedActivities;
  // useEffect(() => {
  //   setLatestInteractions([])
  // }, [props.data]);

  // useEffect(() => {
  //   if(latestInteractions.length===0){
  //     sortedActivities = props.data?.sort((a, b) => b.created_at - a.created_at)
  //     setLatestInteractions(sortedActivities)
  //   }
  // }, [latestInteractions]);

  //This filtration step is to remove the duplicates data source names
  useEffect(() => {
    const uniquePreLoaderDataSource =
      props.latestInteractionsPreLoaderDataSources.filter(
        (item, index) =>
          props.latestInteractionsPreLoaderDataSources.indexOf(item) === index,
      );
    setInteractionsPreloaderDatasource(uniquePreLoaderDataSource);
  }, [props.latestInteractionsPreLoaderDataSources]);

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });

  return (
    <Box
      className={props.isInteractionPage ? "" : "interaction-chart"}
      component="div"
    >
      {_.isEqual(
        interactionsPreloaderDatasource?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true || props.loader == true ? (
        <Box className="fetch-data-interactions-list">
          <FetchingData />
        </Box>
      ) : !_.isEmpty(props.data) ? (
        <>
          <WidgetHeader
            showGroupBy={props.showGroupBy}
            title={props.title}
            totalLabel={props.totalLabel}
            totalValue={props.totalValue}
            showAI={props.showAI}
            isInteractionPage={props.isInteractionPage}
            iconAfterTitle={props.iconAfterTitle}
          />

          {props.data?.map((obj, index) => (
            <InteractionListItem
              unixDate={props.unixDate}
              showProfile={props.showProfile}
              obj={obj}
              key={obj.interaction_id + "_" + index}
              dataSources={props?.dataSources}
              isInteractionPage={props?.isInteractionPage}
              component={props.component}
              isAudiencePage={props.isAudiencePage}
              allawedTrackers={allawedTrackers}
              categoriesOptions={categoriesOptions}
              themesOptions={themesOptions}
              sentimentsOptions={sentimentsOptions}
            />
          ))}
          {/* View More */}
          <RenderProperViewMoreButton {...props} dataSourceId={dataSourceId} />
          {/* Pagination */}
          {/* --- This will be working with Single data source & can’t be used as pagination with Interactions for all data sources --- */}
          {props.pagenation && props.latestInteractionsCount > 10 ? (
            <Pagination
              onChange={props.handleChangePage}
              page={props.page}
              className="pagination-interaction-list"
              count={Math.ceil(props.latestInteractionsCount / 10)}
              variant="outlined"
            />
          ) : null}
        </>
      ) : (
        <Box className="empty-box">
          <NoDataFoundProducts
            image="Interctions"
            title="view_channels_interactions"
            description="click_new_channel"
            linkOne="how_to_use"
            linkOneHref={NoDataLinks.interaction_list_link}
            orCase="or_visit_the"
            linkTwo="knowledge_base"
          />
        </Box>
      )}
      {isThemesPolicy ? (
        <PostQuestionPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setIsThemesPolicy={setIsThemesPolicy}
        />
      ) : null}
    </Box>
  );
};

export default InteractionsList;
