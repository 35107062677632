import _ from "lodash";
import { Box, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";
import FetchingData from "../../components/fetchingData/fetchingData";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Delay from "../delay";
import NoDataFound from "components/no-Data/noDataFound";
import DOMPurify from "dompurify";

// style
import "./statistics.scss";
import { CheckValueLocale } from "utils/helpers";

const StatisticsList = (props) => {
  let data = props.data;
  const { isInboxAnalytics } = props;
  //check if all values empty or not
  let isInValidItem = (val) => {
    return val === "none" || val === null;
  };
  const intl = useIntl();

  if (data === undefined) {
    return null;
  } else {
    var sortedAsceding = data.sort((a, b) => {
      return a.index - b.index;
    });
  }
  return (
    <Box className="component">
      <Delay>
        <div className="d-flex">
          {_.isEqual(
            props.overViewPreLoaderDataSources?.sort(),
            props.preLoaderTrackerDataSources?.sort(),
          ) !== true ? (
            <Box sx={{ width: "100%", marginTop: "5%" }}>
              <FetchingData />
            </Box>
          ) : sortedAsceding.length >= 2 ? (
            sortedAsceding.map((item, i) => {
              if (item !== undefined) {
                return (
                  <div className="statistic" key={i}>
                    {item.toolTipText && (
                      <div className="statistic__tooltip">?</div>
                    )}
                    <div
                      className={
                        "statistic__icon statistic__icon--" + item.color
                      }
                    >
                      <FontAwesomeIcon icon={item.icon} />
                    </div>
                    {isInValidItem(item?.realValue) ? (
                      <span className="statistics-empty-value">
                        {CheckValueLocale(
                          isInboxAnalytics
                            ? "engagement_dm_not_found"
                            : "no_data_text",
                          "",
                          {},
                          intl,
                        )}
                      </span>
                    ) : (
                      <div
                        className={`statistic__value ${
                          props?.isGenesysPage && "genesys-statistic-value"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.netSentiment)
                            ? DOMPurify.sanitize(
                                CheckValueLocale(item?.value, "", {}, intl),
                              )
                            : DOMPurify.sanitize(
                                item?.value === 0
                                  ? item?.value?.toString()
                                  : item?.value,
                              ),
                        }}
                      ></div>
                    )}
                    {item.socialMediaTooltip || item.tooltip ? (
                      <Tooltip
                        title={
                          <div className="tooltip-custom-new">
                            {CheckValueLocale(
                              item.socialMediaTooltip || item.tooltip,
                              "",
                              {},
                              intl,
                            )}
                          </div>
                        }
                        arrow={true}
                        sx={{ cursor: "default" }}
                      >
                        <p>{CheckValueLocale(item.title, "", {}, intl)}</p>
                      </Tooltip>
                    ) : (
                      CheckValueLocale(item.title, "", {}, intl)
                    )}
                  </div>
                );
              }
            })
          ) : null}
        </div>
      </Delay>
    </Box>
  );
};

export default StatisticsList;
