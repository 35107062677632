import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
// Language Context use to check
import LanguageContext from "./context/Context";
// Translation - locales
import translations from "./utils/locale";
// Import routing components
import { BrowserRouter } from "react-router-dom";
// style
import "./App.scss";
import Routes from "./utils/routes";
import Footer from "./shared/footer/Footer.js";

// material ui theme
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import UserAccount from "services/controllers/userAccountController";
import CompaniesController from "services/controllers/companiesController.js";
// Services file
import Services from "services/api/Services.js";
import { jwtDecode as jwt_decode } from "jwt-decode";
import _ from "lodash";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import store from "utils/redux/store/store.js";
import { deleteCookie } from "utils/helpers";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
LicenseInfo.setLicenseKey(
  "1344699100d0558c5790d1111462f903Tz04Njg0NyxFPTE3NDI1NTM1MTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);
import * as Sentry from "@sentry/react";
const tagManagerArgs = {
  gtmId: "GTM-NP9CT8R",
};

const lucUser =
  window.localStorage.getItem("is_lucidya_account") === "1" ||
  localStorage.email?.includes("@lucidya");
const thirdPartyFlag =
  !lucUser &&
  window.location.hostname === "cxm.lucidya.com" &&
  window.location.pathname !== "/login";
if (thirdPartyFlag) {
  TagManager.initialize(tagManagerArgs);
}
const cookieyesFlag =
  !lucUser && window.location.hostname === "cxm.lucidya.com";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [cxmRuturned, setCxmRuturned] = useState(false);
  const [stats, setStats] = useState({
    lang:
      window.localStorage.lang === undefined
        ? window.navigator.language.split("-")[0]
        : window.localStorage.lang,
  });
  const [locations, setLocations] = useState("");
  const switchLang = (value) => {
    setStats({ ...stats, lang: value });
    window.localStorage.setItem("lang", value);
  };
  const [isLiveDashboard, setIsLiveDashboard] = useState(false);
  if (stats.lang === "ar") {
    document.body.classList.add("rtl-dir", "ar-layout");
    document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
  } else {
    document.body.classList.remove("rtl-dir", "ar-layout");
    document.getElementsByTagName("html")[0].setAttribute("lang", "en");
  }

  const [audienceFilters, setAudienceFilters] = useState({});
  const contextData = {
    stats: stats,
    setStats: setStats,
    audienceFilters: audienceFilters,
    setAudienceFilters: setAudienceFilters,
  };
  const cloneScript = (script) => {
    const s = document.createElement("script");

    // copy attributes
    const attrs = script.attributes;
    for (let i = 0; i < attrs.length; i++) {
      s.setAttribute(attrs[i].name, attrs[i].value);
    }

    // copy inner text of script
    s.text = script.text;

    return s;
  };

  const addCustomScripts = (custom_tag, place) => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(custom_tag, "text/html");
      const collection = doc.head.children;
      if (place === "head") {
        Array.from(collection)
          .map((script) => cloneScript(script))
          .forEach((script) => document.head.appendChild(script));
      }
      if (place === "body") {
        Array.from(collection)
          .map((script) => cloneScript(script))
          .forEach((script) => document.body.appendChild(script));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const heapIntegrate = `
  <script type="text/javascript">   
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,
    window.heap.config=t=t||{};var r=document.createElement("script");
    r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
    var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);
    for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},
    p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
    // heap.load("3736821916"); //dev id
    heap.load("2715462033"); //prod id
    heap.identify(localStorage.name?localStorage.name:"-");
    heap.addUserProperties({ Email: localStorage.email ? localStorage.email : "-",
    companyName: localStorage.companyName ? localStorage.companyName : "-"});
    heap.addEventProperties({ 'activeProducts':localStorage.activeProducts?JSON.stringify(localStorage.activeProducts):"-" ,
    'id': localStorage.user_id ? localStorage.user_id : "-",
    'email':localStorage.email ? localStorage.email : "-",
    'created_at': localStorage.created_at ? localStorage.created_at : "-",
    'companyName': localStorage.companyName ? localStorage.companyName : "-"
  });
  </script>`;
  const hupSpot = `
  <!-- Set up the path for the initial page view -->
  <script>
   var _hsq = window._hsq = window._hsq || [];
   _hsq.push(['setPath', '/home']);
 </script>


 <!-- Tracking subsequent page views -->
 <script>
   var _hsq = window._hsq = window._hsq || [];
   _hsq.push(['setPath', '/about-us']);
   _hsq.push(['trackPageView']);
 </script>
`;
  const churnZero = `
  <script id="churnzero-script"></script>
  <script id="churnzero-script-logout"></script>
`;
  const cookieyes = `
  <script
    id="cookieyes"
    type="text/javascript"
    src="https://cdn-cookieyes.com/client_data/7091b313a608112745fdbe57/script.js"
    >
  </script>
`;

  useEffect(() => {
    let link = document.querySelector("link[rel='icon']");
    let title = document.querySelector("title");
    let meta = document.querySelector("meta[property='og:image']");

    meta.content = Services.companyFaviconPathPNG;
    Services.project_name_en == "Falcon"
      ? (link.href = Services.companyFaviconPathSVG)
      : (link.href = Services.companyFaviconPathPNG);
    title.text = Services.project_name_en;
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (Services.project_name_en === "Falcon") {
      htmlElement.setAttribute("env", "falcon");
    } else {
      htmlElement.setAttribute("env", "lucidya");
    }
  }, []);

  useEffect(() => {
    let url = window.location.search;
    if (url.includes("?redirect_url") && localStorage.getItem("user_token")) {
      UserAccount.createUserHutspot().then((result) => {
        if (result?.data) {
          window.open(result.data.url, "_self");
        }
      });
    }
    if (thirdPartyFlag) {
      addCustomScripts(heapIntegrate, "head");
      addCustomScripts(hupSpot, "body");
      addCustomScripts(churnZero, "body");
      addSentry();
    }
    if (cookieyesFlag) {
      addCustomScripts(cookieyes, "body");
    }
  }, []);
  useEffect(() => {
    getCompanyInfo();
  }, []);

  const addSentry = () => {
    Sentry.init({
      dsn: "https://190f61c84d76c392d5c189d19ff771d0@o4507679471239168.ingest.de.sentry.io/4507690758438992",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Profiling
      profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    Sentry.setUser({
      user_id: localStorage.user_id?localStorage.user_id:"-",
      company_id: localStorage.id?localStorage.id:"-",
    });
  };

  const getCompanyInfo = () => {
    if (thirdPartyFlag) {
      window.dataLayer.push({
        event: "pageview",
        page: {
          url: window.location.href,
          title: window.location.href,
        },
        id: localStorage.user_id,
        email: localStorage.email,
        name: localStorage.name,
        app_language: localStorage.lang,
        created_at: localStorage.created_at,
        company_name: localStorage.companyName,
        // company_name: result?.data?.data?.attributes?.name,
        // company_id: result?.data?.data?.attributes?.id,
      });
      // }
      // if(window.localStorage.user_roles!==undefined){
      //   CompaniesController.getCompanyInfo().then((result) => {
      //     if (!result.errorMsg) {
      //     }
      //   });
    }
  };
  React.useEffect(() => {
    if (cxmRuturned == true) {
      let url = _.cloneDeep(window.location.search);
      let href = _.cloneDeep(window.location.href);
      let userToken;

      userToken = url.slice(url.indexOf("&cxm_token=") + 11);
      if (window.location.search.includes("?new_monitor=true")) {
        userToken = url.slice(url.indexOf("&cxm_token=") + 11);
        window.localStorage.setItem("new_monitor", true);
        window.location.href = href.slice(0, href.indexOf("?new_monitor"));
      } else if (window.location.search.includes("?edit_monitor=true")) {
        userToken = url.slice(url.indexOf("&cxm_token=") + 11);
        window.localStorage.setItem("edit_monitor", true);
        window.location.href = href.slice(0, href.indexOf("?edit_monitor"));
      } else {
        window.location.href = href.slice(
          0,
          window.location.href.indexOf("&cxm_token"),
        );
      }
    }
  }, [cxmRuturned]);

  const getCxmData = () => {
    if (window.location.search.includes("token_invalid=true")) {
      store.dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
    }
    if (window.location.search.includes("cxm_token=")) {
      let userToken;
      let url = _.cloneDeep(window.location.search);
      let href = _.cloneDeep(window.location.href);
      userToken = url.slice(url.indexOf("&cxm_token=") + 11);
      localStorage.setItem("user_token", userToken);
      localStorage.setItem("redirectDone", "true");
      let decodeAccessToken = jwt_decode(userToken);
      window.localStorage.setItem(
        "user_roles",
        JSON.stringify(decodeAccessToken.permissions),
      );
      window.localStorage.setItem(
        "active_features",
        JSON.stringify(decodeAccessToken.active_features),
      );
      getNewUserData();
    }
  };
  const getNewUserData = async () => {
    await UserAccount.getNewUserData().then((result) => {
      if (result.data?.data?.id) return getCompanyProducts();
      setCxmRuturned(true);
    });
  };

  const getCompanyProducts = () => {
    CompaniesController.getCompanyProducts().then((data) => {
      const activeProducts = data.data.filter((product) => product.active);

      //SET ALL PRODUCTS ID IN LOCAL STORAGE TEMPORARLY TO TEST BACKEND IN INSIGHTS
      activeProducts.map((product) => {
        if (product.name === "CXM") {
          return window.localStorage.setItem(
            "cxm_id",
            JSON.stringify(product.id),
          );
        } else if (product.name === "SM") {
          window.localStorage.setItem("sm_id", JSON.stringify(product.id));
        }
      });
      //END OF TEMP. TESTING CODE

      if (activeProducts.length > 0) {
        window.localStorage.setItem(
          "activeProducts",
          JSON.stringify(activeProducts),
        );
      }
      if (localStorage.getItem("redirectDone") === "true") {
        localStorage.setItem("redirectDone", "false");
        const redierctLink = window.location.origin + window.location.pathname;
        window.location.href = redierctLink;
      }
    });
  };

  React.useEffect(() => {
    (async () => {
      await getCxmData();
    })();
  }, []);

  const isInvitationLogin =
    window?.location?.pathname?.includes("/invitation_login");
  const isManageSubscription = window?.location?.pathname?.includes(
    "/manage-subscription",
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LanguageContext.Provider
            value={{ lang: stats?.lang, switchLang: switchLang }}
          >
            <IntlProvider
              locale={stats?.lang}
              messages={translations[stats?.lang]}
            >
              <BrowserRouter>
                <Routes
                  switchLang={switchLang}
                  lang={stats?.lang}
                  setLocations={setLocations}
                  setIsLiveDashboard={setIsLiveDashboard}
                />
                {!locations?.includes("/survey/survey-builder/") &&
                  localStorage.getItem("user_token") &&
                  !isLiveDashboard &&
                  !isManageSubscription &&
                  !isInvitationLogin && <Footer />}
              </BrowserRouter>
            </IntlProvider>
          </LanguageContext.Provider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
