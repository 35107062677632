import { Box, Button, Menu, Tooltip, Popover } from "@mui/material";
import {
  CheckValueLocale,
  isArabic,
  handlelUserRoles,
  extractDisplayInfoFromDataSource,
  getEngagementId,
} from "utils/helpers";
import "./engagementHeader.scss";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCopy,
  faCheckSquare,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import ProfileUser from "../profileUser/profileUser";
import EngagementComplete from "./engagementComplete";
import "./engagementHeader.scss";
import { faCircleInfo, faFolderOpen } from "@fortawesome/pro-regular-svg-icons";
import EngagementTranslation from "../engagementTranslation/engagementTranslation";
import publicFacebook from "images/engagements-images/fb-icon-engag.svg";
import publicInstagram from "images/engagements-images/ig-icon-engag.svg";
import LucButton from "shared/lucButton/lucButton";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import profileImg from "images/engagements-images/profileImg.png";
import { ReactComponent as WhatsappIcon } from "images/social-icons/whatsapp-icon.svg";
import { useSelector } from "react-redux";
import {
  engagementStatusMapper,
  engagementTabsActions,
  useEngagementTabs,
} from "pages/engagements/providers/engagementsTabsProvider";

const EngagementHeader = ({
  selectedCard,
  setCardState,
  cardState,
  itemData,
  setItemData,
  headerData,
  setStatusChanged,
  statusChanged,
  setRefresh,
  stats,
  setStats,
  setTabValue,
  setComplete,
  setOpenDetailsSideBar,
  profileSideBarData,
  fetchProfileData,
  showTotalEngagementsHandler,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  handleToggleSlaSideBar,
  setOpenSidebar,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  languagesArraySource,
  languagesArrayTarget,
  translationActive,
  isLogsFetched,
  selectedCardIteractionID,
  openSidebarToAssignTeam,
  setOpenSidebarToAssignTeam,
  aiAgentId,
  slaKeys,
}) => {
  const intl = useIntl();
  const { handleEngagementTabs } = useEngagementTabs();
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(
    selectedCard?.system_replies?.engagement_id
      ? selectedCard?.system_replies?.engagement_id
      : "",
  );
  let statusItemCard = itemData
    ? itemData?.system_replies?.status
    : itemData?.status;
  // const [complete, setComplete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [completionReason, setCompletionReason] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [completionContent, setCompletionContent] = useState("");
  const [anchorElTranslation, setAnchorElTranslation] = useState(null);
  const [disabledAction, setDisabledAction] = useState(false);
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userSignedIn = localStorage.getItem("user_id");
  const assignedUser = reduxSlaData?.[slaKeys]?.userId;
  const assignedTeam = reduxSlaData?.[slaKeys]?.teamId;
  const authorizedToComplete = +userSignedIn == +assignedUser;
  const isAiAndComplete = statusItemCard === "complete" && aiAgentId;
  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );
  const getSocialIcon = (type) => {
    const SocialIcons = {
      facebook: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="facebook" icon={faFacebook} />
        </Box>
      ),
      twitter: (
        <Box className={type + "-icon user-source"}>
          <img src={xPlatform} alt="x-platform-logo" className="twitter" />
        </Box>
      ),
      instagram: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="instagram" icon={faInstagram} />
        </Box>
      ),
      whatsapp: (
        <Box className={type + "-icon user-source"}>
          <WhatsappIcon className="whatsapp" />
        </Box>
      ),
    };
    return SocialIcons[type];
  };

  const updateStatus = (status, closeTap) => {
    if (
      selectedCard?.isDm &&
      statusItemCard === "complete" &&
      (!selectedCard?.is_last_conversation ||
        (selectedCard?.data_source !== "twitter" &&
          selectedCard?.exceed_fb_ig_window))
    ) {
      setStats({
        ...stats,
        open: true,
        title: CheckValueLocale("reopen_failed", "", {}, intl),
        body: CheckValueLocale(
          !selectedCard?.is_last_conversation ? "other_active" : "time_exceed",
          "",
          {},
          intl,
        ),
        severity: "error",
      });
      setTimeout(() => {
        setStats({
          ...stats,
          open: false,
        });
      }, 7000);
    } else {
      setDisabledAction(true);
      let queryData = {};
      const slaInfo = {
        sla_id: selectedCard?.sla_info?.sla_id,
        routing_id: selectedCard?.sla_info?.routing_id,
        assigned_user_id: assignedUser,
        assigned_team_id: assignedTeam,
      };
      if (statusItemCard === "complete") {
        queryData = {
          parent_id: selectedCard?.tweet_id || selectedCard?.id,
          product_id: window.localStorage.getItem("engagementsProductId"),
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source?.toUpperCase(),
          interaction_id: selectedCardIteractionID,
          status: "reopen",
          conversation_staus: itemData?.isDm ? itemData?.status : undefined,
          conversation_created_at: itemData?.created_at,
          client_id: itemData?.isDm ? itemData?.client_id : undefined,
          agent_id: itemData?.isDm ? itemData?.agent_id : undefined,
          ...slaInfo,
        };
      } else {
        queryData = {
          parent_id: selectedCard?.tweet_id || selectedCard?.id,
          product_id: window.localStorage.getItem("engagementsProductId"),
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source?.toUpperCase(),
          interaction_id: selectedCardIteractionID,
          status: status === "complete" ? "reopen" : "complete",
          completion_reason: completionReason,
          other_reason: valueInput,
          conversation_staus: itemData?.isDm ? itemData?.status : undefined,
          conversation_created_at: itemData?.created_at,
          client_id: itemData?.isDm ? itemData?.client_id : undefined,
          agent_id: itemData?.isDm ? itemData?.agent_id : undefined,
          ...slaInfo,
        };
      }

      const isReopen = queryData?.status === "reopen";
      const activeTab = cardState?.activeTab;
      EngagementsController.updateStatus(queryData).then((data) => {
        // setStatusChanged(!statusChanged);
        // setComplete(0);
        if (data?.data?.status === 204 || data?.data?.status === 200) {
          // change the status of the engagement
          setItemData((prevState) => ({
            ...prevState,
            status: isReopen ? "open" : "closed",
            system_replies: {
              ...(prevState?.system_replies || {}),
              status: isReopen ? "reopen" : "complete",
            },
            staticUpdated: true,
          }));
          // don't close the card if the active tab is all, and update the counter
          if (activeTab === "all") {
            setCardState({
              ...cardState,
              changeStateTo: queryData?.status,
              changeStateDone: true,
              notClose: true,
              updateInitialState: true,
            });
          }

          // when we are in the new or active tab, we need to remove the engagement from the list if it's exist.
          if (activeTab !== "complete" && activeTab !== "all") {
            setCardState({
              ...cardState,
              changeStateTo: queryData?.status,
              changeStateDone: true,
              notClose: false,
            });
          }
          handleEngagementTabs(engagementTabsActions.update_current_tab, {
            engagementStatus: engagementStatusMapper[queryData?.status],
          });
          if (statusItemCard === "complete") {
            // if (aiAgentId) {
            //   setTabValue(1);
            // } else {
            //   setTabValue(2);
            // }
            // setComplete("active");
            //setRefresh?.();
          } else {
            // setTabValue(3);
            // setComplete("complete");
            //setRefresh?.();
            setStats({
              ...stats,
              open: true,
              title: "",
              body: CheckValueLocale("engagement_marked", "", {}, intl),
              severity: "success",
            });
            setTimeout(() => {
              setStats({
                ...stats,
                open: false,
              });
            }, 7000);
          }
          const engagementId = getEngagementId(selectedCard);
          if (closeTap) {
            handleEngagementTabs?.(engagementTabsActions.remove_tab, {
              id: engagementId,
            });
          }
        } else {
          if (
            data?.errorMsg?.response?.data?.error?.detail ==
            "INTERACTION_ALREADY_COMPLETED"
          ) {
            setStats({
              ...stats,
              open: true,
              title: "",
              body: CheckValueLocale("interaction_is_completed", "", {}, intl),
              severity: "error",
            });
          } else if (
            data?.errorMsg?.response?.data?.error?.detail == "ACTION_RESTRICTED"
          ) {
            setStats({
              ...stats,
              open: true,
              title: "",
              body: CheckValueLocale(
                "action_restricted_reply_engagement_msg",
                "",
                {},
                intl,
              ),
              severity: "error",
            });
          } else {
            setStats({
              ...stats,
              open: true,
              title: CheckValueLocale(
                "request_access_error_heading",
                "",
                {},
                intl,
              ),
              body: CheckValueLocale("request_access_error_desc", "", {}, intl),
              severity: "error",
            });
          }
          setTimeout(() => {
            setStats({
              ...stats,
              open: false,
            });
          }, 7000);
        }
        setDisabledAction(false);
        handelClosePopup();
      });
    }
  };
  const handelClosePopup = () => {
    setValueInput("");
    setOpenPopup(false);
  };
  const handleUpdate = () => {
    statusItemCard === "complete" ? updateStatus() : setOpenPopup(true);
  };
  const CompletionReasonsApi = () => {
    EngagementsController.getCompletionReasons(
      window.localStorage.getItem("engagementsProductId"),
    ).then((result) => {
      setCompletionContent(result?.data?.completion_reason_tooltips);
    });
  };
  useEffect(() => {
    CompletionReasonsApi();
  }, []);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(userId);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  let menuDirection = intl.locale === "en" ? "left" : "right";

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenProfile = () => {
    setOpenSlaSideBar(false);
    setOpenDetailsSideBarReplay(false);
    setOpenSidebar(false);
    setOpenDetailsSideBar({
      open: true,
      type: "profile",
    });
  };

  // translation Dropdown HANDLER
  const handleClickTranslation = (event) => {
    setAnchorElTranslation(event.currentTarget);
  };
  const handleCloseTranslation = () => {
    setAnchorElTranslation(null);
  };
  const openTranslation = Boolean(anchorElTranslation);
  const isTwitter = selectedCard?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram =
    selectedCard?.data_source?.toLowerCase?.() === "instagram";
  const isDM = selectedCard?.isDm;
  const isRTL = intl?.locale === "ar";
  let { displayName, profilePicture, subName } =
    extractDisplayInfoFromDataSource(selectedCard, {
      displayName: selectedCard?.user_name
        ? isDM && (isTwitter || isInstagram)
          ? selectedCard?.name
          : selectedCard?.user_name
        : "user",
      subName:
        isTwitter || isInstagram
          ? isDM
            ? selectedCard?.user_name
            : selectedCard?.screen_name
          : null,
      profilePicture: selectedCard?.user_profile_image_url,
    });

  useEffect(() => {
    if (isLogsFetched && openSidebarToAssignTeam) {
      setOpenSidebarToAssignTeam(false);
      handleToggleSlaSideBar();
    }
  }, [isLogsFetched]);

  const handleImageError = (event) => {
    event.target.src = profileImg;
  };

  return (
    <>
      <Box className="main-engagment-header-parant">
        <Box className="main-engagment-header">
          <Box className="header-left-side">
            <Box className="engagment-header-user-box">
              <Box
                id="engagement-user-profile"
                className="engagment-header-user"
                onClick={handleOpenProfile}
              >
                <Box className="engagment-header-img">
                  <img
                    className="user-avatar"
                    src={profilePicture || profileImg}
                    onError={handleImageError}
                  />
                  {getSocialIcon(selectedCard?.data_source)}
                </Box>
                <Box className="engagment-header-user-info">
                  <span className="user-name">{displayName}</span>
                  {(isTwitter || (isInstagram && isDM)) && subName ? (
                    <span className="user-sub-name">
                      {isRTL ? `${subName}@` : `@${subName}`}
                    </span>
                  ) : null}
                </Box>
              </Box>
              <div className="engagment-header-profile">
                <ProfileUser
                  fetchProfileData={fetchProfileData}
                  profileSideBarData={profileSideBarData}
                  showTotalEngagementsHandler={showTotalEngagementsHandler}
                  selectedCard={selectedCard}
                />
              </div>
            </Box>
          </Box>
          <Box className="header-right-side">
            {/* {may be used later} */}
            {/* <spna className="monitor-name">{headerData?.monitor_name}</spna>
            <span className="vertical-divider"></span> */}
            {!aiAgentId ? (
              <>
                <Box className="engagement-header-translation">
                  <Tooltip
                    title={CheckValueLocale(
                      "translation_settings",
                      "",
                      {},
                      intl,
                    )}
                    placement="bottom"
                    arrow
                    disableHoverListener={!authorizedToComplete}
                  >
                    <Box
                      id="engagement-header-translation-btn"
                      className={`translation-box ${!authorizedToComplete ? "translation-box-disabled" : ""}`}
                      onClick={
                        authorizedToComplete ? handleClickTranslation : null
                      }
                    >
                      <FontAwesomeIcon
                        icon={faLanguage}
                        className={
                          translationActive ? "active-transelation" : ""
                        }
                      />
                    </Box>
                  </Tooltip>
                  <Popover
                    anchorOrigin={anchorPosition}
                    transformOrigin={transformPosition}
                    onClose={handleCloseTranslation}
                    open={authorizedToComplete ? openTranslation : false}
                    anchorEl={anchorElTranslation}
                  >
                    <EngagementTranslation
                      translationType="header"
                      setTranslationActive={setTranslationActive}
                      translationSource={translationSource}
                      setTranslationSource={setTranslationSource}
                      translationTarget={translationTarget}
                      setTranslationTarget={setTranslationTarget}
                      handleCloseTranslation={handleCloseTranslation}
                      stats={stats}
                      setStats={setStats}
                      languagesArraySource={languagesArraySource}
                      languagesArrayTarget={languagesArrayTarget}
                      showActiveTranselationIcon={translationActive}
                    />
                  </Popover>
                </Box>
                <span className="vertical-divider"></span>
              </>
            ) : null}
            {!aiAgentId || isAiAndComplete ? (
              <Tooltip
                id="translation-settings"
                title={
                  !authorizedToComplete && !isAiAndComplete
                    ? CheckValueLocale("un_auth_to_complete", "", {}, intl)
                    : null
                }
                placement="bottom"
                arrow
              >
                <Box>
                  <LucButton
                    onClick={handleUpdate}
                    variant="outline"
                    size="small"
                    id={
                      statusItemCard === "complete"
                        ? "engagement-reopen-btn"
                        : "engagement-mark-complete-btn"
                    }
                    className={
                      statusItemCard === "complete"
                        ? "reopen-btn"
                        : "complete-btn"
                    }
                    startIcon={
                      <FontAwesomeIcon
                        icon={
                          statusItemCard === "complete"
                            ? faFolderOpen
                            : faCircleCheck
                        }
                      />
                    }
                    disabled={
                      disabledAction ||
                      (!authorizedToComplete && !isAiAndComplete)
                    }
                  >
                    {statusItemCard === "complete"
                      ? CheckValueLocale("re_open", "", {}, intl)
                      : CheckValueLocale("mark_complete", "", {}, intl)}
                  </LucButton>
                </Box>
              </Tooltip>
            ) : null}
            <span className="vertical-divider"></span>
            <Tooltip
              title={CheckValueLocale("engag_det", "", {}, intl)}
              placement="bottom"
              arrow
            >
              <Box
                className={`sla-det-tooltip ${!isLogsFetched && "sla-details-engagement-fetched"}`}
                onClick={() => isLogsFetched && handleToggleSlaSideBar()}
              >
                <FontAwesomeIcon icon={faCircleInfo} />
                <span className="engagement-header-details-text">
                  {CheckValueLocale(
                    "engagement_header_details_text",
                    "",
                    {},
                    intl,
                  )}
                </span>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {openPopup ? (
        <EngagementComplete
          updateStatus={updateStatus}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          completionReason={completionReason}
          setCompletionReason={setCompletionReason}
          valueInput={valueInput}
          setValueInput={setValueInput}
          completionContent={completionContent}
          setCompletionContent={setCompletionContent}
          handelClosePopup={handelClosePopup}
          disabledAction={disabledAction}
        />
      ) : null}
    </>
  );
};

export default EngagementHeader;
