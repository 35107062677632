//this widget already used for MONITOR only , as it has one case for punchCard
import PunchCard from "../echarts/punchCard-monitor";
import WidgetHeader from "components/widgets/widgetHeader";
import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import TwitterIcon from "@mui/icons-material/Twitter";
import NoDataFound from "components/no-Data/noDataFound";

const PunchCardWidget = (props) => {
  return (
    <div
      className="chart"
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
    >
      <WidgetHeader
        title={props.title}
        titleToolTip={props.titleToolTip}
        showDownloadIcon={props.showDownloadIcon}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        bigImg={props.bigImg}
        channelActivityUpdated={props.clientActivity}
        iconAfterTitle={props.iconAfterTitle}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
      />
      {_.isEqual(
        props?.authorActivityPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : props?.clientActivity?.every((row) => row[2] == 0) ? (
        <>
          <NoDataFound />
        </>
      ) : (
        <PunchCard
          clientActivity={props.clientActivity}
          minValueClient={props?.minValueClient}
          maxValueClient={props?.maxValueClient}
          isControlWidget={props?.isControlWidget}
          danger={props?.danger}
        />
      )}
    </div>
  );
};

export default PunchCardWidget;
