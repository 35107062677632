import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LineChartWidget from "components/widgets/lineChartWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import ThemesTrends from "components/widgets/themes/themesTrends/ThemesTrends.js";
import ThemeAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis.js";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const EngagementsSection = (props) => {
  let { TwitterIcon, twitterWidgets, preLoaderTrackerDataSources } = props;
  const intl = useIntl();

  const chartStatsData = [
    {
      name: "total_comments",
      value: twitterWidgets?.totalInteractions,
    },
    {
      name: "insights_avg_comments_per_day",
      value: twitterWidgets?.avgInteractions,
    },
  ];
  const isTwitterAccount = true;

  return (
    <>
      <Typography className="section-name">
        {CheckValueLocale("engagements_page", "", {}, intl).toUpperCase()}
      </Typography>

      <Box mt={3}>
        <LineChartWidget
          title={"comments_volume"}
          titleToolTip={"comments_volume_tw_tooltip"}
          totalLabel={"posts"}
          totalValue={twitterWidgets?.totalCommentsVolumeCount}
          data={twitterWidgets?.commentsVolume}
          showGroupBy={true}
          toolTipLabel={"posts"}
          showDownloadIcon
          bigImg
          lineChartPreLoaderDataSources={
            twitterWidgets?.commentsVolumePreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={TwitterIcon}
          haveData
          labelColor={"#2AAAE2"}
          showChartDetailsWidget
          statsDetailsData={chartStatsData}
          redirectTo={"/interactions/social-media/x-platform-public/"}
        />
      </Box>

      {/* Sentiment_analysis Chart */}
      <Box my={3}>
        <StackedLineWidget
          title={"comments_sentiment_analysis"}
          titleToolTip={"comments_sentiment_analysis_tooltip"}
          data={twitterWidgets?.sentimentAnalysis}
          showGroupBy={true}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI
          showDownloadIcon
          bigImg
          isSwitchCase
          showScales
          iconAfterTitle={TwitterIcon}
          dataPiechart={twitterWidgets?.sentimentPieChart}
          hideLegend
          labelColors={["#89BB2A", "#E50035", "#F9A700"]}
          stackedLineWidgetPreLoaderDataSources={
            twitterWidgets?.sentimentAnalysisPreLoader
          }
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          isSentimentAnalysisShowCharts
        />
      </Box>
      <Box mt={3}>
        <ThemeAnalysis
          title={"monitor_theme_title"}
          titleToolTip="themes_tooltip"
          data={twitterWidgets?.themeAnalysis}
          themeAnalysisPreLoaderDataSources={
            twitterWidgets?.themeAnalysisPreLoader
          }
          showAI
          showDownloadIcon
          bigImg
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={TwitterIcon}
        />
      </Box>
      <Box mt={3}>
        <ThemesTrends
          title={"monitor_theme_trend_title"}
          titleToolTip="themes_trend_tooltip"
          data={twitterWidgets?.themeTrend}
          showGroupBy={false}
          showAI
          showDownloadIcon
          bigImg
          themeTrendPreLoaderDataSources={twitterWidgets?.themeTrendPreLoader}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          iconAfterTitle={TwitterIcon}
        />
      </Box>

      {/* Content Segmentation */}
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title="comments_content_style"
              titleToolTip={"comments_content_style_tw_tooltip"}
              showDownloadIcon={true}
              color={["#ED6C02", "#2AAAE2", "#80868C", "#8CC63F"]}
              data={twitterWidgets?.contentStyles}
              pieChartPreLoaderDataSources={
                twitterWidgets?.contentStylesPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
              showAI
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"comments_associated_topics"}
              titleToolTip={"comments_associated_topics_tooltip"}
              data={twitterWidgets?.associatedTopics}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={
                twitterWidgets?.associatedTopicsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_keywords"}
              titleToolTip={"comments_top_keywords_tooltip"}
              showDownloadIcon={true}
              data={twitterWidgets?.topKeywords}
              leftColomnTitle={"keywords"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={
                twitterWidgets?.topKeywordsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              keywords
              tablePagination
              count={twitterWidgets?.topKeywords?.length}
              iconAfterTitle={TwitterIcon}
              dynamicClass={"rowid-name-quick-inspect"}
              withWordCloud
            />
          </Grid>
          <Grid item xs={6}>
            <TableWidget
              wordsList
              title={"comments_top_hashtags"}
              titleToolTip={"comments_top_hashtags_tooltip"}
              showDownloadIcon={true}
              data={twitterWidgets?.topHashtags}
              leftColomnTitle={"hashtags"}
              rightColomnTitle={"number_of_posts"}
              tableChartPreLoaderDataSources={
                twitterWidgets?.topHashtagsPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              hashtags
              tablePagination
              count={twitterWidgets?.topHashtags?.length}
              iconAfterTitle={TwitterIcon}
              dynamicClass={"rowid-name-quick-inspect"}
              withWordCloud
            />
          </Grid>
        </Grid>
      </Box>
      {/* _____________________________________________________ */}
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              titleToolTip={"top_languages_fb_tooltip"}
              data={twitterWidgets?.topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={
                twitterWidgets?.topLanguagesPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"dialects_subdialects"}
              titleToolTip={"dialects_subdialects_tooltip"}
              data={twitterWidgets?.dialects}
              subData={twitterWidgets?.subDialects}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showAI
              showDownloadIcon
              pieChartPreLoaderDataSources={twitterWidgets?.dialectsPreLoader}
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_countries"}
              titleToolTip={"top_countries_tw_tooltip"}
              data={twitterWidgets?.topCountries}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
              pieChartPreLoaderDataSources={
                twitterWidgets?.topCountriesPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
              showAI
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_cities"}
              titleToolTip={"top_cities_tw_tooltip"}
              data={twitterWidgets?.topCities}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={twitterWidgets?.topCitiesPreLoader}
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
              showAI
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title={"gender_distribution"}
              titleToolTip={"gender_distribution_tw_tooltip"}
              data={twitterWidgets?.genderDistribution}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#2AAAE2", "#FF4081"]}
              pieChartPreLoaderDataSources={
                twitterWidgets?.genderDistributionPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
              showAI
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"account_types"}
              titleToolTip={"account_types_tw_tooltip"}
              data={twitterWidgets?.accountTypes}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              pieChartPreLoaderDataSources={
                twitterWidgets?.accountTypesPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              iconAfterTitle={TwitterIcon}
              showAI
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TableWidget
              circularPP={true}
              itemsList
              title={"top_engagers"}
              data={twitterWidgets?.topEngagers}
              leftColomnTitle={"users"}
              rightColomnTitle={"followers"}
              titleToolTip={
                isTwitterAccount
                  ? "top_engagers_tw_tooltip_twitter"
                  : "top_engagers_tw_tooltip"
              }
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              tableChartPreLoaderDataSources={
                twitterWidgets?.topEngagersPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              twitterIcon={TwitterIcon}
              tablePagination
              count={twitterWidgets?.topEngagers?.length}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <PieChartWidget
              title={"top_sources"}
              titleToolTip={"top_sources_tw_tooltip"}
              data={twitterWidgets?.topSources}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
              pieChartPreLoaderDataSources={twitterWidgets?.topSourcesPreLoader}
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              twitterIcon={TwitterIcon}
              iconAfterTitle={TwitterIcon}
            />
          </Grid> */}
          <Grid item xs={6}>
            <TableWidget
              circularPP={true}
              itemsList
              title={"top_infleuncers"}
              data={twitterWidgets?.topInfleuncers}
              leftColomnTitle={"user"}
              rightColomnTitle={"followers"}
              titleToolTip={
                isTwitterAccount
                  ? "top_infleuncers_tw_tooltip_twitter"
                  : "top_infleuncers_tw_tooltip"
              }
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              tableChartPreLoaderDataSources={
                twitterWidgets?.topInfleuncersPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              twitterIcon={TwitterIcon}
              tablePagination
              count={twitterWidgets?.topInfleuncers?.length}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        {/* _____________________________________________________ */}

        <Grid container spacing={3} mb={10}>
          <Grid item xs={6}>
            <TableWidget
              circularPP={true}
              itemsList
              title={"top_verified_engagers"}
              data={twitterWidgets?.topVerifiedEngagers}
              leftColomnTitle={"users"}
              rightColomnTitle={"followers"}
              titleToolTip={
                isTwitterAccount
                  ? "top_verified_engagers_tw_tooltip_twitter"
                  : "top_verified_engagers_tw_tooltip"
              }
              secondaryTitle={"engagement_overview_title"}
              isXPlatform
              tableChartPreLoaderDataSources={
                twitterWidgets?.topVerifiedEngagersPreLoader
              }
              preLoaderTrackerDataSources={preLoaderTrackerDataSources}
              twitterIcon={TwitterIcon}
              tablePagination
              count={twitterWidgets?.topVerifiedEngagers?.length}
              iconAfterTitle={TwitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      {/* _____________________________________________________ */}
    </>
  );
};

export default EngagementsSection;
