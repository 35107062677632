import UserAvatar from "shared/unifiedUserImage/userAvatar";
import { SLATimeStringify } from "pages/settings/pages/inboundRoutingPage/sharedLogic/inboundRouteAdapter";
import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CheckValueLocale, classNames } from "utils/helpers";
import { useIntl } from "react-intl";

export const getTableHeader = (headers = [], intl) => {
  const headerColumns = headers?.map((column) => {
    if (column?.tooltip) {
      return (
        <Tooltip
          classes={{
            tooltip: "agent-performance-table-tooltip",
          }}
          arrow
          placement="bottom"
          key={column?.name}
          title={CheckValueLocale(column?.tooltip, "", {}, intl)}
        >
          <Typography className="agent-performance-table-header with-border">
            {CheckValueLocale(column?.name, "", {}, intl)}
          </Typography>
        </Tooltip>
      );
    }
    return (
      <Typography className="agent-performance-table-header">
        {CheckValueLocale(column?.name, "", {}, intl)}
      </Typography>
    );
  });
  return headerColumns;
};

export const RenderAgentColumn = ({ agent }) => {
  const intl = useIntl();
  return (
    <Box className="big-table-agent-column">
      <Box className="big-table-agent-column-image">
        <UserAvatar
          fullName={agent?.name}
          sizes={{
            ratio: "34px",
          }}
          showStatusIndicator={true}
          statusIndicatorValue={agent?.availability_status}
          avatarSrc={agent?.avatar}
        />
      </Box>
      <Box className="big-table-agent-column-details">
        <Typography className="big-table-agent-column-name">
          {agent?.name}
        </Typography>
        <Typography className="big-table-agent-column-status">
          {CheckValueLocale(agent?.availability_status, "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export const RenderSLAsColumn = ({ sla }) => {
  return (
    <Box className="big-table-sla-column">
      <Typography className="big-table-sla-column-name">{sla?.name}</Typography>
      <Typography className="big-table-sla-column-values">
        {SLATimeStringify(sla)}
      </Typography>
    </Box>
  );
};

export const RenderNormalColumn = ({ value }) => {
  return <Box className="big-table-normal-column">{value}</Box>;
};

export const RenderTeamColumn = ({ team = {} }) => {
  const intl = useIntl();
  const { name, user_teams_count, user_count } = team;
  return (
    <Box className="big-table-sla-column">
      <Typography className="big-table-sla-column-name">{name}</Typography>
      <Typography className="big-table-sla-column-values">
        {CheckValueLocale(
          "agents_performance_team_availablity",
          "",
          {
            to: user_teams_count,
            from: user_count,
          },
          intl,
        )}
      </Typography>
    </Box>
  );
};
