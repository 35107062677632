import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Snackbar from "components/snackBar";
import ListHeader from "./accountsListsList/accountListHeader";
import AccountsListsList from "./accountsListsList/AccountsListsList";
import DeleteAccountsListModal from "./DeleteAccountsListModal";
import { useAccountsList } from "./hooks/useAccountsList";
import DuplicateAccountsListModal from "./DuplicateAccountsListModal";
import CircularLoading from "components/circularProgress";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteAccountsList } from "./hooks/useDeleteAccountsList";
import { useDuplicateAccountsList } from "./hooks/useDuplicateAccountsList";

const AccountsLists = () => {
  const intl = useIntl();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [accountslists, setAccountsLists] = useState([]);
  const [numberOfLists, setNumberOfLists] = useState(0);
  const [totalAccountsLists, setTotalAccountsLists] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: "",
    name: "",
    isLinkedToSavedFilter: false,
    open: false,
  });
  const [openDuplicateModal, setOpenDuplicateModal] = useState({
    id: "",
    name: "",
    open: false,
  });
  const [showActionMessage, setShowActionMessage] = useState({
    open: false,
    type: "",
    message: "",
  });
  const [isRequestError, setIsRequestError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const queryClient = useQueryClient();
  const { data, isPending, isError, isFetching } = useAccountsList(
    searchInput,
    page,
  );
  useEffect(() => {
    if (isError) {
      setIsRequestError(true);
    } else {
      setIsRequestError(false);
      setAccountsListResponseData(data ?? []);
    }
  }, [isError, data, isFetching]);

  const duplicateMutation = useDuplicateAccountsList();
  const deleteMutation = useDeleteAccountsList();

  const setAccountsListResponseData = (res) => {
    setAccountsLists(res?.data?.data);
    if (!isFetching) {
      setTotalAccountsLists(res?.data?.pagination?.pages || 0);
      setNumberOfLists(res?.data?.pagination?.count || 0);

      if (res?.data?.pagination?.count >= 100) {
        setTimeout(
          () => {
            setShowActionMessage({
              open: true,
              maxWidth: "70%",
              type: "error",
              message: CheckValueLocale(
                "accounts_lists_limit_reached",
                "",
                {},
                intl,
              ),
            });
          },
          showActionMessage?.message ? 2000 : 0,
        );
      }
    }
  };

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const handelTextSearch = () => {
    setSearchInput(searchText);
    setPage(1);
  };

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.editAccountsListData) {
        setShowActionMessage({
          ...location?.state?.editAccountsListData,
          open: true,
        });
        window?.history?.replaceState({}, "editAccountsListData");
      }
      if (location?.state?.createAccountsListData) {
        setShowActionMessage({
          ...location?.state?.createAccountsListData,
          open: true,
        });
        window?.history?.replaceState({}, "createAccountsListData");
      }
      if (location?.state?.deleteAccountsListData) {
        setShowActionMessage({
          ...location?.state?.deleteAccountsListData,
          open: true,
        });
        window?.history?.replaceState({}, "deleteAccountsListData");
      }
    }
  }, [location?.state]);

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: "",
      name: "",
      isLinkedToSavedFilter: false,
      open: false,
    });
  };

  const handleCloseDuplicateModal = () => {
    setOpenDuplicateModal({
      id: "",
      name: "",
      open: false,
    });
  };

  const handleOpenDeleteModal = (accountsListData) => {
    setOpenDeleteModal({ ...accountsListData, open: true });
  };

  const handleOpenDuplicateModal = (accountsListData) => {
    setOpenDuplicateModal({ ...accountsListData, open: true });
  };

  const handleDeleteAccountsList = () => {
    if (openDeleteModal?.id) {
      deleteMutation?.mutate(openDeleteModal.id, {
        onSuccess: () => {
          setShowActionMessage({
            open: true,
            type: "success",
            message: CheckValueLocale(
              "accounts_list_delete_success",
              "",
              { name: openDeleteModal.name },
              intl,
            ),
          });
          setOpenDeleteModal({ id: "", name: "", open: false });
          page != 1 && accountslists?.length == 1
            ? setPage(page - 1)
            : queryClient?.invalidateQueries(["accountsList"]);
        },
        onError: () => {
          setShowActionMessage({
            open: true,
            type: "error",
            message: CheckValueLocale(
              "accounts_list_delete_fail",
              "",
              {},
              intl,
            ),
          });
        },
      });
    }
  };

  const handleDuplicateAccountsList = () => {
    if (openDuplicateModal?.id) {
      duplicateMutation?.mutate(openDuplicateModal.id, {
        onSuccess: () => {
          setShowActionMessage({
            open: true,
            type: "success",
            message: CheckValueLocale(
              "accounts_list_duplicate_successfully",
              "",
              { name: openDuplicateModal.name },
              intl,
            ),
          });
          setOpenDuplicateModal({ id: "", name: "", open: false });
        },
        onError: () => {
          setShowActionMessage({
            open: true,
            type: "error",
            message: CheckValueLocale(
              "accounts_list_duplicate_failed",
              "",
              {},
              intl,
            ),
          });
        },
      });
    }
  };

  return (
    <Container maxWidth="xl">
      {isPending ? (
        <CircularLoading />
      ) : (
        <Box className="accountlist-main-box">
          <DeleteAccountsListModal
            modalData={openDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleDeleteAccountsList={handleDeleteAccountsList}
            buttonLoading={deleteMutation.isPending}
          />
          <DuplicateAccountsListModal
            modalData={openDuplicateModal}
            handleClose={handleCloseDuplicateModal}
            handleDuplicateAccountsList={handleDuplicateAccountsList}
            buttonLoading={duplicateMutation.isPending}
          />
          <ListHeader
            handelTextSearch={handelTextSearch}
            searchText={searchText}
            setSearchText={setSearchText}
            accountslists={accountslists}
            searchInput={searchInput}
            totalAccountsLists={numberOfLists}
          />
          <AccountsListsList
            list={accountslists}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenDuplicateModal={handleOpenDuplicateModal}
            searchText={searchText}
            totalAccountsLists={numberOfLists}
          />
          {totalAccountsLists > 1 && (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list pagination-accountlist-list"
              count={totalAccountsLists}
              variant="outlined"
            />
          )}
        </Box>
      )}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}

      <Snackbar
        lang={window?.localStorage?.lang}
        open={showActionMessage?.open}
        handleClose={() => {
          setShowActionMessage({
            open: false,
            type: "",
            message: "",
          });
        }}
        severity={showActionMessage?.type}
        message={showActionMessage?.message}
        alertStyle={{
          width: showActionMessage?.maxWidth || "100%",
          marginRight:
            intl?.locale === "ar" && showActionMessage?.maxWidth ? "30%" : "0",
        }}
      />
    </Container>
  );
};

export default AccountsLists;
