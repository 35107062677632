import { Box, Pagination, PaginationItem, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./CustomPagination.scss";

export const CustomPagination = ({
  pagination,
  onChange,
  page,
  pagesCount = pagination?.pages || pagesCount,
  itemsCount = pagination?.count || itemsCount,
  className,
  variant = "text",
  shape = "rounded",
  color = "primary",
}) => {
  const intl = useIntl();
  const itemsPerPage = pagination?.items || 10;

  const handleGoToPage = (event) => {
    if (event?.key === "Enter") {
      const pageNumber = event?.target?.value;
      let currentPage = page;
      if (pageNumber < 1) {
        currentPage = 1;
      } else if (pageNumber > pagesCount) {
        currentPage = Number(pagesCount);
      } else {
        currentPage = Number(pageNumber);
      }

      onChange(event, currentPage);
    }
  };

  const getItemRange = (page, itemsPerPage, itemsCount) => {
    const startRow = pagination?.from
      ? pagination?.from
      : (page - 1) * itemsPerPage + 1;

    const endRow = pagination?.to
      ? pagination?.to
      : Math.min(page * itemsPerPage, itemsCount);

    return `${startRow} - ${endRow}`;
  };

  return (
    <Box className={"custom-pagination-container"}>
      <span className="pagination-rows-indicator">
        {getItemRange(page, itemsPerPage, itemsCount)}
        <span className="pagination-rows-count">
          {" " + CheckValueLocale("of", "", {}, intl) + " " + itemsCount}
        </span>
      </span>

      <Pagination
        onChange={onChange}
        page={Number(page)}
        className={`custom-pagination ${className}`}
        count={pagesCount}
        rowsPerPage={itemsPerPage}
        variant={variant}
        shape={shape}
        color={color}
        boundaryCount={1}
        siblingCount={1}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            slots={{
              first: () => <FontAwesomeIcon icon={faAnglesLeft} />,
              last: () => <FontAwesomeIcon icon={faAnglesRight} />,
            }}
            {...item}
          />
        )}
      />
      <span className="pagination-input-container">
        {CheckValueLocale("go_to_page", "", {}, intl)}
        <TextField
          className="pagination-input"
          variant="outlined"
          size="small"
          type="number"
          defaultValue={page}
          onKeyDown={handleGoToPage}
          onInput={(event) => {
            // Remove any special characters
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
          }}
          inputProps={{
            min: 1,
            max: pagesCount,
          }}
        />
        {`${CheckValueLocale("of", "", {}, intl)} ${pagesCount}`}
      </span>
    </Box>
  );
};
