import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import AllPosts from "../components/allPosts/allPosts";
import SnackBar from "components/snackBar";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";
import { CheckValueLocale, isEmptyArray } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";

const PostsBody = (props) => {
  const intl = useIntl();
  const {
    response,
    resetAllFields,
    changePagination,
    pageNumber,
    setPageNumber,
    setTotalPageTw,
    totalPageTw,
    setTotalPageFb,
    totalPageFb,
    setTotalPageIg,
    totalPageIg,
    setTotalPageNb,
    totalPageNb,
    insightsID,
    insightsResponse,
    dates,
    setIsActivePostsTab,
    activePostsTab,
    getActiveTotalValue,
    metricsValues,
    singleTweetAnalysis,
    setTotalPage,
    totalPage,
    monitorType,
    unixDate,
  } = props;

  const [twTopPostComments, setTwTopPostComments] = useState([]);
  const [igTopPostComments, setIgTopPostComments] = useState([]);
  const [fbTopPostComments, setFbTopPostComments] = useState([]);
  const [nbTopPostComments, setNbTopPostComments] = useState([]);

  const [
    twTopPostCommentsPreLoaderDataSources,
    setTwTopPostCommentsPreLoaderDataSources,
  ] = useState([]);
  const [
    igTopPostCommentsPreLoaderDataSources,
    setIgTopPostCommentsPreLoaderDataSources,
  ] = useState([]);
  const [
    fbTopPostCommentsPreLoaderDataSources,
    setFbTopPostCommentsPreLoaderDataSources,
  ] = useState([]);
  const [
    nbTopPostCommentsPreLoaderDataSources,
    setNbTopPostCommentsPreLoaderDataSources,
  ] = useState([]);

  useEffect(() => {
    if (resetAllFields !== 1) {
      setTwTopPostComments([]);
      setTwTopPostCommentsPreLoaderDataSources([]);
      setFbTopPostComments([]);
      setFbTopPostCommentsPreLoaderDataSources([]);
      setIgTopPostComments([]);
      setIgTopPostCommentsPreLoaderDataSources([]);
      setNbTopPostComments([]);
      setNbTopPostCommentsPreLoaderDataSources([]);
      setTotalPage(0);
    }
  }, [resetAllFields]);

  useEffect(() => {
    switch (response.eventName) {
      case "Twitter__PostsPage__top_posts_comments":
        setTwTopPostComments(response.eventData.top_posts);
        if (response?.eventData?.top_posts_count !== undefined) {
          setTotalPage(totalPage + response.eventData.top_posts_count);
          setTotalPageTw(response.eventData.top_posts_count);
        }
        setTwTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__PostsPage__top_posts_comments":
        setFbTopPostComments(response.eventData.top_posts);
        if (response?.eventData?.top_posts_count !== undefined) {
          setTotalPage(totalPage + response.eventData.top_posts_count);
          setTotalPageFb(response.eventData.top_posts_count);
        }
        setFbTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__PostsPage__top_posts_comments":
        setIgTopPostComments(response.eventData.top_posts);
        if (response?.eventData?.top_posts_count !== undefined) {
          setTotalPage(totalPage + response.eventData.top_posts_count);
          setTotalPageIg(response.eventData.top_posts_count);
        }
        setIgTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Talkwalker__PostsPage__top_posts_comments":
        setNbTopPostComments(response.eventData.top_posts);
        if (response?.eventData?.top_posts_count !== undefined) {
          setTotalPage(totalPage + response.eventData.top_posts_count);
          setTotalPageNb(response.eventData.top_posts_count);
        }
        setNbTopPostCommentsPreLoaderDataSources((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  const handleChangePage = (event, activePage) => {
    if (Boolean(activePage) === false) return;
    pageNumber === 1
      ? changePagination(activePage, totalPage)
      : changePagination(activePage);

    setPageNumber(activePage);
  };
  const {
    updateAnalysis,
    handleCloseSnackBar,
    showAnalyzeTweetSnackBar,
    analyzeTweetSnackBar,
    stats,
    handleCloseCreateModal,
    modalBody,
  } = CommonFunctions();

  let checkPagination =
    (!isEmptyArray(twTopPostComments) &&
      activePostsTab === "TWITTER" &&
      totalPageTw !== 0) ||
    (!isEmptyArray(fbTopPostComments) &&
      activePostsTab === "FACEBOOK" &&
      totalPageFb !== 0) ||
    (!isEmptyArray(igTopPostComments) &&
      activePostsTab === "INSTAGRAM" &&
      totalPageIg !== 0) ||
    (!isEmptyArray(nbTopPostComments) &&
      activePostsTab === "NEWSBLOGS" &&
      totalPageNb !== 0);

  return (
    <div className="authors-margin">
      <AllPosts
        igTopPosts={igTopPostComments}
        twTopPosts={twTopPostComments}
        fbTopPosts={fbTopPostComments}
        nbTopPosts={nbTopPostComments}
        preLoaderTrackerDataSources={[
          ...metricsValues?.top_posts_comments?.data_sources,
        ]}
        igTopPostsPreLoaderDataSources={igTopPostCommentsPreLoaderDataSources}
        twTopPostsPreLoaderDataSources={twTopPostCommentsPreLoaderDataSources}
        fbTopPostsPreLoaderDataSources={fbTopPostCommentsPreLoaderDataSources}
        nbTopPostsPreLoaderDataSources={nbTopPostCommentsPreLoaderDataSources}
        allDatasources={[...metricsValues?.top_posts_comments?.data_sources]}
        updateAnalysis={updateAnalysis}
        insightsID={insightsID}
        response={insightsResponse}
        dates={dates}
        isPostsPage
        setIsActivePostsTab={setIsActivePostsTab}
        activePostsTab={activePostsTab}
        singleTweetAnalysis={singleTweetAnalysis}
        isAggregateAccountPage
        hideChangeSentiment={monitorType !== "KEYWORD"}
        hideWhenRetweeted={monitorType === "KEYWORD"}
        unixDate={unixDate}
      />
      {checkPagination &&
      Math.ceil(getActiveTotalValue(activePostsTab) / 10) > 1 ? (
        <Pagination
          onChange={handleChangePage}
          page={pageNumber}
          className="pagination-monitor-list"
          count={Math.ceil(getActiveTotalValue(activePostsTab) / 10)}
          variant="outlined"
        />
      ) : (
        <div className="pagination-one-page"></div>
      )}
      {showAnalyzeTweetSnackBar && (
        <SnackBar
          open={true}
          handleClose={handleCloseSnackBar}
          title={CheckValueLocale(analyzeTweetSnackBar.title, "", {}, intl)}
          message={CheckValueLocale(analyzeTweetSnackBar.message, "", {}, intl)}
          severity={analyzeTweetSnackBar.severity}
        />
      )}
      <PopupModal
        title={stats?.modalTitle}
        body={modalBody}
        open={stats?.openCreateModal}
        close={handleCloseCreateModal}
        warning="warning"
        classeName="create-historical-popup"
      />
    </div>
  );
};

export default PostsBody;
