import React, { useEffect, useState } from "react";
import { Container, Divider } from "@mui/material";
import { useIntl } from "react-intl";
import SurveyHeader from "../components/surveyHeader";
import SurveyController from "services/controllers/surveyController";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import SurveyWidgets from "./surveyInsightsSections/surveyWidgets";
import Luci from "pages/luci";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import SnackBar from "components/snackBar";
import DeletedAlertView from "pages/alerts/components/alertTypesView/components/deletedAlertView/deletedAlertView";

const SurveyInsights = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const surveyId = urlParams?.id;
  const location = useLocation();
  const surveyName = location?.state?.name;
  const surveyStatus = location?.state?.status;

  const [dataSources, setDataSources] = useState([]);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  const [individualSurvey, setIndividualSurvey] = useState([]);
  const [page, setPage] = useState(1);
  const [snackBar, setSnackBar] = useState({
    openSnackBar: false,
    message: "",
    severity: "",
    title: "",
  });

  const [isDeleted, setIsDeleted] = useState(false);

  const handleCloseSnackBar = () => {
    setSnackBar({ openSnackBar: false });
  };

  // __________________________________________________
  //breadcrumb Links
  const links = [
    {
      name: "surveys",
      url: "survey/list",
    },
    {
      name: surveyName,
      url: `survey/survey-insights/${surveyId}`,
      addNameLocal: false,
      disableURL: true,
    },
  ];
  // __________________________________________________

  const [startDate, setStartDate] = useState(
    parseInt(location?.state?.oldest_date),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);

  // __________________________________________________

  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState([]);

  // __________________________________________________

  const getSurveyInsights = (surveyId, productId, startDate, endDate) => {
    setResponse([]);
    SurveyController.getSurveyInsights(
      surveyId,
      window.localStorage.survey_id,
      startDate,
      endDate,
    ).then((data) => {
      if (data?.errorMsg?.response?.status === 404) {
        setIsDeleted(true);
      } else if (!isEmptyObj(data.data)) {
        setPreLoaderTrackerDataSources(data.data.data_sources);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };
  const handleApplyExport = () => {
    const queryData = {
      data: {
        survey: {
          start_date: startDate,
          end_date: moment().unix(),
          survey_id: surveyId,
        },
      },
    };
    SurveyController.exportSurvey(queryData).then((result) => {
      if (result?.data?.status == 204 || result?.data?.status == 200) {
        setSnackBar?.({
          openSnackBar: true,
          message: CheckValueLocale("export_alert_msg", "", {}, intl),
          severity: "success",
          title: CheckValueLocale("export_alert_title", "", {}, intl),
        });
      } else {
        setSnackBar?.({
          openSnackBar: true,
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
          severity: "error",
          title: CheckValueLocale("failed_error_message", "", {}, intl),
        });
      }
    });
  };
  // __________________________________________________

  const applyFilter = (startDate, endDate, filterParams) => {
    setResetAllFields((prev) => prev + 1);
    setStartDate(startDate);
    setEndDate(endDate);
    getSurveyInsights(surveyId, 3, startDate, endDate);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataSources(setDataSources);
  }, []);

  let surveySource =
    dataSources?.length &&
    dataSources?.filter((item) => item?.source === "SURVEY")[0];

  if (isDeleted) {
    return <DeletedAlertView deletedSurvey={true} />;
  }

  return (
    <Container maxWidth="xl" mb="60px">
      <SurveyHeader
        title={"survey_insights"}
        showDatePicker={true}
        showBreadCrumb={true}
        links={links}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        allTimeStartDate={parseInt(location?.state?.oldest_date)}
        handleApplyExport={handleApplyExport}
        surveyStatus={surveyStatus}
      />
      <Divider className="survey-insights-divider" />
      <SurveyWidgets
        response={response}
        resetAllFields={resetAllFields}
        preLoaderTrackerDataSources={[preLoaderTrackerDataSources]}
        id={surveyId}
        productId={window.localStorage.survey_id}
        setCheckAllDataExist={setCheckAllDataExist}
        setCheckDataIsEmpty={setCheckDataIsEmpty}
        individualSurvey={individualSurvey}
        setIndividualSurvey={setIndividualSurvey}
        page={page}
        setPage={setPage}
      />
      <Luci
        isInactive={false}
        isSurvey={true}
        individualSurvey={individualSurvey}
        setIndividualSurvey={setIndividualSurvey}
        page={page}
        setPage={setPage}
        pageParams={{
          surveyId: surveyId,
          startDate: startDate,
          endDate: endDate,
          sectionName: "Insights",
          productId: window.localStorage.survey_id,
          luciFeature: "Survey Insights",
          dataSourceId: surveySource?.id,
          monitorName: surveyName,
          monitorProduct: "surveys",
          dataLoaded: checkAllDataExist,
          dataEmpty: checkDataIsEmpty,
        }}
      />
      <SnackBar
        open={snackBar?.openSnackBar}
        severity={snackBar?.severity}
        message={snackBar?.message}
        title={snackBar?.title}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
export default SurveyInsights;
