import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
import "./headerSideBar.scss";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

const HeaderSideBar = ({
  selectedCard,
  openDetailsSideBar,
  setOpenDetailsSideBar,
}) => {
  const intl = useIntl();

  const handleClose = () => {
    setOpenDetailsSideBar({
      open: false,
      type: "",
    });
  };
  const getSocialIcon = (type) => {
    const SocialIcons = {
      facebook: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="facebook" icon={faFacebook} />
        </Box>
      ),
      twitter: (
        <Box className={type + "-icon user-source"}>
          <img src={xPlatform} alt="x-platform-logo" className="twitter" />
        </Box>
      ),
      instagram: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="twitter" icon={faInstagram} />
        </Box>
      ),
      whatsapp: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="whatsapp" icon={faWhatsappSquare} />
        </Box>
      ),
    };
    return SocialIcons[type];
  };
  return (
    <Box className="details-side-bar-header">
      <Box className="side-bar-name-wrapper">
        {openDetailsSideBar?.type === "profile"
          ? getSocialIcon(selectedCard?.data_source)
          : null}
        <span className="side-bar-name">
          {" "}
          {CheckValueLocale(openDetailsSideBar?.type, "", {}, intl)}{" "}
        </span>
      </Box>
      <Box className="side-bar-close-wrapper">
        <FontAwesomeIcon icon={faTimes} onClick={handleClose} />
      </Box>
    </Box>
  );
};
export default HeaderSideBar;
