import translations from "utils/locale";
import { useSelector } from "react-redux";
import { handleThemesTranslations } from "utils/helpers";
import { account_fb_AA } from "./accountAnalysisMonitors/account-fb-AA";
import { account_ig_AA } from "./accountAnalysisMonitors/account-ig-AA";
import { account_tw_AA } from "./accountAnalysisMonitors/account-tw-AA";
import { engagements_fb_AA } from "./accountAnalysisMonitors/engagements-fb-AA";
import { engagements_ig_AA } from "./accountAnalysisMonitors/engagements-ig-AA";
import { engagements_tw_AA } from "./accountAnalysisMonitors/engagements-tw-AA";
import { twitter_public } from "./channelAnayticsPages/twitter_public";
import { tiktok_public } from "./channelAnayticsPages/tiktok_public";
import { twitter_private } from "./channelAnayticsPages/twitter_private";
import { social_media } from "./channelAnayticsPages/social_media";
import { analytics } from "./channelAnayticsPages/analytics";
import { chat } from "./channelAnayticsPages/chat";
import { googlemybusiness } from "./channelAnayticsPages/googlemybusiness";
import { engagements_fb_Keywords } from "./keywordsMonitors/engagements-fb-Keywords";
import { engagements_ig_Keywords } from "./keywordsMonitors/engagements-ig-Keywords";
import { engagements_nb_Keywords } from "./keywordsMonitors/engagements-nb-keywords";
import { engagements_tw_Keywords } from "./keywordsMonitors/engagements-tw-Keywords";
import { account_fb_MP } from "./managedMonitors/account-fb-MP";
import { account_ig_MP } from "./managedMonitors/account-ig-MP";
import { engagements_fb_MP } from "./managedMonitors/engagements-fb-MP";
import { engagements_ig_MP } from "./managedMonitors/engagements-ig-MP";
//CA pages
import { ig_CA_public } from "./channelAnayticsPages/instagramPublic/ig_CA_public";
import { fb_public } from "./channelAnayticsPages/facebookPublic/facebook-public";
import { fb_private } from "./channelAnayticsPages/facebookPrivate/facebook-private";
import { genesys_public } from "./channelAnayticsPages/callsPages/genesysPage/genesys_public";
import { linkedIn_public } from "./channelAnayticsPages/linkedInPublic/linkedIn_public";
import { whatsapp } from "./channelAnayticsPages/whatsapp";
import { gmail } from "./channelAnayticsPages/gmail";
import { intercom } from "./channelAnayticsPages/intercom";
import { engagementAnalyticsInbox } from "./enagagementAnalytics/inbox";
import { engagementAnalyticsSLAs } from "./enagagementAnalytics/slas";
import { engagementAnalyticsAgents } from "./enagagementAnalytics/agents";
import { engagementAnalyticsTeams } from "./enagagementAnalytics/teams";
import { engagementAnalyticsInsights } from "./enagagementAnalytics/insights";
const generateExcelFunc = (
  response,
  monitorType,
  activeTab,
  monitorDataSource,
  language,
  engagementTabName,
  themesOptions,
) => {
  var xlsLanguage = translations[language];

  const reduxThemesList =
    useSelector((state) => state?.channelAnalytics?.themesOptions) || [];

  const themesTranslations =
    handleThemesTranslations(
      themesOptions ? themesOptions : reduxThemesList, // To check if themesOptions is passed or not and use redux themesOptions
      language,
    ) || {};

  if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "TWITTER" &&
    activeTab === "account"
  ) {
    return account_tw_AA(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "TWITTER" &&
    activeTab === "engagements"
  ) {
    return engagements_tw_AA(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "INSTAGRAM" &&
    activeTab === "engagements"
  ) {
    return engagements_ig_AA(response, xlsLanguage);

    //________________________________________________________________________________________
  } else if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "INSTAGRAM" &&
    activeTab === "account"
  ) {
    return account_ig_AA(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "FACEBOOK" &&
    activeTab === "account"
  ) {
    return account_fb_AA(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "ACCOUNT_ANALYSIS" &&
    monitorDataSource === "FACEBOOK" &&
    activeTab === "engagements"
  ) {
    return engagements_fb_AA(response, xlsLanguage);

    //________________________________________________________________________________________
  } else if (
    monitorType === "KEYWORD" &&
    monitorDataSource === "TWITTER" &&
    activeTab === "engagements"
  ) {
    return engagements_tw_Keywords(response, xlsLanguage, themesTranslations);
    //________________________________________________________________________________________
  } else if (
    monitorType === "KEYWORD" &&
    monitorDataSource === "INSTAGRAM" &&
    activeTab === "engagements"
  ) {
    return engagements_ig_Keywords(response, xlsLanguage, themesTranslations);

    //________________________________________________________________________________________
  } else if (
    monitorType === "KEYWORD" &&
    monitorDataSource === "FACEBOOK" &&
    activeTab === "engagements"
  ) {
    return engagements_fb_Keywords(response, xlsLanguage, themesTranslations);
    //   //________________________________________________________________________________________
  } else if (
    monitorType === "MANAGED_ACCOUNT" &&
    monitorDataSource === "INSTAGRAM" &&
    activeTab === "account"
  ) {
    return account_ig_MP(response, xlsLanguage);

    //________________________________________________________________________________________
  } else if (
    monitorType === "MANAGED_ACCOUNT" &&
    monitorDataSource === "INSTAGRAM" &&
    activeTab === "engagements"
  ) {
    return engagements_ig_MP(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "KEYWORD" &&
    monitorDataSource === "nb" &&
    activeTab === "engagements"
  ) {
    return engagements_nb_Keywords(response, xlsLanguage, themesTranslations);
    //________________________________________________________________________________________
  } else if (
    monitorType === "MANAGED_PAGE" &&
    monitorDataSource === "FACEBOOK" &&
    activeTab === "account"
  ) {
    return account_fb_MP(response, xlsLanguage);
    //________________________________________________________________________________________
  } else if (
    monitorType === "MANAGED_PAGE" &&
    monitorDataSource === "FACEBOOK" &&
    activeTab === "engagements"
  ) {
    return engagements_fb_MP(response, xlsLanguage);
  } else if (monitorDataSource === "instagram_public") {
    return ig_CA_public(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "twitter_public") {
    return twitter_public(response, xlsLanguage, themesTranslations);
    //________________________________________________________________________________________
  } else if (monitorDataSource === "tiktok_public") {
    return tiktok_public(response, xlsLanguage, themesTranslations);
    //________________________________________________________________________________________
  } else if (monitorDataSource === "twitter_private") {
    return twitter_private(response, xlsLanguage, themesTranslations);
  }
  //________________________________________________________________________________________
  else if (monitorDataSource === "facebook_public") {
    return fb_public(response, xlsLanguage, themesTranslations);
  }
  //________________________________________________________________________________________
  else if (monitorDataSource === "facebook_private") {
    return fb_private(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "genesys_calls") {
    return genesys_public(response, xlsLanguage, themesTranslations);
  }
  //________________________________________________________________________________________
  else if (monitorDataSource === "Social_media") {
    return social_media(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "linkedin_public") {
    return linkedIn_public(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "GMB") {
    return googlemybusiness(response, xlsLanguage);
  } else if (monitorDataSource === "whatsapp") {
    return whatsapp(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "analytics") {
    return analytics(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "chats") {
    return chat(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "gmail") {
    return gmail(response, xlsLanguage, themesTranslations);
  } else if (monitorDataSource === "intercom") {
    return intercom(response, xlsLanguage, themesTranslations);
  }
  //________________________________________________________________________________________
  // Engagement Analytics
  else if (engagementTabName === "inbox") {
    return engagementAnalyticsInbox(response, xlsLanguage);
  } else if (engagementTabName === "slas") {
    return engagementAnalyticsSLAs(response, xlsLanguage);
  } else if (engagementTabName === "agents") {
    return engagementAnalyticsAgents(response, xlsLanguage);
  } else if (engagementTabName === "teams") {
    return engagementAnalyticsTeams(response, xlsLanguage);
  } else if (engagementTabName === "Insights") {
    return engagementAnalyticsInsights(response, xlsLanguage);
  }
};

export { generateExcelFunc };
