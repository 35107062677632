// *******COMMON FILE,Any change in this file will have effect on many pages
// Please Don't Change, delete or update in this file without perrmission

import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import GroupBy from "./groupBy";
import GroupByClientAgent from "./groupByClientAgent";
import Scales from "./scales";
import "./styles/WidgetHeader.scss";
import TotalLabels from "./totalLabels";
import TotalLabelsSentimentAnalysis from "./totalLabelsSentimentAnalysis";
//import icons
import aiIcon from "images/ai.svg";
import WidgetDownloader from "./WidgetDownloader";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { makeStyles } from "@mui/styles";
import { CheckValueLocale, extractPathfromURLForIds } from "utils/helpers";
import NotAppliedFilter from "images/shared-images/no-filter.svg";
import WidgetControl from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/widgetControl";
import CustomizedTypeCard from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/customizedTypeCard";
import WidgetHeaderMonitorsCard from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/controlWidget/components/widgetHeaderMonitorsCard";
import StackedBarComparisonTabs from "pages/dashboard/dashboardMainPages/customDashboardPage/customDashboardView/components/widgetsTypes/components/stackedBarComparisonTabs";
import { WidgetViewType } from "./WidgetViewType";
import { GeneralGroupBy } from "./generalGroupBy";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 210,
  },
}));
const WidgetHeader = (props) => {
  const { listDataSourceIcon } = props; //this to show monitor datasource icon with comparison
  const intl = useIntl();
  const classes = useStyles();
  const {
    isSentimentAnalysisWidget,
    isEmptySentimentAnalysis,
    isEmptyCityAnalysis,
    title,
    isQuickExplore,
    isCustomDashboard,
    isControlWidget,
    customDashboardData,
  } = props;
  let toolTipMessage = "filtration_criteria_not_working";

  let pathName = extractPathfromURLForIds(window?.location?.pathname);
  let singleLabelCondition = props?.dataPiechart?.length === 1 ? true : false;
  const widgetTitleHeaderID = `widget-header-title-tooltip-${title ?? "title"}-${pathName ?? "pathName"}`;
  return (
    <Box className="widget-box">
      <Box>
        <Grid container className="widget-header-container-dashboard">
          <Grid
            item
            xs={6}
            md={9}
            mb={1}
            className={`${isCustomDashboard ? "widget-header-grid-item" : ""} ${
              props?.isInteractionPage
                ? "interaction-li"
                : "" + "widget-header-container-title"
            }`}
          >
            {props?.iconBeforeTitle ? (
              <Tooltip title={props?.iconBeforeTitleText} arrow placement="top">
                {props?.iconBeforeTitle}
                {/* pass tag from previous file with its styling
              ex: iconAfterTitle={  <InstagramIcon  sx={{ position: "relative", top: "5px", left: "5px" }} />} */}
              </Tooltip>
            ) : null}
            <div
              id={widgetTitleHeaderID}
              className={props?.showReportIcon ? "display-in-center" : ""}
            >
              {props?.showNotAppliedFilter ? (
                <Tooltip
                  title={
                    <div className="tooltip-not-applied-filter">
                      {CheckValueLocale(
                        "not_applied_fitler_tooltip",
                        "",
                        {},
                        intl,
                      )}
                    </div>
                  }
                  arrow
                  placement="bottom"
                  classes={{ tooltip: classes.customWidth }}
                >
                  <img
                    src={NotAppliedFilter}
                    className="report-icon not-applied-icon"
                  />
                </Tooltip>
              ) : null}
              {props?.showReportIcon && (
                <Tooltip
                  title={
                    <div className="tooltip-text">
                      {CheckValueLocale(toolTipMessage, "", {}, intl)}
                    </div>
                  }
                  arrow
                  placement="top"
                  classes={{ tooltip: classes.customWidth }}
                >
                  <ReportProblemRoundedIcon className="report-icon" />
                </Tooltip>
              )}
              {props?.title && props?.titleToolTip ? (
                <Tooltip
                  id={
                    isControlWidget
                      ? "widget-header-title-tooltip-control"
                      : "widget-header-title-tooltip"
                  }
                  title={
                    <div className="tooltip-custom-new">
                      {props?.titleToolTip &&
                        intl.formatMessage(
                          { id: props?.titleToolTip },
                          { break: <br /> },
                        )}
                    </div>
                  }
                  arrow
                  placement="top"
                  sx={{ zIndex: 9999999999 }}
                >
                  <Typography variant={"h7"} className="widget-title">
                    {CheckValueLocale(props?.title, "", {}, intl)}
                    {props?.widgetSection ? (
                      <span className="widget-title-section">
                        ({CheckValueLocale(props?.widgetSection, "", {}, intl)})
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant={"h7"} className="widget-title">
                  {props?.title
                    ? `${CheckValueLocale(props?.title, "", {}, intl)}
                    ${
                      props?.widgetSection ? (
                        <span className="widget-title-section">
                          (
                          {CheckValueLocale(props?.widgetSection, "", {}, intl)}
                          )
                        </span>
                      ) : (
                        ""
                      )
                    }
                    `
                    : ""}
                </Typography>
              )}

              {
                props?.iconAfterTitle && props?.iconAfterTitle
                //pass tag from previous file with its styling
                //ex: iconAfterTitle={  <InstagramIcon  sx={{ position: "relative", top: "5px", left: "5px" }} />}
              }

              {/* This Component In case of CUSTOM DASHBOARD customized widget only to show (Aggregate, or Comparison) */}
              {isCustomDashboard &&
              !isControlWidget &&
              !customDashboardData?.noControl &&
              !!customDashboardData?.widget?.customized_widget ? (
                <CustomizedTypeCard
                  customDashboardData={customDashboardData}
                  widgetName={title}
                />
              ) : null}
            </div>
          </Grid>

          <Grid
            item
            xs={6}
            md={3}
            mb={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={`${isCustomDashboard ? "widget-header-grid-item" : ""} widget-header-container-filter`}
          >
            {props?.withWordCloud ? (
              <Grid item marginX={2}>
                <WidgetViewType
                  valueType={props?.viewType}
                  handleViewTypeChange={props?.handleViewTypeChange}
                />
              </Grid>
            ) : null}
            {props?.showGeneralGroupBy ? (
              <Grid item marginX={2}>
                <GeneralGroupBy
                  options={props?.generalGroupByOptions}
                  selectedValue={props?.selectedGeneralGroupBy}
                  onChange={props?.handleGeneralGroupByChange}
                  groupByWidgetId={`widget-general-group-by-${title ?? "title"}-${
                    pathName ?? "pathName"
                  }`}
                  disabled={props?.disabledGeneralGroupBy}
                />
              </Grid>
            ) : null}
            {props?.showGroupBy ? (
              <Grid item marginX={2}>
                <GroupBy
                  data={props?.data}
                  getChartMeta={props?.getChartMeta}
                  nestedLoop={props?.nestedLoop}
                  title={props?.title}
                  hidePercentage={props?.hidePercentage}
                  groupByWidgetId={`widget-group-by-${title ?? "title"}-${
                    pathName ?? "pathName"
                  }`}
                />
              </Grid>
            ) : null}
            {props?.showScales ? (
              <Grid item marginX={2}>
                <Scales
                  getChartScales={props?.getChartScales}
                  title={props?.title}
                  scaleWidgetId={`widget-scales-${title ?? "title"}-${
                    pathName ?? "pathName"
                  }`}
                />
              </Grid>
            ) : null}
            {props?.showGroupByClientAgent ? (
              <GroupByClientAgent
                toggleSelectedValue={props?.toggleSelectedValue}
                channelActivityPreLoaderDataSources={
                  props?.channelActivityPreLoaderDataSources
                }
                preLoaderTrackerDataSources={props?.preLoaderTrackerDataSources}
                channelActivityUpdated={props?.channelActivityUpdated}
                groupByWidgetId={`widget-show-group-by-client-agent-${
                  title ?? "title"
                }-${pathName ?? "pathName"}`}
              />
            ) : null}
            <div className="ai_download_wrapper">
              {props?.showAI && (
                <div className="ai-icon-wrapper">
                  <div className="aiIconDiv">
                    <img
                      src={aiIcon}
                      alt=""
                      id={`widget-ai-icon-${title ?? "title"}-${
                        pathName ?? "pathName"
                      }`}
                    />
                  </div>
                </div>
              )}
              {props?.showDownloadIcon && (
                <WidgetDownloader
                  chartId={props?.chartId}
                  bigImg={props?.bigImg}
                  downloadBtnId={`widget-downloader-${title ?? "title"}-${
                    pathName ?? "pathName"
                  }`}
                  showAILine={props?.showAI}
                  isCustomDashboard={isCustomDashboard}
                  isWordCloudShown={props?.isWordCloudShown}
                  withWordCloud={props?.withWordCloud}
                  title={props.title}
                  hiddenChartId={props?.hiddenChartId}
                  widgetTitleHeaderID={widgetTitleHeaderID}
                />
              )}
            </div>
            {isCustomDashboard &&
            !isControlWidget &&
            !customDashboardData?.noControl &&
            customDashboardData?.showControl ? (
              <WidgetControl customDashboardData={customDashboardData} />
            ) : null}
          </Grid>
        </Grid>

        {/* This Component In case of CUSTOM DASHBOARD only to show Monitors names and Types */}
        {isCustomDashboard &&
        props?.customDashboardData?.customizedType !== "comparison" ? (
          <WidgetHeaderMonitorsCard
            isControlWidget={isControlWidget}
            customDashboardData={customDashboardData}
          />
        ) : null}

        {/******** This Component In case of CUSTOM DASHBOARD (Comparison) Type only ********/}
        {/******** Reach Funnel Tabs: [authors, potential_reach, potential_impression] ********/}
        {/******** Themes Analysis Tabs: [themes, sub-themes] ********/}
        {isCustomDashboard && props?.comparisonReachFunnel?.showTabs ? (
          <StackedBarComparisonTabs
            comparisonReachFunnel={props?.comparisonReachFunnel}
          />
        ) : null}
      </Box>
      {props?.totalLabel !== undefined && props?.hideLegend === undefined && (
        <TotalLabels
          labelColor={
            props?.dataChartValue && props?.dataChartValue.length > 0
              ? "#ccc"
              : props?.labelColor
          }
          textColor={
            props?.dataChartValue && props?.dataChartValue.length > 0 && "#ccc"
          }
          totalLabel={props?.totalLabel}
          totalValue={props?.totalValue}
          handleDataChartValue={props?.handleDataChartValue}
          singleLabel={true}
        />
      )}
      {props?.totalLabel === undefined &&
      props?.hideLegend !== undefined &&
      isSentimentAnalysisWidget === undefined &&
      props?.dataReached ? (
        <Box sx={props.totalSentiment === 0 ? { marginBottom: "25px" } : null}>
          <Grid container direction="row" item align="center">
            {props?.dataPiechart?.map((item, index) => (
              <TotalLabels
                labelColor={
                  props?.dataPieChartValue &&
                  props?.dataPieChartValue.indexOf(
                    CheckValueLocale(item?.name, "", {}, intl),
                  ) !== -1
                    ? "#ccc"
                    : props?.labelColors[index]
                }
                textColor={
                  props?.dataPieChartValue &&
                  props?.dataPieChartValue.indexOf(
                    CheckValueLocale(item?.name, "", {}, intl),
                  ) !== -1 &&
                  "#ccc"
                }
                totalLabel={item.name}
                totalValue={item.value}
                percentage={
                  props?.totalSentiment !== 0
                    ? ((item.value / props?.totalSentiment) * 100).toFixed(2) +
                      "%"
                    : "0%"
                }
                hideLegend={props?.hideLegend}
                handleDataChartValue={props?.handleDataChartValue}
                dataPieChartValue={props?.dataPieChartValue}
                hidePercentage={props?.hidePercentage}
                singleLabel={singleLabelCondition}
                dataSourceMonitors={listDataSourceIcon?.[index]} // to display monitor dataSource icon when this widget in comparison
              />
            ))}
          </Grid>
        </Box>
      ) : null}
      {/* in case of sentiment analysis widget new design */}
      {!isCustomDashboard ||
      (isCustomDashboard && isSentimentAnalysisWidget && !isControlWidget) ? (
        !isEmptySentimentAnalysis &&
        !isEmptyCityAnalysis &&
        (props?.postsReach ||
          props?.postsImpression ||
          props?.isSentimentNeutral ||
          props?.isSentimentPositive ||
          props?.isSentimentNegative) ? (
          props?.totalLabel === undefined &&
          props?.hideLegend !== undefined &&
          isSentimentAnalysisWidget !== undefined &&
          props?.dataReached ? (
            <Box className="sentiment-legends-container">
              {props?.dataPiechart?.map((item, index) => (
                <TotalLabelsSentimentAnalysis
                  totalLabel={item.name}
                  totalValue={item.value}
                  percentage={
                    props?.totalSentiment !== 0
                      ? ((item.value / props?.totalSentiment) * 100).toFixed(
                          2,
                        ) + "%"
                      : "0%"
                  }
                  inspectorName={props?.inspectorName}
                  handleOpenQuickInspect={props?.handleOpenQuickInspect}
                  isQuickExplore={isQuickExplore}
                />
              ))}
            </Box>
          ) : null
        ) : null
      ) : null}
    </Box>
  );
};
export default WidgetHeader;
