import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Tooltip, Typography, Zoom, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import "./monitorList.scss";
import { useIntl, FormattedMessage } from "react-intl";
import OptionsDropDown from "../../../components/optionsDropDown/optionsDropDown";
import CircularLoading from "components/circularProgress";
import { data } from "pages/Monitors/Components/sidebar/dataSourceTabs";
import PopupModal from "components/popupModal";
import LoginController from "services/controllers/loginController.js";
import {
  getSocialIcon,
  CheckValueLocale,
  convertTwitterToXPlatform,
  deleteCookie,
  handlelUserRoles,
} from "utils/helpers";
import ErrorIcon from "@mui/icons-material/Error";
import SharedTable from "../../../../../../shared/table/sharedTable";
import { sortedColumnTypes } from "hooks/useSortableTable";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import SnackBar from "components/snackBar";
import { resetChatMessages } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { useDispatch } from "react-redux";
import { useHistoricalRequestStatus } from "pages/settings/pages/historicalRequestPage/hooks/useHistoricalRequestStatus";
import CreateHistoricalRequest from "pages/settings/pages/historicalRequestPage/components/createHistoricalRequest";
import { usePackagesQuota } from "pages/settings/pages/historicalRequestPage/hooks/usePackagesQuota";

export const getMonitorListTableHeader = (intl) => {
  return [
    {
      body: CheckValueLocale("monitor_name", "", {}, intl),
      width: 200,
      name: "name",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("data_sources", "", {}, intl),
      width: 100,
      name: "data_sources_count",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("posts_used", "", {}, intl),
      width: 100,
      name: "posts_used",
      type: sortedColumnTypes.number,
    },
    {
      body: CheckValueLocale("type", "", {}, intl),
      width: 100,
      name: "type",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("creator", "", {}, intl),
      width: 100,
      name: "created_by",
      type: sortedColumnTypes.string,
    },
    {
      body: CheckValueLocale("start_date", "", {}, intl),
      width: 50,
      name: "created_at",
      type: sortedColumnTypes.date,
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      width: 50,
      name: "status",
      type: sortedColumnTypes.string,
    },
    {
      body: null,
      width: 30,
    },
  ];
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const MonitorList = (props) => {
  const { tableHead, isSortableLoading } = props;
  const [isLoading, setLoading] = useState(false);
  const [isProduction, setIsProduction] = useState(false);
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const monitors = props.monitors;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [monitorsListSnack, setMonitorsListSnack] = useState({
    open: false,
    status: null,
    title: "",
    msg: "",
  });
  const [monitorInfo, setMonitorInfo] = useState({});
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [remainNewsBlogs, setRemainNewsBlogs] = useState(0);
  const [remainPosts, setRemainPosts] = useState(0);
  const [remainInteractions, setRemainInteractions] = useState(0);
  const [snackBar, setSnackBar] = useState({});
  // _________________________________________________________

  const { data: processingIndicator } = useHistoricalRequestStatus({
    enableFetch: handlelUserRoles("GENERAL", "VIEW_SETTING"),
  });

  const { data: packagesQuota } = usePackagesQuota({
    enableFetch: openCreateModal && handlelUserRoles("GENERAL", "VIEW_SETTING"),
  });

  useEffect(() => {
    if (packagesQuota) {
      for (let i = 0; i < packagesQuota?.length; i++) {
        if (packagesQuota?.[i]?.name === "SM") {
          setRemainNewsBlogs(
            packagesQuota?.[i]?.quota?.news_blogs?.limit -
              packagesQuota?.[i]?.quota?.news_blogs?.used,
          );
          setRemainPosts(
            packagesQuota?.[i]?.quota?.posts_limit?.limit -
              packagesQuota?.[i]?.quota?.posts_limit?.used,
          );
        }
        if (packagesQuota?.[i]?.name === "CXM") {
          setRemainInteractions(
            packagesQuota?.[i]?.quota?.interactions?.limit -
              packagesQuota?.[i]?.quota?.interactions?.used,
          );
        }
      }
    }
  }, [packagesQuota]);

  // _________________________________________________________
  const checkProductionEnv = () => {
    var productionUrl = window.location.hostname !== "cxm.lucidya.com";
    var productionEnvCheck =
      window.localStorage.getItem("productionEnv") === "true";
    var lucidyaUser =
      window.localStorage.getItem("is_lucidya_account") === "1" ||
      localStorage.email?.includes("@lucidya");
    if (productionEnvCheck && lucidyaUser && productionUrl) {
      setIsProduction(true);
    }
  };

  useEffect(() => {
    checkProductionEnv();
  }, []);

  const handleCloseCheckEnvModal = () => {
    setIsProduction(false);
  };

  const logOutProdEnv = () => {
    let currentLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : window.navigator.language.split("-")[0];
    LoginController.userlogout().then((data) => {
      dispatch(resetChatMessages());
      localStorage.clear();
      deleteCookie("email");
      window.localStorage.setItem("lang", currentLang);
      window.location.href = "/login";
    });
  };

  // _________________________________________________________

  const typeFormat = (type) => {
    const monitor_type = {
      KEYWORD: CheckValueLocale("keyword", "", {}, intl),
      ACCOUNT_ANALYSIS: CheckValueLocale("ACCOUNT_ANALYSIS", "", {}, intl),
      REGION: CheckValueLocale("Region", "", {}, intl),
      MANAGED_PAGE: CheckValueLocale("MANAGED_ACCOUNT", "", {}, intl),
      MANAGED_ACCOUNT: CheckValueLocale("MANAGED_ACCOUNT", "", {}, intl),
    };
    return monitor_type[type];
  };
  // _________________________________________________________

  const getStatusLocalization = (status) => {
    const monitor_type = {
      paused: CheckValueLocale("paused", "", {}, intl),
      collecting: CheckValueLocale("collecting", "", {}, intl),
      unapproved: CheckValueLocale("Under_Approval", "", {}, intl),
    };
    return monitor_type[status];
  };
  // _________________________________________________________

  const skipStatus = (status) => {
    return (
      status == "paused" || status == "collecting" || status == "under approval"
    );
  };
  // _________________________________________________________

  const getMsgStatusFormating = (status) => {
    const statuses = {
      "Fetching GNIP": CheckValueLocale("fetching_gnip", "", {}, intl),
      "Updating Tracker": CheckValueLocale("updating_tracker", "", {}, intl),
      "Authentication error!": CheckValueLocale(
        "authentication_error",
        "",
        {},
        intl,
      ),
      paused: CheckValueLocale("paused", "", {}, intl),
      "non-social": CheckValueLocale(
        "non_social_medias_data_limit_reached",
        "",
        {},
        intl,
      ),
      social: CheckValueLocale(
        "social_medias_data_limit_reached",
        "",
        {},
        intl,
      ),
      "Invalid twitter handler name!": CheckValueLocale(
        "Invalid_twitter_handler_name",
        "",
        {},
        intl,
      ),
      monitor_list_social_twitter_account_locked: CheckValueLocale(
        "monitor_list_social_twitter_account_locked",
        "",
        {},
        intl,
      ),
    };
    return statuses[status];
  };
  // _________________________________________________________
  //this function to redirect user to the correct monitor url from monitorList page
  const redirectToMonitor = (monitor) => {
    const monitor_id = monitor?.id;
    const monitorType = monitor?.attributes?.monitor_type_name;
    const monitorDataSources = monitor?.attributes?.data_sources[0];
    const dataSourceName = convertTwitterToXPlatform(monitorDataSources);
    let monitorPath = "";
    data?.forEach((i) => {
      //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType
      if (Object.keys(i)[0] == `${monitorDataSources}_${monitorType}`) {
        monitorPath = `/monitor/${monitorType}/${
          dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
            ? "NEWSBLOGS"
            : dataSourceName
        }/${Object.values(i)[0][0].analytics[0].tabName}/${monitor_id}`;
      }
    });
    return monitorPath;
  };
  const showTooltipText = (text, textLength) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Typography
              variant="subtitle2"
              className="monitor-title"
              sx={{
                padding: 0,
                margin: 0,
                borderRadius: 0,
              }}
              id="report-create-new-show-report-text-btn"
            >
              {text?.substring(0, textLength)}...
            </Typography>
          </BootstrapTooltip>
        ) : (
          <Typography variant="subtitle2" className="monitor-title">
            {text}
          </Typography>
        )}
      </Box>
    );
  };
  const monitorErorrs = (monitor) => {
    let errors = [];
    let accErrors = [];
    if (
      monitor?.attributes?.limit_error &&
      monitor?.attributes?.limit_error?.length
    ) {
      monitor?.attributes?.limit_error?.map((limit_error) => {
        errors.push(limit_error);
      });
    }
    if (
      !skipStatus(monitor?.attributes?.status) &&
      getMsgStatusFormating(monitor?.attributes?.status)
    ) {
      errors.push(monitor?.attributes?.status);
    }
    if (
      monitor?.attributes?.account_error &&
      monitor?.attributes?.account_error?.length
    ) {
      monitor?.attributes?.account_error?.map((err) => {
        accErrors.push(err);
      });
    }
    if (
      monitor?.attributes?.account_valid_error &&
      monitor?.attributes?.account_valid_error?.length
    ) {
      monitor?.attributes?.account_valid_error?.map((err) => {
        accErrors.push(err);
      });
    }
    return [[...errors], [...accErrors]];
  };
  const monitorNameCell = (monitor) => {
    let errorList = monitorErorrs(monitor);
    return (
      <>
        <Box className="monitor-text">
          <Box>
            {errorList[0]?.length || errorList[1]?.length ? (
              <>
                <Tooltip
                  title={
                    <>
                      {errorList[0]?.map((error, i) => {
                        return (
                          <Box key={"0" + i} className="worning-text">
                            {getMsgStatusFormating(error)}
                          </Box>
                        );
                      })}
                      {errorList[1]?.map((error, i) => {
                        return (
                          <Box key={"1" + i} className="worning-text">
                            <FormattedMessage
                              id="auth_err"
                              values={{
                                datasource: CheckValueLocale(
                                  error,
                                  "",
                                  {},
                                  intl,
                                ),
                              }}
                            />
                          </Box>
                        );
                      })}
                    </>
                  }
                  id="warning-tooltip-report"
                  sx={{ cursor: "default" }}
                  arrow
                  placement="bottom"
                >
                  <Box className="warning-icon-countainer">
                    <ErrorIcon
                      className="dashboard-warning-icon"
                      fontSize="small"
                    />
                  </Box>
                </Tooltip>
              </>
            ) : null}
          </Box>
          <Link to={redirectToMonitor(monitor)}>
            {showTooltipText(
              localStorage.is_lucidya_account === "1"
                ? monitor.id + " - " + monitor?.attributes?.account_name
                : monitor?.attributes?.account_name,
              18,
            )}
          </Link>
        </Box>
      </>
    );
  };

  const stateCell = (monitor) => {
    return (
      <>
        {getStatusLocalization(monitor.attributes.stream_status) ? (
          <Box className={"status " + monitor.attributes.stream_status}>
            {" "}
            {getStatusLocalization(monitor.attributes.stream_status)}
          </Box>
        ) : null}
      </>
    );
  };

  const dataSourcesCell = (monitor) => {
    return (
      <Box className="social">
        {monitor.attributes.data_sources.map((social, i) => {
          return <Box key={i}>{getSocialIcon(social)}</Box>;
        })}
      </Box>
    );
  };

  const postsUsedCell = (monitor) => {
    return (
      <Tooltip
        title={
          <Typography fontSize={11}>
            {CheckValueLocale("tooltip_posts_used", "", {}, intl)}
          </Typography>
        }
        sx={{ cursor: "default" }}
      >
        <Box className="analysis">
          {monitor?.attributes?.total_count?.toLocaleString("en-US")}
          <Box
            className={
              "present-analysis " +
              (monitor.attributes.percentage < 0 ? "decrease" : "increase")
            }
          >{` (${(monitor.attributes.percentage * 100).toFixed(2)}%)`}</Box>
        </Box>
      </Tooltip>
    );
  };

  const options = (monitor) => {
    return (
      <OptionsDropDown
        monitorItem={monitor}
        monitors={monitors}
        setSuccess={props?.setSuccess}
        setSuccessMsg={props?.setSuccessMsg}
        setErrorMsg={props?.setErrorMsg}
        getMonitors={props?.getMonitors}
        setIsSortableLoading={props?.setIsSortableLoading}
        // accountError && accountValidError --> to disable resume btn if two props have errors
        accountError={monitor?.attributes?.account_error}
        accountValidError={monitor?.attributes?.account_valid_error}
        monitorsListSnack={monitorsListSnack}
        setMonitorsListSnack={setMonitorsListSnack}
        setMonitorInfo={setMonitorInfo}
        processingIndicator={processingIndicator}
        setOpenCreateModal={setOpenCreateModal}
      />
    );
  };

  const emptyBody = (monitors) => {
    return (
      <>
        {(monitors.length == 0 && !props.searchActivated) ||
        (monitors.length == 0 &&
          props.searchActivated &&
          props.searchText == "") ? (
          <Box className="empty-item-monitor-list">
            <Box>
              <Box className="empty-item">
                <Box>
                  <NoDataFoundProducts
                    image="monitorListEmpty"
                    title="monitor_empty"
                    description="click_new_monitor"
                    linkOne="how_to_use"
                    linkOneHref={NoDataLinks.monitor_list}
                    orCase="or_visit_the"
                    linkTwo="knowledge_base"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : // Case if Company has 0 monitors and search is activated and search text is not empty
        monitors.length == 0 &&
          props.searchActivated &&
          props.searchText !== "" ? (
          <Box className="empty-item-monitor-list">
            <NoDataFoundProducts
              image="noDataImg"
              title="monitor_list_search_empty"
              subTitle="monitor_list_search_empty_submsg"
            />
          </Box>
        ) : null}
      </>
    );
  };

  useEffect(() => {
    const tabBody =
      monitors?.length == 0
        ? []
        : monitors?.map((item) => {
            return {
              id: item?.id,
              className:
                item?.attributes?.limit_error?.length ||
                (!skipStatus(item?.attributes?.status) &&
                  getMsgStatusFormating(item?.attributes?.status)) ||
                item?.attributes?.account_error?.length ||
                item?.attributes?.account_valid_error?.length
                  ? "warning"
                  : "",
              data: [
                {
                  body: monitorNameCell(item),
                  className: "monitor-name",
                },
                {
                  body: item?.attributes?.data_sources?.length
                    ? dataSourcesCell(item)
                    : "—",
                },
                {
                  body: item?.attributes?.total_count
                    ? postsUsedCell(item)
                    : "—",
                },
                {
                  body: item?.attributes?.monitor_type_name?.length
                    ? typeFormat(item?.attributes?.monitor_type_name)
                    : "—",
                },
                {
                  body: item?.attributes?.added_by?.trim()?.length
                    ? item?.attributes?.added_by
                    : "—",
                  isCreatedBy: true,
                },
                {
                  body: item?.attributes?.created_at
                    ? item?.attributes?.created_at
                    : "—",
                },
                {
                  body: item?.attributes?.stream_status?.length
                    ? stateCell(item)
                    : "—",
                },
                {
                  body: options(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(monitors));
  }, [monitors, intl?.locale]);

  return (
    <>
      {isLoading ? <CircularLoading /> : false}
      <SharedTable
        className="monitor-table"
        isSharedStyle
        tableHeader={tableHead}
        tableBody={tableBody}
        emptyBody={tableEmptyBody}
        loading={isSortableLoading}
      />
      {/* HINT : Modal to check if DEV run on production ENV  */}
      {isProduction &&
        window?.location?.host !== "testing-cxm.lucidya.com" && (
          <PopupModal
            title={CheckValueLocale("warning", "", {}, intl)}
            body={CheckValueLocale(
              "production_users_warning_msg",
              "",
              {},
              intl,
            )}
            leftBtn={CheckValueLocale("sign_out", "", {}, intl)}
            rightBtn={CheckValueLocale("continue", "", {}, intl)}
            open={isProduction}
            close={logOutProdEnv}
            accept={handleCloseCheckEnvModal}
            warning
          />
        )}

      {handlelUserRoles("GENERAL", "VIEW_SETTING") && openCreateModal ? (
        <CreateHistoricalRequest
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          setPageSnackbar={setSnackBar}
          monitorInfo={monitorInfo}
          remainNewsBlogs={remainNewsBlogs}
          remainPosts={remainPosts}
          remainInteractions={remainInteractions}
        />
      ) : null}

      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(
          snackBar?.message,
          "",
          {
            link: (
              <Link
                id="historical-request-snackbar-table-link"
                to="/settings/historical_data"
              >
                {CheckValueLocale("historical_request", "", {}, intl)}
              </Link>
            ),
          },
          intl,
        )}
        handleClose={() => setSnackBar({})}
      />

      {monitorsListSnack?.open && (
        <SnackBar
          open={monitorsListSnack?.open}
          severity={monitorsListSnack?.status}
          title={monitorsListSnack?.title}
          message={monitorsListSnack?.msg}
          autoHideDuration={7000}
          showCloseBtn={true}
          handleClose={() =>
            setMonitorsListSnack({
              open: false,
              status: "",
              title: "",
              msg: "",
            })
          }
        />
      )}
    </>
  );
};
export default MonitorList;
