import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Tooltip } from "@mui/material";
import lucidyaLogoHeader from "images/live-dashboard-images/live-dashboard-logo.svg";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  faAngleRight,
  faAngleLeft,
  faPenToSquare,
  faGlobe,
  faShareNodes,
  faEye,
} from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import Services from "services/api/Services";

const LiveDashboardHeader = ({
  applyFilter,
  startDate,
  endDate,
  lastUpdated,
  liveDashboardName,
  changeLang,
  selectedWidgets,
  redirectToMonitor,
  showEditBtn,
  setOpenShareLinkPopup,
  customLiveDashboardPublic,
  unixRangeToDateRange,
  isDashboardNotAccess,
  countViewersPublic,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = useParams();
  const backButtonIcon =
    window.localStorage.lang === "ar" ? faAngleRight : faAngleLeft;
  const isLiveDashboardPublic = window?.location?.href?.includes(
    "/live-dashboard/public/",
  );

  return (
    <Box className="live-dashboard-heading-wrapper">
      <Box className="live-dashboard-heading">
        {!isLiveDashboardPublic ? (
          <Box
            className="live-dashboard-back"
            onClick={() => redirectToMonitor(urlParams, selectedWidgets)}
          >
            <FontAwesomeIcon icon={backButtonIcon} />
          </Box>
        ) : null}
        {Services.showProjectLogoInLiveDashboard ? (
          <img src={lucidyaLogoHeader} alt="livedashboard" />
        ) : null}
        <Box>
          <Box component="p" className="live-dashboard-name">
            {liveDashboardName}
            {!isLiveDashboardPublic ? (
              <CustomizedTooltip
                title={CheckValueLocale(
                  "live_dashboard_count_viewers_tooltip",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement={"bottom"}
                styles={{ ...customizedStyledTooltip, maxWidth: "175px" }}
              >
                <Box component="spam" className="live-dashboard-count-viewers">
                  <FontAwesomeIcon icon={faEye} />
                  {countViewersPublic}
                </Box>
              </CustomizedTooltip>
            ) : null}
          </Box>
          <Box component="p" className="live-dashboard-date">
            {CheckValueLocale("this_page_refreshed", "", {}, intl)}{" "}
            {lastUpdated?.format("h:mm:ss a")}
          </Box>
        </Box>
      </Box>
      {!isDashboardNotAccess ? (
        <Box className="live-dashboard-button-wrapper">
          <LucButton
            type="primary"
            variant="flat"
            size="small"
            onClick={changeLang}
            id="lang-live-dashboard-btn"
            startIcon={<FontAwesomeIcon icon={faGlobe} />}
            className="live-dashboard-button"
          >
            {CheckValueLocale("change_lang_login", "", {}, intl)}
          </LucButton>
          {!isLiveDashboardPublic ? (
            <Tooltip
              arrow
              placement="bottom"
              title={
                <Box className="go-to-live-dashboard-tooltip">
                  {CheckValueLocale("shar_dashboard_tooltip", "", {}, intl)}
                </Box>
              }
            >
              <Box>
                <LucButton
                  type="primary"
                  variant="flat"
                  size="small"
                  onClick={() => setOpenShareLinkPopup(true)}
                  id="shar-live-dashboard-btn"
                  startIcon={<FontAwesomeIcon icon={faShareNodes} />}
                  className="live-dashboard-button"
                >
                  {CheckValueLocale("shar_link_live_dashboard", "", {}, intl)}
                </LucButton>
              </Box>
            </Tooltip>
          ) : null}

          {!isLiveDashboardPublic ? (
            <LucButton
              type="primary"
              variant="flat"
              size="small"
              onClick={() =>
                navigate(
                  `/edit-live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}/${urlParams?.userDashboardId}`,
                  { state: location.state },
                )
              }
              disabled={!showEditBtn}
              id="edit-live-dashboard-btn"
              startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
              className="live-dashboard-button"
            >
              {CheckValueLocale("edit", "", {}, intl)}
            </LucButton>
          ) : null}
          <Divider
            orientation="vertical"
            className="live-dashboard-button-divider"
          />
          <DateRangePicker
            applyFilter={applyFilter}
            startDate={startDate}
            endDate={endDate}
            openCustomDateRight={!isLiveDashboardPublic}
            datePickerPosition="live-dashboard-datepicker-position"
            id="date-range-picker-live-dashboard-center"
            monitorActiveTabId="live-dashboard"
            lastHourEnabled
            isLiveDashboard
            isLiveDashboardPublic={isLiveDashboardPublic}
            customLiveDashboardPublic={
              customLiveDashboardPublic
                ? unixRangeToDateRange(customLiveDashboardPublic)
                : ""
            }
          />
        </Box>
      ) : null}
    </Box>
  );
};
export default LiveDashboardHeader;
