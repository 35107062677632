import { Box, Typography } from "@mui/material";
import DeletedMessagePreview from "components/conversation/DeletedMessagePreview";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { ReactComponent as AttachmentPlaceholderIcon } from "images/engagements-images/attachment-placeholder.svg";
import StoryReplayPreview from "components/conversation/StoryReplayPreview";
import { TextMessage } from "./messagesPreviewers/textMessage";
import { useMemo } from "react";
import { MediaContentRenderer } from "./mediaContentRenderer";

const checkIfStoryNotEmpty = (story) => {
  try {
    if (story) {
      const storyObject = JSON.parse(story);
      if (Object.keys(storyObject).length) {
        return true;
      }
    }
  } catch (err) {
    // noop
  }
  return false;
};

export const MessageContentRenderer = ({
  message,
  isMessageDeleted,
  selectedCard,
  index,
  handleMediaClick,
  translationActive,
  translationTarget,
  translationSource,
  setStats,
  currentPlayedAudioId,
  setCurrentPlayedAudioId,
}) => {
  const intl = useIntl();

  /*
    messageContent is an object that contains the parsed message content
    {
      text: string, // the text of the message
      media: {
        media_url: string, // the src of the media
        isVideo: boolean, // a flag to indicate if the media is a video or not (used in the media previewer)
        contentType: string, // in case of Whatsapp, the content type of is mimeType of the media (application/pdf, image/jpeg, ...)
      },
      story: string, // the story object in case of instagram
      type: string, // the type of the message content (image, video, ...)
      url: string | string[], // in case of twitter, the tweet url string, in case of whatsapp, the media url string or array of strings
      payload: object, // in case of whatsapp, the payload object of the media (contact, location, ...)
    }
  */
  const messageContent = useMemo(
    () =>
      parseMessageContent(
        message?.content,
        selectedCard?.data_source?.toLowerCase(),
      ),
    [message?.content, message?.data_source],
  );

  const hasStory = checkIfStoryNotEmpty(messageContent?.story);

  // handle the case where the messgage is deleted (instagram case)
  // when the agent or the user delete a message from the instagram.com chat, the backend is handling this event
  // through the socket, and they will send an event to indicate the a message with a specified id is deleted
  // and then we should show this component, also if the agent reload this engagement the rabbitmq will this message as deleted
  if (isMessageDeleted) {
    return <DeletedMessagePreview />;
  }

  // handle temp media case and if the text exists
  // when the agent sent a message that contains media and to speed things up we will add the message to the chat messages list
  // and we will show a placeholder for the media until the socket response with the media url and then we will replace the placeholder with the media
  if (messageContent?.url_temporary) {
    return (
      <>
        <Box className="chat-message-media temp-media">
          <Box className="temp-interactions-media">
            <AttachmentPlaceholderIcon />
            <Typography className="temp-media-text" component="span">
              {CheckValueLocale("engagement_temp_media_text", "", {}, intl)}
            </Typography>
          </Box>
        </Box>
        <TextMessage
          text={messageContent?.text}
          message={message}
          selectedCard={selectedCard}
          index={index}
          translationActive={translationActive}
          translationTarget={translationTarget}
          translationSource={translationSource}
          setStats={setStats}
          showTranslationText={true}
        />
      </>
    );
  }

  // handle instagram story case
  // when the agent add a story in the instagram.com, and if one of the customer reacted to this story or replaied to it
  // The StoryReplayPreview should be rendered to handle this case.
  if (hasStory) {
    return <StoryReplayPreview data={message} isEngagement={true} />;
  }

  // handle the case when the message has a text and media
  return (
    <>
      <MediaContentRenderer
        message={messageContent}
        messageId={message?.message_id}
        handleMediaClick={handleMediaClick}
        dataSource={selectedCard?.data_source?.toLowerCase()}
        currentPlayedAudioId={currentPlayedAudioId}
        setCurrentPlayedAudioId={setCurrentPlayedAudioId}
      />
      <TextMessage
        text={messageContent?.text}
        message={message}
        selectedCard={selectedCard}
        index={index}
        translationActive={translationActive}
        translationTarget={translationTarget}
        translationSource={translationSource}
        setStats={setStats}
        showTranslationText={true}
      />
    </>
  );
};

const safeJsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

// this function aims to provide a standard object shape for twitter media content to match the other platforms
const standardizeTwitter = (media = {}, text = "") => {
  // Remove the tweet URL from the text if present
  const tweetUrl = media?.url || "";
  const cleanedText = tweetUrl ? text?.replace(` ${tweetUrl}`, "") : text;
  return { text: cleanedText };
};

const standardizeMeta = (parsedMedia) => {
  // in twitter, the media object is different from the other platforms, so we need to handle it differently
  // we need to check if the media is a video or an image, also to remap the media object to match the other platforms
  if (parsedMedia?.[0]) {
    const mediaItem = parsedMedia?.[0];
    const media_url = mediaItem?.image_data?.url || mediaItem?.video_data?.url;
    const isVideo = Boolean(mediaItem?.video_data?.url);
    return { ...mediaItem, media_url, isVideo };
  }
  return parsedMedia;
};

const standardizeWhatsapp = (payloadStr, type) => {
  const parsedPayload = safeJsonParse(payloadStr);
  if (!parsedPayload) return {};
  // if the type is contact we need to flat the contact array to be an array of objects
  if (type === "contact" && Array.isArray(parsedPayload?.contacts)) {
    const contacts = parsedPayload?.contacts;
    const flattedConacts = contacts
      ?.map?.((contact) => {
        const info = [];
        const name = contact?.name?.formatted_name;
        contact?.phones?.forEach((phone) => {
          info.push({
            name,
            phone: phone?.phone,
          });
        });
        return info;
      })
      .flat();
    parsedPayload.contacts = flattedConacts;
  }

  return parsedPayload;
};

// this function aims to provide a standard object shape for all the plaforms messages media content
const parseMessageContent = (content = {}, dataSource) => {
  let { text, media, story, type, url, payload } = content;

  const isWhatsapp = dataSource === "whatsapp";
  const isTwitter = dataSource === "twitter";
  const isMeta = dataSource === "facebook" || dataSource === "instagram";

  let parsedMedia = safeJsonParse(media) || {};
  let parsedPayload = {};

  // handle Twitter cases
  if (isTwitter) {
    const twitterMessage = standardizeTwitter(parsedMedia, text);
    text = twitterMessage?.text;
    parsedMedia.isVideo = parsedMedia?.type !== "photo";
  }

  // handle Meta cases
  if (isMeta && Array.isArray(parsedMedia) && parsedMedia[0]) {
    parsedMedia = standardizeMeta(parsedMedia);
  }

  // handle Whatsapp cases
  if (isWhatsapp && payload?.length) {
    parsedPayload = standardizeWhatsapp(payload, type);
  }

  // after we standardize the media object, we need to standardize the other properties of the message content
  const hasMediaUrl = parsedMedia?.media_url;

  // standardize the type of the media
  if (hasMediaUrl) {
    if (!isWhatsapp) {
      const isVideo =
        content?.isVideo ||
        content?.isVideo == "true" ||
        parsedMedia?.isVideo ||
        parsedMedia?.isVideo == "true";
      type = isVideo ? "video" : "image";
    } else {
      parsedMedia.isVideo = type === "video";
    }
  }

  return {
    ...content,
    text,
    media: parsedMedia,
    story,
    type,
    url,
    payload: parsedPayload,
  };
};
