import { handleDateLangFormat } from "utils/helpers";
import moment from "moment";

export const formatDate = (date, lang) => {
  const formatDate = lang === "ar" ? "DD MMMM YYYY" : "MMM DD, YYYY";

  return new Date(
    handleDateLangFormat(new Date(date), formatDate, lang),
  )?.toLocaleDateString(lang == "en" ? "en-us" : "ar", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const dateHourFormat = (date, lang, isUnix) => {
  const dateFromUnix = isUnix
    ? moment.unix(date).format("MM/DD/YYYY HH:mm:ss")
    : new Date(date);

  return `${formatDate(dateFromUnix, lang)}, ${new Date(
    handleDateLangFormat(dateFromUnix, "MM/DD/YYYY HH:mm:ss", lang),
  )?.toLocaleTimeString(lang == "en" ? "en-us" : "ar", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};
