import React, { useEffect, useState } from "react";
import { Button, Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { FilterAlt } from "@mui/icons-material";
import { SwipeableDrawerWrapper } from "../channel-analytics-filter/filter.styles.js";
import "./filter.scss";
import FilterDropDownMenu from "./components/filterDropDownMenu";
import {
  isEmptyArray,
  isEmptyObj,
  CheckValueLocale,
  classNames,
  isEmptyValue,
} from "utils/helpers/index.js";
import filterIcon from "images/engagements-images/filters-alt-icon.svg";
import LucButton from "shared/lucButton/lucButton.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-solid-svg-icons";

const Filter = (props) => {
  const intl = useIntl();
  const {
    isAudiencePage,
    monitorActiveTabFilterID,
    isQuickSearch,
    checkDataSourceSelected,
    isPublishPage,
    disabled,
    isBenchmarkPage,
    isUserActivity,
    isEngagementAnalytics,
    notShowWhatsappDatasource,
  } = props;
  const [state, setState] = useState({
    right: false,
    left: false,
  });
  const [showDeleteFilterBtn, setShowDeleteFilterBtn] = useState(false);
  const [errorMessageKeywords, setErrorMessageKeywords] = useState("");

  var filterDirection = window.localStorage.lang !== "ar" ? "right" : "left";
  // _______________________________________________________________________________________
  //1st call when filter is rendered that get all filter fields
  useEffect(() => {
    if (!props.isDashboardFilter || props?.isDashboardFilter === undefined) {
      if (!isBenchmarkPage) props?.getFiltersFileds();
    }
  }, []);

  const isActiveFilter = props?.allActiveFilters?.length > 0;
  // _______________________________________________________________________________________
  //this function has 2 actions ,
  // 1st:closes the filter SideBar
  // 2nd:send the applied filters to handleApplyFilter func.
  // or
  // 3rd: send saveAndApply prop to go throught save filter cycle
  const toggleDrawer =
    (anchor, open, applyFilter, saveAndApply, deleteFilter) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      if (applyFilter) {
        props.setAssignActiveFilters(props.filterParams);
        props.applyFilter(
          props.startDate,
          props.endDate,
          props.filterParams,
          undefined,
          true,
        ); //function created inside 'src\pages\Monitors\mainMonitorPage.js'
      }
      if (saveAndApply) {
        props.handleShowSaveAndApplyModal(props.filterParams);
        setShowDeleteFilterBtn(true);
      }
      if (deleteFilter) {
        props.setShowDeleteFilterModal(true);
      }
      if (open) {
        if (
          !isActiveFilter &&
          !isEngagementAnalytics &&
          !isBenchmarkPage &&
          !isUserActivity
        ) {
          props.handleClearFilterParams();
        }
      }
    };
  // _______________________________________________________________________________________
  // this Function is fired when user choose a saved filter from drop down menu
  const handleSavedFilter = (e) => {
    props.getSavedFilterData(e.target.value);
    props.setSavedFilterId(e.target.value);
    setShowDeleteFilterBtn(true);
  };
  // _______________________________________________________________________________________
  // this function clear selected params and remove delete btn
  const handleClearFilter = () => {
    props.handleClearFilterParams();
    !isAudiencePage &&
      !isEngagementAnalytics &&
      !isBenchmarkPage &&
      !isUserActivity &&
      props.setSavedFilterId("");
    !isAudiencePage &&
      !isEngagementAnalytics &&
      !isBenchmarkPage &&
      !isUserActivity &&
      setShowDeleteFilterBtn(false);
    setErrorMessageKeywords("");
  };
  const filtersChangesCount = calculateFiltersCount(props?.assignActiveFilters);
  return (
    <>
      <LucButton
        onClick={toggleDrawer(filterDirection, true)}
        startIcon={
          isPublishPage ? (
            <img src={filterIcon} />
          ) : (
            <FontAwesomeIcon icon={faFilter} />
          )
        }
        variant="outline"
        minWidth={isPublishPage ? 36 : 70}
        size={isPublishPage || isEngagementAnalytics ? "small" : "large"}
        type={isAudiencePage ? "primary" : "secondary"}
        disabled={disabled}
        loading={
          isPublishPage
            ? false
            : isEmptyArray(props?.filterFields) && !props.isFilteredFieldsLoaded
              ? true
              : false
        }
        id={monitorActiveTabFilterID ?? "monitor-active-Tab-filter-id"}
      >
        {isPublishPage ? null : (
          <>
            {CheckValueLocale("filters", "", {}, intl)}
            {filtersChangesCount ? (
              <Box
                component="span"
                id="active-filte-count"
                className="active-filter-change-count"
              >
                ({filtersChangesCount})
              </Box>
            ) : null}
          </>
        )}
      </LucButton>

      <SwipeableDrawerWrapper
        anchor={filterDirection}
        open={state[filterDirection]}
        onClose={toggleDrawer(filterDirection, false)}
        onOpen={toggleDrawer(filterDirection, true)}
      >
        <Box
          sx={{
            width: "300px",
            padding: "20px",
            backgroundColor: "#FCFCFC",
            paddingTop: "30px",
          }}
          className={classNames(
            isEngagementAnalytics && "engagegment-analytics-filter-container",
          )}
        >
          {/* FILTER HEADER */}
          <Grid
            container
            className="filter-header-grid"
            columnSpacing={{ xs: 1 }}
            sx={{ marginBottom: "20px", backgroundColor: "#fff" }}
          >
            <Grid item xs={6}>
              <Box className="monitor_filter__head">
                <FilterAlt />
                {CheckValueLocale("filters", "", {}, intl).toUpperCase()}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <LucButton
                onClick={toggleDrawer(filterDirection, false)}
                variant={"outline"}
                type="secondary"
                size="small"
              >
                {CheckValueLocale("hide", "", {}, intl)}
              </LucButton>
            </Grid>
          </Grid>
          <Box className="filter_line"></Box>
          {/* FILTER BODY */}
          <Box className="filterbody__wrapper">
            <FilterDropDownMenu
              {...props}
              isUserActivity={isUserActivity}
              handleSavedFilter={handleSavedFilter}
              handleDeleteBtn={toggleDrawer(
                filterDirection,
                false,
                false,
                false,
                "deleteFilter",
              )}
              showDeleteFilterBtn={showDeleteFilterBtn}
              id="filter-dropdown"
              errorMessageKeywords={errorMessageKeywords}
              setErrorMessageKeywords={setErrorMessageKeywords}
              notShowWhatsappDatasource={notShowWhatsappDatasource}
            />
          </Box>

          {/* FILTER FOOTER */}
          <Box
            className="footer__wrapper"
            style={{
              filter: "drop-shadow(0px -5px 16px rgba(0, 0, 0, 0.08))",
              zIndex: "1",
            }}
          >
            <LucButton
              variant="filled"
              size="small"
              onClick={toggleDrawer(filterDirection, false, true)}
              fullWidth={isAudiencePage}
              id={`${
                monitorActiveTabFilterID ?? "monitor-active-Tab-filter-id"
              }-apply-btn`}
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </LucButton>
            {!isAudiencePage &&
            !isBenchmarkPage &&
            !isEngagementAnalytics &&
            !isUserActivity ? (
              <LucButton
                variant="outline"
                type="secondary"
                size="small"
                onClick={toggleDrawer(filterDirection, false, false, true)}
                disabled={isEmptyObj(props.filterParams) && true}
                id={`${
                  monitorActiveTabFilterID ?? "monitor-active-Tab-filter-id"
                }-save-and-apply-btn`}
              >
                {CheckValueLocale("save_and_apply", "", {}, intl)}
              </LucButton>
            ) : null}
            <LucButton
              variant="flat"
              type="secondary"
              size="small"
              onClick={handleClearFilter}
              fullWidth={isAudiencePage}
              id={`${
                monitorActiveTabFilterID ?? "monitor-active-Tab-filter-id"
              }-clear-btn`}
            >
              {CheckValueLocale("clear", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </SwipeableDrawerWrapper>
    </>
  );
};
export default Filter;

const calculateFiltersCount = (filters) => {
  return Object.keys(filters)?.filter?.((key) => {
    return isEmptyValue(filters[key]) === false;
  })?.length;
};
