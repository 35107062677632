import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const ListSearchFilter = ({ handelTextSearch, searchText, setSearchText }) => {
  const intl = useIntl();

  return (
    <FormControl variant="outlined" className="report-list-search-field">
      <OutlinedInput
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e?.target?.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handelTextSearch}>
              <SearchIcon id="reports-new-search-icon" />
            </IconButton>
          </InputAdornment>
        }
        placeholder={CheckValueLocale("search_by_report_name", "", {}, intl)}
        id="reports-new-search-text"
      />
    </FormControl>
  );
};

export default ListSearchFilter;
