import { Box } from "@mui/material";
import { memo, useContext } from "react";
import { ReportContext } from "../../context/reportContext";
import pdfImage from "images/pdf-icon.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { exportPDF } from "./exportButton/pdf/exportPDF";
import { formatDate } from "../../../utils/formatDateUtlit";
import ExportButton from "./exportButton";
import PageHeader from "components/pageHeader";

const labelLanguage = (name, disabeled, intl) => (
  <Box className="label-language">
    {CheckValueLocale(name, "", {}, intl)}
    {disabeled ? (
      <CustomizedTooltip
        title={CheckValueLocale(
          "tooltip_change_language_disabled",
          "",
          {},
          intl,
        )}
        arrow
        placement="top"
        styles={{
          ...customizedStyledTooltip,
          maxWidth: "178px",
        }}
      >
        <FontAwesomeIcon icon={faCircleInfo} fixedWidth />
      </CustomizedTooltip>
    ) : null}
  </Box>
);

const HeaderTitleAndExport = () => {
  const intl = useIntl();
  const {
    reportData,
    activeMonitor,
    loadindExport,
    setLoadindExport,
    handleSnackBarSuccess,
    handleSnackBarError,
  } = useContext(ReportContext);
  const { articles } = activeMonitor;

  const dropDownTypes = [{ name: "pdf", imageType: pdfImage }];
  const languagesList = [
    {
      value: "english",
      disabled: intl?.locale === "ar",
      label: labelLanguage("english", intl?.locale === "ar", intl),
    },
    {
      value: "arabic",
      disabled: intl?.locale === "en",
      label: labelLanguage("arabic", intl?.locale === "en", intl),
    },
  ];

  const handleExportSnackBarError = () =>
    handleSnackBarError("something_went_wrong", "try_again_download_file");

  const handleExportSnackBarSuccess = () =>
    handleSnackBarSuccess(
      "successfully_exported",
      "successfully_exported_pdf_msg",
    );

  return (
    <Box className="header-title-and-export-newsletter-report">
      <PageHeader
        title={`${reportData?.report_name} - ${formatDate(reportData?.created_at, intl?.locale)}`}
        withBreadCrumb={true}
      />
      {articles?.length ? (
        <ExportButton
          loadingExport={loadindExport}
          dropDownTypes={dropDownTypes}
          languagesList={languagesList}
          langSelect={
            languagesList?.find((language) => !language?.disabled)?.value
          }
          handleClickExport={() =>
            exportPDF(
              articles,
              activeMonitor?.name,
              setLoadindExport,
              handleExportSnackBarSuccess,
              handleExportSnackBarError,
              true, // to show cover
              "article-card-",
            )
          }
        />
      ) : null}
    </Box>
  );
};

export default memo(HeaderTitleAndExport);
