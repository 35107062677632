import { useState } from "react";
import { Button, Grid, Box, TextField, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";
import "../survey.scss";

//Locals
import { useIntl } from "react-intl";
import { handlelUserRoles } from "utils/helpers";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { CheckValueLocale } from "utils/helpers";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { faArrowToBottom } from "@fortawesome/pro-regular-svg-icons";

const SurveyHeader = (props) => {
  const intl = useIntl();
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);
  const [searchActivated, setSearchActivated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const checkSurveyLocation = location.pathname?.includes("/survey/list");
  const [isHovered, setIsHovered] = useState(false);

  const setUrlParams = (key, value) => {
    searchParams?.set(key, value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      props?.setSearchText(props?.searchText);
      setUrlParams("q", props?.searchText);
      setUrlParams("page", 1);
      navigate({ search: searchParams.toString() });
      setSearchActivated(true);
      e.target.blur();
    }
  };

  const handleClearSearch = () => {
    props?.setSearchText("");
    setUrlParams("q", "");
    setUrlParams("page", 1);
    navigate({ search: searchParams.toString() });
  };
  const handleChangeSearch = (e) => {
    setSearchActivated(true);
    props?.setSearchText(e.target.value);
  };
  const handleSearch = () => {
    props?.setSearchText(props?.searchText);
    setUrlParams("q", props?.searchText);
    setUrlParams("page", 1);
    navigate({ search: searchParams.toString() });
  };

  const inputSearch = () => {
    return (
      <Box className="search-box-parant">
        <Box className="search-box">
          <TextField
            className="search-field"
            label={CheckValueLocale("search_by_name", "", {}, intl)}
            variant="outlined"
            error={props?.emptySearch}
            value={props?.searchText}
            helperText={
              props?.emptySearch
                ? CheckValueLocale("survey_search_error_msg", "", {}, intl)
                : null
            }
            onChange={(e) => handleChangeSearch(e)}
            onBlur={() => setSearchBoxClicked(false)}
            onFocus={() => setSearchBoxClicked(true)}
            onKeyPress={handleEnterKeyPress}
          />
          <Box
            className={
              "search-icon-container " +
              (props?.emptySearch
                ? "empty-error"
                : searchBoxClicked
                  ? "search-icon-container-blue"
                  : "")
            }
            onClick={handleSearch}
          >
            <FontAwesomeIcon className="search-icon" icon={faSearch} />
          </Box>
        </Box>
        {props?.searchText ? (
          <Button id="clear-search" onClick={() => handleClearSearch()}>
            {CheckValueLocale("clear_search", "", {}, intl)}
          </Button>
        ) : null}
      </Box>
    );
  };
  return (
    <Box className="title-header-box survey-main-title" id="SurveyHeader">
      {/* Title */}
      <Box>
        {/* BreadCrumb ____________________________________________________ */}
        {props?.showBreadCrumb && (
          <BreadCrumb id="breadcrumb-style" links={props?.links} />
        )}
        {/* _______________________________________________________________ */}
        <PageHeader title={props?.title} withBreadCrumb={true} />
        {checkSurveyLocation && props?.surveyData?.length > 0
          ? inputSearch()
          : null}
        {/* _______________________________________________________________ */}
      </Box>

      {/* Action Btn */}
      {props?.showBtn && handlelUserRoles(props?.type, props?.role) && (
        <Box className="survey-btn" id="title-button-box">
          <span
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Tooltip
              title={CheckValueLocale("max_survey_count", "", {}, intl)}
              placement="bottom"
              arrow
              id="survey-max-btn-tooltip"
              open={props?.stats?.surveyLimitReach && isHovered}
            >
              <LucButton
                startIcon={props?.showActionIcon && <Add />}
                onClick={props?.handleAction}
                loading={props?.isLoading}
                disabled={props?.stats?.surveyLimitReach}
              >
                {CheckValueLocale(props?.action, "", {}, intl)}
              </LucButton>
            </Tooltip>
          </span>
        </Box>
      )}
      {/* _______________________________________________________________ */}

      {/* Date Picker */}
      {props?.showDatePicker && (
        <Grid item xs={3} className="survey-insights-datepicker-parant">
          <Box className="survey-insights-datepicker">
            <DateRangePicker
              applyFilter={props?.applyFilter}
              startDate={props?.startDate}
              endDate={props?.endDate}
              openCustomDateLeft={true}
              datePickerPosition="survey-insights-datepicker-position"
              allTimeEnabled
              allTimeStartDate={props?.allTimeStartDate}
              id="date-range-picker-survey-insights"
              monitorActiveTabId="survey-insights"
            />
            {handlelUserRoles("SURVEY", "EXPORT_SURVEY") &&
            props?.surveyStatus !== "deleted" ? (
              <LucButton
                variant="outline"
                type="secondary"
                size="large"
                className="drop-down-btn-servey"
                onClick={props?.handleApplyExport}
                id={"survey-cards-dropdown-export-as-excel"}
                startIcon={<FontAwesomeIcon icon={faArrowToBottom} />}
              >
                {CheckValueLocale("export_as_excel", "", {}, intl)}
              </LucButton>
            ) : null}
          </Box>
        </Grid>
      )}
    </Box>
  );
};
export default SurveyHeader;
