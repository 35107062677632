import Services from "./Services";

var Engagements = {};

Engagements.getEngagementsData = (queryData, cancelToken) => {
  return Services.getData(
    "api/v4/engagements/data",
    queryData,
    undefined,
    cancelToken,
  );
};

Engagements.getSavedMonitors = (queryData) => {
  return Services.getData("api/v4/engagements/saved_monitors", queryData);
};

Engagements.getSingleInteraction = (queryData, cancelToken) => {
  return Services.getData(
    "api/v4/engagements/single/interaction",
    queryData,
    undefined,
    cancelToken,
  );
};

Engagements.getInteractionDmHistory = (queryData) => {
  return Services.getData("api/v4/engagements/dm_history", queryData);
};

Engagements.getSingleUserProfileData = (queryData) => {
  return Services.getData("api/v4/engagements/profile_data", queryData);
};

Engagements.editInteractions = (queryData) => {
  return Services.putData(`api/v4/engagements/edit_interactions`, queryData);
};

Engagements.putSavedMonitors = (queryData, product_id) => {
  return Services.putData(
    `api/v4/engagements/saved_monitors?product_id=${product_id}`,
    queryData,
  );
};

Engagements.getMonitorsAndChannels = (queryData) => {
  return Services.getData("api/v4/engagements/company_monitors", queryData);
};

Engagements.getCompletionReasons = (queryData) => {
  return Services.getData(
    "api/v4/engagements/completion_reason_tooltips",
    queryData,
  );
};

Engagements.enabledFilters = (queryData) => {
  return Services.getData("api/v4/engagements/enabled_filters", queryData);
};
Engagements.updateStatus = (queryData) => {
  return Services.putData(`api/v4/engagements/update_status`, queryData);
};
Engagements.getSingleProfileData = (queryData) => {
  return Services.getData(`api/v4/engagements/profile_data`, queryData);
};
Engagements.getCalendarData = (queryData) => {
  return Services.getData("api/v4/engagements/scheduled_posts", queryData);
};

//publish
Engagements.getPublishMediaValidations = (queryData) => {
  return Services.getData(
    "api/v4/engagements/scheduled_posts/validations",
    queryData,
  );
};
Engagements.uploadFile = (queryData) => {
  return Services.postData(
    `/api/v4/engagements/upload/company_info`,
    queryData,
  );
};

Engagements.createPostPublish = (queryData) => {
  return Services.postData("api/v4/engagements/scheduled_posts", queryData);
};

Engagements.deleteEngagementPost = (
  id,
  dataSourceId,
  monitorId,
  item_type,
  product_id,
  parent_id,
) => {
  return Services.deleteData(
    `api/v4/engagements/published_posts/${id}?data_source_id=${dataSourceId}&monitor_model_id=${monitorId}&item_type=${item_type}&product_id=${product_id}${parent_id ? `&parent_id=${parent_id}` : ""}`,
  );
};
Engagements.getAiReplaies = (queryData) => {
  return Services.getData("api/v4/engagements/generate_responses", queryData);
};

Engagements.editUserInfo = (queryData) => {
  return Services.putData(`api/v4/engagements/settings`, queryData);
};

Engagements.getLookupMessages = () => {
  return Services.getData("api/v4/lookup_messages");
};

Engagements.getUserInfo = (queryData) => {
  return Services.getData(`api/v4/engagements/settings`, queryData);
};

Engagements.enabledFiltersPublish = (queryData) => {
  return Services.getData(
    "api/v4/engagements/scheduled_posts/enabled_filters",
    queryData,
  );
};

Engagements.getAiSuggestions = (queryData) => {
  return Services.postData(
    "api/v4/engagements/get_custom_responses",
    queryData,
  );
};
Engagements.getAiSavedResponses = (queryData) => {
  return Services.postData("api/v4/engagements/get_saved_responses", queryData);
};
Engagements.deleteReply = (queryData) => {
  return Services.deleteData(
    `api/v4/engagements/saved_replies/${queryData?.id}`,
    queryData,
  );
};
Engagements.getSavedLanguage = (queryData) => {
  return Services.getData("api/v4/user/get_translation_lang", queryData);
};
Engagements.saveTranslationLanguage = (queryData) => {
  return Services.postData("api/v4/user/save_translation_lang", queryData);
};
Engagements.detectTranslate = (queryData) => {
  return Services.getData("api/v4/engagements/detect_translate", queryData);
};
Engagements.addRate = (queryData) => {
  return Services.postData(
    "api/v4/engagements/save_rating_translation",
    queryData,
  );
};
//get upcoming data
Engagements.getUpcomingData = (queryData) => {
  return Services.getData("api/v4/engagements/scheduled_posts", queryData);
};

Engagements.showDetailsPublish = (id, queryData) => {
  return Services.getData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};

Engagements.EditScheduledPost = (queryData, id) => {
  return Services.putData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};

Engagements.editPostPublished = (queryData) => {
  return Services.postData("api/v4/engagements/published_posts", queryData);
};
Engagements.deleteScheduledPost = (id, queryData) => {
  return Services.deleteData(
    `api/v4/engagements/scheduled_posts/${id}`,
    queryData,
  );
};
Engagements.deletePublishedPost = (queryData) => {
  return Services.deleteData(
    `api/v4/engagements/published_posts/${queryData?.id}?data_source_id=${queryData?.data_source_id}&reference_id=${queryData?.reference_id}&product_id=${queryData?.product_id}`,
  );
};

Engagements.editReply = (queryData) => {
  return Services.postData("api/v4/engagements/published_posts", queryData);
};

// New Conversation Message [ DM ]
Engagements.getAllDMAuthrizedAccounts = (queryData) => {
  return Services.getData(
    "api/v4/engagements/dm_authorized_accounts",
    queryData,
  );
};
Engagements.getDMMediaValidations = (product_id) => {
  return Services.getData(
    `api/v4/engagements/validations?product_id=${product_id}&type=direct_message`,
  );
};
Engagements.getSenderUserList = (
  product_id,
  data_source_name,
  page_number,
  query,
) => {
  return Services.getData(
    `api/v4/engagements/users_list?product_id=${product_id}&data_source_name=${data_source_name}&page_number=${page_number}&query=${query}`,
  );
};

Engagements.getLastUsedAccount = (queryData) => {
  return Services.getData(`api/v4/engagements/last_used_account`, queryData);
};
Engagements.getEngagementsAnalyticsCredentials = (queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/private_data`,
    queryData,
  );
};
Engagements.CheckEngageMentAnalytics = (queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/check_engagement_analytics`,
    queryData,
  );
};
Engagements.updateUserAgentStatus = (queryData) => {
  return Services.postData(`api/v4/user/agent_info`, queryData);
};
Engagements.getEnagementConditions = (queryData) => {
  return Services.getData(`api/v4/engagements/conditions`, queryData);
};
Engagements.checkNameExistence = (queryData) => {
  return Services.getData(
    `/api/v4/engagements/inbound_routings/check_name_existence`,
    queryData,
  );
};
Engagements.getRoutingAlgorithms = (queryData) => {
  return Services.getData(
    `api/v4/engagements/inbound_routings/auto_assign_algorithms`,
    queryData,
  );
};
Engagements.getCompanySLAs = (queryData) => {
  return Services.getData(`api/v4/engagements/slas`, queryData);
};
Engagements.createInboundRouting = (queryData) => {
  return Services.postData(`api/v4/engagements/inbound_routings`, queryData);
};
Engagements.getAgentCapacities = (queryData) => {
  return Services.getData(`api/v4/engagements/agent_capacities`, queryData);
};
Engagements.updateAgentCapacities = (queryData) => {
  return Services.postData(`api/v4/engagements/agent_capacities`, queryData);
};
Engagements.createSLA = (queryData) => {
  return Services.postData(`api/v4/engagements/slas`, queryData);
};
Engagements.getInboundRoutings = (queryData) => {
  return Services.getData(`api/v4/engagements/inbound_routings`, queryData);
};

Engagements.deleteInboundRouting = (id) => {
  return Services.deleteData(`api/v4/engagements/inbound_routings/${id}`);
};
Engagements.updateRoutingPriority = (id, queryData) => {
  return Services.putData(
    `api/v4/engagements/inbound_routings/${id}/priority`,
    queryData,
  );
};

Engagements.getInboundRouting = (id) => {
  return Services.getData(`api/v4/engagements/inbound_routings/${id}`);
};
Engagements.updateInboundRouting = (id, queryData) => {
  return Services.putData(
    `api/v4/engagements/inbound_routings/${id}`,
    queryData,
  );
};

Engagements.getUsers = (queryData) => {
  return Services.getData("api/v4/engagements/informal/users/", queryData);
};

Engagements.getTeamUsers = (queryData) => {
  return Services.getData("api/v4/engagements/informal/team_users/", queryData);
};

Engagements.assignTeamsAndUsers = (queryData) => {
  return Services.postData("api/v4/engagements/manual_assign", queryData);
};
Engagements.getEngagementAnalyticsData = (tabName, queryData) => {
  return Services.getData(
    `api/v4/engagements/analytics/v2/${tabName}`,
    queryData,
  );
};
Engagements.getKnowledgeBase = (queryData) => {
  return Services.getData("api/v4/engagements/knowledge_base", queryData);
};
export default Engagements;
