import { Box } from "@mui/material";
import AiAgentsFormHeader from "./aiAgentsFormHeader/aiAgentsFormHeader";
import AiAgentsFormContent from "./aiAgentsFormContent/aiAgentsFormContent";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import AiAgentsFormFooter from "./aiAgentsFormFooter/aiAgentsFormFooter";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { PublishValidationSchema } from "../../aiAgentsValidation/publishValidationSchema";
import { useEditAiAgent } from "../../aiAgentsHooks/useEditAiAgent";
import { AiAgentsContext } from "../../aiAgentsContext";
import { usePublishAiAgent } from "../../aiAgentsHooks/usePublishAiAgent";
import PublishAiAgentModal from "./aiAgentsFormHeader/aiAgentModal/publishAiAgentModal";
import { useIntl } from "react-intl";
import AiAgentPublishedVersionHeader from "./aiAgentsFormHeader/aiAgentPublishedVersionHeader";
import { useResumePublish } from "../../aiAgentsHooks/useResumePublish";

const AiAgentsForm = ({ aiAgentDetails }) => {
  const intl = useIntl();
  const {
    setAiAgentSnackBar,
    selectedAiAgentPublishedVersion,
    setSelectedAiAgentPublishedVersion,
    aiAgentCurrentVersion,
    resetForm,
    aiAgentSelected,
  } = useContext(AiAgentsContext);
  const [aiAgentEditData, setAiAgentEditData] = useState({});
  const [openPopupPublish, setOpenPopupPublish] = useState(false);
  const isCurrentPublishedVersion =
    selectedAiAgentPublishedVersion === aiAgentCurrentVersion;

  const getAiAgentsValues = (dataDetails) => {
    return {
      is_edit: false,
      name: dataDetails?.name || "",
      enable_agent_nickname: dataDetails?.enable_agent_nickname || false,
      agent_nickname: dataDetails?.agent_nickname || "",
      ai_agent_image: dataDetails?.ai_agent_image || "",
      engagement_type: dataDetails?.engagement_type || "",

      business_industry: Array.isArray(dataDetails?.business_industry)
        ? dataDetails?.business_industry
        : [],
      business_type: Array.isArray(dataDetails?.business_type)
        ? dataDetails?.business_type
        : [],
      business_hq: dataDetails?.business_hq || "",
      business_brand_of_tone_voice:
        dataDetails?.business_brand_of_tone_voice || "",
      business_additional_details:
        dataDetails?.business_additional_details || "",
      ai_agent_instruction: dataDetails?.ai_agent_instruction || "",

      auto_assignment: dataDetails?.auto_assignment || "",
      allow_routing_from_ai_to_human:
        dataDetails?.allow_routing_from_ai_to_human || false,
      auto_reply_transfer: dataDetails?.auto_reply_transfer || "",
      inbound_routing_id: dataDetails?.inbound_routing_id || null,
      auto_assign_algorithm_id: dataDetails?.auto_assign_algorithm_id || "",
      team_id: dataDetails?.team_id || null,

      conversation_end_time: dataDetails?.conversation_end_time || "",
      survey_id: dataDetails?.survey_id || null,
      auto_reply: dataDetails?.auto_reply || "",

      resources: dataDetails?.resources_validation || "empty",
      gender: dataDetails?.gender || "",
      nationality: dataDetails?.nationality || "",
      department: dataDetails?.department || "",
      dialect: dataDetails?.dialect || "",
      response_length: dataDetails?.response_length || "",
      response_style: dataDetails?.response_style || "",
      emoji_usage: dataDetails?.emoji_usage || "",
      seasonal_greetings: dataDetails?.seasonal_greetings || false,
      seasonal_event_name: dataDetails?.seasonal_event_name || "",
      event_start_date: dataDetails?.event_start_date || "",
      event_end_date: dataDetails?.event_end_date || "",
      event_greeting_message: dataDetails?.event_greeting_message || "",
      grouping_threshold_time: dataDetails?.grouping_threshold_time || "",
    };
  };

  const isAiAgentEditData = !isEmptyObj(aiAgentEditData);
  const isInboundRouting =
    aiAgentDetails?.auto_assignment === "inbound_routing";
  const isUnassigned = aiAgentDetails?.auto_assignment === "unassigned";
  const isInboundRoutingUsed =
    isInboundRouting && aiAgentDetails?.inbound_routing_used?.status;
  const isUnassignedUsed =
    isUnassigned && aiAgentDetails?.unassigned_used?.status;

  const aiAgentSubmitForm = () => {
    isAiAgentEditData ? editAiAgentHandler() : publishAiAgentHandler();
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: getAiAgentsValues(aiAgentDetails),
    validationSchema: PublishValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: aiAgentSubmitForm,
  });

  if (formik?.errors && Object.keys(formik?.errors || {}).length > 0) {
    const errorElement = document.querySelector(".Mui-error");
    if (errorElement) {
      errorElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  const { mutate: editAiAgent, isPending: editAiAgentLoading } =
    useEditAiAgent();

  const { mutate: publishAiAgent, isPending: publishAiAgentLoading } =
    usePublishAiAgent();

  const { mutate: resumePublish, isPending: resumePublishLoading } =
    useResumePublish();

  const editAiAgentHandler = () => {
    editAiAgent(
      { id: aiAgentDetails?.id, queryData: aiAgentEditData },
      {
        onSuccess: () => {
          handleSnackbar("edit");
        },
        onError: handleErrorSnackbar,
      },
    );
  };

  const publishOnSuccessHandlers = () => {
    setOpenPopupPublish(null);
    handleSnackbar("publish");
    setSelectedAiAgentPublishedVersion(null);
  };

  const publishAiAgentHandler = () => {
    if (isInboundRoutingUsed || isUnassignedUsed) {
      setOpenPopupPublish(true);
    } else if (
      aiAgentDetails?.status === "paused" &&
      aiAgentDetails?.valid_for_publish
    ) {
      resumePublish(
        { id: aiAgentDetails?.id },
        {
          onSuccess: publishOnSuccessHandlers,
          onError: handleErrorSnackbar,
        },
      );
    } else {
      publishAiAgentAction();
    }
  };

  const publishAiAgentAction = () => {
    publishAiAgent(aiAgentDetails?.id, {
      onSuccess: publishOnSuccessHandlers,
      onError: handleErrorSnackbar,
    });
  };

  const handleErrorSnackbar = () => {
    setAiAgentSnackBar({
      success: false,
      title: CheckValueLocale("failed_error_message", "", {}, intl),
      message: CheckValueLocale("try_again_error_message", "", {}, intl),
    });
  };

  const handleSnackbar = (type) => {
    if (type === "edit") {
      setAiAgentEditData({});
    }
    formik?.setFieldValue("is_edit", false);
    setAiAgentSnackBar({
      success: true,
      message: CheckValueLocale(
        type === "edit"
          ? "ai_agent_edit_success_message"
          : "publishing_AI_agent_take_few_minutes",
        "",
        { name: aiAgentDetails?.name },
        intl,
      ),
    });
  };
  const aiAgentshandleChange = (e, luciObj) => {
    const { name, value } = e?.target;
    formik?.setFieldValue("is_edit", true);
    formik?.setErrors({});
    formik?.setFieldValue(name, value);
    if (luciObj?.isEditObj) {
      luciEditDatahandleChange(luciObj);
    } else {
      aiAgentsEditDatahandleChange(name, value);
    }
  };

  const luciEditDatahandleChange = (luciObj) => {
    let luciObjEdit = null;
    Object.keys(luciObj || {})?.map((item) =>
      item != "isEditObj"
        ? (luciObjEdit = { ...luciObjEdit, [item]: luciObj[item] })
        : (luciObjEdit = { ...luciObjEdit }),
    );
    setAiAgentEditData({
      ...aiAgentEditData,
      ...luciObjEdit,
    });
  };

  const aiAgentsEditDatahandleChange = (name, value) => {
    setAiAgentEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const customHandleChange = (name, value, luciObj) => {
    const e = {
      target: {
        name,
        value,
      },
    };
    aiAgentshandleChange(e, luciObj);
  };

  useEffect(() => {
    formik.setFieldValue("team_id", aiAgentDetails?.team_id || null);
    formik.setFieldValue("survey_id", aiAgentDetails?.survey_id || null);
    formik.setFieldValue(
      "inbound_routing_id",
      aiAgentDetails?.inbound_routing_id || null,
    );
  }, [
    aiAgentSelected,
    selectedAiAgentPublishedVersion,
    aiAgentDetails?.team_id,
    aiAgentDetails?.survey_id,
    aiAgentDetails?.inbound_routing_id,
  ]);

  useEffect(() => {
    if (aiAgentDetails) {
      formik?.setFieldValue("resources", aiAgentDetails?.resources_validation);
    }
  }, [aiAgentDetails]);

  useEffect(() => {
    formik?.setValues(getAiAgentsValues(aiAgentDetails));
    formik?.setErrors({});
  }, [resetForm, aiAgentSelected, selectedAiAgentPublishedVersion]);

  const isEditPublishedVersionShown =
    !isCurrentPublishedVersion && !!selectedAiAgentPublishedVersion;

  return (
    <Box className="ai-agents-form-container">
      {isEditPublishedVersionShown ? (
        <AiAgentPublishedVersionHeader aiAgentDetails={aiAgentDetails} />
      ) : (
        <AiAgentsFormHeader
          formik={formik}
          aiAgentDetails={aiAgentDetails}
          isAiAgentEditData={isAiAgentEditData}
          publishAiAgentLoading={publishAiAgentLoading || resumePublishLoading}
        />
      )}
      <AiAgentsFormContent
        isEditPublishedVersionShown={isEditPublishedVersionShown}
        formik={formik}
        aiAgentshandleChange={aiAgentshandleChange}
        customHandleChange={customHandleChange}
        aiAgentDetails={aiAgentDetails}
      />
      <PublishAiAgentModal
        aiAgentDetails={aiAgentDetails}
        openPopupPublish={openPopupPublish}
        setOpenPopupPublish={setOpenPopupPublish}
        publishAiAgentLoading={publishAiAgentLoading || resumePublishLoading}
        type={isInboundRoutingUsed ? "inboundRoutingUsed" : "unassignedUsed"}
        accept={publishAiAgentAction}
      />
      {isAiAgentEditData ? (
        <AiAgentsFormFooter
          formik={formik}
          setAiAgentEditData={setAiAgentEditData}
          editAiAgentLoading={editAiAgentLoading}
        />
      ) : null}
    </Box>
  );
};

export default AiAgentsForm;
