import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import MonitorsController from "services/controllers/monitorsController";
import MonitorList, {
  getMonitorListTableHeader,
} from "./components/monitorList/monitorList";
import CircularLoading from "components/circularProgress";
import MonitorsListHeader from "../components/monitorListHeader/monitorsListHeader";
import { Box } from "@mui/system";
import Snackbar from "components/snackBar";
import { useIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import "./monitorSocial.scss";
import { CheckValueLocale } from "utils/helpers";
import { useDispatch } from "react-redux";
import { clear } from "utils/redux/features/SocialListening/socialListeningSlice.js";
import { useSortableTable } from "hooks/useSortableTable";
import BenchmarkController from "services/controllers/benchmarkController";
import { CustomPagination } from "components/CustomPagination/CustomPagination";
import NotFound from "pages/not-found/NotFoundPage";

const MonitorListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const pageUrlParam = searchParams?.get("page");
  const sortUrlParam = searchParams?.get("sort_by");
  const sortOrderUrlParam = searchParams?.get("order_by");
  const searchUrlParam = searchParams?.get("q");
  const needsAttentionData = location?.state?.needsAttentionData;

  const [monitors, setMonitors] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchText, setSearchText] = useState(searchUrlParam || "");
  const [pagination, setPagination] = useState(1);
  const [isBenchmarkPolicy, setIsBenchmarkPolicy] = useState(false);
  const isLucidyaAccount = window.localStorage.is_lucidya_account;
  const [companyCanCreateMonitor, setCompanyCanCreateMonitor] = useState(false);
  const [monitorSnackBar, setMonitorSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [isInvalidURL, setIsInvalidURL] = useState(false);
  const handleCloseMonitorSnackbar = () => {
    setMonitorSnackBar({
      openSnackBar: false,
    });
  };

  const setUrlParams = (key, value) => {
    searchParams?.set(key, value);
  };

  const onSortChangeCallback = (newSortColumn, newSortDirection) => {
    setIsSortableLoading(true);
    setUrlParams("page", 1);
    setUrlParams("sort_by", newSortColumn);
    setUrlParams("order_by", newSortDirection);
    navigate({ search: searchParams.toString(), needsAttentionData });
  };

  const handlePageChange = (event, value) => {
    setUrlParams("page", value);
    navigate({ search: searchParams.toString() });
  };

  const {
    columns: tableHead,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getMonitorListTableHeader(intl),
    onSortChangeCallback,
    defaultSelectedColumn: sortUrlParam || "created_at",
    defaultDirection: sortOrderUrlParam || "desc",
  });

  useEffect(() => {
    let language = window.localStorage.lang;
    var arabicAlphabetDigits =
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;

    if (location?.state?.monitorName) {
      setSuccess(true);
      setSuccessMsg(
        language !== "ar"
          ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
          : arabicAlphabetDigits?.test(location?.state?.monitorName)
            ? CheckValueLocale("your_new_monitor", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("was_created_successfully", "", {}, intl)
            : CheckValueLocale("was_created_successfully", "", {}, intl) +
              " " +
              `${location?.state?.monitorName}` +
              " " +
              CheckValueLocale("your_new_monitor", "", {}, intl),
      );

      setTimeout(() => {
        setSuccess(null);
        setSuccessMsg("");
      }, 10000);
    } else if (location?.state?.fail) {
      setSuccess(false);
      setErrorMsg(CheckValueLocale(location?.state?.fail, "", {}, intl));
      setTimeout(() => {
        setSuccess(null);
        setErrorMsg("");
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    setSearchText(searchUrlParam || "");
    if (needsAttentionData?.redirected_monitor) {
      getMonitors(needsAttentionData?.redirected_monitor);
    } else getMonitors();
  }, [location?.search, needsAttentionData]);

  useEffect(() => {
    if (
      localStorage.edit_monitor === "true" &&
      window.location.pathname === "/social/monitor_list"
    ) {
      setSuccess(true);
      setSuccessMsg("monitor_edited");
      setTimeout(() => {
        setSuccess(null);
        window.localStorage.setItem("edit_monitor", false);
      }, 10000);
    }
  }, [location]);

  useEffect(() => {
    if (isLucidyaAccount === "1") {
      getCompanyList();
    }
    dispatch(clear());
  }, []);
  const getMonitors = (redirectedMonitor) => {
    setIsSortableLoading(true);
    MonitorsController.monitors(
      window.localStorage.getItem("sm_id"),
      searchUrlParam || "",
      pageUrlParam || 1,
      sortUrlParam || "created_at",
      sortOrderUrlParam || "desc",
      redirectedMonitor,
    ).then((res) => {
      if (res?.errorMsg) {
        if (
          res?.errorMsg?.response?.data?.exception?.message ==
            "WRONG_PAGE_PARAMS" ||
          "INVALID_SORTING_KEY"
        ) {
          setIsInvalidURL(true);
        }
        setMonitors([]);
        setPagination(1);
        setMonitorSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        setMonitors(res?.data);
        setPagination(res?.pagination);
        setCompanyCanCreateMonitor(res?.companyCanCreateMonitor);
      }
      setIsSortableLoading(false);
    });
  };

  const getCompanyList = () => {
    MonitorsController.getCompanyList().then((res) => {
      setCompanies(res.data);
    });
  };
  // ________________________________________________________________
  //This part is related to Edit monitor settings  Page in case of twitter monitor if user selected (save and compute)
  //user gets directed to monitor list page and Success snack bar appears
  const [showMonitorRecomputedSnackBar, setShowMonitorRecomputedSnackBar] =
    useState(false);
  const handleCloseMonitorRecomputedSnackBar = () => {
    setShowMonitorRecomputedSnackBar(false);
    window.localStorage.setItem("monitorRecomputed", false);
  };
  useEffect(() => {
    setShowMonitorRecomputedSnackBar(window.localStorage.monitorRecomputed);
    // }
  }, [window.localStorage.monitorRecomputed]);
  // ________________________________________________________________

  //benchmark policy popup
  const getBenchmarkPolicy = () => {
    BenchmarkController.getApplyPolicy().then((result) => {
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == "benchmark_v4") {
            setIsBenchmarkPolicy(false);
          }
        });
      } else {
        setIsBenchmarkPolicy(true);
      }
    });
  };

  useEffect(() => {
    getBenchmarkPolicy();
  }, []);

  return isInvalidURL ? (
    <NotFound />
  ) : (
    <Container maxWidth="xl">
      <Box>
        <MonitorsListHeader
          totalMonitors={pagination?.count}
          monitors={monitors}
          getMonitors={getMonitors}
          searchText={searchText}
          setSearchActivated={setSearchActivated}
          setSearchText={setSearchText}
          companyCanCreateMonitor={companyCanCreateMonitor}
          isSortableLoading={isSortableLoading}
          searchUrlParam={searchUrlParam}
        />
        <MonitorList
          page={pageUrlParam || 1}
          monitors={monitors}
          setSuccess={setSuccess}
          setSuccessMsg={setSuccessMsg}
          setErrorMsg={setErrorMsg}
          getMonitors={getMonitors}
          setIsSortableLoading={setIsSortableLoading}
          searchActivated={searchActivated}
          searchText={searchText}
          tableHead={tableHead}
          isSortableLoading={isSortableLoading}
          setIsBenchmarkPolicy={setIsBenchmarkPolicy}
          isBenchmarkPolicy={isBenchmarkPolicy}
        />
        {pagination?.pages > 1 && !isSortableLoading ? (
          <CustomPagination
            onChange={handlePageChange}
            page={pageUrlParam || 1}
            pagination={pagination}
          />
        ) : (
          <div className="pagination-one-page"></div>
        )}
      </Box>
      {success != null ? (
        <Snackbar
          lang={window.localStorage.lang}
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={success ? successMsg : errorMsg}
          alertStyle={
            !companyCanCreateMonitor
              ? { width: "100%", marginTop: "85px" }
              : { width: "100%" }
          }
        />
      ) : (
        ""
      )}
      {showMonitorRecomputedSnackBar == "true" && (
        <Snackbar
          open={showMonitorRecomputedSnackBar}
          handleClose={handleCloseMonitorRecomputedSnackBar}
          severity={"success"}
          message={CheckValueLocale(
            "save_monitor_settings_compute",
            "",
            {},
            intl,
          )}
        />
      )}
      <Snackbar
        open={monitorSnackBar?.openSnackBar}
        severity={monitorSnackBar?.severity}
        message={CheckValueLocale(monitorSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(monitorSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseMonitorSnackbar}
      />
    </Container>
  );
};

export default MonitorListPage;
