import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { useIntl } from "react-intl";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import PieChartWidget from "components/widgets/pieChartWidget";
import QuestionCard from "../questionCard";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import {
  translateLocalsFromRabbitMQ,
  CheckValueLocale,
} from "../../../../utils/helpers/index";
import { handleDates } from "utils/helpers/index";

const KeywordsHashtagModal = (props) => {
  const intl = useIntl();
  let { response, inspectorName, quickInspectKeywordMonitor } = props;
  //handle response

  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsOverTime, setCommentsOvertime] = useState([]);
  const [maxInteractions, setMaxInteractions] = useState(0);
  const [totalInteractions, setTotalInteractions] = useState(0);
  const [maxInteractionsDate, setMaxInteractionsDate] = useState("");
  const [avgInteractions, setAvgInteractions] = useState(0);
  // _____________________________________________

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  //______________________________________________
  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  //_____________________________________________
  const [topComments, setTopComments] = useState([]);
  const [topCommentsPreLoader, setTopCommentsPreLoader] = useState([]);
  // _____________________________________________

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  //handle response with useEffect (when load page & any update)
  const handleResponseFunc = async () => {
    if (props.open) {
      if (
        response.eventName === "Twitter__QuickInspectGeneralPage__top_languages"
      ) {
        setTopLanguages(
          translateLocalsFromRabbitMQ(
            "Twitter__QuickInspectGeneralPage__top_languages",
            response.eventData.top_languages,
          ),
        );
        !topLanguagesPreLoader.includes(response.monitor_id) &&
          setTopLanguagesPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName ===
          "Twitter__QuickInspectGeneralPage__posts_interactions" &&
        response.eventData?.comments_interactions?.interactions_over_time
      ) {
        setCommentsInteractions(
          response.eventData.comments_interactions.interactions_over_time,
        );
        setCommentsOvertime(response.eventData.overtime_count);
        setTotalInteractions(
          response?.eventData?.comments_interactions?.total_interaction,
        );
        setAvgInteractions(response?.eventData?.average_interactions);
        setMaxInteractions(
          response?.eventData?.comments_interactions?.max_interaction,
        );
        handleDates(
          response?.eventData?.comments_interactions?.max_interaction_date,
          setMaxInteractionsDate,
        );
        !commentsInteractionsPreLoader.includes(response.monitor_id) &&
          setCommentsInteractionsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName ===
        "Twitter__QuickInspectGeneralPage__sentiment_analysis"
      ) {
        setSentimentAnalysis(response.eventData.sentiment_piechart);
        !sentimentAnalysisPreLoader.includes(response.monitor_id) &&
          setSentimentAnalysisPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName === "Twitter__QuickInspectGeneralPage__top_keywords"
      ) {
        setTopKeywords(response.eventData.top_keywords);
        !topKeywordsPreLoader.includes(response.monitor_id) &&
          setTopKeywordsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName === "Twitter__QuickInspectGeneralPage__top_hashtags"
      ) {
        setTopHashtags(response.eventData.top_hashtags);
        !topHashtagsPreLoader.includes(response.monitor_id) &&
          setTopHashtagsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else if (
        response.eventName ===
        "Twitter__QuickInspectGeneralPage__top_posts_comments"
      ) {
        setTopComments(response.eventData.top_posts);
        !topCommentsPreLoader.includes(response.monitor_id) &&
          setTopCommentsPreLoader((oldArray) => [
            ...oldArray,
            response.monitor_id,
          ]);
      } else {
        return null;
      }
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);
  // _____________________________________________

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (!props.open) {
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setCommentsOvertime([]);
      setMaxInteractions(0);
      setTotalInteractions(0);
      setMaxInteractionsDate("");
      setAvgInteractions(0);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);
      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setTopComments([]);
      setTopCommentsPreLoader([]);
    }
  }, [props.open]);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalInteractions,
    },
    {
      name: "max_interaction",
      value: maxInteractions,
      date: maxInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgInteractions,
    },
  ];

  return (
    <>
      <Box mt={3}>
        <StackedBarWidget
          title={
            quickInspectKeywordMonitor
              ? "posts_interactions"
              : "comments_interactions"
          }
          showDownloadIcon
          bigImg
          showScales
          showChartDetailsWidget
          isInteractionStatsWrapperStyle
          statsDetailsData={interactionsStats}
          posts={commentsInteractions}
          colors={["#2AAAE2", "#80868C"]}
          labels={["likes", "retweet"]}
          showZoomPercentage
          iconAfterTitle={props.twitterIcon}
          postsColumnPreLoaderDataSources={commentsInteractionsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          hideLegend
          dataPiechart={commentsOverTime}
        />
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <PieChartWidget
              title="sentiment_analysis"
              showDownloadIcon
              showAI
              color={["#8CC63F", "#ED1846", "#F5B819"]}
              data={sentimentAnalysis}
              pieChartPreLoaderDataSources={sentimentAnalysisPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
          <Grid item xs={6}>
            <PieChartWidget
              title={"top_languages"}
              data={topLanguages}
              areaStyle={true}
              toolTipLabel={"toolTipLabel"}
              showDownloadIcon
              showAI
              pieChartPreLoaderDataSources={topLanguagesPreLoader}
              preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
              iconAfterTitle={props.twitterIcon}
            />
          </Grid>
        </Grid>
      </Box>
      {inspectorName !== "top_keywords" && (
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"comments_top_keywords"}
                data={topKeywords}
                leftColomnTitle={"keywords"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topKeywordsPreLoader}
                preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
                keywords
                tablePagination
                showDownloadIcon
                count={topKeywords.length}
                iconAfterTitle={props.twitterIcon}
                withWordCloud
              />
            </Grid>
            <Grid item xs={6}>
              <TableWidget
                wordsList
                title={"comments_top_hashtags"}
                data={topHashtags}
                leftColomnTitle={"hashtags"}
                rightColomnTitle={"number_of_hashtags"}
                tableChartPreLoaderDataSources={topHashtagsPreLoader}
                preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
                hashtags
                tablePagination
                showDownloadIcon
                iconAfterTitle={props.twitterIcon}
                count={topHashtags.length}
                withWordCloud
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box mt={3}>
        <Box className="comments-title">
          {CheckValueLocale(
            quickInspectKeywordMonitor ? "top_posts" : "top_comments",
            "",
            {},
            intl,
          )}
          {props.twitterIcon}
        </Box>
        <QuestionCard
          latestUnanswer={topComments}
          latestUnanswerPreloader={topCommentsPreLoader}
          preLoaderTrackerDataSources={props.preLoaderTrackerDataSources}
          icon={xPlatform}
          iconColor={"twitter_icon"}
          showRetweet={true}
          showBanIcon={true}
          showScreenName={true}
          quickInspectClass={"quick-inspect-grid"}
          showDefaultImg
          singleTweetAnalysis={props.singleTweetAnalysis}
        />
      </Box>
    </>
  );
};

export default KeywordsHashtagModal;
