import { useQuery } from "@tanstack/react-query";
import ReportController from "services/controllers/reportController";

export const useGetNewsLetterReport = (reportDataId) => {
  return useQuery({
    queryKey: ["newsLetterReport", reportDataId],
    queryFn: () =>
      ReportController.getNewsLetterReport({ report_data_id: reportDataId }),
    enabled: !!reportDataId,
    select: (data) => data?.data,
    retry: false,
  });
};
