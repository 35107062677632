import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// Stacked bar Chart
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import StackedBarChart from "../echarts/StackedBarChart";
//import icons
import NoDataFound from "components/no-Data/noDataFound";
import "components/widgets/styles/stackedBarWidget.scss";
import WidgetHeader from "components/widgets/widgetHeader";
import moment from "moment";
import ChartDetailsWidget from "./customStatisctics/chartDetailsWidget";
import { CheckValueLocale, truncate } from "utils/helpers";
/*---------------------------------------------------------*/

const StackedBarWidget = (props) => {
  const intl = useIntl();
  var {
    posts,
    showZoomPercentage,
    colors,
    labels,
    dataPiechart,
    hideLegend, // pass this prop to hide chart default legend, and use static one
    isGenderAgeSorted,
    isInteractionStatsWrapperStyle,
    isQuickExplore,
    showLogarithmic,
    hidePercentage,
  } = props;

  var logLabels = [],
    categories = [],
    greaterThanHundred = false,
    sliderPercentage = 0;

  let values = [];

  for (let i = 0; i < labels?.length; i++) {
    values.push([]);
  }

  const [chart, setChart] = useState({
    values: [],
    // labels: [],
    barName: [],
    legends: [],
  });

  let totalSentiment = dataPiechart?.reduce(function (prev, current) {
    return prev + +current.value;
  }, 0);

  const [dataForChart, setDataForChart] = useState(posts);
  const [duration, setDuration] = useState();
  const [scaleType, setScaleType] = useState();

  //here this state is used for handle custom legends..

  const [dataValue, setDataValue] = useState([]);
  /*---------------------------------------------------------*/
  const getChartMeta = (data, duration) => {
    setDataForChart(data);
    setDuration(duration);
  };
  const getChartScales = (scale) => {
    setScaleType(scale);
  };
  useEffect(() => {
    getChartMeta(posts);
  }, [posts]);

  /*---------------------------------------------------------*/
  //this function is used for handle DataChart Value
  const handleDataChartValue = (e, value) => {
    const arr = [...dataValue];
    const arrIndex = arr.indexOf(
      CheckValueLocale(value.toLowerCase(), "", {}, intl),
    );
    if (arrIndex === -1) {
      arr.push(CheckValueLocale(value.toLowerCase(), "", {}, intl));
    } else {
      arr.splice(arrIndex, 1);
    }
    setDataValue(arr);
  };

  //reorder gender age widget
  isGenderAgeSorted &&
    posts &&
    posts?.sort((a, b) =>
      a.name?.split("_")[0] > b.name?.split("_")[0] ? 1 : -1,
    );

  if (posts !== undefined) {
    posts?.forEach(function (post) {
      let interactions = post?.value;
      //this is for showing functionality of custom legends like eCharts legends
      for (let i = 0; i < values?.length; i++) {
        dataPiechart && dataPiechart?.[i]?.name && props?.hideLegend
          ? dataValue?.indexOf(
              CheckValueLocale(dataPiechart?.[i]?.name, "", {}, intl),
            ) === -1
            ? values[i]?.push(parseInt(interactions?.[i], 10))
            : (values[i] = [])
          : values[i]?.push(parseInt(interactions?.[i], 10));
      }

      props?.isComparison
        ? categories?.push(
            props?.isControlWidget
              ? dataForChart?.length < 4
                ? truncate(post?.name, 12)
                : dataForChart?.length < 6
                  ? truncate(post?.name, 5)
                  : truncate(post?.name, 2)
              : post?.name,
          )
        : props?.reachFunnel || props?.isGenderAgeSorted
          ? categories.push(CheckValueLocale(`${post.name}`, "", {}, intl))
          : moment(post.name, "MM/DD/YYYY", true).isValid()
            ? categories.push(post.name)
            : props.xAxisValue
              ? categories.push(CheckValueLocale(`${post.name}`, "", {}, intl))
              : categories.push(
                  moment.unix(post.name).utc().format("YYYY-MM-DD"),
                );
    });
  }
  if (posts !== undefined) {
    labels?.forEach(function (post) {
      logLabels.push(
        CheckValueLocale(`${isQuickExplore ? post?.name : post}`, "", {}, intl),
      );
    });
  }
  var totalLabel = CheckValueLocale("total_interactions", "", {}, intl);

  if (posts !== undefined && posts.length > 30) {
    sliderPercentage = Math.floor(100 - (30 / posts.length) * 100);
  }
  /*---------------------------------------------------------*/
  if (posts?.some((el) => el > 100)) {
    greaterThanHundred = true;
  } else {
    greaterThanHundred = false;
  }
  /*---------------------------------------------------------*/

  return (
    <div
      className="postsColumn_chart"
      id={
        props?.isCustomDashboard
          ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
          : props?.title
      }
    >
      <WidgetHeader
        title={props?.title}
        showDownloadIcon={props?.showDownloadIcon}
        showAI={props?.showAI}
        chartId={
          props?.isCustomDashboard
            ? `${props?.title}-_-${props?.customDashboardData?.widget?.id || ""}`
            : props?.title
        }
        bigImg={props?.bigImg}
        titleToolTip={props?.titleToolTip}
        iconAfterTitle={props?.iconAfterTitle}
        showGroupBy={props?.showGroupBy}
        showScales={props?.showScales}
        data={posts}
        getChartMeta={getChartMeta}
        getChartScales={getChartScales}
        nestedLoop={props?.nestedLoop}
        // below 4 props is used to display static labels, to follow design
        dataPiechart={dataPiechart}
        totalSentiment={totalSentiment}
        hideLegend={hideLegend}
        labelColors={colors}
        dataPieChartValue={dataValue}
        handleDataChartValue={handleDataChartValue}
        showReportIcon={props?.showReportIcon}
        dataReached={_.isEqual(
          props?.postsColumnPreLoaderDataSources?.sort(),
          props?.preLoaderTrackerDataSources?.sort(),
        )}
        isLinkedInPage={props?.isLinkedInPage}
        showNotAppliedFilter={props.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard} // To identify that widget will be rendered in Custom Dashboard
        isControlWidget={props?.isControlWidget} // To identify that widget will be rendered in Custom Dashboard control widget sidebar
        customDashboardData={props?.customDashboardData} // The needed data in widget component for case of Custom Dashboard only
        // tabs component to show authors, potential_reach, potential_impression
        comparisonReachFunnel={{
          showTabs: props?.reachFunnel && props?.isComparison,
          ...props?.comparisonReachFunnel,
        }}
        hidePercentage={hidePercentage}
      />

      {_.isEqual(
        props?.postsColumnPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : dataForChart?.every((item) => item?.value?.every((el) => el == 0)) ? (
        <NoDataFound />
      ) : (
        <>
          {/* THREE BOXS OF Total Followers & Max Change of Followers & Avg. Interaction per Day */}
          {props.showChartDetailsWidget && (
            <ChartDetailsWidget
              statsDetailsData={props?.statsDetailsData}
              isInteractionStatsWrapperStyle={
                props?.isInteractionStatsWrapperStyle
              }
            />
          )}
          <Box>
            <StackedBarChart
              dataLabels={
                props?.isComparison
                  ? categories?.map((label, index) => label + "-" + index) // add "-" and index to can display datasource image
                  : categories
              }
              dataValues={values}
              colors={colors}
              dataNames={logLabels}
              totalLabel={totalLabel}
              sliderPercentage={sliderPercentage}
              showZoomPercentage={showZoomPercentage}
              showDownloadIcon
              showLogChart={showLogarithmic ? true : scaleType == "logarithmic"} // showLogarithmic is a prop you can pass to show log chart
              reachFunnel={props.reachFunnel} // prop for reach funnel only to handle left [ Yaxis ] label
              hideLabels={props.hideLabels}
              greaterThanHundred={greaterThanHundred}
              hideLegend={props.hideLegend === undefined ? true : false}
              legendPosition={
                window.localStorage.lang === "ar" ? "topRight" : "topLeft"
              }
              isControlWidget={props?.isControlWidget}
              listDataSourceIcon={
                props?.isComparison
                  ? posts?.map((post) => post?.dataSource)
                  : []
              }
              isComparison={props?.isComparison}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default StackedBarWidget;
