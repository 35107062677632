import { Box } from "@mui/material";
import CardStatistic from "./cardStatistic";
import { handleDateLangFormat, CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { ReportContext } from "../../context/reportContext";
import { dateHourFormat } from "pages/reportsNew/utils/formatDateUtlit";

const StatisticReport = () => {
  const intl = useIntl();
  const { reportData } = useContext(ReportContext);
  const { articlesCount, reportType, startDate, endDate, isUnixDate } =
    reportData;
  return (
    <Box className="view-report-card">
      <CardStatistic
        title={CheckValueLocale("number_of_articles", "", {}, intl)}
        value={`${articlesCount || 0} ${CheckValueLocale("article", "", {}, intl)}${articlesCount > 1 && intl?.locale === "en" ? "s" : ""}`}
      />
      <CardStatistic
        title={CheckValueLocale("frequency", "", {}, intl)}
        value={CheckValueLocale(reportType?.toLowerCase(), "", {}, intl)}
      />
      <CardStatistic
        title={CheckValueLocale("date_period", "", {}, intl)}
        value={`${dateHourFormat(
          startDate,
          intl?.locale,
          isUnixDate,
        )} ${CheckValueLocale("to_lang", "", {}, intl)} ${dateHourFormat(
          endDate,
          intl?.locale,
          isUnixDate,
        )} `}
      />
    </Box>
  );
};

export default StatisticReport;
