import { useState, useEffect } from "react";
import { Box, IconButton, Tooltip, Divider, Popover } from "@mui/material";
import "./postItem.scss";
import {
  faChartColumn,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faRetweet,
  faHeart,
  faComments,
  faPlay,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import { faBadgeCheck, faCommentCheck } from "@fortawesome/pro-solid-svg-icons";
import dummyImage from "images/monitor_revamp/user.svg";
import PostSentimentItem from "./postSentimentItem";
import {
  CheckValueLocale,
  checkIfWeNeedShowMoreTweet,
  handleSubThemes,
  handleThemes,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import DOMPurify from "dompurify";

import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import ReplyModal from "../../replyModal/replyModal";
import SnackBar from "components/snackBar.js";
import CustomizedTooltip from "components/customizedTooltip";
import { MediaPreviewer } from "pages/engagements/components/mediaPreviewer/mediaPreviewer";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";
import ManageUserAccountListsDialog from "pages/accountsLists/components/ManageUserAccountListsDialog/manageUserAccountListDialog";
import { handleClassNameForMediaPreview } from "../../questionCard/cardBody";

const PostItem = (props) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);
  const [text, setText] = useState("");
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [defaultMediaIndex, setDefaultMediaIndex] = useState(0);
  const [media, setMedia] = useState([]);
  const [tweetText, setTweetText] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [manageUserAccountListOpenDialog, setManageUserAccountListOpenDialog] =
    useState(false);

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
    setAnchorEl(null);
  };

  //check if user image is broken or not & replace it with dummyImage
  const handleImageError = (event) => {
    event.target.src = dummyImage;
    event.target.alt = "placeholder-image";
  };

  //check if text contain Arabic character or not to handle [ RTL, LTR ]
  let arabic = /[\u0600-\u06FF]/;
  let arabicText = props?.post?.tweet_text; // some Arabic string from Wikipedia
  let checkText = arabic.test(arabicText);

  /* localizedDate____________________________________________________________________________________ */
  const localizedDate =
    window.localStorage.lang === "en"
      ? moment.unix(props?.post?.created_at).utc().format("D MMM - YYYY h:mm A")
      : moment
          .unix(props?.post?.created_at)
          .utc()
          .format("D MMM - YYYY h:mm A")
          .split(" ")[0] +
        " " +
        CheckValueLocale(
          moment
            .unix(props?.post?.created_at)
            .utc()
            .format("D MMM - YYYY h:mm A")
            .split(" ")[1]
            .toLocaleLowerCase(),
          "",
          {},
          intl,
        ) +
        ", " +
        moment
          .unix(props?.post?.created_at)
          .utc()
          .format("D MMM - YYYY h:mm A")
          .split(" ")[3];

  const localizedTime =
    window.localStorage.lang === "en"
      ? ""
      : moment
          ?.unix(props?.post?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[4] +
        " " +
        moment
          ?.unix(props?.post?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[5];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  //Handle click with Media Preivew
  const handleMediaClick = (mediaArray, indexItem = 0) => {
    if (mediaArray?.length > 0) {
      setShowMediaPreviewer(true);
      const formattedMedia = mediaArray?.map((item) => ({
        url: item?.type === "photo" ? item?.media_url : item?.expanded_url,
        type: item?.type === "photo" ? "image" : "video",
        img_preview: item?.media_url,
      }));
      setMedia(formattedMedia);
    }
    setDefaultMediaIndex(indexItem);
  };

  const quotedPostMedia =
    props?.post?.quoted_status?.extended_tweet?.entities?.media ||
    props?.post?.quoted_status?.extended_tweet?.extended_entities?.media;

  return (
    <Box className="post-item">
      <Box className="post-head">
        <Box className="post-title-date">
          <Box className="post-user-img">
            <img
              className="post-img"
              src={props?.post?.user_profile_image_url}
              alt=""
              onError={handleImageError}
            />
            <Box className="post-icon">
              {
                props?.iconAfterTitle && props?.iconAfterTitle
                //pass tag from previous file with its styling
                //ex: iconAfterTitle={  <InstagramIcon  sx={{ position: "relative", top: "5px", left: "5px" }} />}
              }
            </Box>
          </Box>

          <Box className="post_title">
            <Box className="display-in-row gap-between-five gab-title">
              {/* <FontAwesomeIcon icon={faInstagram} className="instagram_icon" solid /> */}
              {/* <FontAwesomeIcon icon={faFacebook} className="facebook_icon" solid /> */}
              {/* @{props?.post.screen_name} */}
              <Box component="span" className="screen-name">
                {props?.post?.user_name?.length > 15 ? (
                  <Tooltip title={props?.post?.user_name} placement="top" arrow>
                    <div>
                      {props?.post?.user_name?.toString()?.slice(0, 15) + ".."}
                    </div>
                  </Tooltip>
                ) : (
                  props?.post?.user_name
                )}
                {props?.post?.verified !== "false" && (
                  <FontAwesomeIcon
                    className="check-icon"
                    icon={faBadgeCheck}
                    color="#1D9BF0"
                  />
                )}
              </Box>
              <Box component="span" className="user-at-name">
                @{props?.post?.screen_name}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="post-top-icons">
          {/* Zendesk Icon __________________________________________________*/}
          {/* <img src={zVector} alt="" /> */}
          {/* analyze_tweet icon_____________________________________________*/}
          <Tooltip
            title={
              <Box className="tooltip-custom-new">
                {props?.singleTweetAnalysis
                  ? CheckValueLocale("analyze_tweet", "", {}, intl)
                  : CheckValueLocale("analyze_tweet_upgrade", "", {}, intl)}
              </Box>
            }
            arrow
            placement="bottom"
            componentsProps={{
              tooltip: {
                sx: {
                  height: "40px",
                  padding: "0px",
                },
              },
            }}
          >
            <Box
              onClick={() =>
                !props?.singleTweetAnalysis
                  ? false
                  : props?.updateAnalysis(
                      props?.post?.tweet_id,
                      props?.post?.screen_name,
                      props?.post?.tracker_id,
                      props?.post?.created_at,
                    )
              }
              className="top-posts-header-icon"
            >
              <FontAwesomeIcon icon={faChartColumn} solid />
            </Box>
          </Tooltip>
          {/* open link icon_____________________________________________*/}
          {handlelUserRoles("SM", "CHANGE_POST_ATTRIBUTES") ? (
            <CustomizedTooltip
              title={
                <Box className="tooltip-custom-new">
                  {CheckValueLocale("manage_lists", "", {}, intl)}
                </Box>
              }
              arrow
              placement="bottom"
            >
              <Box
                className="top-posts-header-icon"
                onClick={() => setManageUserAccountListOpenDialog(true)}
              >
                <FontAwesomeIcon icon={faSliders} />
              </Box>
            </CustomizedTooltip>
          ) : null}
          <CustomizedTooltip
            title={
              <Box className="tooltip-custom-new">
                {CheckValueLocale("external_media_tooltip", "", {}, intl)}
              </Box>
            }
            arrow
            placement="bottom"
          >
            {props?.instagramAccount == true ? (
              <a
                href={`${props?.post?.post_link}`}
                target="_blank"
                rel="noreferrer"
              >
                <Box className="top-posts-header-icon">
                  <FontAwesomeIcon icon={faExternalLink} solid />
                </Box>
              </a>
            ) : (
              <a
                href={`${props?.post?.tweet_link}`}
                target="_blank"
                rel="noreferrer"
              >
                <Box className="top-posts-header-icon">
                  <FontAwesomeIcon icon={faExternalLink} solid />
                </Box>
              </a>
            )}
          </CustomizedTooltip>
          {props?.monitorDataSource !== "NEWSBLOGS" &&
            handlelUserRoles("ENGAGEMENT_ACCESS", "SL_ENGAGEMENT") &&
            retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
            props?.allawedTrackers?.includes(+props?.post?.tracker_id) && (
              <>
                <Divider orientation="vertical" className="reply-divider" />
                {!showSuccess &&
                (props?.post?.system_replies?.is_replied === undefined ||
                  props?.post?.system_replies?.is_replied === 0) ? (
                  <div
                    className={
                      open
                        ? "display-in-row reply-button reply-button-active"
                        : "display-in-row reply-button"
                    }
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      className="reply-icon"
                    />
                    <span>{CheckValueLocale("reply", "", {}, intl)}</span>
                  </div>
                ) : (
                  <div
                    className={
                      open
                        ? "display-in-row replied-button replied-button-active"
                        : "display-in-row replied-button"
                    }
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <FontAwesomeIcon
                      icon={faCommentCheck}
                      className="replied-icon"
                    />
                    <span>{CheckValueLocale("replied", "", {}, intl)}</span>
                  </div>
                )}
              </>
            )}
          <Popover
            anchorOrigin={anchorPosition}
            transformOrigin={transformPosition}
            onClose={handleClose}
            id={id}
            open={open}
            anchorEl={anchorEl}
            className="reply-modal-popover"
          >
            <ReplyModal
              question={props?.post}
              setShowSuccessModal={setShowSuccess}
              showSuccessModal={showSuccess}
              replyModalData={props?.replyModalData}
              index={props?.index}
              setShowErrorSnackBar={setShowErrorSnackBar}
              setAnchorEl={setAnchorEl}
              setText={setText}
              text={text}
            />
          </Popover>
          {/* _____________________________________________*/}
        </Box>
      </Box>
      {/* // ___________________________________________________________ */}
      {showMediaPreviewer ? (
        <MediaPreviewer
          media={media}
          postText={tweetText}
          showMediaPreviewer={showMediaPreviewer}
          setShowMediaPreviewer={setShowMediaPreviewer}
          defaultIndex={defaultMediaIndex} // default is 0
          monitorDataSource={props?.monitorDataSource}
        />
      ) : null}
      <Box
        className={
          props?.post?.retweeted_status !== null &&
          props?.post?.quoted_status !== null
            ? "post-content"
            : "post-content-wrapper"
        }
      >
        <Box className={`${props?.post?.media !== null ? "margin-style" : ""}`}>
          {props?.post?.reply_to &&
            props?.question?.retweeted_status == null &&
            props?.question?.quoted_status == null && (
              <Box className="reply-text">
                <Box style={{ color: "#80868c" }}>
                  {CheckValueLocale("reply_to", "", {}, intl)}
                </Box>
                <Box className="reply-text-style">@{props?.post?.reply_to}</Box>
              </Box>
            )}
          {/* Main Case --> tweetText & image without Retweet || Quote */}
          <Box
            className={`${
              checkText ? "tweetTextRtl" : "post-tweet-text-default"
            }`}
          >
            {props?.post?.retweeted_status == null &&
              props?.post?.quoted_status == null && (
                <Box>
                  <Box
                    component="span"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props?.post?.tweet_text),
                    }}
                  />
                  {checkIfWeNeedShowMoreTweet(
                    props?.post?.tweet_text,
                    props?.post?.tweet_full_text,
                  ) ? (
                    <Box
                      component="span"
                      className="tweet-full-text-read-all-btn"
                      onClick={() => setShowFullTextTweet(true)}
                    >
                      {CheckValueLocale("read_all", "", {}, intl)}
                    </Box>
                  ) : null}
                </Box>
              )}
            {props?.post?.media !== null &&
              !props?.post?.retweeted_status?.extended_entities?.media &&
              !props?.post?.quoted_status?.extended_tweet?.entities?.media &&
              (props?.post?.media ? (
                <div
                  className={handleClassNameForMediaPreview(
                    props?.post?.media?.length,
                  )}
                >
                  {Object.keys(props?.post?.media)
                    ?.slice(0, 4)
                    ?.map((key, index) => {
                      const image = props?.post?.media[key];
                      const mediaArray = Object.values(props?.post?.media);
                      return (
                        <div
                          className="image-container-card-body"
                          key={image?.id}
                          onClick={() => {
                            handleMediaClick(mediaArray, index);
                            setTweetText(props?.post?.tweet_text);
                          }}
                        >
                          {image?.type === "video" ||
                          image?.type === "animated_gif" ? (
                            <Box component={"span"} className="video-icon">
                              <FontAwesomeIcon icon={faPlay} />
                            </Box>
                          ) : null}
                          <img src={image?.media_url_https} alt="" />
                          {index === 3 && props?.post?.media?.length > 4 ? (
                            <Box className="image-container-card-body-overlay">
                              +{props?.post?.media?.length - 4}
                            </Box>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              ) : null)}
          </Box>
        </Box>
        {/* // ______________Quoted Case_____________________________________________ */}
        <Box
          className={`${
            checkText ? "tweetTextRtl" : "post-tweet-text-default"
          }`}
        >
          {props?.post?.quoted_status &&
            props?.monitorDataSource === "TWITTER" && (
              <Box>
                <Box component="span">{props?.post?.tweet_text}</Box>
                {checkIfWeNeedShowMoreTweet(
                  props?.post?.tweet_text,
                  props?.post?.tweet_full_text,
                ) ? (
                  <Box
                    component="span"
                    className="tweet-full-text-read-all-btn"
                    onClick={() => setShowFullTextTweet(true)}
                  >
                    {CheckValueLocale("read_all", "", {}, intl)}
                  </Box>
                ) : null}
              </Box>
            )}
        </Box>
        {props?.post?.quoted_status !== null && (
          <Box className="retweeted-quoted-wrapper">
            <Box className="retweeted-quoted-name-wrapper">
              <Box className="retweeted-quoted-user-name">
                {props?.post?.quoted_status?.user?.name}
              </Box>
              <Box className="retweeted-quoted-screen-name">
                @{props?.post?.quoted_status?.user?.screen_name}
              </Box>
            </Box>
            <Box className="retweeted-quote-text">
              {quotedPostMedia !== undefined ? (
                <Box
                  className="retweeted-quote-img"
                  onClick={() => {
                    handleMediaClick([quotedPostMedia?.[0]]);
                    setTweetText(
                      props?.post?.quoted_status !== null &&
                        props?.post?.quoted_status?.extended_tweet
                          ?.full_text !== undefined
                        ? props?.post?.quoted_status?.extended_tweet?.full_text
                        : props?.post?.quoted_status?.text,
                    );
                  }}
                >
                  <img
                    src={quotedPostMedia?.[0]?.media_url_https}
                    alt=""
                    className="customer_media"
                  />
                </Box>
              ) : null}
              <Box>
                {props?.post?.quoted_status !== null &&
                props?.post?.quoted_status?.extended_tweet?.full_text !==
                  undefined
                  ? props?.post?.quoted_status?.extended_tweet?.full_text
                  : props?.post?.quoted_status?.text}
              </Box>
            </Box>
          </Box>
        )}
        {/* // ______________Retweeted Case_____________________________________________ */}
        <Box
          className={`${
            checkText ? "tweetTextRtl" : "post-tweet-text-default"
          }`}
        >
          {props?.post?.retweeted_status &&
            props?.monitorDataSource === "TWITTER" && (
              <Box>
                <Box component="span">{props?.post?.tweet_text}</Box>
                {checkIfWeNeedShowMoreTweet(
                  props?.post?.tweet_text,
                  props?.post?.tweet_full_text,
                ) ? (
                  <Box
                    component="span"
                    className="tweet-full-text-read-all-btn"
                    onClick={() => setShowFullTextTweet(true)}
                  >
                    {CheckValueLocale("read_all", "", {}, intl)}
                  </Box>
                ) : null}
              </Box>
            )}
        </Box>
        {props?.post?.retweeted_status !== null && (
          <Box className="retweeted-quoted-wrapper">
            <Box className="retweeted-quoted-name-wrapper">
              <Box className="retweeted-quoted-user-name">
                {props?.post?.retweeted_status?.user?.name}
              </Box>
              <Box className="retweeted-quoted-screen-name">
                @{props?.post?.retweeted_status?.user?.screen_name}
              </Box>
            </Box>
            <Box className="retweeted-quote-text">
              {props?.post?.retweeted_status?.extended_entities?.media !==
                undefined && (
                <Box
                  className="retweeted-quote-img"
                  onClick={() => {
                    handleMediaClick([
                      props?.post?.retweeted_status?.extended_entities
                        ?.media?.[0],
                    ]);
                    setTweetText(
                      props?.post?.retweeted_status !== null &&
                        props?.post?.retweeted_status?.extended_tweet
                          ?.full_text !== undefined
                        ? props?.post?.retweeted_status?.extended_tweet
                            ?.full_text
                        : props?.post?.retweeted_status?.text,
                    );
                  }}
                >
                  <img
                    src={
                      props?.post?.retweeted_status?.extended_entities?.media[0]
                        ?.media_url_https
                    }
                    alt=""
                    className="customer_media"
                  />
                </Box>
              )}

              {props?.post?.retweeted_status !== null &&
              props?.post?.retweeted_status?.extended_tweet?.full_text !==
                undefined
                ? props?.post?.retweeted_status?.extended_tweet?.full_text
                : props?.post?.retweeted_status?.text}
            </Box>
          </Box>
        )}
        {/* // ______________Reply Case_____________________________________________ */}
        {props?.post?.reply_status !== null && (
          <Box className="retweeted-quoted-wrapper">
            <Box className="retweeted-quoted-name-wrapper">
              <Box className="retweeted-quoted-user-name">
                {props?.post?.reply_status?.user?.name}
              </Box>
              <Box className="retweeted-quoted-screen-name">
                @{props?.post?.reply_status?.user?.screen_name}
              </Box>
            </Box>
            <Box className="retweeted-quote-text">
              {props?.post?.reply_status?.extended_tweet?.media !==
                undefined && (
                <Box
                  className="retweeted-quote-img"
                  onClick={() => {
                    handleMediaClick([
                      props?.post?.reply_status?.extended_entities?.media?.[0],
                    ]);
                    setTweetText(
                      props?.post?.reply_status !== null &&
                        props?.post?.reply_status?.extended_tweet?.full_text !==
                          undefined
                        ? props?.post?.reply_status?.extended_tweet?.full_text
                        : props?.post?.reply_status?.text,
                    );
                  }}
                >
                  <img
                    src={
                      props?.post?.reply_status?.extended_tweet?.media[0]
                        ?.media_url_https
                    }
                    alt=""
                    className="customer_media"
                  />
                </Box>
              )}

              {props?.post?.reply_status !== null &&
              props?.post?.reply_status?.extended_tweet?.full_text !== undefined
                ? props?.post?.reply_status?.extended_tweet?.full_text
                : props?.post?.reply_status?.text}
            </Box>
          </Box>
        )}
      </Box>
      {/* // ___________________________________________________________ */}
      <Box className="post-item-footer">
        <Box className="post-sentiment">
          <Box className="statistics-icons">
            <Box>
              <FontAwesomeIcon icon={faHeart} />
              {props?.post?.heart_count}
            </Box>
            {props?.instagramAccount !== true && (
              <Box>
                <FontAwesomeIcon icon={faRetweet} />
                {props?.post?.retweet_count}
              </Box>
            )}
            <Box>
              <FontAwesomeIcon icon={faComments} />
              {props?.post?.comments_count}
            </Box>
          </Box>
          {localizedDate ? (
            <Divider orientation="vertical" className="footer-divider" />
          ) : null}
          <Box className="display-in-row date-row">
            <Box className="post-date ltr-direction">{localizedDate}</Box>
            <Box className="post-time ltr-direction">{localizedTime}</Box>
          </Box>
          {props?.hideChangeSentiment === undefined ? (
            <PostSentimentItem
              question={props?.post}
              monitorDataSource={props?.monitorDataSource}
              monitorId={props?.monitorId}
            />
          ) : null}
        </Box>
        <Box className="question-theme">
          <>
            {props?.post?.themes && props?.post?.themes?.length > 0 ? (
              <Box className="theme-box">
                <span className="theme-heaading">
                  {" "}
                  {CheckValueLocale("themes", "", {}, intl)}{" "}
                </span>
                {props?.post?.themes?.map((theme) => (
                  <Box className="main-box-color">
                    <Box
                      style={{
                        backgroundColor: handleThemes(theme),
                      }}
                      className="box-color color-theme"
                    ></Box>
                    <span className="theme-text">
                      {CheckValueLocale(theme, "", {}, intl)}
                    </span>
                  </Box>
                ))}
              </Box>
            ) : null}
            {props?.post?.sub_themes && props?.post?.sub_themes?.length > 0 ? (
              <Box className="theme-box">
                <span className="theme-heaading">
                  {" "}
                  {CheckValueLocale("sub_themes", "", {}, intl)}{" "}
                </span>
                {props?.post?.sub_themes?.map((sub_themes) => (
                  <Box className="main-box-color">
                    <Box
                      style={{
                        backgroundColor: handleSubThemes(sub_themes),
                      }}
                      className="box-color color-theme"
                    ></Box>
                    <span className="theme-text">
                      {CheckValueLocale(sub_themes, "", {}, intl)}
                    </span>
                  </Box>
                ))}
              </Box>
            ) : null}
          </>
        </Box>
      </Box>

      {showErrorSnackBar && (
        <SnackBar
          open={showErrorSnackBar}
          autoHideDuration={5000}
          severity="error"
          message={CheckValueLocale("please_try_again", "", {}, intl)}
          title={CheckValueLocale("wrong_request_parameter", "", {}, intl)}
          handleClose={handleCloseRepliedSnack}
        />
      )}
      {showFullTextTweet ? (
        <FullTextTweetPopUp
          closeFilter={() => setShowFullTextTweet(false)}
          post={props?.post}
          showRetweet={props?.instagramAccount !== true}
          showHeart={true}
          ShowComments={true}
        />
      ) : null}

      <ManageUserAccountListsDialog
        openDialog={manageUserAccountListOpenDialog}
        setOpenDialog={setManageUserAccountListOpenDialog}
        post={props?.post}
      />
    </Box>
  );
};

export default PostItem;
